import { Arc, BuildingPart, DOORTYPE, Door, Line, Pointer, Segment, SegmentClass, TileWrapper } from 'src/model';
import Layout from 'src/model/tile/Layout';
import { DEFAULT_LAYOUT_SIZE, createRealizedLayoutFromGeometry, emptyLayout } from './drawMachine';
import Shape from 'src/model/tile/Shape';
import { getShapeBoundingRect, isDoor } from 'src/helpers';
import { TILE_SCALE, TILE_TRANSFORM_SCALE } from 'src/global/variable';
import { ClosedArea } from 'src/model/ClosedArea';
import { getLayoutGeometry, getTile } from "src/services/api";
import { drawSend } from "../layout.store";
import Matrix2D from "src/model/tile/Matrix2D";
import { cachedUserLayouts } from 'src/store';
import { get } from "svelte/store";
const isTileWrapper = (seg) => seg.class === SegmentClass.TILE_WRAPPER || seg.class === SegmentClass.CLOSED_AREA;
export default class StoreSerializer {
    static cachedSegments = [];
    static cachedLayoutGeometries = new Map();
    static cachedTiles = new Map();
    static asyncSegments = new Map();
    static asyncUserLayouts = new Map();
    static addAsyncSegmentPromise(id, promise, isLayoutGeometry = false) {
        let find = this.asyncSegments.get(id);
        if (find === undefined) {
            this.asyncSegments.set(id, {
                layoutGeometry: isLayoutGeometry ? promise : undefined,
                tiles: isLayoutGeometry ? [] : [promise],
            });
        }
        else {
            if (isLayoutGeometry)
                find.layoutGeometry = promise;
            // else if (find.tiles === undefined)
            //     find.tiles = [promise];
            else
                find.tiles.push(promise);
        }
    }
    static addAsyncUserLayoutPromise(id, promise, isLayoutGeometry = false) {
        let find = this.asyncUserLayouts.get(id);
        if (find === undefined) {
            this.asyncUserLayouts.set(id, {
                layoutGeometry: isLayoutGeometry ? promise : undefined,
                tiles: isLayoutGeometry ? [] : [promise],
            });
        }
        else {
            if (isLayoutGeometry)
                find.layoutGeometry = promise;
            // else if (find.tiles === undefined)
            //     find.tiles = [promise];
            else
                find.tiles.push(promise);
        }
    }
    static loadAsyncSegments(layoutGeometries, baseShapes, onError) {
        let allPromises = [];
        this.asyncSegments.forEach((promises, segmentId) => {
            allPromises.push(promises.layoutGeometry);
            allPromises.push(...promises.tiles);
            Promise.allSettled([promises.layoutGeometry, ...promises.tiles]).then(results => {
                const cachedSegment = this.createSegmentByType({ id: segmentId }, layoutGeometries, baseShapes);
                let layoutGeometryPromise = results[0];
                let tilesPromises = results.slice(1);
                let layoutGeometryId = cachedSegment.layoutGeometryId;
                if (promises.layoutGeometry !== undefined) {
                    if (layoutGeometryPromise.status === "rejected" || !layoutGeometryPromise.value || !layoutGeometryPromise.value?.id)
                        return;
                    layoutGeometryId = layoutGeometryPromise.value?.id;
                }
                let layoutGeometry = layoutGeometries.find((geometry) => geometry.id === layoutGeometryId);
                // if (!!e.savedGeometryLayoutId && !!tileWrapper)
                // {
                //   tileWrapper.layoutGeometryId = e.savedGeometryLayoutId;
                //   tileWrapper.overrideAspectRatio = [];
                //   tileWrapper.updateLayout = true;
                // }
                if (!layoutGeometry)
                    return;
                const boundingRect = getShapeBoundingRect(cachedSegment.shape, cachedSegment.rotation);
                tilesPromises.forEach((e) => {
                    let overrideAspectRatio = cachedSegment.tileLayout.overrideAspectRatio;
                    const tileId = e.status === "fulfilled" ? e.value?.tileId : e.reason?.tile;
                    if (tileId === undefined)
                        return;
                    let idxOverride = overrideAspectRatio?.findIndex(oar => oar.tile === tileId);
                    if (idxOverride !== undefined && idxOverride > -1) {
                        if (e.status === "fulfilled") {
                            cachedSegment.tileLayout.overrideAspectRatio[idxOverride].tile = e.value.shape.clone();
                        }
                        else if (e.status === "rejected") {
                            cachedSegment.tileLayout.overrideAspectRatio.splice(idxOverride, 1);
                        }
                    }
                });
                if (tilesPromises.every(e => e.status === "fulfilled")) {
                    // at least one tile has been replaced
                    if (cachedSegment.tileLayout.overrideAspectRatio.length > 0) {
                        cachedSegment.tileLayout = createRealizedLayoutFromGeometry(layoutGeometry, baseShapes, undefined, cachedSegment.tileLayout).resize(boundingRect[2] / TILE_SCALE, boundingRect[3] / TILE_SCALE);
                    }
                    // no tile has been replaced, get textured tiles
                    else {
                        let shapes = [];
                        let texturedTiles = {};
                        tilesPromises.forEach(res => {
                            if (res.status === "rejected" || !res.value)
                                return;
                            shapes.push(res.value.shape);
                        });
                        cachedSegment.setLayoutInfo(layoutGeometryId, cachedSegment.tiles);
                        shapes.forEach(shape => {
                            let index = cachedSegment.tiles.indexOf(shape.tileId);
                            if (index > -1)
                                texturedTiles[index] = shape;
                        });
                        cachedSegment.tileLayout = createRealizedLayoutFromGeometry(layoutGeometry, baseShapes, texturedTiles).resize(boundingRect[2] / TILE_SCALE, boundingRect[3] / TILE_SCALE);
                    }
                }
                else {
                    cachedSegment.tileLayout = createRealizedLayoutFromGeometry(layoutGeometry, baseShapes).resize(boundingRect[2] / TILE_SCALE, boundingRect[3] / TILE_SCALE);
                }
                cachedSegment.updateLayout = true;
                console.log(`TileWrapper ${segmentId} loaded`);
            });
        });
        Promise.allSettled(allPromises).then(results => {
            this.asyncSegments = new Map();
            if (onError)
                onError({ finished: true });
            drawSend({ type: "LOADED_ASYNC_SEGMENTS" });
            console.log("All asynchronous segments loaded");
        });
    }
    static loadAsyncUserLayouts(layoutGeometries, baseShapes, onError) {
        let allPromises = [];
        this.asyncUserLayouts.forEach((promises, layoutId) => {
            allPromises.push(promises.layoutGeometry);
            allPromises.push(...promises.tiles);
            Promise.allSettled([promises.layoutGeometry, ...promises.tiles]).then(results => {
                let layoutGeometryPromise = results[0];
                let tilesPromises = results.slice(1);
                if (promises.layoutGeometry === undefined)
                    return;
                if (layoutGeometryPromise.status === "rejected" || !layoutGeometryPromise.value)
                    return;
                const layoutGeometry = layoutGeometryPromise.value;
                if (!layoutGeometry)
                    return;
                if (tilesPromises.every(e => e.status === "fulfilled")) {
                    let layout = createRealizedLayoutFromGeometry(layoutGeometry, baseShapes);
                    let texturedTiles = {};
                    tilesPromises.forEach((res) => {
                        if (!res.value)
                            return;
                        let result = res?.value;
                        texturedTiles[result.shapeIndex] = result.shape;
                        layout.calcOverrideAspectRatio(result.shape.clone(), result.shapeIndex, result.shape.width, result.shape.height, layoutGeometry, baseShapes);
                    });
                    layout = createRealizedLayoutFromGeometry(layoutGeometry, baseShapes, undefined, layout),
                        cachedUserLayouts.update(map => {
                            map.set(layoutId, layout);
                            return map;
                        });
                }
                console.log(`User layout ${layoutId} loaded`);
            });
        });
        Promise.allSettled(allPromises).then(results => {
            this.asyncUserLayouts = new Map();
            if (onError)
                onError({ finished: true });
            drawSend({ type: "LOADED_ASYNC_USER_LAYOUTS" });
            console.log("All asynchronous user layouts loaded");
        });
    }
    static createSegmentByType(segment, layoutGeometries, baseShapes, onError) {
        let result;
        const cached = this.cachedSegments.find((seg) => seg.id === segment.id);
        if (cached)
            return cached;
        switch (segment?.class) {
            case SegmentClass.CLOSED_AREA:
                const closedArea = segment;
                const newClosedArea = new ClosedArea(new Pointer(closedArea.startPointer.x, closedArea.startPointer.y), new Pointer(closedArea.endPointer.x, closedArea.endPointer.y), closedArea.id, closedArea.parentId, {
                    results: closedArea.shape.results.map((id) => this.createSegmentByType({ id }, layoutGeometries, baseShapes, onError)),
                    points: closedArea.shape.points.map((p) => new Pointer(p.x, p.y)),
                }, closedArea.zIndex, closedArea.name);
                newClosedArea.altName = closedArea.altName;
                result = newClosedArea;
                break;
            case SegmentClass.TILE_WRAPPER:
                const tileWrapper = segment;
                const layoutGeometryId = segment?.tileLayout.layoutGeometry;
                let layoutGeometry = layoutGeometries.find((geometry) => geometry.id === segment?.tileLayout.layoutGeometry);
                const newTileWrapper = new TileWrapper(new Pointer(tileWrapper.startPointer.x, tileWrapper.startPointer.y), new Pointer(tileWrapper.endPointer.x, tileWrapper.endPointer.y), tileWrapper.id, tileWrapper.closedAreaId, tileWrapper.parentId, {
                    results: tileWrapper.shape.results.map((id) => this.createSegmentByType({ id }, layoutGeometries, baseShapes, onError)),
                    points: tileWrapper.shape.points.map((p) => new Pointer(p.x, p.y)),
                }, layoutGeometry ? createRealizedLayoutFromGeometry(layoutGeometry, baseShapes) : emptyLayout, tileWrapper.rotation, new Pointer(tileWrapper.offset.x, tileWrapper.offset.y), undefined, tileWrapper.zIndex, tileWrapper.name, false);
                newTileWrapper.tileLayout = newTileWrapper.tileLayout.withGapSize(tileWrapper.tileLayout.gap);
                newTileWrapper.tileLayout.id = tileWrapper.tileLayout.id;
                newTileWrapper.tileLayout.overrideAspectRatio = tileWrapper.tileLayout.overrideAspectRatio;
                newTileWrapper.tileLayout.groutColor = tileWrapper.tileLayout.groutColor;
                newTileWrapper.altName = tileWrapper.altName;
                newTileWrapper.setLayoutInfo(segment?.tileLayout.layoutGeometry, segment?.tileLayout.tiles);
                const bounding = getShapeBoundingRect(newTileWrapper.shape, newTileWrapper.rotation);
                newTileWrapper.tileLayout = newTileWrapper.tileLayout.resize(bounding[2] / TILE_SCALE, bounding[3] / TILE_SCALE);
                result = newTileWrapper;
                const parentClosedArea = this.createSegmentByType({ id: tileWrapper.closedAreaId }, layoutGeometries, baseShapes, onError);
                if (parentClosedArea && onError)
                    parentClosedArea.addTileWrapper(newTileWrapper);
                if (!layoutGeometry && layoutGeometryId > 0) {
                    let cachedPromise = this.cachedLayoutGeometries.get(layoutGeometryId);
                    if (!cachedPromise) {
                        const promise = getLayoutGeometry(layoutGeometryId)
                            .then(layout => {
                            let isTileMissing = false;
                            layout.tile_shapes.forEach(tile => {
                                if (onError && !baseShapes.find((bs) => bs.tileId === tile)) {
                                    isTileMissing = true;
                                    onError({ tile: tile });
                                }
                            });
                            if (isTileMissing)
                                return;
                            layoutGeometry = {
                                ...layout,
                                tile_shapes: layout.tile_shapes.map((tileShapeId) => {
                                    const baseShape = baseShapes.find((v) => v.shapeId === tileShapeId);
                                    return {
                                        id: tileShapeId,
                                        name: baseShape.name,
                                        svg_path: baseShape.path,
                                        default_width: baseShape.width,
                                        default_height: baseShape.height
                                    };
                                })
                            };
                            layoutGeometries.push(layoutGeometry);
                            return layout;
                        })
                            .catch(error => {
                            if (onError) // && error.notFound)
                                onError({ layout: layoutGeometryId });
                        });
                        this.cachedLayoutGeometries.set(layoutGeometryId, promise);
                        cachedPromise = promise;
                    }
                    this.addAsyncSegmentPromise(newTileWrapper.id, cachedPromise, true);
                }
                segment?.tileLayout.tiles?.forEach((id, index) => {
                    let tileId = id;
                    let overrideAspectRatio = segment?.tileLayout?.overrideAspectRatio;
                    let idxOverride = overrideAspectRatio?.findIndex(oar => oar.shapeIndex === index);
                    if (idxOverride !== undefined && idxOverride > -1)
                        tileId = overrideAspectRatio[idxOverride].tile;
                    if (!baseShapes.find((bs) => bs.tileId === tileId)) {
                        let cachedPromise = this.cachedTiles.get(tileId);
                        if (!cachedPromise) {
                            const promise = getTile(tileId)
                                .then(tile => {
                                let tileInfo = tile;
                                const baseTileShape = baseShapes.find(gt => gt.shapeId === tileInfo.tile_shape.id);
                                //apply 90° rotation
                                if (tile.rotation === 90 || tile.rotation === 270)
                                    [tileInfo.width, tileInfo.height] = [tileInfo.height, tileInfo.width];
                                let svg = baseTileShape.path.resizeAndCenter([
                                    (tileInfo.width ?? TILE_TRANSFORM_SCALE) / TILE_TRANSFORM_SCALE,
                                    (tileInfo.height ?? TILE_TRANSFORM_SCALE) / TILE_TRANSFORM_SCALE,
                                ]);
                                //apply 90° rotation
                                // if (!!tile.rotation)
                                //     svg = svg.transformed(Matrix2D.fromRotationTranslation(tile.rotation * Math.PI / 180));
                                const newShape = new Shape(svg, baseTileShape.shapeId, tileInfo.id, tileInfo.name, {
                                    images: tileInfo.images,
                                    filterId: `tile-fill-${baseTileShape.shapeId}-${tileInfo.id}`,
                                }, tileInfo.slug, tileInfo.width, tileInfo.height, tile.rotation);
                                return {
                                    tileId: tileId,
                                    shape: newShape,
                                    tile: tile,
                                };
                            })
                                .catch(error => {
                                if (onError) // && error.notFound)
                                    onError({ tile: id });
                            });
                            this.cachedTiles.set(tileId, promise);
                            cachedPromise = promise;
                        }
                        const promise = cachedPromise.then((v) => ({ ...v, shapeIndex: index }));
                        this.addAsyncSegmentPromise(newTileWrapper.id, promise);
                    }
                });
                break;
            case SegmentClass.LINE:
                const line = segment;
                result = new Line(new Pointer(line.startPointer.x, line.startPointer.y), new Pointer(line.endPointer.x, line.endPointer.y), line.type, line.parentId, line.id, line.zIndex, line.height, line.thick, line.name);
                break;
            case SegmentClass.ARC:
                const arc = segment;
                result = new Arc(new Pointer(arc.startPointer.x, arc.startPointer.y), new Pointer(arc.endPointer.x, arc.endPointer.y), new Pointer(arc.heightPointer.x, arc.heightPointer.y), arc.id, arc.zIndex, arc.name);
                break;
            case SegmentClass.DOOR:
                const door = segment;
                result = new Door(new Pointer(door.startPointer.x, door.startPointer.y), new Pointer(door.endPointer.x, door.endPointer.y), door.parentId, door.width, door.length, door.rotation, door.buildingType, door.buildingId, door.id, door.zIndex, door.name, door.foldingType, door.openToOutSide, door.wallSide, door.wallOpening);
                break;
            case SegmentClass.BUILDING_PART:
                const building = segment;
                result = new BuildingPart(new Pointer(building.startPointer.x, building.startPointer.y), new Pointer(building.endPointer.x, building.endPointer.y), building.width, building.length, building.parentId, building.rotation, building.buildingType, building.buildingId, building.closedAreaId, building.id, building.zIndex, building.name);
                break;
        }
        if (result)
            this.cachedSegments.push(result);
        return result;
    }
    static importDrawState(context, layoutGeometries, baseShapes, onError) {
        const segments = context.current?.segments;
        const past = context.past;
        const future = context.future;
        this.cachedSegments = []; // clear cached segments
        this.asyncSegments = new Map(); // clear async segments
        if (Array.isArray(segments)) {
            context.current.segments =
                segments.sort((a, b) => Number(isTileWrapper(a)) - Number(isTileWrapper(b)))
                    .map((segment) => this.createSegmentByType(segment, layoutGeometries, baseShapes, onError));
        }
        if (Array.isArray(past)) {
            context.past = past.map((segs) => {
                const pastSegments = segs?.segments.sort((a, b) => Number(isTileWrapper(a)) - Number(isTileWrapper(b)))
                    .map((segment) => this.createSegmentByType(segment, layoutGeometries, baseShapes));
                return {
                    segments: pastSegments
                };
            });
        }
        if (Array.isArray(future)) {
            context.future = future.map((segs) => {
                const futureSegments = segs?.segments.sort((a, b) => Number(isTileWrapper(a)) - Number(isTileWrapper(b)))
                    .map((segment) => this.createSegmentByType(segment, layoutGeometries, baseShapes));
                return {
                    segments: futureSegments
                };
            });
        }
        if (this.asyncSegments.size > 0) {
            this.loadAsyncSegments(layoutGeometries, baseShapes, onError);
            context.loadingAsyncSegments = true;
        }
        else
            onError({ finished: true });
        return context;
    }
    static findCategoryById(categories, id) {
        for (let i = 0; i < categories.length; i++) {
            if (categories[i].id === id) {
                return categories[i];
            }
            else if (categories[i].children && categories[i].children.length > 0) {
                const ret = this.findCategoryById(categories[i].children, id);
                if (ret)
                    return ret;
            }
        }
        return null;
    }
    static importDrawFile(state, categories, layoutGeometries, baseShapes, onError) {
        const { layouts, furnitures, segments } = state.data;
        this.cachedSegments = []; // clear cached segments
        this.asyncSegments = new Map(); // clear async segments
        let newSegments = segments.map((seg) => {
            // if( seg.tileLayout !== undefined && seg.tileLayout !== -1 ) {
            //     return {
            //         ...seg,
            //         tileLayout: layouts[seg.tileLayout]
            //     }
            // } else if( seg.building !== undefined ) {
            if (seg.building !== undefined) {
                if (seg?.class === SegmentClass.DOOR) {
                    return {
                        ...seg,
                        buildingId: 0,
                        buildingType: seg.building
                    };
                }
                else if (seg?.class === SegmentClass.BUILDING_PART) {
                    const buildingId = furnitures[seg.building];
                    const buildingElement = this.findCategoryById(categories, buildingId);
                    if (buildingElement) {
                        return {
                            ...seg,
                            buildingId: buildingId,
                            buildingType: buildingElement.name
                        };
                    }
                }
            }
            return seg;
        });
        const ret = {
            loadingAsyncSegments: false,
            segments: newSegments.sort((a, b) => Number(isTileWrapper(a)) - Number(isTileWrapper(b)))
                .map((segment) => this.createSegmentByType(segment, layoutGeometries, baseShapes, onError))
        };
        if (this.asyncSegments.size > 0) {
            this.loadAsyncSegments(layoutGeometries, baseShapes, onError);
            ret.loadingAsyncSegments = true;
        }
        else
            onError({ finished: true });
        return ret;
    }
    static loadUserLayouts(layouts, layoutGeometries, baseShapes, onError) {
        this.asyncUserLayouts = new Map(); // clear async segments
        // {
        //     "id": 49,
        //     "updated_at": "2024-04-18T15:01:09.251+02:00",
        //     "layout_geometry": 87,
        //     "tiles": [
        //         512203
        //     ]
        // }
        const ret = {
            loadingAsyncUserLayouts: false,
            cachedLayouts: []
        };
        layouts.forEach((userLayout) => {
            let find = get(cachedUserLayouts).get(userLayout.id);
            if (find !== undefined) {
                ret.cachedLayouts.push(find);
                return;
            }
            else
                ret.cachedLayouts.push(userLayout.id);
            const layoutGeometryId = userLayout.layout_geometry;
            let layoutGeometry = layoutGeometries.find((geometry) => geometry.id === layoutGeometryId);
            let cachedPromise = (!layoutGeometry && layoutGeometryId > 0) ? this.cachedLayoutGeometries.get(layoutGeometryId) : Promise.resolve(layoutGeometry);
            if (!cachedPromise) {
                const promise = getLayoutGeometry(layoutGeometryId)
                    .then(layout => {
                    let isTileMissing = false;
                    layout.tile_shapes.forEach(tile => {
                        if (onError && !baseShapes.find((bs) => bs.tileId === tile)) {
                            isTileMissing = true;
                            onError({ tile: tile });
                        }
                    });
                    if (isTileMissing)
                        return;
                    layoutGeometry = {
                        ...layout,
                        tile_shapes: layout.tile_shapes.map((tileShapeId) => {
                            const baseShape = baseShapes.find((v) => v.shapeId === tileShapeId);
                            return {
                                id: tileShapeId,
                                name: baseShape.name,
                                svg_path: baseShape.path,
                                default_width: baseShape.width,
                                default_height: baseShape.height
                            };
                        })
                    };
                    layoutGeometries.push(layoutGeometry);
                    return layoutGeometry;
                })
                    .catch(error => {
                    if (onError) // && error.notFound)
                        onError({ layout: layoutGeometryId });
                });
                this.cachedLayoutGeometries.set(layoutGeometryId, promise);
                cachedPromise = promise;
            }
            this.addAsyncUserLayoutPromise(userLayout.id, cachedPromise, true);
            userLayout.tiles.forEach((id, index) => {
                const tileId = id;
                if (!baseShapes.find((bs) => bs.tileId === tileId)) {
                    let cachedPromise = this.cachedTiles.get(tileId);
                    if (!cachedPromise) {
                        const promise = getTile(tileId)
                            .then(tile => {
                            let tileInfo = tile;
                            const baseTileShape = baseShapes.find(gt => gt.shapeId === tileInfo.tile_shape.id);
                            let svg = baseTileShape.path.resizeAndCenter([
                                (tileInfo.width ?? TILE_TRANSFORM_SCALE) / TILE_TRANSFORM_SCALE,
                                (tileInfo.height ?? TILE_TRANSFORM_SCALE) / TILE_TRANSFORM_SCALE,
                            ]);
                            //apply 90° rotation
                            if (!!tile.rotation)
                                svg = svg.transformed(Matrix2D.fromRotationTranslation(tile.rotation * Math.PI / 180));
                            const newShape = new Shape(svg, baseTileShape.shapeId, tileInfo.id, tileInfo.name, {
                                images: tileInfo.images,
                                filterId: `tile-fill-${baseTileShape.shapeId}-${tileInfo.id}`,
                            }, tileInfo.slug, tileInfo.width, tileInfo.height, tileInfo.rotation);
                            return {
                                tileId: tileId,
                                shape: newShape,
                                tile: tile
                            };
                        })
                            .catch(error => {
                            if (onError) // && error.notFound)
                                onError({ tile: id });
                        });
                        this.cachedTiles.set(tileId, promise);
                        cachedPromise = promise;
                    }
                    const promise = cachedPromise.then((v) => ({ ...v, shapeIndex: index }));
                    this.addAsyncUserLayoutPromise(userLayout.id, promise);
                }
            });
        });
        if (this.asyncUserLayouts.size > 0) {
            this.loadAsyncUserLayouts(layoutGeometries, baseShapes, onError);
            ret.loadingAsyncUserLayouts = true;
        }
        else
            onError({ finished: true });
        return ret;
    }
}
