const isProduction = process.env.NODE_ENV;
export var ROUTE;
(function (ROUTE) {
    ROUTE[ROUTE["HOME"] = 0] = "HOME";
    ROUTE[ROUTE["NEW_PROJECT_STEP_1"] = 1] = "NEW_PROJECT_STEP_1";
    ROUTE[ROUTE["NEW_PROJECT_STEP_2"] = 2] = "NEW_PROJECT_STEP_2";
    ROUTE[ROUTE["AUTH_CALLBACK"] = 3] = "AUTH_CALLBACK";
    ROUTE[ROUTE["AUTH_CALLBACK_ACTION"] = 4] = "AUTH_CALLBACK_ACTION";
    ROUTE[ROUTE["BOARD"] = 5] = "BOARD";
    ROUTE[ROUTE["BOARD_ACTION"] = 6] = "BOARD_ACTION";
    ROUTE[ROUTE["PROJECTS_LIST"] = 7] = "PROJECTS_LIST";
    ROUTE[ROUTE["OTHER"] = 8] = "OTHER";
})(ROUTE || (ROUTE = {}));
export default [
    { path: "/" },
    { path: "/new-project-step-1" },
    { path: "/new-project-step-2" },
    { path: `/oauth/callback` },
    { path: `/oauth/callback/:action` },
    { path: "/board" },
    { path: "/board/:action" },
    { path: "/projects-list" },
    { path: "/*" },
];
