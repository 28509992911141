import { get } from "svelte/store";
import { assign, createMachine, send, interpret } from "xstate";
import { log } from "xstate/lib/actions";
import { Map as ImmutableMap, List as ImmutableList } from "immutable";
import { Arc, LINE_TYPE, Line, Pointer, Segment, TileWrapper } from "../model";
import { Door, FOLDINGTYPE } from "../model/Door";
import { BuildingPart } from "../model/BuildingPart";
import { METRIC_UNITS, PRECISION, PRECISION_UNITS, TILE_SCALE, TOLERANCE_DISTANCE, } from "../global/variable";
import { linkTool } from ".";
import { convertPointerToClientBox, snapShotPointer, getSnapShotPointer, dist, checkValidMovePointer, translatePointerWithSegments, getDistanceLineAndPointer, getSnapBuildingPartPoints, getDistancePointer, getParentLine, getAllBuildingPartsInSegment, isBuildingPart, getAngleBetweenTwoLines, getAngleBetweenTwoLinesInPI, isDoor, getAllClosedShape, isLine, getNewLinesByCrossed, getSegmentsOfPoint, getShapeBoundingRect, getShapeId, getUpdatedTiles, getTileLayouts, centroid, getTileSnapPointers, getSvgPointersOfTile, getMetricWithUnit, isTileWrapper, checkPointerInClosedArea, getClosedAreas, getClosedAreaWithPointer, isInShape, getCrossedPointBetweenSegments, refreshClosedArea, getParentClosedArea, splitLinesForDashed, checkBuildingPartInRoom, getClosedAreaByLine, getAllBuildingPartsInRoom, rotateLine, getRotatedPointer } from "../helpers";
import RealizedLayout from "../model/tile/RealizedLayout";
import Layout, { Neighbors } from "../model/tile/Layout";
import { LineSegment } from "../model/tile/Path";
import { v4 as uuidv4 } from 'uuid';
import StoreSerializer from "./serializer";
import Shape from "src/model/tile/Shape";
import Neighbor, { NeighborOffset, } from "../model/tile/Neighbor";
import EdgeReference, { ANCHOR_POINTS } from "../model/tile/EdgeReference";
import { isLayoutRepeatable, isRootTile, isTileDraggable, isTileSelectable, moveTileInternal, } from "../tools/LayoutTools";
import { TOOLS } from "src/global/types";
import { ClosedArea } from "src/model/ClosedArea";
import { drawState } from "../layout.store";
import { TILE_TRANSFORM_SCALE } from "src/global/variable";
import { getTile } from "src/services/api";
import { cachedUserLayouts } from "src/store";
export const DEFAULT_LAYOUT_SIZE = 20;
const DEFAULT_LAYOUT_DEPTH = Layout.MAX_DEPTH;
export const emptyLayout = new RealizedLayout(DEFAULT_LAYOUT_SIZE, DEFAULT_LAYOUT_SIZE, DEFAULT_LAYOUT_DEPTH);
export function initialDrawContext(tool) {
    return {
        startPointer: undefined,
        lastPointer: undefined,
        drawingObject: undefined,
        tool: tool
    };
}
export function initialDragContext() {
    return {
        selectedObject: undefined,
        selectedCPObj: undefined,
        offset: undefined,
        initialAngle: undefined,
        resizeIndicator: undefined,
        currentShapeId: undefined,
        snapTile: undefined,
        isRotating: false,
    };
}
export function initialSnapContext() {
    return {
        snapPointer: undefined,
        snapType: -1,
        mousePointer: new Pointer(0, 0),
    };
}
export function initialSplitContext() {
    return {
        splitCount: null,
        splitedLineArray: [],
    };
}
export function initialStackContext() {
    return {
        segments: [],
    };
}
export const editorMachine = createMachine({
    id: "editor",
    type: "parallel",
    predictableActionArguments: true,
    context: {
        projectBaseInfo: undefined,
        past: [],
        current: initialStackContext(),
        backup: initialStackContext(),
        future: [],
        loadingAsyncSegments: false,
        loadingAsyncUserLayouts: false,
        paginatedUserLayouts: [],
        svgElement: undefined,
        svgSize: { w: 0, h: 0 },
        lineLength: 0.1,
        drawContext: initialDrawContext(),
        dragContext: initialDragContext(),
        snapContext: initialSnapContext(),
        splitContext: initialSplitContext(),
        currentMetricUnit: "m",
        layoutContext: {
            layout: emptyLayout,
            prevLayout: emptyLayout,
            autoRepeatPreviewLayout: emptyLayout,
            addTileContext: createEmptyAddTileContext(emptyLayout),
            dragContext: createEmptyTileDragContext(emptyLayout),
            selectedTileID: undefined,
            replaceTileContext: {},
            baseShapes: [],
            layoutGeometries: [],
        }
    },
    states: {
        main: {
            initial: "preload",
            states: {
                preload: {
                    id: "preload",
                    on: {
                        SET_SVG: { actions: ["setCurrentSVG"] },
                    },
                },
                loading: {
                    id: "loading",
                },
                savePreview: {
                    id: "savePreview",
                    on: {
                        CANCEL_PREVIEW: {
                            target: "normal",
                            actions: ["cancelDrawing"]
                        }
                    }
                },
                printPreview: {
                    id: "printPreview",
                    on: {
                        MOUSE_MOVE: {
                            actions: ["updateSnapPointer"],
                        },
                        CANCEL_PREVIEW: {
                            target: "normal",
                            actions: ["cancelDrawing"]
                        }
                    }
                },
                printing: {
                    id: "printing",
                    on: {
                        CANCEL_PREVIEW: {
                            target: "normal",
                            actions: ["cancelDrawing"]
                        }
                    }
                },
                drawingState: {
                    initial: "freeSnapping",
                    on: {
                        CANCEL_DRAWING: {
                            target: "normal",
                            actions: ["cancelDrawing"],
                        },
                    },
                    states: {
                        freeSnapping: {
                            on: {
                                MOUSE_MOVE: {
                                    actions: ["updateSnapPointer"],
                                },
                                MOUSE_DOWN: {
                                    target: "drawStarted",
                                    actions: ["updateStartPointer"],
                                    cond: "isInClosedShape",
                                },
                            },
                        },
                        drawStarted: {
                            on: {
                                MOUSE_MOVE: {
                                    target: "drawing",
                                    actions: ["updatePast", "addSegment"],
                                },
                            },
                        },
                        drawing: {
                            on: {
                                MOUSE_MOVE: {
                                    actions: ["updateDrawingObject"],
                                },
                                MOUSE_DOWN: [
                                    {
                                        cond: "isNotInClosedShape"
                                    },
                                    {
                                        cond: "isInValidMovePointer"
                                    },
                                    {
                                        target: "#normal",
                                        actions: ["updateClosedShape"],
                                        cond: "isClosedShape",
                                    },
                                    {
                                        target: "drawStarted",
                                        actions: ["updateLastPointer"],
                                        cond: "isValidMovePointer",
                                    },
                                ],
                            },
                        },
                    },
                },
                normal: {
                    id: "normal",
                    on: {
                        SET_SVG: { actions: ["setCurrentSVG"] },
                        ENTER_DRAWING: { target: "drawingState", actions: ["enterNormal"] },
                        CANCEL_DRAWING: { actions: ["cancelDrawing"] },
                        ENTER_SELECT: {
                            target: "selectState",
                            actions: ["enterSelect"],
                        },
                        MOUSE_MOVE: {
                            actions: ["updateMousePointer"],
                        },
                        MOUSE_DOWN: {
                            actions: ["updateOffset"],
                        },
                        UNDO: { actions: ["undo"] },
                        REDO: { actions: ["redo"] },
                        CREATE_BUILDING_PART: {
                            target: "selectState",
                            actions: ["backupCurrent", "createdBuildingPart"],
                        },
                        CREATE_ROOM: {
                            actions: ["cleanPast", "createRoom"],
                        },
                    },
                },
                selectState: {
                    initial: "objectSelected",
                    states: {
                        objectSelected: {
                            on: {
                                ENTER_SELECT: {
                                    actions: ["enterSelect"],
                                },
                                SELECT_POINTER: {
                                    target: "pointSelected",
                                    actions: ["selectPointer"],
                                },
                                MOUSE_MOVE: {
                                    actions: ["updateMousePointer"],
                                },
                                MOUSE_DOWN: {
                                    target: "#normal",
                                    actions: ["enterNormal"],
                                },
                                UPDATE_OFFSET: {
                                    actions: ["updateOffset"],
                                },
                                DRAGGING: {
                                    target: "dragging",
                                    actions: ["updatePast", "dragging"],
                                },
                                CHANGE_ARC: {
                                    target: "changingArc",
                                    actions: ["updatePast", "changeArc"],
                                },
                                CREATE_ARC: {
                                    actions: ["updatePast", "createdArc"],
                                },
                                CREATE_BUILDING_PART: {
                                    actions: ["backupCurrent", "createdBuildingPart"],
                                },
                                SPLIT_LINE: {
                                    target: "splittingLine",
                                    actions: ["updatePast", "splitLine"],
                                },
                                CHANGE_FOLDING_TYPE: {
                                    actions: ["changeFoldingType"],
                                },
                                CHANGE_WALL_SIDE: {
                                    actions: ["changeWallSide"],
                                },
                                CHANGE_NAME: {
                                    actions: ["updatePast", "changeName"],
                                },
                                CHANGE_WIDTH: {
                                    actions: ["updatePast", "changeWidth", "draggingEnd"],
                                },
                                CHANGE_HEIGHT: {
                                    actions: ["updatePast", "changeHeight"],
                                },
                                CHANGE_THICK: {
                                    actions: ["updatePast", "changeThick"],
                                },
                                SWITCH_LINE_POINTS: {
                                    actions: ["updatePast", "switchLinePoints"],
                                },
                                CHANGE_OPEN_SIDE: {
                                    actions: ["changeOpenSide"],
                                },
                                ROTATING: {
                                    target: "rotating",
                                    actions: ["updatePast", "enterRotating"],
                                },
                                RESIZING: {
                                    target: "resizing",
                                    actions: ["updatePast", "enterResizingBuildingPart"],
                                },
                                CHANGE_ROTATION: {
                                    actions: ["changeRotating"],
                                },
                                CHANGE_LAYOUT_GAP: {
                                    actions: ["changeLayoutGap"],
                                },
                                CHANGE_GROUT_COLOR: {
                                    actions: ["changeGroutColor"],
                                },
                                DELETE: {
                                    target: "#normal",
                                    actions: ["updatePast", "deleteSegment"],
                                },
                                Z_INDEX_UPDATE: {
                                    actions: ["updateZIndex"],
                                },
                                UPDATE_BACKUP: {
                                    actions: ["backupCurrent"],
                                },
                                CANCEL_UPDATE: {
                                    actions: ["restoreCurrent"],
                                },
                                CONFIRM_UPDATE: {
                                    actions: ["updatePastWithBackup", "backupCurrent"],
                                },
                                REPLACE_TILE: {
                                    target: "replacingTile",
                                    actions: ["setReplaceTileContext"],
                                },
                                HIGHLIGHT_TILES: {
                                    actions: ["highlightTiles"],
                                },
                                UNDO: { target: "#normal", actions: ["undo"] },
                                REDO: { target: "#normal", actions: ["redo"] },
                                EYEDROPPER: {
                                    target: "#eyeDropper"
                                }
                            },
                        },
                        eyeDropper: {
                            id: "eyeDropper",
                            initial: "loading",
                            on: {
                                CANCEL_EYEDROPPER: {
                                    target: "objectSelected",
                                },
                            },
                            states: {
                                loading: {
                                    on: {
                                        EYEDROPPER_LOADED: {
                                            target: "loaded",
                                        },
                                    }
                                },
                                loaded: {
                                    on: {
                                        MOUSE_MOVE: {
                                            actions: ["updateMousePointer"],
                                        },
                                    }
                                }
                            }
                        },
                        pointSelected: {
                            on: {
                                ENTER_SELECT: {
                                    target: "objectSelected",
                                    actions: ["enterSelect"],
                                },
                                MOUSE_MOVE: {
                                    actions: ["updateMousePointer"],
                                },
                                MOUSE_DOWN: {
                                    target: "#normal",
                                    actions: ["enterNormal"],
                                },
                                UPDATE_OFFSET: {
                                    actions: ["updateOffset"],
                                },
                                DRAGGING: {
                                    target: "dragging",
                                    actions: ["updatePast", "dragging"],
                                },
                            },
                        },
                        dragging: {
                            on: {
                                DRAGGING: {
                                    actions: ["dragging"],
                                },
                                DRAG_END: {
                                    target: "objectSelected",
                                    actions: ["draggingEnd"],
                                },
                                POP: { target: "#normal", actions: ["pop"] },
                            },
                        },
                        rotating: {
                            on: {
                                ROTATING: {
                                    actions: ["rotatingObject"],
                                },
                                // MOUSE_MOVE: {
                                //   actions: ["updatePast"],
                                // },
                                DRAG_END: {
                                    target: "objectSelected",
                                    actions: ["draggingEnd"],
                                },
                            }
                        },
                        resizing: {
                            on: {
                                RESIZING: {
                                    actions: ["resizingBuildingPart"],
                                },
                                DRAG_END: {
                                    target: "objectSelected",
                                    actions: ["draggingEnd"],
                                },
                            },
                        },
                        changingArc: {
                            on: {
                                CHANGE_ARC: {
                                    actions: ["changeArc"],
                                },
                                MOUSE_MOVE: {
                                    actions: ["updateMousePointer"],
                                },
                                DRAG_END: {
                                    target: "objectSelected",
                                    actions: ["draggingEnd"],
                                },
                            },
                        },
                        splittingLine: {
                            on: {
                                SPLIT_LINE: {
                                    actions: ["splitLine"],
                                },
                                MOUSE_MOVE: {
                                    actions: ["updateMousePointer"],
                                },
                                CONFIRM: { target: "#normal", actions: ["updateCurrent"] },
                            },
                        },
                        replacingTile: {
                            on: {
                                CONFIRM_TILE_SELECTION: {
                                    target: "objectSelected",
                                    actions: ["replaceTile"],
                                },
                                CANCEL_TILE_SELECTION: {
                                    target: "objectSelected",
                                    actions: ["setReplaceTileContext"],
                                },
                                HIGHLIGHT_TILES: {
                                    actions: ["highlightTiles"],
                                },
                            },
                        },
                    },
                    on: {
                        ENTER_DRAWING: {
                            target: "drawingState",
                            actions: ["enterNormal"],
                        },
                    },
                },
            },
            on: {
                LOAD_TILE_LAYOUT: {
                    actions: ["updatePast", "loadTileLayout"],
                },
                SHOW_LAYOUT_GEOMETRY: {
                    actions: ["updatePast", "saveTileLayout"],
                },
                ADD_LAYOUT_GEOMETRY: {
                    actions: ["addLayoutGeometry"],
                },
                LOAD_RESOURCE: {
                    actions: ["updateCurrent"],
                },
                EMPTY_PROJECT: {
                    actions: ["emptyProject"],
                },
                LOAD_PROJECT_LOCAL: {
                    target: "main.loading",
                    actions: ["loadProjectLocal"],
                },
                LOAD_PROJECT: {
                    target: "main.loading",
                    actions: ["loadProject"],
                },
                LOAD_USER_LAYOUTS: {
                    actions: ["loadUserLayouts"],
                },
                LOADED_ASYNC_SEGMENTS: {
                    target: "main.loading",
                    actions: ["loadedAsynchronousSegments"],
                },
                LOADED_ASYNC_USER_LAYOUTS: {
                    actions: ["loadedAsynchronousUserLayouts"],
                },
                LOAD_SUCCESS: {
                    target: "main.normal",
                },
                SAVE_PREVIEW: {
                    target: "#savePreview"
                },
                PRINT_PREVIEW: {
                    target: "#printPreview"
                },
                PRINT: {
                    target: "#printing"
                },
                UPDATE_PROJECT_INFO: {
                    actions: "updateProjectInfo",
                }
            },
        },
        mouseHover: {
            initial: "normal",
            states: {
                normal: {
                    on: {
                        MOUSE_HOVER: { target: "hovered" },
                    },
                },
                hovered: {
                    on: {
                        MOUSE_LEAVE: { target: "normal" },
                    },
                },
            },
        },
        grid: {
            initial: "showGrid",
            states: {
                showGrid: {
                    on: {
                        TOGGLE_GRID: { target: "hideGrid" },
                    },
                },
                hideGrid: {
                    on: {
                        TOGGLE_GRID: { target: "showGrid" },
                    },
                },
            },
        },
        helper: {
            initial: "showHelper",
            states: {
                showHelper: {
                    on: {
                        TOGGLE_HELPER: { target: "hideHelper" },
                    },
                },
                hideHelper: {
                    on: {
                        TOGGLE_HELPER: { target: "showHelper" },
                    },
                },
            },
        },
        lineTool: {
            initial: "hidden",
            states: {
                shown: {
                    on: {
                        HIDE_LINE_TOOL: { target: "hidden" },
                    },
                },
                hidden: {
                    on: {
                        SHOW_LINE_TOOL: { target: "shown" },
                    },
                },
            },
        },
        tileLayoutTool: {
            initial: "displayTile",
            states: {
                displayTile: {
                    on: {
                        EDIT_TILE_LAYOUT: {
                            target: "editTile",
                            actions: ["updatePast", "savePrevLayout", "editTileLayout"],
                        },
                        REMOVE_TILE_LAYOUT: {
                            actions: ["updatePast", "removeTileLayout"],
                        },
                    },
                },
                editTile: {
                    on: {
                        SAVE_TILE_LAYOUT: {
                            target: "displayTile",
                            actions: ["updatePast", "saveTileLayout"],
                        },
                        CLOSE_TILE_LAYOUT: {
                            target: "displayTile",
                            actions: ["reloadPrevLayout"]
                        },
                    },
                },
            },
        },
        metricTool: {
            initial: "init",
            states: {
                init: {
                    on: {
                        CHANGE_METRIC: {
                            target: "init",
                            actions: ["updateMetric"],
                        },
                    },
                },
            },
        },
        layoutDesign: {
            initial: "preloadBaseShapes",
            states: {
                preloadBaseShapes: {
                    invoke: {
                        src: "loadJSONBaseShapes",
                        onDone: {
                            target: "init",
                            actions: "appendLoadedBaseShapes",
                        },
                        onError: {
                            target: "init",
                            actions: "logLoadedBaseShapesError",
                        },
                    },
                },
                init: {
                    id: "rootState",
                    always: [
                        { target: "noLayout", cond: "thereIsNoLayout" },
                        { target: "showLayout", cond: "thereIsLayout" },
                    ],
                },
                noLayout: {
                    initial: "idle",
                    on: {
                        LOAD_TILE_SHAPE: {
                            actions: "appendLoadedBaseShapes",
                        },
                        LOAD_LAYOUT_GEOMETRY: {
                            actions: "loadLayoutGeometries",
                        },
                    },
                    states: {
                        idle: {
                            on: {
                                ADD_ROOT_TILE: {
                                    target: "showAddRootTile",
                                    actions: "setEmptyAddTileData",
                                },
                                LOAD_CONTEXT: {
                                    target: "#rootState",
                                    actions: "loadContext",
                                },
                                LOAD_GEOMETRY: {
                                    target: "#rootState",
                                    actions: "loadLayoutGeometry",
                                },
                            },
                        },
                        showAddRootTile: {
                            on: {
                                CONFIRM_TILE_SELECTION: {
                                    target: "#rootState",
                                    actions: "addRootTile",
                                },
                                CANCEL_TILE_SELECTION: {
                                    target: "#rootState",
                                },
                            },
                        },
                    },
                },
                showLayout: {
                    type: "parallel",
                    on: {
                        LOAD_TILE_SHAPE: {
                            actions: "appendLoadedBaseShapes",
                        },
                        LOAD_LAYOUT_GEOMETRY: {
                            actions: "loadLayoutGeometries",
                        },
                    },
                    states: {
                        layoutState: {
                            initial: "idle",
                            states: {
                                idle: {
                                    entry: send({ type: "RESET_AUTO_REPEAT_LAYOUT" }),
                                    on: {
                                        ADD_TILE_TO_EDGE: {
                                            target: "showAddTileSelection",
                                            actions: "setAddTileAddPointData",
                                        },
                                        AUTO_REPEAT_LAYOUT: {
                                            target: "idle",
                                            cond: "layoutCanBeAutoRepeated",
                                            actions: "autoRepeatLayout",
                                        },
                                        LOAD_CONTEXT: {
                                            target: "#rootState",
                                            actions: "loadContext",
                                        },
                                        LOAD_GEOMETRY: {
                                            target: "#rootState",
                                            actions: "loadLayoutGeometry",
                                        },
                                    },
                                },
                                showAddTileSelection: {
                                    on: {
                                        CONFIRM_TILE_SELECTION: {
                                            target: "confirmAddTileSelection",
                                            actions: "setAddTileShapeData",
                                        },
                                        CANCEL_TILE_SELECTION: {
                                            target: "idle",
                                        },
                                        REPEAT_TILE_SELECTION: {
                                            target: "showRepeatConnectPoints",
                                        },
                                    },
                                },
                                showRepeatConnectPoints: {
                                    entry: "setInitAddTileLayout",
                                    on: {
                                        SHOW_REPEAT_PREVIEW: {
                                            actions: "setRepeatLayout",
                                        },
                                        HIDE_REPEAT_PREVIEW: {
                                            actions: "unsetRepeatLayout",
                                        },
                                        keyup: {
                                            target: "idle",
                                            cond: "isEscPressed",
                                            actions: "unsetRepeatLayout",
                                        },
                                        REPEAT_CONNECTION_SELECTION: {
                                            target: "idle",
                                            actions: ["setRepeatLayout", "setEmptyAddTileData"],
                                        },
                                    },
                                },
                                confirmAddTileSelection: {
                                    always: {
                                        target: "idle",
                                        actions: "addTileToEdge",
                                    },
                                },
                            },
                        },
                        selectionState: {
                            id: "selection",
                            initial: "noSelection",
                            states: {
                                selection: {
                                    entry: "initDragData",
                                    on: {
                                        SELECT_TILE: [
                                            {
                                                target: "selection",
                                                actions: "setTileSelected",
                                                cond: "isSelectableTile",
                                            },
                                            {
                                                target: "noSelection",
                                                actions: "setTileDeselected",
                                            },
                                        ],
                                        DESELECT_TILE: {
                                            target: "noSelection",
                                            // TODO this could be optimized by checking if layout has changed and need to be invalidated
                                            actions: ["setTileDeselected", "resetAutoRepeatLayout"],
                                        },
                                        DRAG_START: {
                                            target: "dragging",
                                            cond: "isDraggableTile",
                                            actions: "fillStartDragData",
                                        },
                                        ROTATE_TILE: {
                                            target: "selection",
                                            cond: "isSelectableTile",
                                            actions: "rotateTile",
                                        },
                                        REMOVE_TILE: {
                                            target: "removeTile",
                                        },
                                        LOCK_TILE_WIDTH: {
                                            target: "selection",
                                            actions: "lockTileWidth",
                                        },
                                        UNLOCK_TILE_WIDTH: {
                                            target: "selection",
                                            actions: "unlockTileWidth",
                                        },
                                        LOCK_TILE_HEIGHT: {
                                            target: "selection",
                                            actions: "lockTileHeight",
                                        },
                                        UNLOCK_TILE_HEIGHT: {
                                            target: "selection",
                                            actions: "unlockTileHeight",
                                        },
                                        CHANGE_ANCHOR_POINT: {
                                            target: "selection",
                                            actions: "changeAnchorPoint",
                                        },
                                    },
                                },
                                removeTile: {
                                    initial: "resolveTileType",
                                    states: {
                                        resolveTileType: {
                                            always: [
                                                {
                                                    target: "showRemoveRootTileDialog",
                                                    cond: "isSelectedTileRoot",
                                                },
                                                { target: "showRemoveTileDialog" },
                                            ],
                                        },
                                        showRemoveTileDialog: {
                                            on: {
                                                CONFIRM_REMOVE_TILE: {
                                                    target: "#selection.noSelection",
                                                    actions: [
                                                        "removeSelectedTile",
                                                        "resetAutoRepeatLayout",
                                                    ],
                                                },
                                                CANCEL_REMOVE_TILE: {
                                                    target: "#selection.selection",
                                                },
                                            },
                                        },
                                        showRemoveRootTileDialog: {
                                            on: {
                                                CONFIRM_REMOVE_TILE: {
                                                    target: "#rootState",
                                                    actions: "removeRootTile",
                                                },
                                                CANCEL_REMOVE_TILE: {
                                                    target: "#selection.selection",
                                                },
                                            },
                                        },
                                    },
                                },
                                dragging: {
                                    on: {
                                        DRAG_MOVE: {
                                            actions: "evaluateDragLayout",
                                        },
                                        DRAG_END: {
                                            actions: "evaluateLastDragLayout",
                                            target: "selection",
                                        },
                                    },
                                    exit: send({ type: "RESET_AUTO_REPEAT_LAYOUT" }),
                                },
                                noSelection: {
                                    on: {
                                        SELECT_TILE: {
                                            target: "selection",
                                            actions: "setTileSelected",
                                            cond: "isSelectableTile",
                                        },
                                    },
                                },
                            },
                        },
                        autoRepeatState: {
                            initial: "resolveState",
                            states: {
                                resolveState: {
                                    always: [
                                        { target: "repeatEval", cond: "layoutCanBeAutoRepeated" },
                                        { target: "resolvedAutoRepeat.notRepeatableLayout" },
                                    ],
                                },
                                repeatEval: {
                                    entry: "evalAutoRepeatLayoutPreview",
                                    always: [{ target: "resolvedAutoRepeat.repeatPreview" }],
                                },
                                resolvedAutoRepeat: {
                                    states: {
                                        repeatPreview: {
                                            on: {
                                                CONFIRM_AUTO_REPEAT_LAYOUT: {
                                                    target: "notRepeatableLayout",
                                                    actions: "assignAutoRepeatPreviewToLayout",
                                                },
                                            },
                                        },
                                        notRepeatableLayout: {
                                            type: "final",
                                        },
                                    },
                                    on: {
                                        RESET_AUTO_REPEAT_LAYOUT: {
                                            target: "resolveState",
                                        },
                                    },
                                },
                            },
                        },
                        layoutDebugState: {
                            initial: "disabled",
                            states: {
                                enabled: {
                                    on: {
                                        SWITCH_LAYOUT_DEBUG_STATE: {
                                            target: "disabled",
                                        },
                                        keyup: {
                                            target: "disabled",
                                            cond: "isDebugKeyPressed",
                                        },
                                    },
                                },
                                disabled: {
                                    on: {
                                        SWITCH_LAYOUT_DEBUG_STATE: {
                                            target: "enabled",
                                        },
                                        keyup: {
                                            target: "enabled",
                                            cond: "isDebugKeyPressed",
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        }
    },
}, {
    guards: {
        isInValidMovePointer: (ctx, _) => {
            const pointer = ctx.drawContext.drawingObject.endPointer;
            return !checkValidMovePointer(ctx.current.segments, pointer);
        },
        isValidMovePointer: (ctx, _) => {
            const pointer = ctx.drawContext.drawingObject.endPointer;
            return checkValidMovePointer(ctx.current.segments, pointer);
        },
        isInClosedShape: (ctx, e) => {
            if (ctx.drawContext.tool !== TOOLS.DRAW_LINE)
                return true;
            const drawingClosedArea = getClosedAreaWithPointer(ctx.current.segments, ctx.snapContext.snapPointer ?? e.pointer);
            return !!drawingClosedArea;
        },
        isNotInClosedShape: (ctx, _) => {
            if (ctx.drawContext.tool !== TOOLS.DRAW_LINE)
                return false;
            if (!ctx.drawContext.drawingClosedArea)
                return false;
            const pointer = ctx.snapContext.snapPointer ?? ctx.drawContext.drawingObject.endPointer;
            return !isInShape(pointer, ctx.drawContext.drawingClosedArea.shape);
        },
        isClosedShape: (ctx, _) => {
            let updatedCurrent = ctx.current.segments;
            if (ctx.drawContext.drawingObject) {
                updatedCurrent = getNewLinesByCrossed(ctx.current.segments, ctx.drawContext.drawingObject);
            }
            const pairs = getAllClosedShape(updatedCurrent);
            return pairs.some((pair) => pair.points.some((p) => p.equals(ctx.drawContext.drawingObject.endPointer)));
        },
        thereIsNoLayout: (context) => context.layoutContext.layout.tiles.length == 0,
        thereIsLayout: (context) => context.layoutContext.layout.tiles.length != 0,
        isSelectableTile: (context, event) => isTileSelectable(context.layoutContext.layout, event.tileID),
        isDraggableTile: (context, event) => isTileDraggable(context.layoutContext.layout, context.layoutContext.selectedTileID, event.tileID),
        layoutCanBeAutoRepeated: (context) => isLayoutRepeatable(context.layoutContext.layout),
        isEscPressed: (_, event) => event.key === "Escape",
        isSelectedTileRoot: (context) => isRootTile(context.layoutContext.layout, context.layoutContext.selectedTileID),
        isDebugKeyPressed: (_, event) => {
            const e = event;
            return e.code === "KeyD" && e.ctrlKey && e.altKey;
        },
    },
    actions: {
        setCurrentSVG: assign((ctx, e) => {
            return {
                ...ctx,
                svgElement: e.svg,
                svgSize: e.svgSize,
            };
        }),
        emptyProject: assign((ctx, e) => {
            return {
                ...ctx,
                current: initialStackContext(),
                projectBaseInfo: undefined,
                past: [],
                future: [],
                drawContext: initialDrawContext(),
                dragContext: initialDragContext(),
                snapContext: initialSnapContext(),
                splitContext: initialSplitContext(),
            };
        }),
        loadProjectLocal: assign((ctx, e) => {
            const context = StoreSerializer.importDrawState(e.savedLocalData, ctx.layoutContext.layoutGeometries, ctx.layoutContext.baseShapes, e.onError);
            return {
                ...ctx,
                ...context
            };
        }),
        loadProject: assign((ctx, e) => {
            const { segments, loadingAsyncSegments } = StoreSerializer.importDrawFile(e.savedData, e.categories, ctx.layoutContext.layoutGeometries, ctx.layoutContext.baseShapes, e.onError);
            return {
                ...ctx,
                current: {
                    segments
                },
                projectBaseInfo: {
                    id: e.savedData.id,
                    name: e.savedData.name,
                    slug: e.savedData.slug
                },
                loadingAsyncSegments,
                past: [],
                future: [],
                drawContext: initialDrawContext(),
                dragContext: initialDragContext(),
                snapContext: initialSnapContext(),
                splitContext: initialSplitContext(),
            };
        }),
        loadUserLayouts: assign((ctx, e) => {
            const { cachedLayouts, loadingAsyncUserLayouts } = StoreSerializer.loadUserLayouts(e.userLayouts, ctx.layoutContext.layoutGeometries, ctx.layoutContext.baseShapes, e.onError);
            return {
                ...ctx,
                paginatedUserLayouts: cachedLayouts,
                loadingAsyncUserLayouts
            };
        }),
        loadedAsynchronousSegments: assign((ctx, e) => {
            return {
                ...ctx,
                loadingAsyncSegments: false
            };
        }),
        loadedAsynchronousUserLayouts: assign((ctx, e) => {
            let userLayout;
            const length = ctx.paginatedUserLayouts.length;
            for (let i = 0; i < length; ++i) {
                if (typeof ctx.paginatedUserLayouts[i] === 'number') {
                    userLayout = get(cachedUserLayouts).get(ctx.paginatedUserLayouts[i]);
                    ctx.paginatedUserLayouts[i] = userLayout;
                }
            }
            return {
                ...ctx,
                loadingAsyncUserLayouts: false
            };
        }),
        updateSnapPointer: assign((ctx, e) => {
            const newSnapShotPointer = getSnapShotPointer(e.pointer, ctx.current.segments, undefined, 
            // PRECISION_UNITS[ctx.currentMetricUnit] * (ctx.currentMetricUnit === METRIC_UNITS[0] ? 1 : 2)
            PRECISION_UNITS[ctx.currentMetricUnit]);
            if (newSnapShotPointer.pointer) {
                return {
                    ...ctx,
                    snapContext: {
                        snapPointer: newSnapShotPointer.pointer,
                        snapType: newSnapShotPointer.type,
                        mousePointer: convertPointerToClientBox(newSnapShotPointer.pointer, ctx.svgElement),
                    },
                };
            }
            else {
                return {
                    ...ctx,
                    snapContext: {
                        snapPointer: undefined,
                        snapType: -2,
                        mousePointer: convertPointerToClientBox(e.pointer, ctx.svgElement),
                    },
                };
            }
        }),
        updateStartPointer: assign((ctx, e) => {
            let drawingClosedArea;
            if (ctx.drawContext.tool === TOOLS.DRAW_LINE) {
                drawingClosedArea = getClosedAreaWithPointer(ctx.current.segments, ctx.snapContext.snapPointer ?? e.pointer);
            }
            return {
                ...ctx,
                drawContext: {
                    ...ctx.drawContext,
                    startPointer: ctx.snapContext.snapPointer ?? e.pointer,
                    lastPointer: ctx.snapContext.snapPointer ?? e.pointer,
                    drawingObject: undefined,
                    drawingClosedArea: drawingClosedArea
                },
            };
        }),
        addSegment: assign((ctx, e) => {
            const drawingObject = new Line(ctx.drawContext.lastPointer, e.pointer, ctx.drawContext.tool === TOOLS.DRAW_LINE ? LINE_TYPE.DASHED : LINE_TYPE.SOLID, ctx.drawContext.drawingClosedArea?.id);
            return {
                ...ctx,
                current: {
                    ...ctx.current,
                    segments: [...ctx.current.segments, drawingObject].sort((a, b) => {
                        if (isBuildingPart(a) && !isBuildingPart(b)) {
                            return 1;
                        }
                        else if (!isBuildingPart(a) && isBuildingPart(b)) {
                            return -1;
                        }
                        else {
                            return 0; //a.id.localeCompare(b.id);
                        }
                    }),
                },
                drawContext: {
                    ...ctx.drawContext,
                    drawingObject: drawingObject,
                },
            };
        }),
        updateDrawingObject: assign((ctx, e) => {
            let pointer = e.pointer;
            const segmentList = [];
            for (const s of ctx.current.segments) {
                if (s !== ctx.drawContext.drawingObject) {
                    segmentList.push(s);
                }
            }
            const newSnapShotPointer = snapShotPointer(e.pointer, segmentList, //.filter((s) => !(s instanceof TileWrapper)),
            [ctx.drawContext.drawingObject], ctx.drawContext.drawingObject.endPointer, 
            // PRECISION_UNITS[ctx.currentMetricUnit] * (ctx.currentMetricUnit === METRIC_UNITS[0] ? 1 : 2)
            PRECISION_UNITS[METRIC_UNITS[0]]);
            if (newSnapShotPointer.pointer && !e.disableSnapping) {
                pointer = newSnapShotPointer.pointer;
                ctx.snapContext.snapPointer = newSnapShotPointer.pointer;
                ctx.snapContext.snapType = newSnapShotPointer.type;
                ctx.snapContext.mousePointer = convertPointerToClientBox(pointer, ctx.svgElement);
            }
            else {
                ctx.snapContext.snapPointer = undefined;
                ctx.snapContext.snapType = -2;
                ctx.snapContext.mousePointer = convertPointerToClientBox(e.pointer, ctx.svgElement);
            }
            ctx.drawContext.drawingObject.endPointer = pointer;
            if (ctx.drawContext.tool === TOOLS.DRAW_LINE) {
                const closedArea = ctx.drawContext.drawingClosedArea;
                let minLength = dist(ctx.drawContext.drawingObject.startPointer, pointer);
                let endPointer = pointer;
                closedArea.shape.results.forEach((segment) => {
                    const crossedPointer = getCrossedPointBetweenSegments(ctx.drawContext.drawingObject, segment);
                    if (crossedPointer && dist(crossedPointer, ctx.drawContext.drawingObject.startPointer) > TOLERANCE_DISTANCE &&
                        minLength > dist(ctx.drawContext.drawingObject.startPointer, crossedPointer)) {
                        endPointer = crossedPointer;
                    }
                });
                ctx.drawContext.drawingObject.endPointer = endPointer;
            }
            const newLength = Number(getMetricWithUnit(ctx.drawContext.drawingObject.getLineLength(), ctx.currentMetricUnit, true));
            if (ctx.lineLength.toFixed(2) !== newLength.toFixed(2)) {
                ctx.lineLength = Number(newLength.toFixed(2));
            }
            return ctx;
        }),
        changeLayoutGap: assign((ctx, e) => {
            if (isTileWrapper(ctx.dragContext.selectedObject)) {
                const tileWrapper = ctx.dragContext.selectedObject;
                const wrapperWithSameLayouts = ctx.current.segments.filter((segment) => isTileWrapper(segment) && segment.tileLayout.id === tileWrapper.tileLayout.id);
                wrapperWithSameLayouts.forEach((wrapper) => {
                    wrapper.tileLayout = wrapper.tileLayout.withGapSize(e.newGap);
                    wrapper.updateLayout = true;
                });
            }
            return ctx;
        }),
        changeGroutColor: assign((ctx, e) => {
            if (isTileWrapper(ctx.dragContext.selectedObject)) {
                const tileWrapper = ctx.dragContext.selectedObject;
                const wrapperWithSameLayouts = ctx.current.segments.filter((segment) => isTileWrapper(segment) && segment.tileLayout.id === tileWrapper.tileLayout.id);
                wrapperWithSameLayouts.forEach((wrapper) => {
                    wrapper.tileLayout.groutColor = e.newGroutColor;
                    wrapper.update = true;
                });
            }
            return ctx;
        }),
        changeRotating: assign((ctx, e) => {
            if (isBuildingPart(ctx.dragContext.selectedObject)) {
                const buildingPart = ctx.dragContext.selectedObject;
                buildingPart.rotation = e.newAngle ?? 0;
            }
            else if (isTileWrapper(ctx.dragContext.selectedObject)) {
                const tileWrapper = ctx.dragContext.selectedObject;
                tileWrapper.rotation = e.newAngle ?? 0;
                const bounding = getShapeBoundingRect(tileWrapper.shape, tileWrapper.rotation);
                tileWrapper.tileLayout = tileWrapper.tileLayout.resize(bounding[2] / TILE_SCALE, bounding[3] / TILE_SCALE);
                tileWrapper.update = true;
            }
            return ctx;
        }),
        enterRotating: assign((ctx, e) => {
            return {
                ...ctx,
                snapContext: {
                    ...ctx.snapContext,
                    mousePointer: convertPointerToClientBox(e.pointer, ctx.svgElement),
                },
                dragContext: {
                    ...ctx.dragContext,
                    offset: e.pointer ?? ctx.dragContext.offset,
                    initialPos: e.pointer ?? ctx.dragContext.offset,
                    initialAngle: e.newAngle,
                    isRotating: true,
                },
            };
        }),
        rotatingObject: assign((ctx, e) => {
            let isSnapped = false;
            if (ctx.dragContext.selectedObject instanceof TileWrapper) {
                const tileWrapper = ctx.dragContext.selectedObject;
                if (e.pointer && ctx.dragContext.initialPos) {
                    const centerPointer = centroid(tileWrapper.shape);
                    const aVector = new Pointer(e.pointer.x - centerPointer.x, e.pointer.y - centerPointer.y);
                    const bVector = new Pointer(ctx.dragContext.initialPos.x - centerPointer.x, ctx.dragContext.initialPos.y - centerPointer.y);
                    const deltaAngle = Math.round(((Math.atan2(bVector.y, bVector.x) -
                        Math.atan2(aVector.y, aVector.x)) *
                        1800) /
                        Math.PI) / 10;
                    const newAngle = (ctx.dragContext.initialAngle - deltaAngle + 360) % 360;
                    if (Math.abs(tileWrapper.rotation) % 45 === 0 &&
                        Math.abs(tileWrapper.rotation - newAngle) < 10) {
                        isSnapped = true;
                    }
                    else {
                        tileWrapper.rotation = newAngle;
                        const delta = Math.abs(tileWrapper.rotation) % 45;
                        if (deltaAngle < 0 && 45 - delta < 10) {
                            tileWrapper.rotation += 45 - delta;
                        }
                        else if (deltaAngle > 0 && delta < 10) {
                            tileWrapper.rotation -= delta;
                        }
                    }
                    tileWrapper.update = true;
                    //update tilelayout size
                    // throttleRedraw(tileWrapper, tileWrapper.tileLayout);
                }
            }
            else if (isBuildingPart(ctx.dragContext.selectedObject)) {
                const buildingPart = ctx.dragContext.selectedObject;
                if (e.pointer && ctx.dragContext.initialPos) {
                    const centerPointer = new Pointer(buildingPart.startPointer.x + buildingPart.width / 2, buildingPart.startPointer.y + buildingPart.length / 2);
                    const aVector = new Pointer(e.pointer.x - centerPointer.x, e.pointer.y - centerPointer.y);
                    const bVector = new Pointer(ctx.dragContext.initialPos.x - centerPointer.x, ctx.dragContext.initialPos.y - centerPointer.y);
                    const deltaAngle = ((Math.atan2(bVector.y, bVector.x) -
                        Math.atan2(aVector.y, aVector.x)) *
                        180) /
                        Math.PI;
                    const newAngle = (ctx.dragContext.initialAngle - deltaAngle + 360) % 360;
                    if (Math.abs(buildingPart.rotation) % 45 === 0 &&
                        Math.abs(buildingPart.rotation - newAngle) < 10) {
                        isSnapped = true;
                    }
                    else {
                        const originalAngle = buildingPart.rotation;
                        buildingPart.rotation = newAngle;
                        const delta = Math.abs(buildingPart.rotation) % 45;
                        if (deltaAngle < 0 && 45 - delta < 10) {
                            buildingPart.rotation += 45 - delta;
                        }
                        else if (deltaAngle > 0 && delta < 10) {
                            buildingPart.rotation -= delta;
                        }
                        const closedArea = ctx.current.segments.find((s) => s instanceof ClosedArea &&
                            s.id === buildingPart.closedAreaId);
                        if (closedArea) {
                            if (!checkBuildingPartInRoom(buildingPart, closedArea)) {
                                buildingPart.rotation = originalAngle;
                            }
                        }
                    }
                }
            }
            return {
                ...ctx,
                snapContext: {
                    ...ctx.snapContext,
                    mousePointer: convertPointerToClientBox(e.pointer, ctx.svgElement),
                },
                dragContext: {
                    ...ctx.dragContext,
                    offset: e.pointer ?? ctx.dragContext.offset,
                },
            };
        }),
        enterResizingBuildingPart: assign((ctx, e) => {
            return {
                ...ctx,
                snapContext: {
                    ...ctx.snapContext,
                    mousePointer: convertPointerToClientBox(e.pointer, ctx.svgElement),
                },
                dragContext: {
                    ...ctx.dragContext,
                    offset: e.pointer ?? ctx.dragContext.offset,
                    resizeIndicator: e.index ?? undefined,
                },
            };
        }),
        resizingBuildingPart: assign((ctx, e) => {
            if (isBuildingPart(ctx.dragContext.selectedObject)) {
                const buildingPart = ctx.dragContext.selectedObject;
                const [pointer1, pointer2, pointer3, pointer4] = buildingPart.getRectPoints();
                if (e.pointer &&
                    ctx.dragContext.offset &&
                    ctx.dragContext.resizeIndicator) {
                    const vector = new Line(ctx.dragContext.offset, e.pointer);
                    let widthLine, heightLine;
                    switch (ctx.dragContext.resizeIndicator) {
                        case 1:
                            widthLine = new Line(pointer1, pointer2);
                            heightLine = new Line(pointer1, pointer3);
                            break;
                        case 2:
                            widthLine = new Line(pointer2, pointer1);
                            heightLine = new Line(pointer2, pointer4);
                            break;
                        case 3:
                            widthLine = new Line(pointer3, pointer4);
                            heightLine = new Line(pointer3, pointer1);
                            break;
                        case 4:
                            widthLine = new Line(pointer4, pointer3);
                            heightLine = new Line(pointer4, pointer2);
                            break;
                        case 5:
                            widthLine = new Line(pointer1, pointer2);
                            heightLine = new Line(pointer1, pointer3);
                            break;
                        case 6:
                            widthLine = new Line(pointer3, pointer4);
                            heightLine = new Line(pointer3, pointer1);
                            break;
                        case 7:
                            widthLine = new Line(pointer1, pointer2);
                            heightLine = new Line(pointer1, pointer3);
                            break;
                        case 8:
                            widthLine = new Line(pointer4, pointer3);
                            heightLine = new Line(pointer4, pointer2);
                            break;
                    }
                    const deltaWidth = vector.getLineLength() *
                        Math.cos(getAngleBetweenTwoLines(vector, widthLine));
                    const deltaHeight = vector.getLineLength() *
                        Math.cos(getAngleBetweenTwoLines(vector, heightLine));
                    let realDx = deltaWidth, realDy = deltaHeight;
                    if (ctx.dragContext.resizeIndicator <= 4) {
                        if (Math.abs(deltaWidth) > Math.abs(deltaHeight)) {
                            realDy =
                                (deltaWidth * buildingPart.length) / buildingPart.width;
                        }
                        else {
                            realDx =
                                (deltaHeight * buildingPart.width) / buildingPart.length;
                        }
                    }
                    else if (ctx.dragContext.resizeIndicator <= 6) {
                        realDx = 0;
                    }
                    else {
                        realDy = 0;
                    }
                    const orgCenterPos = buildingPart.startPointer.translate(buildingPart.width / 2, buildingPart.length / 2);
                    const radRotation = buildingPart.rotation * Math.PI / 180;
                    let fixedPointer;
                    switch (ctx.dragContext.resizeIndicator) {
                        case 1:
                            fixedPointer =
                                getRotatedPointer(buildingPart.startPointer.translate(buildingPart.width, buildingPart.length), orgCenterPos, radRotation);
                            break;
                        case 2:
                            fixedPointer =
                                getRotatedPointer(buildingPart.startPointer.translate(0, buildingPart.length), orgCenterPos, radRotation);
                            break;
                        case 3:
                            fixedPointer =
                                getRotatedPointer(buildingPart.startPointer.translate(buildingPart.width, 0), orgCenterPos, radRotation);
                            break;
                        case 4:
                            fixedPointer =
                                getRotatedPointer(buildingPart.startPointer, orgCenterPos, radRotation);
                            break;
                        case 5:
                            fixedPointer =
                                getRotatedPointer(buildingPart.startPointer.translate(buildingPart.width / 2, buildingPart.length), orgCenterPos, radRotation);
                            break;
                        case 6:
                            fixedPointer =
                                getRotatedPointer(buildingPart.startPointer.translate(buildingPart.width / 2, 0), orgCenterPos, radRotation);
                            break;
                        case 7:
                            fixedPointer =
                                getRotatedPointer(buildingPart.startPointer.translate(buildingPart.width, buildingPart.length / 2), orgCenterPos, radRotation);
                            break;
                        case 8:
                            fixedPointer =
                                getRotatedPointer(buildingPart.startPointer.translate(0, buildingPart.length / 2), orgCenterPos, radRotation);
                            break;
                        default:
                            return;
                    }
                    buildingPart.width -= realDx;
                    buildingPart.length -= realDy;
                    let alpha = buildingPart.width < 1e-2 ? Math.PI / 2 : Math.atan(buildingPart.length / buildingPart.width);
                    const diag = Math.sqrt(buildingPart.width * buildingPart.width + buildingPart.length * buildingPart.length);
                    let distCenter = diag / 2;
                    switch (ctx.dragContext.resizeIndicator) {
                        case 1:
                            alpha = -Math.PI / 2 + alpha;
                            break;
                        case 2:
                            alpha = Math.PI / 2 - alpha;
                            break;
                        case 3:
                            alpha = -Math.PI / 2 - alpha;
                            break;
                        case 4:
                            alpha = Math.PI / 2 + alpha;
                            break;
                        case 5:
                            alpha = 0;
                            distCenter = buildingPart.length / 2;
                            break;
                        case 6:
                            alpha = Math.PI;
                            distCenter = buildingPart.length / 2;
                            break;
                        case 7:
                            alpha = -Math.PI / 2;
                            distCenter = buildingPart.width / 2;
                            break;
                        case 8:
                            alpha = Math.PI / 2;
                            distCenter = buildingPart.width / 2;
                            break;
                        default:
                            return;
                    }
                    const newCenterPos = getRotatedPointer(fixedPointer.translate(0, -distCenter), fixedPointer, radRotation + alpha);
                    let prevStartPointer = buildingPart.startPointer;
                    let prevEndPointer = buildingPart.endPointer;
                    buildingPart.startPointer = newCenterPos.translate(-buildingPart.width / 2, -buildingPart.length / 2);
                    buildingPart.endPointer = buildingPart.startPointer.translate(buildingPart.width, buildingPart.length);
                    const closedArea = ctx.current.segments.find((s) => s instanceof ClosedArea &&
                        s.id === buildingPart.closedAreaId);
                    if (closedArea) {
                        if (!checkBuildingPartInRoom(buildingPart, closedArea)) {
                            buildingPart.width += realDx;
                            buildingPart.length += realDy;
                            buildingPart.startPointer = prevStartPointer;
                            buildingPart.endPointer = prevEndPointer;
                        }
                    }
                }
            }
            return {
                ...ctx,
                snapContext: {
                    ...ctx.snapContext,
                    mousePointer: convertPointerToClientBox(e.pointer, ctx.svgElement),
                },
                dragContext: {
                    ...ctx.dragContext,
                    offset: e.pointer ?? ctx.dragContext.offset,
                },
            };
        }),
        updateLastPointer: assign((ctx, _) => {
            const updatedCurrent = getNewLinesByCrossed(ctx.current.segments, ctx.drawContext.drawingObject).filter((s) => !(s instanceof TileWrapper) && !(s instanceof ClosedArea));
            const { closedAreas, tileWrappers: updatedTiles, segments } = getUpdatedTiles(updatedCurrent, getClosedAreas(ctx.current.segments), getTileLayouts(ctx.current.segments), ctx.layoutContext.layoutGeometries, ctx.layoutContext.layout, ctx.layoutContext.selectedLayoutGeometryID, undefined);
            return {
                ...ctx,
                current: {
                    ...ctx.current,
                    segments: [...closedAreas, ...updatedTiles, ...segments],
                },
                lineLength: 0,
                drawContext: {
                    ...ctx.drawContext,
                    lastPointer: ctx.drawContext.drawingObject.endPointer,
                    drawingObject: undefined,
                },
            };
        }),
        cancelDrawing: assign((ctx, e) => {
            return {
                ...ctx,
                current: ctx.drawContext.drawingObject
                    ? ctx.past.pop() || initialStackContext()
                    : ctx.current,
                drawContext: initialDrawContext(),
                dragContext: initialDragContext(),
                snapContext: {
                    ...initialSnapContext(),
                    mousePointer: ctx.snapContext.mousePointer,
                },
            };
        }),
        updateClosedShape: assign((ctx, e) => {
            const updatedCurrent = getNewLinesByCrossed(ctx.current.segments, ctx.drawContext.drawingObject);
            const { closedAreas, tileWrappers: updatedTiles, segments } = getUpdatedTiles(updatedCurrent, getClosedAreas(ctx.current.segments), getTileLayouts(ctx.current.segments), ctx.layoutContext.layoutGeometries, ctx.layoutContext.layout, ctx.layoutContext.selectedLayoutGeometryID, ctx.drawContext.drawingObject
                ? [ctx.drawContext.drawingObject.id]
                : undefined);
            return {
                ...ctx,
                current: {
                    ...ctx.current,
                    segments: [
                        ...closedAreas,
                        ...updatedTiles,
                        ...segments
                            .sort((a, b) => {
                            if (isBuildingPart(a) && !isBuildingPart(b)) {
                                return 1;
                            }
                            else if (!isBuildingPart(a) && isBuildingPart(b)) {
                                return -1;
                            }
                            else {
                                return 0; //a.id.localeCompare(b.id);
                            }
                        }),
                    ],
                },
                drawContext: initialDrawContext(),
                dragContext: initialDragContext(),
                snapContext: {
                    ...initialSnapContext(),
                    mousePointer: ctx.snapContext.mousePointer,
                },
            };
        }),
        enterSelect: assign((ctx, e) => ({
            ...ctx,
            dragContext: {
                ...ctx.dragContext,
                selectedObject: e.segment,
                selectedCPObj: undefined,
                snapTile: e.snapTile,
            },
        })),
        selectPointer: assign((ctx, e) => ({
            ...ctx,
            dragContext: {
                ...ctx.dragContext,
                selectedCPObj: e.pointer,
            },
        })),
        enterNormal: assign((ctx, e) => ({
            ...ctx,
            drawContext: initialDrawContext(e.tool),
            dragContext: initialDragContext(),
            snapContext: {
                ...ctx.snapContext,
                mousePointer: ctx.snapContext.mousePointer,
            },
        })),
        dragging: assign((ctx, e) => {
            let pointer = e.pointer;
            ctx.snapContext.mousePointer = convertPointerToClientBox(pointer, ctx.svgElement);
            if (ctx.dragContext.selectedCPObj) {
                const newSnapShotPointer = getSnapShotPointer(pointer, ctx.current.segments, ctx.dragContext.selectedCPObj, 
                // PRECISION_UNITS[ctx.currentMetricUnit] * (ctx.currentMetricUnit === METRIC_UNITS[0] ? 1 : 2)
                PRECISION_UNITS[METRIC_UNITS[0]]);
                if (newSnapShotPointer.pointer) {
                    pointer = newSnapShotPointer.pointer;
                    ctx.snapContext.snapType = newSnapShotPointer.type;
                    ctx.snapContext.snapPointer = pointer;
                    ctx.snapContext.mousePointer = convertPointerToClientBox(pointer, ctx.svgElement);
                }
                else {
                    ctx.snapContext.snapPointer = undefined;
                }
                // }
                ctx.dragContext.selectedCPObj = translatePointerWithSegments(pointer.x - ctx.dragContext.offset.x, pointer.y - ctx.dragContext.offset.y, ctx.dragContext.selectedCPObj, ctx.current.segments);
                if (ctx.snapContext.snapPointer) {
                    ctx.dragContext.selectedCPObj = translatePointerWithSegments(ctx.dragContext.selectedCPObj.x - ctx.snapContext.snapPointer.x, ctx.dragContext.selectedCPObj.y - ctx.snapContext.snapPointer.y, ctx.dragContext.selectedCPObj, ctx.current.segments);
                    pointer = ctx.dragContext.selectedCPObj;
                }
                const ownedSegments = getSegmentsOfPoint(ctx.current.segments, pointer);
                ownedSegments.forEach((item) => {
                    if (isLine(item) && item.isDashed()) {
                        item.translate(0, 0, ctx.current.segments);
                    }
                });
                ctx.dragContext.offset = pointer;
            }
            else if (ctx.dragContext.selectedObject) {
                if (ctx.dragContext.selectedObject instanceof Line) {
                    const selectedLine = ctx.dragContext.selectedObject;
                    const realOffset = selectedLine.getRealTransition(pointer.x - ctx.dragContext.offset.x, pointer.y - ctx.dragContext.offset.y, ctx.svgSize);
                    const transStartPointer = selectedLine.startPointer.translate(realOffset.dx, realOffset.dy);
                    const newSSSPointer = getSnapShotPointer(transStartPointer, ctx.current.segments, selectedLine.startPointer, 
                    // PRECISION_UNITS[ctx.currentMetricUnit] * (ctx.currentMetricUnit === METRIC_UNITS[0] ? 1 : 2)
                    PRECISION_UNITS[METRIC_UNITS[0]]);
                    const distStart = newSSSPointer.pointer
                        ? dist(transStartPointer, newSSSPointer.pointer)
                        : Number.MAX_VALUE;
                    const transEndPointer = selectedLine.endPointer.translate(realOffset.dx, realOffset.dy);
                    const newESSPointer = getSnapShotPointer(transEndPointer, ctx.current.segments, selectedLine.endPointer, 
                    // PRECISION_UNITS[ctx.currentMetricUnit] * (ctx.currentMetricUnit === METRIC_UNITS[0] ? 1 : 2)
                    PRECISION_UNITS[METRIC_UNITS[0]]);
                    const distEnd = newESSPointer.pointer
                        ? dist(transEndPointer, newESSPointer.pointer)
                        : Number.MAX_VALUE;
                    const newSnapShotPointer = distStart < distEnd ? newSSSPointer : newESSPointer;
                    let realDx, realDy;
                    if (newSnapShotPointer.pointer) {
                        realDx =
                            newSnapShotPointer.pointer.x -
                                (distStart < distEnd
                                    ? selectedLine.startPointer.x
                                    : selectedLine.endPointer.x);
                        realDy =
                            newSnapShotPointer.pointer.y -
                                (distStart < distEnd
                                    ? selectedLine.startPointer.y
                                    : selectedLine.endPointer.y);
                        pointer = ctx.dragContext.offset.translate(realDx, realDy);
                        ctx.snapContext.mousePointer = convertPointerToClientBox(pointer, ctx.svgElement);
                        ctx.snapContext.snapPointer = newSnapShotPointer.pointer;
                        ctx.snapContext.snapType = newSnapShotPointer.type;
                    }
                    else {
                        realDx = realOffset.dx;
                        realDy = realOffset.dy;
                        ctx.snapContext.snapPointer = undefined;
                    }
                    selectedLine.translate(realDx, realDy, ctx.current.segments);
                    const rooms = getClosedAreaByLine(ctx.current.segments, ctx.dragContext.selectedObject);
                    for (const room of rooms) {
                        const buildingParts = getAllBuildingPartsInRoom(ctx.current.segments, room);
                        for (const buildingPart of buildingParts) {
                            if (!checkBuildingPartInRoom(buildingPart, room)) {
                                buildingPart.translate(realDx, realDy, []);
                            }
                        }
                    }
                    const buildingParts = getAllBuildingPartsInSegment(ctx.current.segments, selectedLine);
                    for (const buildingPart of buildingParts) {
                        if (buildingPart instanceof Door) {
                            buildingPart.translate(realDx, realDy, []);
                        }
                        else {
                            buildingPart.parentId = 0;
                        }
                    }
                    ctx.dragContext.offset = pointer;
                }
                else if (ctx.dragContext.selectedObject instanceof Door) {
                    const door = ctx.dragContext.selectedObject;
                    const parentSegment = getParentLine(ctx.current.segments, door.parentId);
                    if (parentSegment && parentSegment instanceof Line) {
                        const distance = getDistanceLineAndPointer(parentSegment, pointer);
                        if (distance < PRECISION * 2) {
                            const dPointer1 = getDistancePointer(parentSegment, ctx.dragContext.offset);
                            const dPointer2 = getDistancePointer(parentSegment, pointer);
                            const tempVector = new Line(ctx.dragContext.offset, pointer);
                            const angle = tempVector.getLineAngle() - parentSegment.getLineAngle();
                            const deltaD = tempVector.getLineLength() * Math.cos(angle);
                            let ySign = dPointer2.y - dPointer1.y >= 0 ? 1 : -1;
                            let xSign = dPointer2.x - dPointer1.x >= 0 ? 1 : -1;
                            ctx.dragContext.selectedObject.translate(xSign *
                                Math.abs(deltaD * Math.cos(parentSegment.getLineAngle())), ySign *
                                Math.abs(deltaD * Math.sin(parentSegment.getLineAngle())), []);
                            //check flip
                            const newV = new Line(door.startPointer, pointer);
                            const newVAngle = newV.getLineAngle() - parentSegment.getLineAngle();
                            if (!door.openToOutSide) {
                                if ((newVAngle > 0 && newVAngle < Math.PI / 2) ||
                                    (newVAngle > -Math.PI * 2 && newVAngle < (-Math.PI * 3) / 2)) {
                                    door.openToOutSide = true;
                                }
                            }
                            else {
                                if (!(newVAngle > 0 && newVAngle < Math.PI / 2) &&
                                    !(newVAngle > -Math.PI * 2 && newVAngle < (-Math.PI * 3) / 2)) {
                                    door.openToOutSide = false;
                                }
                            }
                            ctx.dragContext.offset = pointer;
                        }
                        else {
                            door.parentId = 0;
                            const length = door.getLineLength();
                            door.startPointer = new Pointer(pointer.x - length / 2, pointer.y);
                            door.endPointer = door.startPointer.translate(length, 0);
                            ctx.dragContext.offset = pointer;
                        }
                    }
                    else {
                        const { line, pointer: dPointer } = getSnapBuildingPartPoints(ctx.current.segments, pointer);
                        if (line) {
                            const length = door.getLineLength();
                            const angle = line.getLineAngle();
                            door.startPointer = new Pointer(dPointer.x - (length / 2) * Math.cos(angle), dPointer.y + (length / 2) * Math.sin(angle));
                            door.endPointer = new Pointer(dPointer.x + (length / 2) * Math.cos(angle), dPointer.y - (length / 2) * Math.sin(angle));
                            door.parentId = line.id;
                        }
                        else {
                            ctx.dragContext.selectedObject.translate(pointer.x - ctx.dragContext.offset.x, pointer.y - ctx.dragContext.offset.y, []);
                        }
                        ctx.dragContext.offset = pointer;
                    }
                }
                else if (isBuildingPart(ctx.dragContext.selectedObject) &&
                    ctx.dragContext.selectedObject instanceof BuildingPart) {
                    let resultLine, resultPointer, resultElementPointer, resultElementLinePointer, resultDistance = Infinity;
                    const buildingPart = ctx.dragContext.selectedObject;
                    const closedArea = ctx.current.segments.find((s) => s instanceof ClosedArea &&
                        s.id === buildingPart.closedAreaId);
                    let isOutRoom = false;
                    if (!checkBuildingPartInRoom(buildingPart, closedArea)) {
                        isOutRoom = true;
                    }
                    let isPrevSnapped = false;
                    const [pointer1, pointer2, pointer3, pointer4] = buildingPart.getRectPoints();
                    const { line: line1, pointer: dPointer1 } = getSnapBuildingPartPoints(ctx.current.segments, pointer1);
                    if (dPointer1) {
                        resultLine = line1;
                        resultPointer = dPointer1;
                        resultElementPointer = pointer1;
                        resultDistance = dist(dPointer1, pointer1);
                        if (getAngleBetweenTwoLinesInPI(line1, new Line(pointer1, pointer2)) <
                            getAngleBetweenTwoLinesInPI(line1, new Line(pointer1, pointer3))) {
                            resultElementLinePointer = pointer2;
                        }
                        else {
                            resultElementLinePointer = pointer3;
                        }
                        isPrevSnapped =
                            line1.id === buildingPart.parentId;
                    }
                    const { line: line2, pointer: dPointer2 } = getSnapBuildingPartPoints(ctx.current.segments, pointer2);
                    if (dPointer2 &&
                        ((dist(dPointer2, pointer2) < resultDistance && !isPrevSnapped) ||
                            line2.id === buildingPart.parentId)) {
                        resultLine = line2;
                        resultPointer = dPointer2;
                        resultElementPointer = pointer2;
                        resultDistance = dist(dPointer2, pointer2);
                        if (getAngleBetweenTwoLinesInPI(line2, new Line(pointer2, pointer1)) <
                            getAngleBetweenTwoLinesInPI(line2, new Line(pointer2, pointer4))) {
                            resultElementLinePointer = pointer1;
                        }
                        else {
                            resultElementLinePointer = pointer4;
                        }
                        isPrevSnapped =
                            line2.id === buildingPart.parentId;
                    }
                    const { line: line3, pointer: dPointer3 } = getSnapBuildingPartPoints(ctx.current.segments, pointer3);
                    if (dPointer3 &&
                        ((dist(dPointer3, pointer3) < resultDistance && !isPrevSnapped) ||
                            line3.id === buildingPart.parentId)) {
                        resultLine = line3;
                        resultPointer = dPointer3;
                        resultElementPointer = pointer3;
                        resultDistance = dist(dPointer3, pointer3);
                        if (getAngleBetweenTwoLinesInPI(line3, new Line(pointer3, pointer1)) <
                            getAngleBetweenTwoLinesInPI(line3, new Line(pointer3, pointer4))) {
                            resultElementLinePointer = pointer1;
                        }
                        else {
                            resultElementLinePointer = pointer4;
                        }
                        isPrevSnapped =
                            line3.id === buildingPart.parentId;
                    }
                    const { line: line4, pointer: dPointer4 } = getSnapBuildingPartPoints(ctx.current.segments, pointer4);
                    if (dPointer4 &&
                        ((dist(dPointer4, pointer4) < resultDistance && !isPrevSnapped) ||
                            line4.id === buildingPart.parentId)) {
                        resultLine = line4;
                        resultPointer = dPointer4;
                        resultElementPointer = pointer4;
                        resultDistance = dist(dPointer4, pointer2);
                        if (getAngleBetweenTwoLinesInPI(line4, new Line(pointer4, pointer2)) <
                            getAngleBetweenTwoLinesInPI(line4, new Line(pointer4, pointer3))) {
                            resultElementLinePointer = pointer2;
                        }
                        else {
                            resultElementLinePointer = pointer3;
                        }
                    }
                    const transX = pointer.x - ctx.dragContext.offset.x;
                    const transY = pointer.y - ctx.dragContext.offset.y;
                    buildingPart.translate(transX, transY, ctx.current.segments);
                    ctx.dragContext.offset = pointer;
                    if (closedArea && !isOutRoom) {
                        if (!checkBuildingPartInRoom(buildingPart, closedArea)) {
                            buildingPart.translate(-transX, -transY, ctx.current.segments);
                        }
                    }
                    if (resultLine &&
                        resultElementPointer &&
                        resultElementLinePointer &&
                        resultPointer) {
                        const snapElementLine = new Line(resultElementPointer, resultElementLinePointer);
                        let centerAngle = snapElementLine.getLineAngle();
                        if (centerAngle < 0)
                            centerAngle += Math.PI;
                        let lineAngle = resultLine.getLineAngle();
                        if (lineAngle < 0)
                            lineAngle += Math.PI;
                        const deltaAngle = (((centerAngle - lineAngle) * 180) / Math.PI) % 90;
                        if (Math.abs(deltaAngle) < 20 ||
                            Math.abs(deltaAngle) > 70 ||
                            (dist(resultElementPointer, resultPointer) < 1 &&
                                !buildingPart.parentId) ||
                            (dist(resultElementPointer, resultPointer) < 5 &&
                                !!buildingPart.parentId)) {
                            buildingPart.rotation += deltaAngle;
                            if (checkBuildingPartInRoom(buildingPart, closedArea)) {
                                buildingPart.parentId = resultLine.id;
                            }
                            else {
                                buildingPart.parentId = 0;
                            }
                            buildingPart.rotation -= deltaAngle;
                        }
                        else {
                            buildingPart.parentId = 0;
                        }
                    }
                    else {
                        buildingPart.parentId = 0;
                    }
                }
                else if (ctx.dragContext.selectedObject instanceof TileWrapper) {
                    if (ctx.dragContext.snapTile) {
                        if (pointer && ctx.dragContext.offset) {
                            ctx.dragContext.selectedObject.translate(pointer.x - ctx.dragContext.offset.x, pointer.y - ctx.dragContext.offset.y, ctx.current.segments);
                            const { snapLinePointers, snapTilepointers, lines, minDistX, minDistY, resultElementLinePointer, deltaAngle, } = getTileSnapPointers(ctx.dragContext.selectedObject, ctx.dragContext.snapTile);
                            if (snapTilepointers.length === 1 &&
                                ctx.dragContext.snapTile.segments.every((s) => s instanceof LineSegment)) {
                                ctx.snapContext.mousePointer = convertPointerToClientBox(pointer, ctx.svgElement);
                                if (resultElementLinePointer &&
                                    (Math.abs(deltaAngle) < 20 ||
                                        Math.abs(deltaAngle) > 70 ||
                                        dist(snapTilepointers[0], snapLinePointers[0]) < 5)) {
                                    ctx.dragContext.selectedObject.parentId = lines[0].id;
                                    ctx.dragContext.selectedObject.snapTile =
                                        ctx.dragContext.snapTile;
                                }
                                else {
                                    ctx.dragContext.selectedObject.parentId = 0;
                                    ctx.dragContext.selectedObject.snapTile = undefined;
                                    pointer = pointer.translate(snapLinePointers[0].x - snapTilepointers[0].x, snapLinePointers[0].y - snapTilepointers[0].y);
                                    ctx.dragContext.selectedObject.translate(snapLinePointers[0].x - snapTilepointers[0].x, snapLinePointers[0].y - snapTilepointers[0].y, ctx.current.segments);
                                }
                            }
                            else if (snapTilepointers.length > 1 &&
                                (minDistX !== Infinity || minDistY !== Infinity)) {
                                pointer = pointer.translate(minDistX === Infinity ? 0 : minDistX, minDistY === Infinity ? 0 : minDistY);
                                ctx.snapContext.mousePointer = convertPointerToClientBox(pointer, ctx.svgElement);
                                ctx.dragContext.selectedObject.translate(minDistX === Infinity ? 0 : minDistX, minDistY === Infinity ? 0 : minDistY, ctx.current.segments);
                                ctx.dragContext.selectedObject.parentId = 0;
                                ctx.dragContext.selectedObject.snapTile = undefined;
                            }
                            else {
                                ctx.dragContext.selectedObject.parentId = 0;
                                ctx.dragContext.selectedObject.snapTile = undefined;
                            }
                        }
                        ctx.dragContext.offset = pointer;
                    }
                    // const tileWrapper = ctx.dragContext.selectedObject as TileWrapper;
                    // throttleRedraw(tileWrapper, tileWrapper.tileLayout);
                }
                else {
                    if (pointer && ctx.dragContext.offset) {
                        ctx.dragContext.selectedObject.translate(pointer.x - ctx.dragContext.offset.x, pointer.y - ctx.dragContext.offset.y, ctx.current.segments);
                    }
                    ctx.dragContext.offset = pointer;
                }
                ctx.dragContext.selectedObject.update = true;
            }
            return ctx;
        }),
        draggingEnd: assign((ctx, _) => {
            let updatedCurrent = [...ctx.current.segments];
            if (ctx.dragContext.selectedObject) {
                if (isLine(ctx.dragContext.selectedObject) ||
                    ctx.dragContext.selectedObject instanceof Arc) {
                    let movingSegments = [];
                    movingSegments = [
                        ...getSegmentsOfPoint(ctx.current.segments, ctx.dragContext.selectedObject.startPointer),
                    ];
                    movingSegments = [
                        ...movingSegments,
                        ...getSegmentsOfPoint(ctx.current.segments, ctx.dragContext.selectedObject.endPointer),
                    ];
                    if (isLine(ctx.dragContext.selectedObject) &&
                        ctx.dragContext.selectedObject.isDashed()) {
                        // nothing for dashed lines
                    }
                    else {
                        updatedCurrent = getNewLinesByCrossed(ctx.current.segments, ctx.dragContext.selectedObject);
                        for (const s of movingSegments) {
                            if (s !== ctx.dragContext.selectedObject &&
                                updatedCurrent.includes(s)) {
                                updatedCurrent = getNewLinesByCrossed(updatedCurrent, s);
                            }
                        }
                    }
                }
                else if (isBuildingPart(ctx.dragContext.selectedObject) &&
                    !isDoor(ctx.dragContext.selectedObject)) {
                    const buildingPart = ctx.dragContext.selectedObject;
                    const originalStart = buildingPart.startPointer.translate(0, 0);
                    const originalEnd = buildingPart.endPointer.translate(0, 0);
                    if (buildingPart.parentId) {
                        const snapInfo = buildingPart.getSnapInfos(ctx.current.segments);
                        const length = buildingPart.length / 2;
                        const width = buildingPart.width / 2;
                        let centerPointer = new Pointer(buildingPart.startPointer.x + width, buildingPart.startPointer.y + length);
                        const deltaWidth = centerPointer.x - snapInfo.snapLinePointer1.x;
                        const deltaHeight = -(centerPointer.y - snapInfo.snapLinePointer1.y);
                        const deltaAngle = (snapInfo.deltaAngle * Math.PI) / 180;
                        centerPointer = new Pointer(deltaWidth * Math.cos(deltaAngle) +
                            deltaHeight * Math.sin(deltaAngle) +
                            snapInfo.snapLinePointer1.x, -(deltaHeight * Math.cos(deltaAngle) -
                            deltaWidth * Math.sin(deltaAngle)) + snapInfo.snapLinePointer1.y).translate(snapInfo.distance.deltaX, snapInfo.distance.deltaY);
                        buildingPart.startPointer = centerPointer.translate(-width, -length);
                        buildingPart.endPointer = centerPointer.translate(width, -length);
                        buildingPart.parentId = 0;
                        buildingPart.rotation += snapInfo.deltaAngle;
                        const closedArea = ctx.current.segments.find((s) => s instanceof ClosedArea &&
                            s.id === buildingPart.closedAreaId);
                        if (closedArea && !checkBuildingPartInRoom(buildingPart, closedArea)) {
                            buildingPart.startPointer = originalStart;
                            buildingPart.endPointer = originalEnd;
                            buildingPart.rotation -= snapInfo.deltaAngle;
                        }
                    }
                }
                else if (isTileWrapper(ctx.dragContext.selectedObject)) {
                    const tileWrapper = ctx.dragContext.selectedObject;
                    const bounding = getShapeBoundingRect(tileWrapper.shape, tileWrapper.rotation);
                    tileWrapper.tileLayout = tileWrapper.tileLayout.resize(bounding[2] / TILE_SCALE, bounding[3] / TILE_SCALE);
                    tileWrapper.update = true;
                    if (ctx.dragContext.isRotating)
                        tileWrapper.updateLayout = true;
                }
            }
            updatedCurrent = updatedCurrent.filter((s) => !(s instanceof TileWrapper) && !(s instanceof ClosedArea));
            getClosedAreas(ctx.current.segments).forEach((closedArea) => {
                refreshClosedArea(updatedCurrent, closedArea);
            });
            splitLinesForDashed(updatedCurrent);
            const { closedAreas, tileWrappers: updatedTiles, segments } = getUpdatedTiles(updatedCurrent, getClosedAreas(ctx.current.segments), getTileLayouts(ctx.current.segments), ctx.layoutContext.layoutGeometries, ctx.layoutContext.layout, ctx.layoutContext.selectedLayoutGeometryID, ctx.dragContext.selectedCPObj
                ? getSegmentsOfPoint(ctx.current.segments, ctx.dragContext.selectedCPObj).map((s) => s.id)
                : ctx.dragContext.selectedObject &&
                    !(ctx.dragContext.selectedObject instanceof TileWrapper) &&
                    !(ctx.dragContext.selectedObject instanceof ClosedArea)
                    ? [
                        ctx.dragContext.selectedObject.id,
                        ...getSegmentsOfPoint(ctx.current.segments, ctx.dragContext.selectedObject.startPointer).map((s) => s.id),
                        ...getSegmentsOfPoint(ctx.current.segments, ctx.dragContext.selectedObject.endPointer).map((s) => s.id),
                    ]
                    : undefined);
            updatedTiles.forEach((tileWrapper) => {
                if (tileWrapper.parentId && tileWrapper.snapTile) {
                    const snapInfos = tileWrapper.getSnapInfos();
                    const svgPointers = getSvgPointersOfTile(tileWrapper.snapTile, tileWrapper);
                    const index = svgPointers.findIndex((p) => p.equals(snapInfos.snapPointer));
                    if (index > -1) {
                        tileWrapper.rotation += snapInfos.deltaAngle;
                        tileWrapper.offset = new Pointer(0, 0);
                        const originalPoint = getSvgPointersOfTile(tileWrapper.snapTile, tileWrapper).at(index);
                        tileWrapper.offset = new Pointer(snapInfos.snapLinePointer1.x - originalPoint.x, snapInfos.snapLinePointer1.y - originalPoint.y);
                        const bounding = getShapeBoundingRect(tileWrapper.shape);
                        tileWrapper.tileLayout = tileWrapper.tileLayout.resize(tileWrapper.tileLayout.width +
                            (Math.abs(tileWrapper.offset.x) > bounding[2] / 2
                                ? Math.abs(tileWrapper.offset.x) - bounding[2] / 2
                                : 0), tileWrapper.tileLayout.height +
                            (Math.abs(tileWrapper.offset.y) > bounding[3] / 2
                                ? Math.abs(tileWrapper.offset.y) - bounding[3] / 2
                                : 0));
                    }
                    tileWrapper.parentId = 0;
                    tileWrapper.snapTile = undefined;
                    tileWrapper.update = true;
                }
            });
            return {
                ...ctx,
                current: updatedCurrent.length > 0
                    ? {
                        ...ctx.current,
                        segments: [...closedAreas, ...updatedTiles, ...segments],
                    }
                    : ctx.current,
                dragContext: {
                    ...ctx.dragContext,
                    snapTile: undefined,
                    isRotating: false,
                },
                snapContext: initialSnapContext(),
            };
        }),
        updateMousePointer: assign((ctx, e) => ({
            ...ctx,
            snapContext: {
                ...ctx.snapContext,
                mousePointer: convertPointerToClientBox(e.pointer, ctx.svgElement),
            },
            dragContext: {
                ...ctx.dragContext,
                offset: e.offset ?? ctx.dragContext.offset,
            },
        })),
        updateOffset: assign((ctx, e) => {
            return {
                ...ctx,
                dragContext: {
                    ...ctx.dragContext,
                    offset: e.pointer,
                },
            };
        }),
        createdArc: assign((ctx, e) => {
            for (const segment of ctx.current.segments) {
                const builtInParts = getAllBuildingPartsInSegment(ctx.current.segments, ctx.dragContext.selectedObject);
                for (const bP of builtInParts) {
                    bP.parentId = 0;
                    if (bP instanceof Door) {
                        const length = bP.getLineLength();
                        bP.endPointer = bP.startPointer.translate(length, 0);
                    }
                }
                if (segment === ctx.dragContext.selectedObject) {
                    const childIndex = ctx.current.segments.indexOf(ctx.dragContext.selectedObject);
                    ctx.current.segments.splice(childIndex, 1);
                    ctx.current.segments.push(e.segment);
                    break;
                }
            }
            ctx.dragContext.selectedObject = e.segment;
            const newLength = Number(getMetricWithUnit(ctx.dragContext.selectedObject.getLineLength(), ctx.currentMetricUnit, true));
            if (ctx.lineLength.toFixed(2) !== newLength.toFixed(2)) {
                ctx.lineLength = Number(newLength.toFixed(2));
            }
            const { closedAreas, tileWrappers: updatedTiles, segments } = getUpdatedTiles(ctx.current.segments, getClosedAreas(ctx.current.segments), getTileLayouts(ctx.current.segments), ctx.layoutContext.layoutGeometries, ctx.layoutContext.layout, ctx.layoutContext.selectedLayoutGeometryID, ctx.dragContext.selectedObject
                ? [ctx.dragContext.selectedObject.id]
                : undefined);
            return {
                ...ctx,
                current: {
                    ...ctx.current,
                    segments: [
                        ...closedAreas,
                        ...updatedTiles,
                        ...segments
                    ],
                },
            };
        }),
        changeArc: assign((ctx, e) => {
            let pointer = e.pointer;
            ctx.snapContext.mousePointer = convertPointerToClientBox(e.pointer, ctx.svgElement);
            const arcObject = ctx.dragContext.selectedObject;
            const newSnapShotPointer = getSnapShotPointer(e.pointer, ctx.current.segments.filter((s) => s !== arcObject), arcObject.heightPointer, 
            // PRECISION_UNITS[ctx.currentMetricUnit] * (ctx.currentMetricUnit === METRIC_UNITS[0] ? 1 : 2)
            PRECISION_UNITS[METRIC_UNITS[0]]);
            if (newSnapShotPointer.pointer && !e.disableSnapping) {
                pointer = newSnapShotPointer.pointer;
                ctx.snapContext.snapType = newSnapShotPointer.type;
                ctx.snapContext.snapPointer = pointer;
                ctx.snapContext.mousePointer = convertPointerToClientBox(pointer, ctx.svgElement);
            }
            else {
                ctx.snapContext.snapPointer = undefined;
            }
            ctx.dragContext.selectedObject.heightPointer = pointer;
            ctx.dragContext.selectedObject.updatePointsArray();
            const newLength = Number(getMetricWithUnit(ctx.dragContext.selectedObject.getLineLength(), ctx.currentMetricUnit, true));
            if (ctx.lineLength.toFixed(2) !== newLength.toFixed(2)) {
                ctx.lineLength = Number(newLength.toFixed(2));
            }
            const { closedAreas, tileWrappers: updatedTiles, segments } = getUpdatedTiles(ctx.current.segments, getClosedAreas(ctx.current.segments), getTileLayouts(ctx.current.segments), ctx.layoutContext.layoutGeometries, ctx.layoutContext.layout, ctx.layoutContext.selectedLayoutGeometryID, ctx.dragContext.selectedObject
                ? [ctx.dragContext.selectedObject.id]
                : undefined);
            return {
                ...ctx,
                current: {
                    ...ctx.current,
                    segments: [
                        ...closedAreas,
                        ...updatedTiles,
                        ...segments,
                    ],
                },
            };
        }),
        splitLine: assign((ctx, e) => {
            const nv = e.splitCount || 0;
            ctx.splitContext.splitCount = nv;
            const oldSplitedLineArray = [];
            let oldAreaOrTileWrapper = [];
            if (ctx.dragContext.selectedObject && nv > 0) {
                if (ctx.splitContext.splitedLineArray.length > 0) {
                    ctx.splitContext.splitedLineArray.forEach((item) => {
                        const index = ctx.current.segments.indexOf(item);
                        if (index >= 0)
                            ctx.current.segments.splice(index, 1);
                        ctx.current.segments.forEach((s, index) => {
                            if ((s instanceof TileWrapper || s instanceof ClosedArea) &&
                                getShapeId(s.shape).includes(item.id.toString())) {
                                oldAreaOrTileWrapper = [...oldAreaOrTileWrapper, ...ctx.current.segments.splice(index, 1)];
                            }
                        });
                    });
                }
                const selectedLineObject = ctx.dragContext.selectedObject;
                const linesArray = selectedLineObject.splitLine(ctx.splitContext.splitCount, ctx.current.segments);
                oldSplitedLineArray.push(...ctx.splitContext.splitedLineArray);
                ctx.splitContext.splitedLineArray = linesArray;
                const buildingParts = getAllBuildingPartsInSegment(ctx.current.segments, selectedLineObject);
                for (const bP of buildingParts) {
                    bP.parentId = 0;
                    if (bP instanceof Door) {
                        const length = bP.getLineLength();
                        bP.endPointer = bP.startPointer.translate(length, 0);
                    }
                }
                if (ctx.splitContext.splitedLineArray.length > 0) {
                    ctx.current.segments = ctx.current.segments.filter((item) => item !== ctx.dragContext.selectedObject);
                    ctx.current.segments = ctx.current.segments.concat(ctx.splitContext.splitedLineArray);
                }
            }
            else {
                ctx.splitContext.splitedLineArray = [];
            }
            ctx.current.segments.sort((a, b) => {
                if (isBuildingPart(a) && !isBuildingPart(b)) {
                    return 1;
                }
                else if (!isBuildingPart(a) && isBuildingPart(b)) {
                    return -1;
                }
                else {
                    return 0; // a.id.localeCompare(b.id);
                }
            });
            const updatedShapes = getAllClosedShape(ctx.current.segments);
            const updatedClosedAreas = [];
            const updatedTiles = [];
            const splittedKeys = ctx.splitContext.splitedLineArray.map((l) => l.id);
            oldAreaOrTileWrapper.sort((a, b) => {
                if (a instanceof ClosedArea) {
                    return 1;
                }
                else if (b instanceof ClosedArea) {
                    return -1;
                }
                return 0;
            });
            [...ctx.current.segments, ...oldAreaOrTileWrapper].forEach((s) => {
                if (s instanceof ClosedArea) {
                    const k = getShapeId(s.shape);
                    if (k.includes(ctx.dragContext.selectedObject.id.toString()) || oldSplitedLineArray.some(oldLine => k.includes(oldLine.id.toString()))) {
                        const newShape = updatedShapes.find((s) => s.results.every((seg) => {
                            if (splittedKeys.includes(seg.id))
                                return true;
                            return k.includes(seg.id.toString());
                        }));
                        if (newShape) {
                            const newClosedArea = s.clone();
                            // newClosedArea.id = uuidv4();
                            newClosedArea.shape = newShape;
                            const newTileWrappers = [];
                            newClosedArea.tileWrappers.forEach(t => {
                                const updatedTile = updatedTiles.find(ut => ut.id === t.id);
                                if (updatedTile) {
                                    newTileWrappers.push(updatedTile);
                                }
                            });
                            newClosedArea.setTileWrappers(newTileWrappers);
                            updatedClosedAreas.push(newClosedArea);
                        }
                    }
                    else {
                        updatedClosedAreas.push(s);
                    }
                }
                else if (s instanceof TileWrapper) {
                    const k = getShapeId(s.shape);
                    if (k.includes(ctx.dragContext.selectedObject.id.toString()) || oldSplitedLineArray.some(oldLine => k.includes(oldLine.id.toString()))) {
                        const newShape = updatedShapes.find((s) => s.results.every((seg) => {
                            if (splittedKeys.includes(seg.id))
                                return true;
                            return k.includes(seg.id.toString());
                        }));
                        if (newShape) {
                            const newTileWrapper = s.clone();
                            // newTileWrapper.id = uuidv4();
                            newTileWrapper.shape = newShape;
                            updatedTiles.push(newTileWrapper);
                        }
                    }
                    else {
                        updatedTiles.push(s);
                    }
                }
            });
            return {
                ...ctx,
                current: {
                    ...ctx.current,
                    segments: [
                        ...updatedClosedAreas,
                        ...updatedTiles,
                        ...ctx.current.segments.filter((s) => !(s instanceof TileWrapper) && !(s instanceof ClosedArea)),
                    ],
                },
            };
        }),
        createRoom: assign((ctx, e) => {
            if (e.roomType === "Rectangle-1") {
                const length = Math.round(Math.pow(e.roomSurface, 1 / 2) * 100);
                const startPointer = new Pointer(-length / 2, -length / 2);
                ctx.current.segments = [];
                ctx.current.segments.push(new Line(startPointer, startPointer.translate(length, 0)));
                ctx.current.segments.push(new Line(startPointer.translate(length, 0), startPointer.translate(length, length)));
                ctx.current.segments.push(new Line(startPointer.translate(length, length), startPointer.translate(0, length)));
                ctx.current.segments.push(new Line(startPointer.translate(0, length), startPointer));
            }
            else if (e.roomType.startsWith("L-")) {
                const length = Math.round(Math.pow((e.roomSurface * 4) / 3, 1 / 2) * 100);
                ctx.current.segments = [];
                let angle = 0;
                if (e.roomType.endsWith("1")) {
                    angle = 0;
                }
                else if (e.roomType.endsWith("2")) {
                    angle = 90;
                }
                else if (e.roomType.endsWith("3")) {
                    angle = 180;
                }
                else if (e.roomType.endsWith("4")) {
                    angle = 270;
                }
                const startPointer = new Pointer(-length / 2, -length / 2);
                const centerPointer = new Pointer(0, 0);
                ctx.current.segments.push(rotateLine(new Line(startPointer, startPointer.translate(length / 2, 0)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(length / 2, 0), startPointer.translate(length / 2, length / 2)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(length / 2, length / 2), startPointer.translate(length, length / 2)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(length, length / 2), startPointer.translate(length, length)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(length, length), startPointer.translate(0, length)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(0, length), startPointer), centerPointer, angle));
            }
            else if (e.roomType.startsWith("Pentagon")) {
                const length = Math.round(Math.pow((e.roomSurface * 25) / 23, 1 / 2) * 100);
                ctx.current.segments = [];
                let angle = 0;
                if (e.roomType.endsWith("1")) {
                    angle = 0;
                }
                else if (e.roomType.endsWith("2")) {
                    angle = 90;
                }
                else if (e.roomType.endsWith("3")) {
                    angle = 180;
                }
                else if (e.roomType.endsWith("4")) {
                    angle = 270;
                }
                const startPointer = new Pointer(-length / 2, -length / 2);
                const centerPointer = new Pointer(0, 0);
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(0, 0), startPointer.translate((length * 3) / 5, 0)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate((length * 3) / 5, 0), startPointer.translate(length, (length * 2) / 5)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(length, (length * 2) / 5), startPointer.translate(length, length)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(length, length), startPointer.translate(0, length)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(0, length), startPointer.translate(0, 0)), centerPointer, angle));
            }
            else if (e.roomType.startsWith("Convex")) {
                const length = Math.round(Math.pow((e.roomSurface * 9) / 7, 1 / 2) * 100);
                ctx.current.segments = [];
                let angle = 0;
                if (e.roomType.endsWith("1")) {
                    angle = 0;
                }
                else if (e.roomType.endsWith("2")) {
                    angle = 90;
                }
                else if (e.roomType.endsWith("3")) {
                    angle = 180;
                }
                else if (e.roomType.endsWith("4")) {
                    angle = 270;
                }
                const startPointer = new Pointer(-length / 2, -length / 2);
                const centerPointer = new Pointer(0, 0);
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(0, (length * 1) / 3), startPointer.translate(0, length)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(0, length), startPointer.translate(length, length)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(length, length), startPointer.translate(length, (length * 1) / 3)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(length, (length * 1) / 3), startPointer.translate((length * 2) / 3, (length * 1) / 3)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate((length * 2) / 3, (length * 1) / 3), startPointer.translate((length * 2) / 3, 0)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate((length * 2) / 3, 0), startPointer.translate((length * 1) / 3, 0)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate((length * 1) / 3, 0), startPointer.translate((length * 1) / 3, (length * 1) / 3)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate((length * 1) / 3, (length * 1) / 3), startPointer.translate(0, (length * 1) / 3)), centerPointer, angle));
            }
            else if (e.roomType.startsWith("Concave")) {
                const length = Math.round(Math.pow((e.roomSurface * 9) / 8, 1 / 2) * 100);
                ctx.current.segments = [];
                let angle = 0;
                if (e.roomType.endsWith("1")) {
                    angle = 0;
                }
                else if (e.roomType.endsWith("2")) {
                    angle = 90;
                }
                else if (e.roomType.endsWith("3")) {
                    angle = 180;
                }
                else if (e.roomType.endsWith("4")) {
                    angle = 270;
                }
                const startPointer = new Pointer(-length / 2, -length / 2);
                const centerPointer = new Pointer(0, 0);
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(0, 0), startPointer.translate(0, length)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(0, length), startPointer.translate(length, length)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(length, length), startPointer.translate(length, 0)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(length, 0), startPointer.translate((length * 2) / 3, 0)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate((length * 2) / 3, 0), startPointer.translate((length * 2) / 3, (length * 1) / 3)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate((length * 2) / 3, (length * 1) / 3), startPointer.translate((length * 1) / 3, (length * 1) / 3)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate((length * 1) / 3, (length * 1) / 3), startPointer.translate((length * 1) / 3, 0)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate((length * 1) / 3, 0), startPointer.translate(0, 0)), centerPointer, angle));
            }
            else if (e.roomType.startsWith("Trapezium")) {
                const length = Math.round(Math.pow((e.roomSurface * 18) / 15, 1 / 2) * 100);
                ctx.current.segments = [];
                let angle = 0;
                if (e.roomType.endsWith("1")) {
                    angle = 0;
                }
                else if (e.roomType.endsWith("2")) {
                    angle = 90;
                }
                else if (e.roomType.endsWith("3")) {
                    angle = 180;
                }
                else if (e.roomType.endsWith("4")) {
                    angle = 270;
                }
                const startPointer = new Pointer(-length / 2, -length / 2);
                const centerPointer = new Pointer(0, 0);
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(0, 0), startPointer.translate(0, length)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(0, length), startPointer.translate(length, length)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate(length, length), startPointer.translate((length * 2) / 3, 0)), centerPointer, angle));
                ctx.current.segments.push(rotateLine(new Line(startPointer.translate((length * 2) / 3, 0), startPointer.translate(0, 0)), centerPointer, angle));
            }
            const { closedAreas, tileWrappers: updatedTiles, segments } = getUpdatedTiles(ctx.current.segments, getClosedAreas(ctx.current.segments), getTileLayouts(ctx.current.segments), ctx.layoutContext.layoutGeometries, ctx.layoutContext.layout, ctx.layoutContext.selectedLayoutGeometryID, undefined);
            updatedTiles.forEach((tileWrapper) => {
                if (tileWrapper.parentId && tileWrapper.snapTile) {
                    const snapInfos = tileWrapper.getSnapInfos();
                    const svgPointers = getSvgPointersOfTile(tileWrapper.snapTile, tileWrapper);
                    const index = svgPointers.findIndex((p) => p.equals(snapInfos.snapPointer));
                    if (index > -1) {
                        tileWrapper.rotation += snapInfos.deltaAngle;
                        tileWrapper.offset = new Pointer(0, 0);
                        const originalPoint = getSvgPointersOfTile(tileWrapper.snapTile, tileWrapper).at(index);
                        tileWrapper.offset = new Pointer(snapInfos.snapLinePointer1.x - originalPoint.x, snapInfos.snapLinePointer1.y - originalPoint.y);
                        const bounding = getShapeBoundingRect(tileWrapper.shape);
                        tileWrapper.tileLayout = tileWrapper.tileLayout.resize(tileWrapper.tileLayout.width +
                            (Math.abs(tileWrapper.offset.x) > bounding[2] / 2
                                ? Math.abs(tileWrapper.offset.x) - bounding[2] / 2
                                : 0), tileWrapper.tileLayout.height +
                            (Math.abs(tileWrapper.offset.y) > bounding[3] / 2
                                ? Math.abs(tileWrapper.offset.y) - bounding[3] / 2
                                : 0));
                    }
                    tileWrapper.parentId = 0;
                    tileWrapper.snapTile = undefined;
                }
            });
            return {
                ...ctx,
                projectBaseInfo: !!e.projectName ? { name: e.projectName } : undefined,
                current: ctx.current.segments.length > 0
                    ? {
                        ...ctx.current,
                        segments: [...closedAreas, ...updatedTiles, ...segments],
                    }
                    : ctx.current,
                dragContext: {
                    ...ctx.dragContext,
                    snapTile: undefined,
                },
                snapContext: initialSnapContext(),
            };
        }),
        createdBuildingPart: assign((ctx, e) => {
            ctx.current.segments.push(e.segment);
            ctx.dragContext.selectedObject = e.segment;
            return { ...ctx };
        }),
        changeWallSide: assign({
            dragContext: (ctx, _) => {
                const buildingPart = ctx.dragContext.selectedObject;
                buildingPart.wallSide = !buildingPart.wallSide;
                return ctx.dragContext;
            },
        }),
        changeOpenSide: assign({
            dragContext: (ctx, _) => {
                const buildingPart = ctx.dragContext.selectedObject;
                buildingPart.wallOpening = !buildingPart.wallOpening;
                return ctx.dragContext;
            },
        }),
        changeFoldingType: assign({
            dragContext: (ctx, e) => {
                const buildingPart = ctx.dragContext.selectedObject;
                buildingPart.foldingType = e.newFoldingType;
                return ctx.dragContext;
            },
        }),
        changeName: assign({
            dragContext: (ctx, e) => {
                if (e.segment)
                    e.segment.name = e.name;
                else if (ctx.dragContext.selectedObject)
                    ctx.dragContext.selectedObject.name = e.name;
                return ctx.dragContext;
            },
        }),
        changeWidth: assign({
            dragContext: (ctx, e) => {
                if (isBuildingPart(ctx.dragContext.selectedObject) || isDoor(ctx.dragContext.selectedObject)) {
                    const buildingPart = ctx.dragContext.selectedObject;
                    const delta = (e.newWidth - buildingPart.getLineLength()) / 2;
                    const partVector = new Line(buildingPart.startPointer, buildingPart.endPointer);
                    const angle = partVector.getLineAngle();
                    const deltaX = delta * Math.abs(Math.cos(angle));
                    const deltaY = delta * Math.abs(Math.sin(angle));
                    const centerPointer = new Pointer((buildingPart.startPointer.x + buildingPart.endPointer.x) / 2, (buildingPart.startPointer.y + buildingPart.endPointer.y) / 2);
                    const originStart = buildingPart.startPointer.translate(0, 0);
                    const originEnd = buildingPart.endPointer.translate(0, 0);
                    buildingPart.startPointer = originStart.translate(-Math.sign(centerPointer.x - originStart.x) * deltaX, -Math.sign(centerPointer.y - originStart.y) * deltaY);
                    buildingPart.endPointer = originEnd.translate(-Math.sign(centerPointer.x - originEnd.x) * deltaX, -Math.sign(centerPointer.y - originEnd.y) * deltaY);
                    if (get(linkTool)) {
                        buildingPart.length *= e.newWidth / buildingPart.width;
                    }
                    buildingPart.width = e.newWidth;
                }
                else if (isLine(ctx.dragContext.selectedObject)) {
                    const line = ctx.dragContext.selectedObject;
                    const delta = e.newWidth - line.getLineLength();
                    const angle = line.getLineAngle();
                    const deltaX = -Math.sign(line.startPointer.x - line.endPointer.x) * delta * Math.abs(Math.cos(angle));
                    const deltaY = -Math.sign(line.startPointer.y - line.endPointer.y) * delta * Math.abs(Math.sin(angle));
                    const newPointer = line.endPointer.translate(deltaX, deltaY);
                    translatePointerWithSegments(deltaX, deltaY, line.endPointer, ctx.current.segments);
                    const ownedSegments = getSegmentsOfPoint(ctx.current.segments, newPointer);
                    ownedSegments.forEach((item) => {
                        if (isLine(item) && item.isDashed()) {
                            item.translate(0, 0, ctx.current.segments);
                        }
                    });
                }
                return ctx.dragContext;
            },
        }),
        changeHeight: assign({
            dragContext: (ctx, e) => {
                if (isBuildingPart(ctx.dragContext.selectedObject) || isDoor(ctx.dragContext.selectedObject)) {
                    const buildingPart = ctx.dragContext.selectedObject;
                    const delta = ((buildingPart.width * e.newHeight) / buildingPart.length -
                        buildingPart.getLineLength()) /
                        2;
                    const partVector = new Line(buildingPart.startPointer, buildingPart.endPointer);
                    const angle = partVector.getLineAngle();
                    const deltaX = delta * Math.abs(Math.cos(angle));
                    const deltaY = delta * Math.abs(Math.sin(angle));
                    if (get(linkTool)) {
                        const centerPointer = new Pointer((buildingPart.startPointer.x + buildingPart.endPointer.x) / 2, (buildingPart.startPointer.y + buildingPart.endPointer.y) / 2);
                        buildingPart.startPointer = buildingPart.startPointer.translate(-Math.sign(centerPointer.x - buildingPart.startPointer.x) *
                            deltaX, -Math.sign(centerPointer.y - buildingPart.startPointer.y) * deltaY);
                        buildingPart.endPointer = buildingPart.endPointer.translate(-Math.sign(centerPointer.x - buildingPart.endPointer.x) * deltaX, -Math.sign(centerPointer.y - buildingPart.endPointer.y) * deltaY);
                        buildingPart.width *= e.newHeight / buildingPart.length;
                    }
                    buildingPart.length = e.newHeight;
                }
                else if (isLine(ctx.dragContext.selectedObject)) {
                    const line = ctx.dragContext.selectedObject;
                    line.height = e.newHeight;
                }
                return ctx.dragContext;
            },
        }),
        changeThick: assign({
            dragContext: (ctx, e) => {
                if (isLine(ctx.dragContext.selectedObject)) {
                    const line = ctx.dragContext.selectedObject;
                    line.thick = e.newThick;
                }
                return ctx.dragContext;
            },
        }),
        switchLinePoints: assign({
            dragContext: (ctx, e) => {
                if (isLine(e.segment)) {
                    const line = e.segment;
                    const temp = line.startPointer;
                    line.startPointer = line.endPointer;
                    line.endPointer = temp;
                }
                return ctx.dragContext;
            },
        }),
        savePrevLayout: assign((ctx) => {
            return {
                ...ctx,
                layoutContext: {
                    ...ctx.layoutContext,
                    prevLayout: ctx.layoutContext.layout,
                },
            };
        }),
        reloadPrevLayout: assign((ctx) => {
            return {
                ...ctx,
                layoutContext: {
                    ...ctx.layoutContext,
                    layout: ctx.layoutContext.prevLayout,
                },
            };
        }),
        editTileLayout: assign((ctx, e) => {
            return {
                ...ctx,
                dragContext: {
                    ...ctx.dragContext,
                    currentShapeId: e.shapeId,
                },
            };
        }),
        saveTileLayout: assign((ctx, e) => {
            if (e.segment || ctx.dragContext.currentShapeId) {
                const tileWrapper = (e.segment ?? ctx.current.segments.find(s => s instanceof TileWrapper &&
                    getShapeId(s.shape) === ctx.dragContext.currentShapeId));
                const boundingRect = getShapeBoundingRect(tileWrapper?.shape);
                tileWrapper.tileLayout = e.tileData.resize(boundingRect[2] / TILE_SCALE, boundingRect[3] / TILE_SCALE);
                if (e.isNewLayout) {
                    tileWrapper.rotation = 0;
                }
                if (e.savedGeometryLayoutId !== undefined && !!tileWrapper) {
                    tileWrapper.layoutGeometryId = e.savedGeometryLayoutId;
                    tileWrapper.updateLayout = true;
                    tileWrapper.tiles = tileWrapper.tileLayout.getGraph().tiles;
                    tileWrapper.initializeOverrideAspectRatio(ctx.layoutContext.layoutGeometries, tileWrapper.tileLayout.shapes);
                }
            }
            return {
                ...ctx,
                dragContext: {
                    ...ctx.dragContext,
                    currentShapeId: undefined,
                },
            };
        }),
        loadTileLayout: assign((ctx, e) => {
            const tileWrapper = ctx.dragContext.selectedObject;
            if (e.tileData && tileWrapper && isTileWrapper(tileWrapper)) {
                tileWrapper.setLayoutInfo(e.savedGeometryLayoutId, e.tileData.getGraph().tiles);
                const boundingRect = getShapeBoundingRect(tileWrapper?.shape);
                tileWrapper.tileLayout = e.tileData.resize(boundingRect[2] / TILE_SCALE, boundingRect[3] / TILE_SCALE);
                // tileWrapper.tileLayout.overrideAspectRatio = srcTileWrapper.tileLayout.overrideAspectRatio.map((v) => ({...v, tile: v.tile.clone()}));
                if (e.duplicate)
                    tileWrapper.tileLayout.id = uuidv4();
                tileWrapper.updateLayout = true;
            }
            return {
                ...ctx,
                layoutContext: {
                    ...ctx.layoutContext,
                    layout: tileWrapper.tileLayout
                }
            };
        }),
        removeTileLayout: assign((ctx, e) => {
            if (e.shapeId) {
                const tileWrapper = ctx.current.segments.find((s) => s instanceof TileWrapper && getShapeId(s.shape) === e.shapeId);
                const boundingRect = getShapeBoundingRect(tileWrapper.shape);
                tileWrapper.rotation = 0;
                tileWrapper.snapTile = undefined;
                tileWrapper.tileLayout = RealizedLayout.sampleLayout(boundingRect[2] / TILE_SCALE, boundingRect[3] / TILE_SCALE);
                // tileWrapper.tileLayout.overrideAspectRatio = [];
                tileWrapper.layoutGeometryId = -1;
                tileWrapper.tiles = [];
            }
            return ctx;
        }),
        deleteSegment: assign((ctx, e) => {
            if (!ctx.dragContext.selectedObject) {
                return ctx;
            }
            let index = ctx.current.segments.indexOf(ctx.dragContext.selectedObject);
            if (index >= 0) {
                ctx.current.segments = [
                    ...ctx.current.segments.slice(0, index),
                    ...ctx.current.segments.slice(index + 1),
                ];
            }
            const buildingElements = getAllBuildingPartsInSegment(ctx.current.segments, ctx.dragContext.selectedObject);
            for (const e of buildingElements) {
                e.parentId = 0;
                if (e instanceof Door) {
                    const length = e.getLineLength();
                    e.endPointer = e.startPointer.translate(length, 0);
                }
            }
            getClosedAreas(ctx.current.segments).forEach((closedArea) => {
                refreshClosedArea(ctx.current.segments, closedArea);
            });
            splitLinesForDashed(ctx.current.segments);
            const { closedAreas, tileWrappers: updatedTiles, segments } = getUpdatedTiles(ctx.current.segments, getClosedAreas(ctx.current.segments), getTileLayouts(ctx.current.segments), ctx.layoutContext.layoutGeometries, ctx.layoutContext.layout, ctx.layoutContext.selectedLayoutGeometryID, ctx.dragContext.selectedObject
                ? [ctx.dragContext.selectedObject.id]
                : undefined);
            return {
                ...ctx,
                current: {
                    ...ctx.current,
                    segments: [
                        ...closedAreas,
                        ...updatedTiles,
                        ...segments,
                    ],
                },
                dragContext: initialDragContext(),
                drawContext: initialDrawContext(),
                snapContext: {
                    ...initialSnapContext(),
                    mousePointer: ctx.snapContext.mousePointer,
                },
                splitContext: initialSplitContext(),
            };
        }),
        updateZIndex: assign({
            dragContext: (ctx, e) => {
                ctx.dragContext.selectedObject.zIndex = e.newZIndex;
                return ctx.dragContext;
            },
        }),
        updateMetric: assign((ctx, e) => {
            ctx.currentMetricUnit = e.newMetricUnit ?? "m";
            return ctx;
        }),
        cleanPast: assign({
            past: (ctx) => [],
            future: (ctx) => [],
        }),
        updatePast: assign({
            past: (ctx) => [
                ...ctx.past,
                {
                    segments: ctx.current.segments.map((segment) => segment.clone()),
                },
            ],
            future: (ctx) => [],
        }),
        updatePastWithBackup: assign({
            past: (ctx) => [
                ...ctx.past,
                {
                    segments: ctx.backup.segments.map((segment) => segment.clone()),
                },
            ],
            future: (ctx) => [],
        }),
        backupCurrent: assign({
            backup: (ctx) => ({
                segments: ctx.current.segments.map((segment) => segment.clone()),
            })
        }),
        restoreCurrent: assign((ctx, e) => {
            const newSegments = ctx.backup.segments.map((segment) => segment.clone());
            return {
                ...ctx,
                current: {
                    ...ctx.current,
                    segments: newSegments,
                },
                dragContext: {
                    ...ctx.dragContext,
                    selectedObject: newSegments.find((segment) => segment.id === ctx.dragContext.selectedObject?.id),
                    selectedCPObj: undefined
                }
            };
        }),
        updateCurrent: assign((ctx, e) => {
            return {
                ...ctx,
                dragContext: initialDragContext(),
                drawContext: initialDrawContext(),
                snapContext: {
                    ...initialSnapContext(),
                    mousePointer: ctx.snapContext.mousePointer,
                },
                splitContext: initialSplitContext(),
            };
        }),
        undo: assign((ctx) => {
            const previous = ctx.past[ctx.past.length - 1];
            const newPast = ctx.past.slice(0, ctx.past.length - 1);
            return {
                ...ctx,
                past: newPast,
                current: previous,
                future: [ctx.current, ...ctx.future],
                dragContext: initialDragContext(),
                drawContext: initialDrawContext(),
                snapContext: {
                    ...initialSnapContext(),
                    mousePointer: ctx.snapContext.mousePointer,
                },
            };
        }),
        redo: assign((ctx) => {
            const next = ctx.future[0];
            const newFuture = ctx.future.slice(1);
            return {
                ...ctx,
                past: [...ctx.past, ctx.current],
                current: next,
                future: newFuture,
                dragContext: initialDragContext(),
                drawContext: initialDrawContext(),
                snapContext: {
                    ...initialSnapContext(),
                    mousePointer: ctx.snapContext.mousePointer,
                },
            };
        }),
        pop: assign((ctx) => {
            const previous = ctx.past[ctx.past.length - 1];
            const newPast = ctx.past.slice(0, ctx.past.length - 1);
            return {
                ...ctx,
                past: newPast,
                current: previous,
                future: [],
                dragContext: initialDragContext(),
                drawContext: initialDrawContext(),
                snapContext: {
                    ...initialSnapContext(),
                    mousePointer: ctx.snapContext.mousePointer,
                },
            };
        }),
        ///////////////////////////////////////////////
        appendLoadedBaseShapes: assign({
            layoutContext: (context, event) => {
                const result = [...context.layoutContext.baseShapes];
                const newShapes = event.data;
                for (const nS of newShapes) {
                    if (!!newShapes.find((s) => s.tileId === nS.tileId)) {
                        result.push(nS);
                    }
                }
                return {
                    ...context.layoutContext,
                    baseShapes: result
                };
            },
        }),
        loadLayoutGeometries: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    layoutGeometries: [...(event.layoutGeometries ?? [])],
                };
            }
        }),
        addLayoutGeometry: assign({
            layoutContext: (context, event) => {
                const newLayoutGeometries = context.layoutContext.layoutGeometries.filter((layout) => layout.id !== event.layoutGeometry.id);
                return {
                    ...context.layoutContext,
                    layoutGeometries: [...newLayoutGeometries, event.layoutGeometry],
                };
            }
        }),
        logLoadedBaseShapesError: log((_, event) => `Failed to load base shape JSON, err: ${event.data}`, "JSON loading failed"),
        setEmptyAddTileData: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    addTileContext: createEmptyAddTileContext(context.layoutContext.layout),
                };
            }
        }),
        addRootTile: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    layout: layoutFromRoot(cloneShape(event.shape)),
                    selectedLayoutGeometryID: undefined
                };
            }
        }),
        setAddTileAddPointData: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    addTileContext: {
                        ...context.layoutContext.addTileContext,
                        addPoint: event.addPoint,
                    },
                };
            }
        }),
        setInitAddTileLayout: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    addTileContext: {
                        ...context.layoutContext.addTileContext,
                        initLayout: context.layoutContext.layout,
                    },
                };
            }
        }),
        unsetRepeatLayout: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    layout: context.layoutContext.addTileContext.initLayout,
                };
            }
        }),
        setRepeatLayout: assign({
            layoutContext: (context, event) => {
                const { addTileContext } = context.layoutContext;
                let result = addTileContext.initLayout;
                if (addTileContext.addPoint !== undefined) {
                    // TODO error message for invalid layout?
                    const addPoint = addTileContext.initLayout.addPoints[addTileContext.addPoint];
                    if (addPoint) {
                        const newLayout = addTileContext.initLayout.add(addPoint, event.edge ?? addPoint.edge);
                        result = newLayout ?? result;
                    }
                }
                return {
                    ...context.layoutContext,
                    layout: result,
                };
            }
        }),
        setAddTileShapeData: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    addTileContext: {
                        ...context.layoutContext.addTileContext,
                        shape: cloneShape(event.shape),
                    },
                };
            }
        }),
        addTileToEdge: assign({
            layoutContext: (context, event) => {
                const { addTileContext } = context.layoutContext;
                let nextLayout = context.layoutContext.layout;
                if (addTileContext.addPoint !== undefined &&
                    addTileContext.shape) {
                    nextLayout = addNeighbor(context.layoutContext.layout, addTileContext.shape, addTileContext.addPoint);
                }
                return {
                    ...context.layoutContext,
                    layout: nextLayout,
                    addTileContext: createEmptyAddTileContext(context.layoutContext.layout)
                };
            }
        }),
        autoRepeatLayout: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    layout: context.layoutContext.layout.autoRepeat(),
                };
            }
        }),
        evalAutoRepeatLayoutPreview: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    autoRepeatPreviewLayout: context.layoutContext.layout.autoRepeat(),
                };
            }
        }),
        assignAutoRepeatPreviewToLayout: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    layout: context.layoutContext.autoRepeatPreviewLayout,
                };
            }
        }),
        resetAutoRepeatLayout: send({ type: "RESET_AUTO_REPEAT_LAYOUT" }),
        setTileSelected: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    selectedTileID: event.tileID,
                };
            }
        }),
        setTileDeselected: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    selectedTileID: undefined,
                };
            }
        }),
        initDragData: assign({
            layoutContext: (context, event) => {
                const { dragContext } = context.layoutContext;
                return {
                    ...context.layoutContext,
                    dragContext: dragContext ? { ...dragContext, initCoordinate: undefined } : dragContext,
                };
            }
        }),
        fillStartDragData: assign({
            layoutContext: (context, event) => {
                const { dragContext, layout } = context.layoutContext;
                return {
                    ...context.layoutContext,
                    dragContext: layout ? {
                        ...dragContext,
                        initCoordinate: event.pt,
                        initLayout: layout,
                        tileID: event.tileID,
                        snapPoint: undefined,
                        direction: true,
                    } : dragContext,
                };
            }
        }),
        evaluateDragLayout: assign({
            layoutContext: (context, event) => {
                const { dragContext } = context.layoutContext;
                if (dragContext && dragContext.tileID) {
                    let layout = context.layoutContext.layout;
                    let snapPoint = dragContext?.snapPoint;
                    const newLayoutData = moveTileInternal(dragContext.initLayout, dragContext.tileID, event.pt, dragContext.initCoordinate);
                    layout = newLayoutData?.layout ?? layout;
                    snapPoint = newLayoutData?.snapPoint;
                    const direction = newLayoutData?.direction ?? true;
                    return {
                        ...context.layoutContext,
                        layout: layout,
                        dragContext: { ...dragContext, snapPoint, direction },
                    };
                }
                return context.layoutContext;
            }
        }),
        evaluateLastDragLayout: assign({
            layoutContext: (context, event) => {
                const { dragContext } = context.layoutContext;
                if (dragContext?.tileID) {
                    const newLayoutData = moveTileInternal(dragContext.initLayout, dragContext.tileID, event.pt, dragContext.initCoordinate, true);
                    const nextLayout = newLayoutData?.layout ?? context.layoutContext.layout;
                    return {
                        ...context.layoutContext,
                        layout: nextLayout,
                        dragContext: createEmptyTileDragContext(nextLayout),
                    };
                }
                return context.layoutContext;
            }
        }),
        rotateTile: assign({
            layoutContext: (context, event) => {
                const tile = context.layoutContext.layout.tiles.get(event.tileID);
                if (tile !== undefined) {
                    return {
                        ...context.layoutContext,
                        layout: context.layoutContext.layout.rotate(tile),
                    };
                }
                return context.layoutContext;
            }
        }),
        lockTileWidth: assign({
            layoutContext: (context, event) => {
                let layout = context.layoutContext.layout.clone();
                layout.lockedDimensions[event.shapeIndex][0] = 1;
                return {
                    ...context.layoutContext,
                    layout: layout,
                };
            }
        }),
        unlockTileWidth: assign({
            layoutContext: (context, event) => {
                let layout = context.layoutContext.layout.clone();
                layout.lockedDimensions[event.shapeIndex][0] = 0;
                return {
                    ...context.layoutContext,
                    layout: layout,
                };
            }
        }),
        lockTileHeight: assign({
            layoutContext: (context, event) => {
                let layout = context.layoutContext.layout.clone();
                layout.lockedDimensions[event.shapeIndex][1] = 1;
                return {
                    ...context.layoutContext,
                    layout: layout,
                };
            }
        }),
        unlockTileHeight: assign({
            layoutContext: (context, event) => {
                let layout = context.layoutContext.layout.clone();
                layout.lockedDimensions[event.shapeIndex][1] = 0;
                return {
                    ...context.layoutContext,
                    layout: layout,
                };
            }
        }),
        changeAnchorPoint: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    layout: context.layoutContext.layout.changeAnchorPoint(event.changeAnchorPoint.source, event.changeAnchorPoint.target, event.changeAnchorPoint.targetIsChild),
                };
            }
        }),
        setReplaceTileContext: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    replaceTileContext: event.replaceTileContext ?? {}
                };
            },
        }),
        replaceTile: assign({
            layoutContext: (context, event) => {
                //shape: tile, shapeIndex: 0, width: res.width, height: res.height
                if (!isTileWrapper(context.dragContext.selectedObject))
                    return { ...context.layoutContext };
                const replaceTileContext = context.layoutContext.replaceTileContext;
                const tileWrapper = context.dragContext.selectedObject;
                const geometry = context.layoutContext.layoutGeometries.find(e => e.id === tileWrapper.layoutGeometryId);
                const wrapperWithSameLayouts = context.current.segments.filter((segment) => isTileWrapper(segment) && segment.tileLayout.id === tileWrapper.tileLayout.id);
                wrapperWithSameLayouts.forEach((wrapper) => {
                    replaceTileContext.replaceTileIndices?.forEach((idx) => {
                        wrapper.tileLayout.calcOverrideAspectRatio(event.shape.clone(), idx, event.width, event.height, geometry, context.layoutContext.baseShapes);
                    });
                    wrapper.updateLayout = true;
                });
                return {
                    ...context.layoutContext,
                    layout: createRealizedLayoutFromGeometry(geometry, context.layoutContext.baseShapes, undefined, tileWrapper.tileLayout),
                    selectedLayoutGeometryID: tileWrapper.layoutGeometryId,
                    replaceTileContext: {}
                };
            },
        }),
        highlightTiles: assign((ctx, e) => {
            if (isTileWrapper(ctx.dragContext.selectedObject)) {
                const tileWrapper = ctx.dragContext.selectedObject;
                tileWrapper.highlightTile = e.highlightTile;
                tileWrapper.update = true;
            }
            return ctx;
        }),
        removeSelectedTile: assign({
            layoutContext: (context, event) => {
                const { layout, selectedTileID } = context.layoutContext;
                const tile = selectedTileID !== undefined
                    ? layout.tiles.get(selectedTileID)
                    : undefined;
                if (tile !== undefined) {
                    return {
                        ...context.layoutContext,
                        layout: layout.remove(tile),
                    };
                }
                return context.layoutContext;
            }
        }),
        removeRootTile: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    layout: emptyLayout,
                    autoRepeatPreviewLayout: emptyLayout,
                    addTileContext: createEmptyAddTileContext(emptyLayout),
                    dragContext: createEmptyTileDragContext(emptyLayout),
                    selectedTileID: undefined,
                    selectedLayoutGeometryID: undefined
                };
            }
        }),
        loadContext: assign({
            layoutContext: (context, e) => {
                return {
                    ...context.layoutContext,
                    layout: e.tileContext ?? emptyLayout,
                    autoRepeatPreviewLayout: e.tileContext
                        ? e.tileContext.autoRepeat()
                        : emptyLayout,
                    addTileContext: createEmptyAddTileContext(e.tileContext ?? emptyLayout),
                    dragContext: createEmptyTileDragContext(e.tileContext ?? emptyLayout),
                    selectedTileID: undefined,
                    selectedLayoutGeometryID: undefined
                };
            }
        }),
        loadLayoutGeometry: assign({
            layoutContext: (context, event) => {
                return {
                    ...context.layoutContext,
                    layout: createRealizedLayoutFromGeometry(event.layoutGeometry, context.layoutContext.baseShapes),
                    selectedLayoutGeometryID: event.savedGeometryLayoutId
                };
            },
        }),
        updateProjectInfo: assign((ctx, e) => {
            return {
                ...ctx,
                projectBaseInfo: e.projectInfo
            };
        }),
    },
    services: {
        loadJSONBaseShapes: () => loadJSONBaseShapes(),
    },
});
// export const createLayoutDesignerService = (_initLayout?: RealizedLayout) =>
// interpret(
//     initLayout
//         ? layoutDesignerMachine.withContext({ ...layoutDesignerMachine.context, layout: initLayout })
//         : layoutDesignerMachine,
// );
// interpret(layoutDesignerMachine, { devTools: true });
function createEmptyAddTileContext(currentLayout) {
    return { addPoint: undefined, shape: undefined, initLayout: currentLayout };
}
function createEmptyTileDragContext(layout) {
    return {
        initCoordinate: undefined,
        initLayout: layout,
        snapPoint: undefined,
        tileID: undefined,
        direction: true
    };
}
function layoutFromRoot(shape) {
    return RealizedLayout.fromShape(shape, DEFAULT_LAYOUT_SIZE, DEFAULT_LAYOUT_SIZE);
}
function addNeighbor(layout, shape, addPointIdx) {
    const newLayout = layout.addShape(layout.addPoints[addPointIdx], shape);
    if (newLayout === undefined)
        return layout;
    //by default lock the dimensions of the added shape
    newLayout.lockedDimensions.push([1, 1]);
    return newLayout;
}
function cloneShape(shape) {
    return new Shape(shape.path, shape.shapeId, shape.tileId, shape.name, shape.tileData, shape.slug, shape.width, shape.height, shape.rotation);
}
async function loadJSONBaseShapes() {
    // https://stackoverflow.com/questions/11496734/add-a-background-image-png-to-a-svg-circle-shape
    // https://stackoverflow.com/questions/22883994/crop-to-fit-an-svg-pattern
    // const jsonShapes: Array<Shape> = await fetch("/test-tiles.json")
    //   .then((res) => res.json())
    //   .then((json) => {
    //     return (json as any[]).slice(0, 40).reduce((acc, s) => {
    //       const testScale = 1 / 40;
    //       const shape = Shape.fromPoints(
    //         [
    //           new Vector2(0, 0),
    //           new Vector2(s.width * testScale, 0),
    //           new Vector2(s.width * testScale, s.height * testScale),
    //           new Vector2(0, s.height * testScale),
    //         ],
    //         {
    //           images: [`https://www.tilelook.com${s.image}`],
    //           filterId: `tile-fill-${s.id}`,
    //         }
    //       );
    //       acc.push(shape);
    //       return acc;
    //     }, new Array<Shape>());
    //   });
    // return []; //jsonShapes;
    const result = await new Promise((resolve, reject) => {
        resolve([]);
    });
    return result;
}
export function createRealizedLayoutFromGeometry(geometry, baseShapes, tilesByIndex, layoutInfo) {
    const svgPath = JSON.parse(geometry.svg_path);
    return new RealizedLayout(DEFAULT_LAYOUT_SIZE, DEFAULT_LAYOUT_SIZE, Layout.MAX_DEPTH, createLayoutFromGeometry(geometry, baseShapes, tilesByIndex, layoutInfo), Layout.DEFAULT_MARGIN, Layout.DEFAULT_GROUT_COLOR, layoutInfo?.id ?? uuidv4(), layoutInfo?.overrideAspectRatio ?? [], svgPath.locked_dimensions, geometry.id);
}
export function createLayoutFromGeometry(geometry, baseShapes, tilesByIndex, layoutInfo) {
    const svgPath = JSON.parse(geometry.svg_path);
    const neighbors = svgPath.neighbors;
    let unalteredShapes = svgPath.shape_index.reduce((filtered, tileId, index) => {
        const dimensionsShape = Array.isArray(svgPath.dimensions) && svgPath.dimensions.length > 0 ? svgPath.dimensions[index] : undefined;
        const geometryShape = geometry.tile_shapes.find((gs) => gs.id === tileId);
        const baseShape = baseShapes.find((bs) => bs.tileId === tileId);
        // let result = null;
        if (!baseShape || !geometryShape)
            return filtered;
        let width = dimensionsShape !== undefined ? dimensionsShape[0] : (geometryShape.default_width ?? TILE_TRANSFORM_SCALE);
        let height = dimensionsShape !== undefined ? dimensionsShape[1] : (geometryShape.default_height ?? TILE_TRANSFORM_SCALE);
        // override base shape dimensions with those of layout geometry shape
        const svg = baseShape.path.resizeAndCenter([
            width / TILE_TRANSFORM_SCALE,
            height / TILE_TRANSFORM_SCALE,
        ]);
        if (svg)
            filtered.push(new Shape(svg, baseShape.shapeId, baseShape.tileId, baseShape.name, {}, baseShape.slug, width, height, baseShape.rotation));
        // result = cloneShape(baseShape);
        return filtered;
    }, []);
    let modifiedShapes = svgPath.shape_index.reduce((filtered, tileId, index) => {
        const dimensionsShape = Array.isArray(svgPath.dimensions) && svgPath.dimensions.length > index ? svgPath.dimensions[index] : undefined;
        const geometryShape = geometry.tile_shapes.find((gs) => gs.id === tileId);
        const baseShape = baseShapes.find((bs) => bs.tileId === tileId);
        // let result = null;
        if (!baseShape || !geometryShape)
            return filtered;
        if (tilesByIndex !== undefined && tilesByIndex[index] !== undefined) {
            filtered.push(tilesByIndex[index]);
            return filtered;
        }
        let widthMultiplyFactor = 1, heightMultiplyFactor = 1;
        if (layoutInfo?.overrideAspectRatio.length > 0) {
            const isReplacedTile = layoutInfo.overrideAspectRatio.find(e => e.shapeIndex === index);
            // return replaced tile
            if (isReplacedTile) {
                filtered.push(isReplacedTile.tile);
                return filtered;
            }
            const factors = layoutInfo.getMultiplyFactors(geometry, index);
            widthMultiplyFactor = factors.widthMultiplyFactor;
            heightMultiplyFactor = factors.heightMultiplyFactor;
        }
        let width = dimensionsShape !== undefined ? dimensionsShape[0] : (geometryShape.default_width ?? TILE_TRANSFORM_SCALE);
        let height = dimensionsShape !== undefined ? dimensionsShape[1] : (geometryShape.default_height ?? TILE_TRANSFORM_SCALE);
        // override base shape dimensions with those of layout geometry shape
        const svg = baseShape.path.resizeAndCenter([
            width * widthMultiplyFactor / TILE_TRANSFORM_SCALE,
            height * heightMultiplyFactor / TILE_TRANSFORM_SCALE,
        ]);
        if (svg)
            filtered.push(new Shape(svg, baseShape.shapeId, baseShape.tileId, baseShape.name, {}, baseShape.slug, width, height, baseShape.rotation));
        // result = cloneShape(baseShape);
        return filtered;
    }, []); //.filter((s) => s !== null);
    // Calculate limits based on positions
    // const limit = [
    //   -neighbor.sourceEdgeLength / 2 - neighbor.targetEdgeLength / 2,
    //   neighbor.sourceEdgeLength / 2 + neighbor.targetEdgeLength / 2,
    // ];
    const rootShape = modifiedShapes[0];
    let graph = ImmutableMap();
    let index = 0;
    for (const shape of modifiedShapes) {
        const neighbor = neighbors[index];
        if (!neighbor)
            continue;
        const children = Array();
        for (const child of neighbor.children) {
            const unalteredSourceShape = unalteredShapes[child.source.shapeIndex];
            const unalteredTargetShape = unalteredShapes[child.target.shapeIndex];
            const sourceShape = modifiedShapes[child.source.shapeIndex];
            const targetShape = modifiedShapes[child.target.shapeIndex];
            if (child.source.edgeIndex >= sourceShape.edges.length || child.target.edgeIndex >= targetShape.edges.length)
                continue;
            const unalteredAnchorPointOffset = (child.source.anchorPoint * unalteredSourceShape.edges[child.source.edgeIndex].length / 2) - (child.target.anchorPoint * unalteredTargetShape.edges[child.target.edgeIndex].length / 2);
            const anchorPointOffset = (child.source.anchorPoint * sourceShape.edges[child.source.edgeIndex].length / 2) - (child.target.anchorPoint * targetShape.edges[child.target.edgeIndex].length / 2);
            //Scale absolute value on altered range
            const absoluteValue = Neighbor.scale(
            // child.offset.absolute * unalteredSourceShape.edges[child.source.edgeIndex].length,
            child.offset.absolute - unalteredAnchorPointOffset, Neighbor.movementRange(unalteredSourceShape.edges[child.source.edgeIndex].length, unalteredTargetShape.edges[child.target.edgeIndex].length), Neighbor.movementRange(sourceShape.edges[child.source.edgeIndex].length, targetShape.edges[child.target.edgeIndex].length));
            const newRef = new Neighbor(new EdgeReference(sourceShape.edges[child.source.edgeIndex], sourceShape, child.source.offset, child.source.anchorPoint), new EdgeReference(targetShape.edges[child.target.edgeIndex], targetShape, child.target.offset, child.target.anchorPoint), new NeighborOffset(absoluteValue + anchorPointOffset, 
            // child.offset.absolute * sourceShape.edges[child.source.edgeIndex].length,
            child.offset.relativeToGap));
            children.push(newRef);
        }
        const parents = Array();
        for (const parent of neighbor.parents) {
            const unalteredSourceShape = unalteredShapes[parent.source.shapeIndex];
            const unalteredTargetShape = unalteredShapes[parent.target.shapeIndex];
            const sourceShape = modifiedShapes[parent.source.shapeIndex];
            const targetShape = modifiedShapes[parent.target.shapeIndex];
            if (parent.source.edgeIndex >= sourceShape.edges.length || parent.target.edgeIndex >= targetShape.edges.length)
                continue;
            const unalteredAnchorPointOffset = (parent.target.anchorPoint * unalteredSourceShape.edges[parent.source.edgeIndex].length / 2) - (parent.source.anchorPoint * unalteredTargetShape.edges[parent.target.edgeIndex].length / 2);
            const anchorPointOffset = (parent.target.anchorPoint * sourceShape.edges[parent.source.edgeIndex].length / 2) - (parent.source.anchorPoint * targetShape.edges[parent.target.edgeIndex].length / 2);
            //Scale absolute value on altered range
            const absoluteValue = Neighbor.scale(
            // parent.offset.absolute * unalteredSourceShape.edges[parent.source.edgeIndex].length,
            parent.offset.absolute - unalteredAnchorPointOffset, Neighbor.movementRange(unalteredSourceShape.edges[parent.source.edgeIndex].length, unalteredTargetShape.edges[parent.target.edgeIndex].length), Neighbor.movementRange(sourceShape.edges[parent.source.edgeIndex].length, targetShape.edges[parent.target.edgeIndex].length));
            parents.push(new Neighbor(new EdgeReference(sourceShape.edges[parent.source.edgeIndex], sourceShape, parent.source.offset, parent.source.anchorPoint), new EdgeReference(targetShape.edges[parent.target.edgeIndex], targetShape, parent.target.offset, parent.target.anchorPoint), new NeighborOffset(absoluteValue + anchorPointOffset, 
            // parent.offset.absolute * sourceShape.edges[parent.source.edgeIndex].length,
            parent.offset.relativeToGap)));
        }
        graph = graph.set(shape, new Neighbors(ImmutableList(children), ImmutableList(parents)));
        index++;
    }
    return new Layout(graph, rootShape);
}
