<rect
  x="4"
  y="4"
  width="16"
  height="16"
  fill="transparent"
  stroke-width="2"
  stroke="#005500"
  rx="2"
/>
