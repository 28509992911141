<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import Input from "../../base/Input.svelte";
  import ColorPicker from "../../base/ColorPicker.svelte";
  import { convertUnit, getMetricWithUnit, isBuildingPart, isDoor } from "src/helpers";
  import { METRIC_SMALL_UNITS, METRIC_UNITS, PRECISION_UNITS } from "src/global/variable";
  import { currentTool, linkTool } from "src/store";
  import type { BuildingPart } from "src/model";
  import { TOOLS } from "src/global/types";

  const dispatch = createEventDispatcher();

  let unsubscribe;
  let furnitureColor: string;
  let buildingPart: BuildingPart;
  $: {
    const selectedObj = $drawState.context.dragContext.selectedObject;
    if ( selectedObj && isBuildingPart(selectedObj) )
      buildingPart = selectedObj as BuildingPart;
    else dispatch("back");
  }
  $: isMetric = $drawState.context.currentMetricUnit === METRIC_UNITS[0]
  $: minLen = isMetric ? 10 : 1;
  $: step = isMetric ? 1 : 1;

  $: name = buildingPart?.getName();
  $: length = getMetricWithUnit(
    Math.round(buildingPart?.getLineLength() * 100000) / 100000,
    $drawState.context.currentMetricUnit,
    $drawState.context.currentMetricUnit === METRIC_UNITS[0],
    true
  );
  $: height = getMetricWithUnit(
    Math.round(buildingPart?.length * 100000) / 100000,
    $drawState.context.currentMetricUnit,
    $drawState.context.currentMetricUnit === METRIC_UNITS[0],
    true
  );
  $: rotation = buildingPart?.rotation;
  // $: color = buildingPart?.color;
  $: layer = buildingPart?.zIndex;

  const handleChangeLength = (e) => {
    if ( +e.target.value >= minLen ) {
      drawSend({
        type: "CHANGE_WIDTH",
        newWidth: convertUnit(+e.target.value, $drawState.context.currentMetricUnit, true)
      });
    }
  };

  const handleChangeHeight = (e) => {
    if ( +e.target.value >= minLen ) {
      drawSend({
        type: "CHANGE_HEIGHT",
        newHeight: convertUnit(+e.target.value, $drawState.context.currentMetricUnit, true),
      });
    }
  };

  const handleChangeRotation = (e) => {
    drawSend({
      type: "CHANGE_ROTATION",
      newAngle: Number(e.target.value),
    });
  }

  const handleChangeZIndex = (e) => {
    drawSend({
      type: "Z_INDEX_UPDATE",
      newZIndex: Number(e.target.value),
    });
  }
  
  const handleClickEyeDropper = () => {
    drawSend({ type: "EYEDROPPER" });
    currentTool.set(TOOLS.EYE_DROPPER);
  }

</script>

<div class="w-full flex flex-col gap-4">
  <div class="w-full">
    <div class="text-lg font-bold mb-2.5">
      {$_("side_menu.dimensions")}
    </div>
    <div class="w-full flex items-end gap-4">
      <Input
        type="number"
        label={$_("side_menu.height")}
        value={height}
        min={minLen}
        step={step}
        unit={isMetric ? METRIC_SMALL_UNITS[0] : METRIC_SMALL_UNITS[1] }
        on:input={handleChangeHeight}
        fullWidth
      />
      <Input
        type="number"
        label={$_("side_menu.length")}
        value={length}
        min={minLen}
        step={step}
        unit={isMetric ? METRIC_SMALL_UNITS[0] : METRIC_SMALL_UNITS[1] }
        on:input={handleChangeLength}
        fullWidth
      />
      <button
        class="btn-default p-1"
        on:click={() => {
          linkTool.set(!$linkTool);
        }}
      >
        <span class="fa-stack">
          <i class="fa-solid fa-link" />
          {#if !$linkTool}
            <i class="fal fa-slash fa-stack-1x" color="black" />
          {/if}
        </span>
      </button>
    </div>
  </div>

  <div class="w-full flex items-center gap-2">
    <i class="fa-solid fa-rotate font-black text-xl text-secondary-500" />
    <div class="text-lg font-bold mr-auto">
      {$_("side_menu.rotation")}
    </div>
    <Input
      type="number"
      value={rotation % 360}
      min={0}
      max={360}
      unit="°"
      class="w-input-sm"
      on:input={handleChangeRotation}
      fullWidth
    />
  </div>
  
  <div class="w-full flex items-center gap-2">
    <div class="text-lg font-bold mr-auto">
      {$_("side_menu.color")}
    </div>
    <button class="mr-2 w-9 h-9 flex items-center justify-center border-0 rounded-full" on:click={handleClickEyeDropper}>
      <i class="fa fa-eye-dropper font-black text-secondary-500" />
    </button>
    <ColorPicker hex={furnitureColor}/>
  </div>
  
  <div class="w-full flex items-center gap-2">
    <i class="fa-solid fa-layer-group font-black text-xl text-secondary-500" />
    <div class="text-lg font-bold mr-auto">
      {$_("side_menu.layer")}
    </div>
    <Input
      type="number"
      value={layer}
      step={1}
      class="w-input-sm"
      on:input={handleChangeZIndex}
      fullWidth
    />
  </div>
</div>
