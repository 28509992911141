<!-- <svelte:options immutable={true} /> -->

<script lang="ts">
  import type { Segment, TileWrapper } from "src/model/";
  import type WebGLRenderer from "src/model/WebGLRenderer";
  import { onMount, beforeUpdate } from "svelte";
  import Room from "src/model/tile/Room";
  import { debounce, throttle, isEqual } from "lodash";
  import { getShapeId } from "src/helpers";

  export let viewBox;
  export let scale;
  export let tileWrappers: TileWrapper[];
  export let layout: WebGLRenderer;
  
  let rooms: Room[] = [];
  let numRoom: number = 0;

  $: {
    layout.updatePanAndZoom(viewBox, scale);  
    redrawTiles();
  }

  $: {
    tileWrappers;
    updateRooms();
  }
  
  const redrawTiles = debounce(() => {
    rooms.forEach(r => layout.updateZoom(r));
    layout.updateScene();
  }, 300);

  const throttleUpdateRooms = throttle(() => updateRooms(), 300);

  function updateRooms() {
    if (numRoom !== tileWrappers.length)
    {
      rooms.forEach(r => {

        if (tileWrappers.some((tw) => tw.id === r.id ))
          return;

        layout.clearRoom(r);
      })

      if (tileWrappers.length > 0)
      {
        let maxIndex = rooms.length === 0 ? 0 : Math.max(...rooms.map(r => r.stencilBufferReference))
        tileWrappers?.forEach(tw => {

          if (rooms.some(r => r.id === tw.id))
            return;

          tw.updateLayout = true;
          let r: Room = new Room(tw, maxIndex);
          maxIndex++;
          rooms.push(r);
        });

        rooms = rooms.filter((room) => tileWrappers.some((tw) => tw.id === room.id))
      }
      else
        rooms = [];

      numRoom = tileWrappers.length;
    }

    rooms.forEach(r =>
    {
      let segment = tileWrappers.find((tw => tw.id === r.id));
      if (segment === undefined)
      {
        // room not found because the id inside the tilewrapper is changed, search and replace it inside the room
        segment = tileWrappers.find(tw => rooms.find(r => r.id === tw.id) === undefined);
        if (segment !== undefined)
        {
          segment.loadingLayout = true;
          //Svelte requires an assignment to trigger an update
          tileWrappers = tileWrappers;
          r.copy(segment);
          layout.setTiles(r);
          layout.updateTransform(r);
          r.setGapColor(segment.tileLayout.groutColor);

          Promise.allSettled(r.tilesImages).then(results => {
            segment.loadingLayout = false;
            //Svelte requires an assignment to trigger an update
            tileWrappers = tileWrappers;
            console.log("All asynchronous tiles images loaded");
          });
        }
      }
      else if (segment.updateLayout ||
                r.layoutGeometryId !== segment.layoutGeometryId ||
                r.tileLayout.tiles.length !== segment.tileLayout.tiles.length ||
                getShapeId(r.shape) !== getShapeId(segment.shape))
      {
        segment.updateLayout = false;
        segment.loadingLayout = true;
        //Svelte requires an assignment to trigger an update
        tileWrappers = tileWrappers;
        r.copy(segment);
        layout.setTiles(r);
        layout.updateTransform(r);
        r.setGapColor(segment.tileLayout.groutColor);

        Promise.allSettled(r.tilesImages).then(results => {
          console.log("All asynchronous tiles images loaded");
          segment.loadingLayout = false;
          //Svelte requires an assignment to trigger an update
          tileWrappers = tileWrappers;
        });
      }
      else if (r.rotation !== segment.rotation ||
                r.offset !== segment.offset)
      {
        r.copy(segment);
        layout.updateTransform(r);
      }
      else if (r.groutColor !== segment.tileLayout.groutColor)
      {
        r.setGapColor(segment.tileLayout.groutColor);
        // r.setLinesColor(segment.groutColor);
      }
      else if (segment.highlightTile && !isEqual(r.highlightTile, segment.highlightTile))
      {
        if (r.animateMesh(segment.highlightTile.index, segment.highlightTile.on))
        {
          r.highlightTile = segment.highlightTile;
          if (r.highlightTile.on)
            layout.animate(r);
          else
            layout.cancelAnimation(r);
        }
        // if (r.highlightMesh(segment.highlightTile.index, segment.highlightTile.on))
        // {
        //   r.highlightTile = segment.highlightTile;
        //   layout.updateScene();
        // }
        //when replacing a tile it needs to wait for the texture
        else
          setTimeout(updateRooms, 300);
      }
    });
  }

  onMount(async () => {

    if (layout !== undefined)
      layout.initScene();
  })

  beforeUpdate(async () => {
    if (layout !== undefined)
      layout.updateScene();
  })

</script>

<foreignObject
  x={viewBox.x}
  y={viewBox.y}
  width={viewBox.w}
  height={viewBox.h}
>
  <canvas
    bind:this={layout.canvas}
    width={window.innerWidth}
    height={window.innerHeight}
    style="width: {viewBox.w}px; height: {viewBox.h}px; clip-path: url(#WebGLRendererClipPath);"
  />

</foreignObject>