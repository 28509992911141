<script lang="ts">
  import { drawState, drawService, drawSend } from "../../layout.store";
  import Modal from "./Modal.svelte";
  import { _ } from "src/services/i18n";
  import { SORT_TYPES, TILES_FILTERS } from "src/global/variable";
  import type ShapeInstance from "src/model/tile/ShapeInstance";
  import Loading from "../util/Loading.svelte";
  import { bboxToSVGViewBox } from "src/model/tile/Tools";
  import PassiveTile from "../tiles/PassiveTile.svelte";
  import Shape from "src/model/tile/Shape";
  import { evalTileTextureId } from "src/tools/LayoutTools";
  import { useSelector } from "@xstate/svelte";
  import { queryParamsTiles, getTile, getWishLists, getWishlistTiles, getTilesList, getAllTileFilters, getAvailableTileFilters } from "src/services/api";
  import { COLLAPSING_STATUS, FilterCategory, Filter } from "src/model/tile/Filter";
  import type { TileWrapper } from "src/model";
  import Input from "../base/Input.svelte";
  import Pagination from "../base/Pagination.svelte";
  import { TILE_TRANSFORM_SCALE } from "src/global/variable";
  import { debounce, isEmpty, merge } from "lodash";
  import noUiSlider from 'nouislider';
  import 'nouislider/dist/nouislider.css';
  import { formatNumberWithCommas, isTileWrapper } from "src/helpers";
  import Checkbox from "../base/Checkbox.svelte";
  import Select from "../base/Select.svelte";
  import Button from "../base/Button.svelte";
  import TileCard from "./TileCard.svelte";
  import type { TileInfoResponse } from "src/global/types";
  import Matrix2D from "src/model/tile/Matrix2D";

  $: isRootSelection = $drawState.matches("layoutDesign.noLayout.showAddRootTile");
  $: isRegularSelection = $drawState.matches("layoutDesign.showLayout.layoutState.showAddTileSelection");
  $: isReplacingTile = $drawState.matches("main.selectState.replacingTile");
  // $: replaceTileContext = $drawState.context.layoutContext.replaceTileContext;
  $: visible = isRootSelection || isRegularSelection || isReplacingTile;
  $: repeatShape = new Array<ShapeInstance>();
  $: repeatShapeBBox = "0 0 1 1";
  $: selectedObject = $drawState.context.dragContext.selectedObject;
  $: tileWrapper = isTileWrapper(selectedObject) ? (selectedObject as TileWrapper) : undefined;

  let brandFilter = '';
  let tilesLoading: boolean = false;
  
  let tileContainerRef: any;
  let lockedWidth: boolean  = false,
      lockedHeight: boolean = false;

  let tileSlugId: string | null = null;

  $: {
    if (tileWrapper !== undefined && $replaceTileContext !== undefined) {
      tileWrapper.tileLayout.shapes;
      const shapeIndices = $replaceTileContext.replaceTileIndices;
      const shapeIndex = shapeIndices?.length > 0 ? shapeIndices[0] : -1;
      if (shapeIndex >= 0)
      {
        lockedWidth = $replaceTileContext.lockWidth !== undefined && 
                      tileWrapper.tileLayout.lockedDimensions[shapeIndex][0] === 1;

        lockedHeight = $replaceTileContext.lockHeight !== undefined && 
                       tileWrapper.tileLayout.lockedDimensions[shapeIndex][1] === 1;
      }
    }
  }
  
  $: {
    if (isRegularSelection) {
      repeatShape = $drawState.context.layoutContext.layout.tiles.takeWhile((t) => t.depth === 0);
      const bbox = repeatShape.reduce(
        (bb, t) => bb.union(t.bounds),
        repeatShape[0].bounds
      );
      repeatShapeBBox = bboxToSVGViewBox(bbox);
    }
  }

  $: $replaceTileContext, reactiveReplaceTileContext()
  function reactiveReplaceTileContext() {
    // selectedFilters = new queryParamsTiles();
    if( $replaceTileContext?.lockWidth ) {
      selectedFilters.min_width  = selectedFilters.max_width = $replaceTileContext?.lockWidth;
      lockedWidth = true;
    } else if (lockedWidth) {
      lockedWidth = false;
      selectedFilters.min_width  = selectedFilters.max_width = undefined;
    }
    if( $replaceTileContext?.lockHeight ) {
      selectedFilters.min_height = selectedFilters.max_height = $replaceTileContext?.lockHeight;
      lockedHeight = true;
    } else if (lockedHeight) {
      lockedHeight = false;
      selectedFilters.min_height = selectedFilters.max_height = undefined;
    }

    itemsPerPage = isRegularSelection ? 11 : 12;

    if (!isEmpty($replaceTileContext)) {
      Object.keys(selectedFilters.tile_shapes).map((shape) => {
        const filter = filtersCategories[TILES_FILTERS.TILE_SHAPES].filters.find((v) => v.slug === shape);
        if ( filter && filter.checked ) {
          toggleFilter(filter);
        }
      })

      const shape = $baseShapes.find((bs) => bs.tileId === $replaceTileContext.lockShape);
      if( shape ) {
        if( $replaceTileContext.layoutShapes.some((_shape) => _shape.slug !== shape.slug) && (shape.slug === "rectangle" || shape.slug === "square")) {
          selectedFilters.tile_shapes = {
            "rectangle": "lock",
            "square": "lock",
          }
        } else {
          selectedFilters.tile_shapes = {
            [shape.slug]: "lock"
          }
        }
      }

      activeTab = tabs[0].id;

      itemsPerPage = 12;
    } else {      
      Object.keys(selectedFilters.tile_shapes).map((shape) => {
        if( selectedFilters.tile_shapes[shape] === "lock" ) {
          const filter = filtersCategories[TILES_FILTERS.TILE_SHAPES].filters.find((v) => v.slug === shape);
          if ( filter && filter.checked ) {
            toggleFilter(filter);
          }
          delete selectedFilters.tile_shapes[shape];
        } 
      })
    }

    updateTilesList();
    updateAvailableTileFilters();
  }

  $: isRegularSelection, $baseShapes, reactiveUpdateTilesList()
  function reactiveUpdateTilesList() {

    const newItemsPerPage = isRegularSelection ? 11 : 12;
    // texturedTiles = $baseShapes.filter((shape) => evalTileTextureId(shape) !== undefined);
    genericTiles = $baseShapes.filter((shape) => evalTileTextureId(shape) === undefined);

    if (itemsPerPage !== newItemsPerPage)
      itemsPerPage = newItemsPerPage;

    if (genericTiles.length > 0) {
      updateTilesList();
    }
  }

  // $: {
  //   if (visible) {
  //     tick().then(() => {
  //       (
  //         document.querySelector("[data-base-shape-id]") as HTMLElement | null
  //       )?.focus();
  //     });
  //   }
  // }

  let tilesList: {
    total: number,
    total_pages: number,
    tiles: TileInfoResponse[]
  };
  let genericTiles: Shape[];

  const baseShapes = useSelector(drawService, (state) => state.context.layoutContext.baseShapes);
  const replaceTileContext = useSelector(drawService, (state) => state.context.layoutContext.replaceTileContext);

  let pagination, itemsPerPage: number = 12, currentPage: number = 1, sort: string = SORT_TYPES.NEWEST;

  let onlyWishlistsTiles: boolean = false, wishlistId: number | string = undefined;
  
  let sliderWidth,  rangeMinWidth:  number = 0, rangeMaxWidth:  number = 200;
  let sliderHeight, rangeMinHeight: number = 0, rangeMaxHeight: number = 200;
  let minWidth: number, minHeight: number, maxWidth: number, maxHeight: number;

  let filtersCategories: { [category:string] : FilterCategory } = {};
  const filterOrder = [
    "tile_types",
    "producers",
    "materials",
    "colors",
    "dimensions",
    "tile_suitabilities",
    "tile_shapes",
    "tile_effects",
    "tile_styles",
    "tech_features",
    "tile_finishes",
    "tile_pattern_designs"
  ]

  let selectedFilters: queryParamsTiles = new queryParamsTiles();

  $: {
    if ( sort ) {
      updateTilesList();
    }
  }

  function initSliderWidth(el) {

    let startMin = selectedFilters.min_width ?? rangeMinWidth,
        startMax = selectedFilters.max_width ?? rangeMaxWidth;

    minWidth = startMin
    maxWidth = startMax
    sliderWidth = el;
    noUiSlider.create(sliderWidth, {
      start: [startMin, startMax],
      step: 10,
      connect: true,
      range: {
        min: rangeMinWidth,
        max: rangeMaxWidth
      },
      format: {
        to: value => Math.round(value),
        from: Number
      }
    });

    sliderWidth.noUiSlider.on('slide', function (values, handle, unencoded) {
      if (handle === 0)
      {
        minWidth = values[0];
      }

      if (handle === 1)
      {
        maxWidth = values[1];
      }
    });

    if (lockedWidth)
      sliderWidth.noUiSlider.disable();
  }

  function initSliderHeight(el) {

    let startMin = selectedFilters.min_height ?? rangeMinHeight,
        startMax = selectedFilters.max_height ?? rangeMaxHeight;
    
    minHeight = startMin
    maxHeight = startMax
    sliderHeight = el;
    noUiSlider.create(sliderHeight, {
      start: [startMin, startMax],
      step: 10,
      connect: true,
      range: {
        min: rangeMinHeight,
        max: rangeMaxHeight
      },
      format: {
        to: value => Math.round(value),
        from: Number
      }
    });

    sliderHeight.noUiSlider.on('slide', function (values, handle, unencoded) {
      if (handle === 0)
      {
        minHeight = values[0];
      }

      if (handle === 1)
      {
        maxHeight = values[1];
      }
    });

    if (lockedHeight)
      sliderHeight.noUiSlider.disable();
	}

  let tabs = [
    { id: "1", title: "Real products" },
    { id: "2", title: "Generic shapes" }
  ];

  $: sortOptions = Object.entries(SORT_TYPES).map(([key, value]) => (
    { label: $_(`tile.sort.${value}`), value}
  ))

  let activeTab = tabs[0].id;
  
  // $: {
  //   activeTab = isReplacingTile ? tabs[0].id : tabs[1].id;
  // }

  function setActiveTab(id) {
    activeTab = id;
  }

  const handleFilterByName = debounce(e => {

    if (e.target.value.length > 0)
      selectedFilters.text_search = e.target.value;
    else
      selectedFilters.text_search = undefined;

    updateTilesList();
  }, 300);

  getAllTileFilters()
    .then((res) => {
      if ( !res?.data ) return;

      let childrenTypes = res.data[TILES_FILTERS.CHILDREN_TYPES];
      delete res.data[TILES_FILTERS.CHILDREN_TYPES];
      
      for (let category in res.data)
      {
        if (Array.isArray(res.data[category]) && category !== TILES_FILTERS.SORT)
        {
          let filters = res.data[category]
          if( category === "tile_shapes" ) {
            filters = filters.map((filter) => {
              const boundingBox = getBBFromDHidden(filter.svg)
              let scaleX = filter.default_width / 12 / boundingBox.width;
              let scaleY = filter.default_height / 12 / boundingBox.height;
              if (boundingBox.height * scaleY > 2) {
                const ratio = boundingBox.height * scaleY / 2;
                scaleX /= ratio;
                scaleY /= ratio; 
              }

              return {
                ...filter,
                scale: {
                  x: scaleX,
                  y: scaleY
                },
                translate: {
                  x: -(boundingBox.width / 2 + boundingBox.x),
                  y: -(boundingBox.height / 2 + boundingBox.y)
                },
                stroke: boundingBox.width / 50
              }
            })
          }
          filtersCategories[category] = new FilterCategory(category, filters, childrenTypes ? childrenTypes[category] : undefined);
          // if (category === TILES_FILTERS.PRODUCERS)
          // {
          //   filtersCategories[category]?.filters.forEach(filter => {
          //     filter.setCollapsingSubfilters(COLLAPSING_STATUS.COLLAPSED);
          //     collapsedFilters[category][filter.slug] = undefined;
          //   });
          // }
        }
      }
      filtersCategories[TILES_FILTERS.DIMENSIONS] = new FilterCategory(TILES_FILTERS.DIMENSIONS, []);
    })
    .then(() => updateAvailableTileFilters());

  const applyDimensions = () => {
    selectedFilters.min_width = minWidth
    selectedFilters.max_width = maxWidth
    selectedFilters.min_height = minHeight
    selectedFilters.max_height = maxHeight

    updateTilesList();
    updateAvailableTileFilters();
    //Svelte requires an assignment to trigger an update
    filtersCategories = filtersCategories;
  }

  const updateTilesList = debounce((resetPagination: boolean = true) => {
    tilesLoading = true;
    let queryParams = new queryParamsTiles();
    Object.assign(queryParams, selectedFilters);
    queryParams.limit = itemsPerPage;
    queryParams.page = resetPagination ? currentPage = 1 : currentPage;
    queryParams.sort = sort;

    if (wishlistId !== undefined)
      getWishlistTiles(wishlistId, queryParams).then((res) => updateTilesListSuccess(res)).catch(() => tilesLoading = false)
    else
      getTilesList(queryParams).then((res) => updateTilesListSuccess(res)).catch(() => tilesLoading = false)

    function updateTilesListSuccess (res: any) {
      tilesLoading = false;
      tilesList = res?.data;
      if ( tileContainerRef )
        tileContainerRef.scrollTop = 0;
      if (resetPagination && pagination)
        pagination.resetPagination(tilesList?.total);
    }
  }, 500)

  const updateAvailableTileFilters = debounce(() => {

    let queryParams = new queryParamsTiles();
    // merge(queryParams, selectedFilters, collapsedFilters);
    merge(queryParams, selectedFilters);

    getAvailableTileFilters(queryParams)
      .then((res) => {
        let availableFilters = res?.data?.available_filters;

        function setAvailability(filters: Filter[], category: string, availableFilters: any) {
          
          let availableCategoryFilters = availableFilters[category];
          //all filters of the category are available
          let all: boolean = availableCategoryFilters.length > 0 && availableCategoryFilters[0] === "all";

          for (let filter of filters)
          {
            let index: number = -1;

            if (!all)
            {
              //if filter is found then is available, so remove it from array to speed up subsequent searches
              index = availableCategoryFilters.findIndex((id) => id === filter.id);
              if (index >= 0)
                availableCategoryFilters.splice(index, 1);
            }

            //set availability
            filter.available = (all || index >= 0);

            if (filter.subfilters.length > 0)
            {
              setAvailability(filter.subfilters, filter.subcategory, availableFilters);

              //if filter isn't available but at least one of its subfilters is, then check as available
              if (!filter.available)
              {
                for (let subfilter of filter.subfilters)
                {
                  if (subfilter.available)
                  {
                    filter.available = true;
                    break;
                  }
                }
              }
            }
            //set availability if there are no subfilters
            // else
            //   filter.available = (all || index >= 0);
          }
        }
        for (let category in availableFilters)
        {
          let filters = filtersCategories[category]?.filters;
          if (filters)
            setAvailability(filters, category, availableFilters);
        }

        if (availableFilters?.width === undefined || availableFilters?.height === undefined)
          return;
        
        if (selectedFilters?.min_width === undefined ||
            selectedFilters?.min_width < availableFilters.width.min ||
            selectedFilters?.min_width > availableFilters.width.max)
        {
          if( sliderWidth )
            sliderWidth.noUiSlider.setHandle(0, availableFilters.width.min);
          selectedFilters.min_width = undefined;
          minWidth = availableFilters.width.min
        }

        if (selectedFilters?.max_width === undefined ||
            selectedFilters?.max_width < availableFilters.width.min ||
            selectedFilters?.max_width > availableFilters.width.max)
        {
          if( sliderWidth )
            sliderWidth.noUiSlider.setHandle(1, availableFilters.width.max);
          selectedFilters.max_width = undefined;
          maxWidth = availableFilters.width.max
        }

        if (selectedFilters?.min_height === undefined ||
            selectedFilters?.min_height < availableFilters.height.min ||
            selectedFilters?.min_height > availableFilters.height.max)
        {
          if( sliderHeight )
            sliderHeight.noUiSlider.setHandle(0, availableFilters.height.min);
          selectedFilters.min_height = undefined;
          minHeight = availableFilters.height.min
        }

        if (selectedFilters?.max_height === undefined ||
            selectedFilters?.max_height < availableFilters.height.min ||
            selectedFilters?.max_height > availableFilters.height.max)
        {
          if( sliderHeight )
            sliderHeight.noUiSlider.setHandle(1, availableFilters.height.max);
          selectedFilters.max_height = undefined;
          maxHeight = availableFilters.height.max
        }
        //Svelte requires an assignment to trigger an update
        filtersCategories = filtersCategories;
        //sliders not ready yet

        rangeMinWidth  = +availableFilters.width.min;
        rangeMaxWidth  = +availableFilters.width.max;
        rangeMinHeight = +availableFilters.height.min;
        rangeMaxHeight = +availableFilters.height.max;

        if (!sliderWidth || !sliderHeight)
          return;
        //update width slider range if is changed
        let currentOptions = sliderWidth.noUiSlider.options;
        if (currentOptions.range.min != availableFilters.width.min ||
            currentOptions.range.max != availableFilters.width.max)
        {
          sliderWidth.noUiSlider.updateOptions({
            range: {
              min: +availableFilters.width.min,
              max: +availableFilters.width.max
            }
          }, false);
          sliderWidth.noUiSlider.set([minWidth, maxWidth])
        }

        //update height slider range if is changed
        currentOptions = sliderHeight.noUiSlider.options;
        if (currentOptions.range.min != availableFilters.height.min ||
            currentOptions.range.max != availableFilters.height.max)
        {
          sliderHeight.noUiSlider.updateOptions({
              range: {
                min: +availableFilters.height.min,
                max: +availableFilters.height.max
              }
            }, false);
          sliderHeight.noUiSlider.set([minHeight, maxHeight])
        }
      })
  }, 500)

  function simplifyQueryParams(filter: Filter) {

    let descendants;

    if (filter.checked)
    {
      let ancestor = filter.getLastCheckedAncestor();

      if (ancestor.category === ancestor.subcategory)
      {
        //remove all descendants slug from query params only
        let descendants = ancestor.getDescendantsArray();
        for (let descendant of descendants)
            delete selectedFilters[descendant.category][descendant.slug];

        //add the checked ancestor slug in query params
        selectedFilters[ancestor.category][ancestor.slug] = undefined;
      }
      else
      {
        //add all checked descendants slugs in query params
        descendants = filter.getCheckedDescendantsArray();
        for (let descendant of descendants)
          selectedFilters[descendant.category][descendant.slug] = undefined;

        //add checked filter slug from query params
        selectedFilters[filter.category][filter.slug] = undefined;
      }
    }
    else
    {
      //remove unchecked filter slug from query params
      delete selectedFilters[filter.category][filter.slug];

      if (!filter.indipendentSubfilters || filter.category !== filter.subcategory)
      // if (!filter.indipendentSubfilters)
      {
        //remove all descendants slugs of the unchecked filter from query params
        descendants = filter.getDescendantsArray();
        for (let descendant of descendants)
          delete selectedFilters[descendant.category][descendant.slug];
      }
      else
      {
        //add all checked descendants slugs in query params
        descendants = filter.getCheckedDescendantsArray();
        for (let descendant of descendants)
          selectedFilters[descendant.category][descendant.slug] = undefined;
      }

      let root = filter.root();
      if (!root.indipendentSubfilters)
      {
        //remove root slug from query params
        delete selectedFilters[root.category][root.slug];

        //add only the shallower checked descendants slugs in query params
        descendants = root.getCheckedDescendantsArray();
        for (let descendant of descendants)
          selectedFilters[descendant.category][descendant.slug] = undefined;
      }
    }
  }

  function getSelectedFilter(category: FilterCategory, filterKey: string) {
    const result = category.filters.find((v) => v.slug === filterKey || v.subfilters.find((k) => k.slug === filterKey))
    if( result.slug !== filterKey )
      return result.subfilters.find((k) => k.slug === filterKey)
    return result;
  }

  function resetWidthFilter() {
    if( lockedWidth )
      return
    selectedFilters.min_width = undefined;
    selectedFilters.max_width = undefined;
    
    sliderWidth.noUiSlider.setHandle(0, rangeMinWidth);
    sliderWidth.noUiSlider.setHandle(1, rangeMaxWidth);
    minWidth = rangeMinWidth
    maxWidth = rangeMaxWidth
  }

  function resetHeightFilter() {
    if( lockedHeight )
      return
    selectedFilters.min_height = undefined;
    selectedFilters.max_height = undefined;
    
    sliderHeight.noUiSlider.setHandle(0, rangeMinHeight);
    sliderHeight.noUiSlider.setHandle(1, rangeMaxHeight);
    minHeight = rangeMinHeight
    maxHeight = rangeMaxHeight
  }

  function resetFilter() {
    resetWidthFilter()
    resetHeightFilter()
    const newFilters = new queryParamsTiles();
    if( $replaceTileContext?.lockShape ) {
      newFilters.tile_shapes = selectedFilters.tile_shapes;
    }
    if( lockedWidth ) {
      newFilters.min_width = selectedFilters.min_width;
      newFilters.max_width = selectedFilters.max_width;
    }
    if( lockedHeight ) {
      newFilters.min_height = selectedFilters.min_height;
      newFilters.max_height = selectedFilters.max_height;
    }
    selectedFilters = newFilters;
    filterOrder.forEach((category) => {
      filtersCategories[category]?.filters.forEach((filter) => {
        if( filter.checked ) {
          filter.toggle();
        } else if ( filter.subfilters.length > 0 ) {
          filter.subfilters.forEach((subFilter) => {
            if( subFilter.checked ) {
              subFilter.toggle();
            }
          })
        }
      })
    })
    //Svelte requires an assignment to trigger an update
    filtersCategories = filtersCategories;
    
    updateTilesList();
    updateAvailableTileFilters();
  }

  function handleBrandFilter (e) {
    brandFilter = e.target.value || '';
  }

  function toggleCategory(category: FilterCategory) {

    category.toggle();
    //Svelte requires an assignment to trigger an update
    filtersCategories = filtersCategories;
  }

  function toggleSubfilters(filter: Filter) {

    filter.toggleCollapsingSubfilters();
    //Svelte requires an assignment to trigger an update
    filtersCategories = filtersCategories;
  }

  function toggleFilter(filter: Filter) {

    filter.toggle();
    //Svelte requires an assignment to trigger an update
    filtersCategories = filtersCategories;
    
    let queryParams = new queryParamsTiles();
    merge(queryParams, selectedFilters);

    simplifyQueryParams(filter);

    //if the query parameters remain the same after simplification, then updating is not necessary
    if (queryParams.getQueryString() === selectedFilters.getQueryString())
      return;

    updateTilesList();
    updateAvailableTileFilters();
  }

  function getBBFromDHidden (d: string) {
    // create temporary and hidden svg
    let ns = 'http://www.w3.org/2000/svg';
    let svg = document.createElementNS(ns, 'svg')
    let path = document.createElementNS(ns, 'path') as SVGGraphicsElement
    svg.setAttribute('style', 'width:0!important; height:0!important; position:fixed!important; overflow:hidden!important; visibility:hidden!important; opacity:0!important' )
    path.setAttribute('d', d)
    svg.append(path)
    document.body.append(svg);
    let bb = path.getBBox()
    
    //remove temporary svg
    svg.remove();
    return bb;
  }
  
  function closePanel() {
    // resetFilter();
    currentPage = 1;

    drawSend("CANCEL_TILE_SELECTION");
  }

  function confirmTileSelection(shape: TileInfoResponse | Shape, closeModal: Function, genericTile: boolean = false) {

    if (genericTile)
    {
      drawSend({ type: "CONFIRM_TILE_SELECTION", shape: shape as Shape});
      closeModal();
    }
    else {
      tileSlugId = shape.slug;

      getTile(shape.slug)
        .then((res) => {
          let tile = res as TileInfoResponse
          const baseTileShape = genericTiles.find(gt => gt.shapeId === tile.tile_shape.id);
          // baseTileShape.path.toSvgPath()
          //apply 90° rotation
          if (tile.rotation === 90 || tile.rotation === 270)
            [tile.width, tile.height] = [tile.height, tile.width];

          let svg = baseTileShape.path.resizeAndCenter([
            (tile.width ?? TILE_TRANSFORM_SCALE) / TILE_TRANSFORM_SCALE,
            (tile.height ?? TILE_TRANSFORM_SCALE) / TILE_TRANSFORM_SCALE,
          ]);

          //apply 90° rotation
          // if (!!tile.rotation)
          //   svg = svg.transformed(Matrix2D.fromRotationTranslation(tile.rotation * Math.PI / 180));

          const newShape = new Shape(svg, baseTileShape.shapeId, tile.id, tile.name,
            {
              images: res?.images, //get images with more resolution
              filterId: `tile-fill-${baseTileShape.shapeId}-${tile.id}`,
            },
            tile.slug, tile.width, tile.height, tile.rotation)

          if (isReplacingTile)
          {
            drawSend({ type: "CONFIRM_TILE_SELECTION", shape: newShape, width: res.width, height: res.height });
            
            const wrapperWithSameLayouts = 
              $drawState.context.current.segments.filter((segment) => isTileWrapper(segment) && (segment as TileWrapper).tileLayout.id === tileWrapper.tileLayout.id) as TileWrapper[]
            wrapperWithSameLayouts.forEach((wrapper) => {
              drawSend({
                type: "SHOW_LAYOUT_GEOMETRY",
                tileData: $drawState.context.layoutContext.layout,
                segment: wrapper
              });
            })
          }
          else
            drawSend({ type: "CONFIRM_TILE_SELECTION", shape: newShape});

          closeModal();
        })
        .finally(() => {
          tileSlugId = null;
        })
    }
  }

</script>

<Modal onClose={closePanel} width={"w-full"} height={"h-full"} contentClass={"h-full"}>
  <div slot="trigger" let:open>
    {#if visible}
      {open()};
    {/if}
  </div>
  <div slot="header" let:close>
    <div class="flex bg-secondary-500 text-white justify-between items-center px-2 py-3">
      <div class="text-2xl mr-2">{$_(`tile.select`)}</div>
      <i
        class="fal fa-times cursor-pointer"
        color={"black"}
        on:click={close}
      />
    </div>
  </div>
  <div slot="content" let:close let:contentClass class={contentClass}>
  {#if visible}
    <div class="grid grid-cols-4 gap-4 p-4 bg-white shadow-xl h-full overflow-auto place-content-start">
      <!-- <nav class="col-span-full h-full overflow-auto flex space-x-4 {isReplacingTile? 'hidden' : ''}">
        {#each tabs as tab (tab.id)}
          <button class="border-transparent hover:bg-gray-200 border-0 rounded-md w-full px-3 py-2 text-sm 
                        {activeTab === tab.id ? 'font-bold bg-gray-100 text-secondary-500' : 'font-medium'}"
                        on:click={() => setActiveTab(tab.id)}>{tab.title}
          </button>
        {/each}
      </nav> -->
      <div class="col-span-1 px-1 h-full overflow-auto {activeTab === tabs[0].id ? '' : 'hidden'}">
        <div>
          <div class="flex justify-between items-center mb-1">
            <div class="text-sm text-gray-400">Applied filters:</div>
            <div class="text-xs font-bold cursor-pointer text-primary-500 underline" on:click={resetFilter}>Reset Filters</div>
          </div>

          <div class="flex items-center flex-wrap gap-1 mb-2">
            {#each filterOrder as category}
              {#if category === TILES_FILTERS.DIMENSIONS}
                {#if selectedFilters.min_width !== undefined || selectedFilters.max_width !== undefined}
                  <div class="bg-gray-200 rounded-md min-h-6 pl-1 inline-flex items-center justify-center text-xs">
                    <div class="font-bold capitalize">
                      Width:{selectedFilters.min_width !== undefined ? ` From ${selectedFilters.min_width}cm` : ''}{selectedFilters.max_width !== undefined ? ` To ${selectedFilters.max_width}cm` : ''}
                    </div>
                    <div class="flex items-center justify-center m-1 cursor-pointer" on:click={() => resetWidthFilter()} on:keydown>
                      <i class="fa fa-times text-sm" />
                    </div>
                  </div>
                {/if}
                {#if selectedFilters.min_height !== undefined || selectedFilters.max_height !== undefined}
                  <div class="bg-gray-200 rounded-md min-h-6 pl-1 inline-flex items-center justify-center text-xs">
                    <div class="font-bold capitalize">
                      Height:{selectedFilters.min_height !== undefined ? ` From ${selectedFilters.min_height}cm` : ''}{selectedFilters.max_height !== undefined ? ` To ${selectedFilters.max_height}cm` : ''}
                    </div>
                    <div class="flex items-center justify-center m-1 cursor-pointer" on:click={() => resetHeightFilter()} on:keydown>
                      <i class="fa fa-times text-sm" />
                    </div>
                  </div>
                {/if}
              {:else if category === 'tile_shapes' && $replaceTileContext?.lockShape !== undefined}
                <div></div>
              {:else}
                {#each Object.keys(selectedFilters[category]) as selectedItem}
                  <div class="bg-gray-200 rounded-md min-h-6 pl-1 inline-flex items-center justify-center text-xs">
                    <div class="font-bold capitalize">{$_(`tile.filter.${category}`)}: {getSelectedFilter(filtersCategories[category], selectedItem)?.name}</div>
                    <div class="flex items-center justify-center m-1 cursor-pointer" on:click={() => toggleFilter(getSelectedFilter(filtersCategories[category], selectedItem))} on:keydown>
                      <i class="fa fa-times text-sm" />
                    </div>
                  </div>
                {/each}
              {/if}
            {/each}
          </div>
        </div>

        <!-- <label class="flex items-stretch relative cursor-pointer select-none">
          <input type="checkbox" bind:checked={onlyWishlistsTiles} on:change={() => handleOnlyWishlistsTiles()}
                  class="{onlyWishlistsTiles ? 'border-primary-500' : 'border-gray-200'} w-14 h-7 flex-none appearance-none cursor-pointer rounded-full transform transition-colors"/>
          <span class="{onlyWishlistsTiles ? 'bg-primary-500 translate-x-6' : 'bg-gray-200 translate-x-0'} left-1 top-1 w-6 h-5 absolute rounded-full transform transition-transform transition-colors" />
          <span class="ml-3 flex items-center flex-grow">Search only on my wishlist</span>
        </label> -->
        
        <!-- <button type="button" role="switch" aria-checked="true" class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2">
          <span class="sr-only">Use setting</span> -->
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <!-- <span aria-hidden="true" class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
        </button> -->

        {#each filterOrder as category}
          <div class="flex flex-col max-h-1/2 
                      {(category === TILES_FILTERS.TILE_SHAPES && $replaceTileContext?.lockShape  !== undefined) ||
                       (category === TILES_FILTERS.DIMENSIONS  && lockedWidth && lockedHeight) ? 'hidden' : ''}">
            <div class="flex items-center bg-gray-100 my-2 p-2 cursor-pointer">
              <input class="filter-category hidden" type="checkbox" id="{category}" bind:checked={filtersCategories[category].expanded} on:click={() => toggleCategory(filtersCategories[category])}/>
              <label for="{category}" class="flex-grow font-bold text-base cursor-pointer">{$_(`tile.filter.${category}`)}</label>
              <div class="w-9 h-9 flex items-center justify-center flex-none ml-2" on:click={() => toggleCategory(filtersCategories[category])} on:keydown>
                <i class="fa-solid text-xl text-gray-500 {filtersCategories[category]?.expanded ? 'fa-caret-down' : 'fa-caret-up'}"/>
              </div>
            </div>
            <div class="flex-1 p-2 pb-6 overflow-auto {filtersCategories[category]?.expanded ? '' : 'hidden'}">
              <div class="{category === TILES_FILTERS.COLORS ? "grid grid-cols-5 gap-2 transition-opacity duration-200 w-full" :
                  category === TILES_FILTERS.TILE_SHAPES ? "grid grid-cols-2 gap-2" : "flex flex-col"}">
              {#if category === TILES_FILTERS.DIMENSIONS}
                <div class="flex flex-col gap-2">
                  <!-- <div class="{$replaceTileContext?.lockWidth !== undefined ? 'hidden' : ''}"> -->
                  <!-- <div class="{lockedWidth ? 'hidden' : ''}"> -->
                  <div>
                    <div class="text-sm">Width:</div>
                    <div class="flex h-8 items-center justify-center mb-2">
                      <div class="relative w-11/12">
                        <div id="slider-width" use:initSliderWidth></div>
                      </div>
                    </div>
                    <div class="flex gap-4 mb-2">
                      <Input 
                        type="number"
                        value={minWidth}
                        min={rangeMinWidth}
                        max={maxWidth}
                        on:input={(e) => {
                          minWidth = e.target.value;
                          sliderWidth.noUiSlider.set([minWidth, null]);
                        }}
                        fullWidth 
                        disabled={lockedWidth}
                      />
                      <Input 
                        type="number"
                        value={maxWidth}
                        min={minWidth}
                        max={rangeMaxWidth}
                        on:input={(e) => {
                          maxWidth = e.target.value
                          sliderWidth.noUiSlider.set([null, maxWidth]);
                        }}
                        fullWidth 
                        disabled={lockedWidth}
                      />
                    </div>
                    {#if minWidth < rangeMinWidth || minWidth > rangeMaxWidth || maxWidth < rangeMinWidth || maxWidth > rangeMaxWidth}
                    <p class="text-red-700">Enter a value between {rangeMinWidth} and {rangeMaxWidth}</p>
                    {/if}
                  </div>
                  <!-- <div class="{$replaceTileContext?.lockHeight !== undefined ? 'hidden' : ''}"> -->
                  <!-- <div class="{lockedHeight ? 'hidden' : ''}"> -->
                  <div>
                    <div class="text-sm">Height:</div>
                    <div class="flex h-8 items-center justify-center mb-2">
                      <div class="relative w-11/12">
                        <div id="slider-height" use:initSliderHeight></div>
                      </div>
                    </div>
                      <div class="flex gap-4 mb-2">
                        <Input 
                          type="number" 
                          value={minHeight}
                          min={rangeMinHeight}
                          max={maxHeight}
                          on:input={(e) => {
                            minHeight = e.target.value;
                            sliderHeight.noUiSlider.set([minHeight, null]);
                          }}
                          fullWidth 
                          disabled={lockedHeight}
                        />
                        <Input 
                          type="number" 
                          value={maxHeight}
                          min={minHeight}
                          max={rangeMaxHeight}
                          on:input={(e) => {
                            maxHeight = e.target.value;
                            sliderHeight.noUiSlider.set([null, maxHeight]);
                          }}
                          fullWidth 
                          disabled={lockedHeight}
                        />
                      </div>
                      {#if minHeight < rangeMinHeight || minHeight > rangeMaxHeight || maxHeight < rangeMinHeight || maxHeight > rangeMaxHeight}
                      <p class="text-red-700">Enter a value between {rangeMinHeight} and {rangeMaxHeight}</p>
                      {/if}
                  </div>
                  <Button variant="primary" class="w-full" on:click={applyDimensions} title="Apply" />
                </div>
              {:else if category === TILES_FILTERS.PRODUCERS}
                <Input type="search" on:input={handleBrandFilter} value={brandFilter} icon="fa-solid fa-magnifying-glass" class="mb-2" fullWidth />
                {#if filtersCategories[category]}
                  {#each filtersCategories[category].filters as filter (filter.slug)}
                    {#if filter.available && (filter.name.toLowerCase().includes(brandFilter.toLowerCase()) || filter.subfilters.some((v) => v.available && v.name.toLowerCase().includes(brandFilter.toLowerCase())))}
                      <Checkbox indeterminate={filter.indeterminate} checked={filter.checked} on:change={() => toggleFilter(filter)} label={filter.name} class="px-1 hover:bg-gray-100" /> 
                      {#each filter.subfilters as subfilter (subfilter.slug)}
                        {#if subfilter.available && (subfilter.name.toLowerCase().includes(brandFilter.toLowerCase()) || filter.name.toLowerCase().includes(brandFilter.toLowerCase()))}
                          <Checkbox checked={subfilter.checked} on:change={() => toggleFilter(subfilter)} label={subfilter.name} class="pl-4 pr-1 hover:bg-gray-100" /> 
                        {/if}
                      {/each}
                    {/if}
                  {/each}
                {/if}
              {:else if filtersCategories[category]}
                {#each filtersCategories[category]?.filters as filter (filter.slug)}
                  {#if filter.available}
                    {#if category === TILES_FILTERS.COLORS}
                      <button type="button" class="rounded-lg group relative m-0.5 h-6 w-full shadow-md" style={`background-color: #${filter.data?.code}`} title={filter.name} on:click={() => toggleFilter(filter)}>
                        {#if filter.checked}
                          {#if filter.name === "White" }
                            <i class="fa fa-check text-sm text-black opacity-70" />
                          {:else}
                            <i class="fa fa-check text-sm text-white" />
                          {/if}
                        {/if}
                      </button>
                    {:else if category === TILES_FILTERS.TILE_SHAPES}
                      <button type="button" class={`flex flex-col items-center justify-center rounded-lg w-full max-h-20 border-2 ${filter.checked ? "border-primary-500" : "border-gray-100"}`} title={filter.name} on:click={() => toggleFilter(filter)}>
                        <svg class="p-1 w-full" viewBox="-1 -1 2 2">
                          <g transform={`scale(${filter.data.scale.x}, ${filter.data.scale.y}) translate(${filter.data.translate.x}, ${filter.data.translate.y})`}>
                            <path 
                              d={filter.data.svg}
                              stroke-width={filter.data.stroke}
                              fill="white"
                              stroke="#555555"
                            />
                          </g>
                        </svg>
                        <p class="text-sm font-semibold">{filter.name}</p>
                      </button>
                    {:else}
                      <div class="flex">
                        <Checkbox indeterminate={filter.indeterminate} checked={filter.checked} on:change={() => toggleFilter(filter)} label={filter.name} class="px-1 hover:bg-gray-100" /> 
                        {#if filter.collapsingSubfilters !== COLLAPSING_STATUS.ALWAYS_VISIBILE}
                          <div class="flex items-center justify-end flex-grow">
                            <i class="fa-solid text-sm cursor-pointer {filter.collapsingSubfilters === COLLAPSING_STATUS.COLLAPSED ? 'fa-plus' : 'fa-minus'}" on:click={() => toggleSubfilters(filter)} on:keydown/>
                          </div>
                          <input class="subfilters-collapser hidden" type="checkbox" id="{filter.slug}_collapser"
                                 checked={filter.collapsingSubfilters === COLLAPSING_STATUS.NOT_COLLAPSED} on:change={() => toggleSubfilters(filter)}/>
                        {/if}
                      </div>
                      <div class="{filter.collapsingSubfilters ===  COLLAPSING_STATUS.COLLAPSED? 'hidden' : ''}">
                        {#each filter.subfilters as subfilter (subfilter.slug)}
                          {#if subfilter.available}
                            <Checkbox checked={subfilter.checked} on:change={() => toggleFilter(subfilter)} label={subfilter.name} class="pl-4 pr-1 hover:bg-gray-100" /> 
                          {/if}
                        {/each}
                      </div>
                    {/if}
                  {/if}
                {/each}
              {/if}
              </div>
            </div>
          </div>
        {/each}

      </div>
      <div class="col-span-3 flex flex-col h-full overflow-auto {activeTab === tabs[0].id ? '' : 'hidden'}">
        <div class="flex items-center gap-4 mb-2">
          <Input type="search" on:input={handleFilterByName} value={selectedFilters.text_search ?? ''} icon="fa-solid fa-magnifying-glass"/>
          <div class="text-base font-bold ml-auto">{formatNumberWithCommas(tilesList?.total || 0)} results</div>
          <Select options={sortOptions} bind:value={sort} inputProps="w-48"/>
        </div>

        {#if tilesLoading}
          <div class="flex h-full justify-center items-center">
            <Loading />
          </div>
        {:else}
          
          <ul
            role="listbox"
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-8 overflow-auto"
            bind:this={tileContainerRef}
          >
            {#if isRegularSelection}
              <li>
                <TileCard
                  onClick={() => {
                    drawSend({ type: "REPEAT_TILE_SELECTION" });
                    close();
                  }}
                >
                  <svg viewBox="-1.5 -1.5 3 3" slot="image">
                    {#each repeatShape as repeatShape}
                      <PassiveTile
                        shape={repeatShape.shape}
                        transform={repeatShape.transform}
                        tileID={repeatShape.shape.tileId}
                      />
                    {/each}
                  </svg>
                  <div slot="name">{$_("tile.repeat")}</div>
                </TileCard>
              </li>
            {/if}
            {#if tilesList?.tiles}
              {#each tilesList?.tiles as tile (tile.slug)}
                <li>
                  <TileCard 
                    tile={tile} 
                    loading={tileSlugId === tile.slug}
                    onClick={() => confirmTileSelection(tile, close)}
                  />
                </li>
              {/each}
            {/if}
          </ul>
        {/if}
        {#if tilesList?.total > 0}
        <Pagination bind:totalItems={tilesList.total} bind:itemsPerPage={itemsPerPage} bind:currentPage={currentPage}
                    bind:this={pagination} onPageChange={() => updateTilesList(false)}></Pagination>
        {/if}
      </div>
      <div class="col-span-full h-full overflow-auto {activeTab === tabs[1].id ? '' : 'hidden'}">
        <ul
          role="listbox"
          class="grid grid-flow-row grid-cols-fluid-56 gap-x-4 gap-y-8"
        >
          {#if isRegularSelection}
            <li>
              <TileCard
                onClick={() => {
                  drawSend({ type: "REPEAT_TILE_SELECTION" });
                  close();
                }}
              >
                <svg viewBox="-1.5 -1.5 3 3" slot="image">
                  {#each repeatShape as repeatShape}
                    <PassiveTile
                      shape={repeatShape.shape}
                      transform={repeatShape.transform}
                      tileID={repeatShape.shape.tileId}
                    />
                  {/each}
                </svg>
                <div slot="name">{$_("tile.repeat")}</div>
              </TileCard>
            </li>
          {/if}
          {#each genericTiles as shape, i}
            <li class="">
              <TileCard
                onClick={() => confirmTileSelection(shape, close, true)}
              >
                <svg viewBox="-1.5 -1.5 3 3" slot="image">
                  <PassiveTile {shape} tileID={shape.tileId} />
                </svg>
                <div slot="name">{shape.name ?? `Tile ${i}`}</div>
              </TileCard>
            </li>
          {/each}
        </ul>
    </div>
    </div>
  {/if}
  </div>
</Modal>

<style global>
  .noUi-connect	{
    background-color: #C29436;
  }

  .noUi-connects {
    background-color: #cccccc;
  }

  .noUi-horizontal {
    height: 8px;
  }

  .noUi-horizontal .noUi-handle {
    height: 16px;
    width: 24px;
    cursor: grab;
    border-radius: 6px;
    background-color: #C29436;
    box-shadow: none;
    border-width: 0px;
    right: -12px;
  }
  .noUi-handle::before {
    display: none;
  }
  .noUi-handle::after {
    display: none;
  }
  .noUi-target, .noUi-handle {
    cursor: pointer;
  }

  [disabled].noUi-target,
  [disabled].noUi-handle,
  [disabled] .noUi-handle {
    cursor: default;
    background-color: #CCCCCC;
  }
</style>
