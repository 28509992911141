<script lang="ts">
  export let title = "";
  export let className = "";
  export let active = false;
  export let special = false;
  export let size: "sm" | "md" | "lg" | "xl" | "2xl" = "xl";

  export { className as class };

  let sizeCls = '';
  $: {
    switch(size) {
      case '2xl':
        sizeCls = 'w-grid-item-2xl h-grid-item-2xl'
        break;
      case 'xl':
        sizeCls = 'w-grid-item-xl h-grid-item-xl'
        break;
      case 'lg':
        sizeCls = 'w-grid-item-lg h-grid-item-lg'
        break;
      case 'md':
        sizeCls = 'w-grid-item-md h-grid-item-md'
        break;
      case 'sm':
        sizeCls = 'w-grid-item-sm h-grid-item-sm'
        break;
    }
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class={`${className} flex flex-col justify-self-center group`} on:click on:mouseenter on:mouseleave>
  <div
    class={`relative flex items-center justify-center self-center transition-colors duration-300 
      ${sizeCls} border-2 rounded-xl p-2 cursor-pointer
      ${ special ? "border-primary-200 text-primary-200" : active ? "border-secondary-500 text-secondary-500" : "border-grid-item text-grid-item" } 
      group-hover:border-primary-500 group-hover:text-primary-500`
    }
  >
    <slot />
  </div>
  {#if title}
    <div class="text-center text-xxs text-secondary-500 group-hover:font-bold mt-2">{title}</div>
  {/if}
</div>
