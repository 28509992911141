export var ANCHOR_POINTS;
(function (ANCHOR_POINTS) {
    ANCHOR_POINTS[ANCHOR_POINTS["LEFT"] = -1] = "LEFT";
    ANCHOR_POINTS[ANCHOR_POINTS["CENTER"] = 0] = "CENTER";
    ANCHOR_POINTS[ANCHOR_POINTS["RIGHT"] = 1] = "RIGHT";
})(ANCHOR_POINTS || (ANCHOR_POINTS = {}));
export default class EdgeReference {
    edge;
    shape;
    offset;
    anchorPoint;
    constructor(edge, shape, offset, anchorPoint = ANCHOR_POINTS.CENTER) {
        this.edge = edge;
        this.shape = shape;
        this.offset = offset;
        this.anchorPoint = anchorPoint;
    }
    get index() {
        return this.edge.index;
    }
    isSameEdge(other) {
        return other.shape === this.shape && other.edge.index === this.edge.index;
    }
    toJSON(shapes) {
        return {
            shapeIndex: shapes.indexOf(this.shape),
            offset: this.offset,
            edgeIndex: this.edge.index,
            anchorPoint: this.anchorPoint
        };
    }
}
