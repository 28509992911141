export default class Vector2 {
    x;
    y;
    static UNIT_X = new Vector2(1, 0);
    static UNIT_Y = new Vector2(0, 1);
    static ZERO = new Vector2(0, 0);
    static fromPoints(p1, p2) {
        return new Vector2(p2.x - p1.x, p2.y - p1.y);
    }
    static linearCombination(points, coeffs) {
        let x = 0;
        let y = 0;
        const n = Math.min(points.length, coeffs.length);
        for (let i = 0; i < n; i++) {
            x += coeffs[i] * points[i].x;
            y += coeffs[i] * points[i].y;
        }
        return new Vector2(x, y);
    }
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
    add(other) {
        return new Vector2(this.x + other.x, this.y + other.y);
    }
    sub(other) {
        return new Vector2(this.x - other.x, this.y - other.y);
    }
    negated() {
        return new Vector2(-this.x, -this.y);
    }
    inverse() {
        return new Vector2(1 / this.x, 1 / this.y);
    }
    mul(other) {
        if (typeof other === "number") {
            return new Vector2(this.x * other, this.y * other);
        }
        else {
            return new Vector2(this.x * other.x, this.y * other.y);
        }
    }
    div(other) {
        if (typeof other === "number") {
            return new Vector2(this.x / other, this.y / other);
        }
        else {
            return new Vector2(this.x / other.x, this.y / other.y);
        }
    }
    dot(other) {
        return other.x * this.x + other.y * this.y;
    }
    crossMagnitude(other) {
        return this.x * other.y - this.y * other.x;
    }
    length() {
        return Math.sqrt(this.dot(this));
    }
    lengthSquared() {
        return this.dot(this);
    }
    distanceSquared(to) {
        return this.sub(to).lengthSquared();
    }
    distance(to) {
        return Math.sqrt(this.distanceSquared(to));
    }
    map(fn) {
        return new Vector2(fn(this.x), fn(this.y));
    }
    reduce(fn) {
        return fn(this.x, this.y);
    }
    max(other) {
        return new Vector2(Math.max(this.x, other.x), Math.max(this.y, other.y));
    }
    lerp(that, t) {
        const omt = 1.0 - t;
        return new Vector2(this.x * omt + that.x * t, this.y * omt + that.y * t);
    }
    distanceToSegmentSquared(v, w) {
        const l2 = v.distanceSquared(w);
        if (l2 === 0) {
            return this.distanceSquared(v);
        }
        let t = ((this.x - v.x) * (w.x - v.x) + (this.y - v.y) * (w.y - v.y)) / l2;
        t = Math.max(0, Math.min(1, t));
        return this.distanceSquared(new Vector2(v.x + t * (w.x - v.x), v.y + t * (w.y - v.y)));
    }
    distanceToSegment(v, w) {
        return Math.sqrt(this.distanceToSegmentSquared(v, w));
    }
    min(other) {
        return new Vector2(Math.min(this.x, other.x), Math.min(this.y, other.y));
    }
    normalVector() {
        return new Vector2(this.y, -this.x);
    }
    normalized() {
        const len = this.length();
        return new Vector2(this.x / len, this.y / len);
    }
    angleBetween(that) {
        let cos = this.dot(that) / (this.length() * that.length());
        cos = Math.max(-1, Math.min(cos, 1));
        const radians = Math.acos(cos);
        return this.crossMagnitude(that) < 0.0 ? -radians : radians;
    }
    hash(tolerance = 1e-5) {
        const invTolerance = 1.0 / tolerance;
        return (Math.round(this.x * invTolerance) * tolerance +
            "_" +
            Math.round(this.y * invTolerance) * tolerance);
    }
    equalTo(other, eps = 1e-6) {
        return (Math.abs(this.x - other.x) <= eps && Math.abs(this.y - other.y) <= eps);
    }
    toString() {
        return "[" + this.x + ", " + this.y + "]";
    }
}
