<script lang="ts">
  import { convertMousePointerToToolPosition } from "src/helpers";
  import { drawSend, drawState } from "src/layout.store";
  import { METRIC_UNITS } from "src/global/variable";
  import { _ } from "../../services/i18n";

  export let svgRef: SVGAElement;
  export let onInputLength: any;
  export let isEditArc: boolean;
  export let lineInputRef: any = undefined;
  export let lineLength: string | number | null;

  $: drawingObject = $drawState.context.drawContext.drawingObject;
  $: lineToolPosition = convertMousePointerToToolPosition(
          $drawState.context.snapContext.mousePointer,
          svgRef
        );
</script>
  
{#if lineToolPosition && (drawingObject || isEditArc)}
<div
  class="line-length-container"
  style="left: {lineToolPosition.x + 10}px; top: {lineToolPosition.y -
    50}px;"
>
  <!-- svelte-ignore a11y-label-has-associated-control -->
  <label id="label-for-line-length"
    >{drawingObject ? $_("app.length") : $_("app.height")}</label
  >
  <!-- svelte-ignore a11y-autofocus -->
  <input
    bind:this={lineInputRef}
    bind:value={lineLength}
    type="number"
    min="0"
    step={$drawState.context.currentMetricUnit === METRIC_UNITS[0] && !isEditArc ? 0.1 : 1}
    autofocus
    placeholder="0.00"
    on:keydown={onInputLength}
    class="line-length-input"
  />
</div>
{/if}

<style lang="scss" scoped>
  
  .line-length-container {
    position: absolute;
    display: flex;
    align-items: center;
    background: white;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .line-length-input {
    margin-left: 10px;
    padding-left: 10px;
    width: 100px;
    border: 2px solid grey;
    border-radius: 4px;
  }
  input {
    width: 50px;
    margin-right: 10px;
    padding-left: 5px;
    border: 2px solid grey;
    border-radius: 4px;
  }

</style>