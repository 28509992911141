<script lang="ts">
  import { editSegment, visibleSideMenu, viewBox, activeMenu } from "src/store";
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import Header from "./Header.svelte";
  import Button from "../../base/Button.svelte";
  import { centroid, checkBuildingPartInRoom, dist, isBuildingPart, isClosedArea } from "src/helpers";
  import { Pointer, type BuildingPart, ClosedArea } from "src/model";
  import SegmentList from "./SegmentList.svelte";
  import FurnitureInfo from "./FurnitureInfo.svelte";
  import { SEGMENT_LIST_TYPE, SIDE_MENUS } from "src/global/types";
  import type { CategoryElement } from "src/model/CategoryElement";
  import FurnitureItemList from "./FurnitureItemList.svelte";
  import FurnitureCategories from "./FurnitureCategories.svelte";
  import { onMount, onDestroy } from "svelte";
    
  export let categories: CategoryElement[] = [];

  enum FURNITURE_VIEW {
    INDEX,
    ADD_FURNITURE,
    EDIT_FURNITURE,
    FURNITURE_CATEGORY_LIST,
    FURNITURE_ITEM_LIST
  }

  let routes : FURNITURE_VIEW[] = [FURNITURE_VIEW.INDEX];

  let currentView: FURNITURE_VIEW = FURNITURE_VIEW.INDEX;
  let headerInfo = {
    title: "",
    info: "",
    path: "",
    editable: false
  };

  let currentCategory: CategoryElement;
  let isValidFurniture: boolean;

  let unsubscribe
  onMount(() => {
    unsubscribe = visibleSideMenu.subscribe((visible) => {
      if( $activeMenu !== SIDE_MENUS.FURNITURE )
        return;

      if( !visible ) {
        handleBack();
        routes = [FURNITURE_VIEW.INDEX];
        currentView = FURNITURE_VIEW.INDEX;
      } else if ($editSegment) {
        if( isBuildingPart($editSegment) ) {
          routes = [FURNITURE_VIEW.INDEX, FURNITURE_VIEW.EDIT_FURNITURE];
          currentView = FURNITURE_VIEW.EDIT_FURNITURE
        } else {
          routes = [FURNITURE_VIEW.INDEX];
          currentView = FURNITURE_VIEW.INDEX;
        }
        editSegment.set(undefined);
      }
    })
  })
  
  $: rooms = $drawState.context.current.segments.filter((segment) =>
    isClosedArea(segment)
  ) as ClosedArea[];

  $: sortedRooms = rooms.map((room) => {
    const centerPt = centroid(room.shape);
    const viewCenter = new Pointer($viewBox.x + $viewBox.w / 2, $viewBox.y + $viewBox.h / 2)
    return {
      room,
      distance: dist(centerPt, viewCenter)
    }
  }).sort((a, b) => a.distance - b.distance)

  $: furnitures = $drawState.context.current.segments.filter((segment) =>
    isBuildingPart(segment)
  ).sort((a, b) => a.name.localeCompare(b.name)) as BuildingPart[];

  const handleNext = (view: FURNITURE_VIEW) => {
    currentView = view;
    routes.push(currentView);
  };

  $: {
    const selectedObj = $drawState.context.dragContext.selectedObject;
    if ( selectedObj && isBuildingPart(selectedObj) ) {
      const buildingPart = selectedObj as BuildingPart;
      const closedArea = $drawState.context.current.segments.find(
          (s) =>
            s instanceof ClosedArea &&
            s.id === buildingPart.closedAreaId
        ) as ClosedArea;
        isValidFurniture = closedArea && checkBuildingPartInRoom(buildingPart, closedArea)
    } else 
      isValidFurniture = false
  }

  const handleBack = () => {
    drawSend({
      type: "CONFIRM_UPDATE",
    });
    routes.pop();
    if (currentView === FURNITURE_VIEW.EDIT_FURNITURE) {
      routes.pop();
      routes.pop();
      if( routes.length === 0 ) {
        visibleSideMenu.set(false);
      }
    }
    if (routes.length) currentView = routes[routes.length - 1];
  };

  const handleReset = () => {
    drawSend({
      type: "CANCEL_UPDATE",
    });
  }

  const handleSelectFurniture = (e: CustomEvent) => {
    let currentFurniture = e.detail.segment;
    if (currentFurniture) {
      drawSend({
        type: "ENTER_SELECT",
        segment: currentFurniture,
      });
    }
    
    handleNext(e.detail.segment ? FURNITURE_VIEW.EDIT_FURNITURE : FURNITURE_VIEW.FURNITURE_CATEGORY_LIST);
  }

  const handleSelectCategory = (e: CustomEvent) => {
    currentCategory = e.detail.category;
    handleNext(FURNITURE_VIEW.FURNITURE_ITEM_LIST);
  };


  $: {
    switch (currentView) {
      case FURNITURE_VIEW.INDEX:
        headerInfo = {
          title: $_("side_menu.furniture.list_furniture"),
          info: "",
          path: "",
          editable: false,
        };
        break;
      case FURNITURE_VIEW.ADD_FURNITURE:
      case FURNITURE_VIEW.EDIT_FURNITURE:
        headerInfo = {
          title: $drawState.context.dragContext.selectedObject?.getName(),
          info: "",
          path: `${$_("side_menu.furnitures")} > ${currentView === FURNITURE_VIEW.ADD_FURNITURE ? $_("side_menu.furniture.add_furniture") : $_("side_menu.furniture.edit_furniture")}`,
          editable: true,
        };
        break;
    }
  }

  const handleTitleChange = (e) => {
    drawSend({
      type: "CHANGE_NAME",
      name: e.detail.value,
    });
  }

  onDestroy(() => {
    if( unsubscribe ) unsubscribe();
  })
</script>

<div class="flex flex-col h-full">
  <Header {...headerInfo} on:change={handleTitleChange} />

  {#if currentView !== FURNITURE_VIEW.INDEX}
    <div class="flex items-center gap-1 px-3 hover:text-primary-500 cursor-pointer" on:click={handleBack}>
      <i class="fa-regular fa-chevron-left text-sm" />
      <span class="font-medium text-sm">{$_("side_menu.back")}</span>
    </div>
  {/if}
  <div class="w-full flex flex-col flex-1 p-3 overflow-auto gap-4">
    {#if currentView === FURNITURE_VIEW.INDEX}
      <SegmentList segments={furnitures} type={SEGMENT_LIST_TYPE.BUILDING_PART} on:select={handleSelectFurniture} hasAdd={rooms.length > 0}/>
    {:else if currentView === FURNITURE_VIEW.FURNITURE_CATEGORY_LIST}
      <FurnitureCategories {categories} on:select={handleSelectCategory} />
    {:else if currentView === FURNITURE_VIEW.FURNITURE_ITEM_LIST}
      {#if sortedRooms.length > 0}
        <FurnitureItemList
          categories={currentCategory?.children}
          room={sortedRooms[0].room}
          on:select={handleSelectFurniture}
        />
      {/if}
    {:else if currentView === FURNITURE_VIEW.EDIT_FURNITURE}
      <FurnitureInfo on:back={handleBack} />
    {/if}
    {#if currentView !== FURNITURE_VIEW.INDEX}
      <div class="flex items-center mt-auto">
        {#if currentView === FURNITURE_VIEW.EDIT_FURNITURE}
          <Button
            variant="outline-secondary"
            title={$_("side_menu.reset")}
            class="w-wide-btn ml-auto"
            on:click={handleReset}
            disabled={!isValidFurniture}
          />
        {/if}
      </div>
    {/if}
  </div>
</div>
