<script lang="ts">
  import { drawSend, drawState } from "../../layout.store";
  import { BuildingPart, ClosedArea, Door, Line, Pointer, Segment, TileWrapper } from "../../model";
  import {
    convertPointerToViewBox,
    getMetricWithUnit,
    isBuildingPart,
    isClosedArea,
    isDoor,
    isLine,
    isTileWrapper,
    isArc,
    makePointerInSvgBox,
  } from "../../helpers";
  import { currentTool } from "src/store";
  import { TOOLS } from "src/global/types";

  export let segment: Segment;
  export let svgRef: any;
  export let svgSize: any;
  export let scale: number;

  $: selectedObject = $drawState.context.dragContext.selectedObject;
  $: drawingObject = $drawState.context.drawContext.drawingObject;
  $: closedArea = isTileWrapper(selectedObject) || isBuildingPart(selectedObject) ? $drawState.context.current.segments.find((_seg) => _seg.id === (selectedObject as any).closedAreaId &&
          isClosedArea(_seg)) as ClosedArea : null;
  $: buildingParts = $drawState.context.current.segments.filter((_seg) => isBuildingPart(_seg)) as BuildingPart[]
  $: isDashLine = isLine(segment) && (segment as Line).isDashed()
  $: line = segment as Line

  const mouseDown = (e: any, segment: Segment) => {
    let pointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );
    pointer = convertPointerToViewBox(pointer, svgRef);
    pointer = makePointerInSvgBox(pointer, svgSize);

    if( $currentTool === TOOLS.EYE_DROPPER ) {
      return;
    }

    drawSend({ type: "ENTER_SELECT", segment: segment });
    drawSend({
      type: "UPDATE_OFFSET",
      pointer,
    });
    drawSend({ type: "SHOW_LINE_TOOL" });
  };

  const segmentHovered = () => {
    if ($drawState.matches("main.drawingState")) return;

    drawSend({ type: "MOUSE_HOVER" });
  };

  const segmentMouseLeave = () => {
    if ($drawState.matches("main.drawingState")) {
      return;
    }
    drawSend({ type: "MOUSE_LEAVE" });
  };
</script>

<g>
  <!-- svelte-ignore a11y-mouse-events-have-key-events -->
  <path
    d={isDashLine ? line.generatePath(false, undefined, 6) : segment.generatePath()}
    class={`${
      selectedObject === segment ? isDashLine ? "segment_dashed_path_selected" : "segment_path_selected" : ""
    } ${isDashLine ? "segment_dashed_path" : "segment_path"} segment`}
    stroke-linecap="round"
    stroke-width="1.5px"
    fill="none"
    on:mousedown={(e) => mouseDown(e, segment)}
    on:mouseover={() => segmentHovered()}
    on:mouseleave={() => segmentMouseLeave()}
  />
  {#if $drawState.matches("helper.showHelper") && 
    ($drawState.matches("main.printPreview") ||
    (((selectedObject?.id === segment.id || drawingObject?.id === segment.id) && !isDoor(selectedObject)) ||
    (closedArea && closedArea.shape.results.find((v) => v.id === segment.id))))
  }
    <g>
      {#each segment.generateHelperPath(buildingParts) as helperItem, j}
        <path
          d={helperItem}
          fill="none"
          stroke-linecap="round"
          stroke="grey"
          stroke-width="0.3px"
        />
      {/each}
      {#each segment.generateHelperTextData(buildingParts) as helperTextItem, j}
        <g>
          {#if helperTextItem}
            <text
              class="stroke-text"
              transform="translate({helperTextItem.x}, {helperTextItem.y}) rotate({helperTextItem.angle})"
              style={`font-size: ${16 / scale}px;`}
            >
              {isArc(segment) ? 
                getMetricWithUnit(
                  Math.round(helperTextItem.length * 100) / 100,
                  $drawState.context.currentMetricUnit,
                  false,
                  true
                ) :
                getMetricWithUnit(
                Math.round(helperTextItem.length * 100) / 100,
                $drawState.context.currentMetricUnit
              )}
            </text>
          {/if}
        </g>
      {/each}
    </g>
  {/if}
</g>

<style>
  text {
    font-family: sans-serif;
    text-anchor: middle;
    pointer-events: none;
    user-select: none;
  }
</style>
