export class Pointer {
    x;
    y;
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
    translate(dx, dy) {
        return new Pointer(this.x + dx, this.y + dy);
    }
    translateWithRate(rate) {
        return new Pointer(this.x * rate, this.y * rate);
    }
    equals(other) {
        return this.x === other.x && this.y === other.y;
    }
    isGreater(other) {
        return this.x > other.x || (this.x === other.x && this.y > other.y);
    }
    isLess(other) {
        return this.x < other.x || (this.x === other.x && this.y < other.y);
    }
    toJSON() {
        return {
            x: this.x,
            y: this.y,
        };
    }
}
