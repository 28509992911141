export default class Edge {
    index;
    length;
    center;
    segment;
    constructor(index, segment) {
        this.index = index;
        this.segment = segment;
        this.length = segment.length;
        this.center = segment.pointAt(0.5);
    }
    get from() {
        return this.segment.start;
    }
    get to() {
        return this.segment.end;
    }
}
