import Layout, {} from "./Layout";
import Neighbor, { NeighborOffset } from "./Neighbor";
import { createSampleLayout } from "./Tools";
import { EdgeOccupancySegment, EdgeOccupancy } from "./EdgeOccupancy";
import { LineSegment } from "./Path";
import AddPoint from "./AddPoint";
import LazyArray from "./LazyArray";
import BoundingBox from "./BoundingBox";
import Lazy from "./Lazy";
import AABBTree from "./AABBTree";
import { TOLERANCE_LAYOUT_DISTANCE } from "src/global/variable";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from 'uuid';
export default class RealizedLayout {
    width;
    height;
    maxDepth;
    protoLayout;
    gap;
    groutColor;
    id;
    overrideAspectRatio;
    lockedDimensions;
    layoutGeometryId;
    /// How small of a gap between tiles will be considered as worth of investigating whether a tile can be added there
    // public static readonly MIN_OCCUPANCY_MARGIN = 0.08;
    static MIN_OCCUPANCY_MARGIN = 0;
    static fromShape(shape, width, height, maxDepth = Layout.MAX_DEPTH, gap = Layout.DEFAULT_MARGIN, groutColor = Layout.DEFAULT_GROUT_COLOR) {
        return new RealizedLayout(width, height, maxDepth, Layout.fromShape(shape), gap, groutColor);
    }
    static sampleLayout(width, height, maxDepth = Layout.MAX_DEPTH, gap = Layout.DEFAULT_MARGIN, groutColor = Layout.DEFAULT_GROUT_COLOR) {
        return new RealizedLayout(width, height, maxDepth, createSampleLayout(), gap, groutColor);
    }
    static layoutCount = 0;
    tiles;
    addPointsLazy;
    edgeOccupancyLazy;
    constructor(width, height, maxDepth, protoLayout = new Layout(), gap = Layout.DEFAULT_MARGIN, groutColor = Layout.DEFAULT_GROUT_COLOR, id = uuidv4(), 
    // "overrideAspectRatio" is an array containing ONLY the replaced tiles, in chronological order
    overrideAspectRatio = [], lockedDimensions, layoutGeometryId) {
        this.width = width;
        this.height = height;
        this.maxDepth = maxDepth;
        this.protoLayout = protoLayout;
        this.gap = gap;
        this.groutColor = groutColor;
        this.id = id;
        this.overrideAspectRatio = overrideAspectRatio;
        this.lockedDimensions = lockedDimensions;
        this.layoutGeometryId = layoutGeometryId;
        RealizedLayout.layoutCount++;
        this.tiles = new LazyArray(protoLayout.recalculate(width, height, maxDepth));
        this.edgeOccupancyLazy = new Lazy(() => this.calculateOccupancy(Math.max(protoLayout.gapSize, RealizedLayout.MIN_OCCUPANCY_MARGIN) * 2 +
            1e-5));
        this.addPointsLazy = new Lazy(() => this.calculateAddPoints(Math.max(protoLayout.gapSize, RealizedLayout.MIN_OCCUPANCY_MARGIN)));
        const graph = this.protoLayout.getGraph();
        const shapes = graph.keySeq().toArray();
        this.lockedDimensions = cloneDeep(lockedDimensions) ?? Array.from({ length: shapes.length }, e => [1, 1]);
    }
    get addPoints() {
        return this.addPointsLazy.get();
    }
    get rootShape() {
        return this.protoLayout.rootShape;
    }
    get shapes() {
        // return Array.from(this.protoLayout.getGraph().keys());
        return this.protoLayout.getGraph().keySeq().toArray();
    }
    get gapSize() {
        return this.protoLayout.gapSize;
    }
    withGapSize(newGapSize) {
        return new RealizedLayout(this.width, this.height, this.maxDepth, this.protoLayout?.withGapSize(newGapSize), newGapSize, this.groutColor, this.id, this.overrideAspectRatio, this.lockedDimensions, this.layoutGeometryId);
    }
    remove(shape) {
        if (shape.depth === 0 && shape.shape === this.protoLayout.rootShape) {
            this.lockedDimensions = undefined;
            return this.withLayout(new Layout());
        }
        const oldShapes = this.shapes;
        const newLayout = this.withLayout(this.protoLayout.remove(shape.shape));
        const newShapes = newLayout.shapes;
        let lockedDimensions = [];
        for (let shape of oldShapes) {
            let index = newShapes.findIndex(s => shape === s);
            if (index >= 0)
                lockedDimensions.push(this.lockedDimensions[index]);
        }
        newLayout.lockedDimensions = lockedDimensions;
        return newLayout;
    }
    rotate(shape, by = 1) {
        if (this.isRepeatShape(shape) && shape.neighborLink) {
            const rotatedNeighbor = new Neighbor(shape.neighborLink.neighbor.source, shape.neighborLink.neighbor.targetShape.edge(shape.neighborLink.targetIndex - by, undefined, shape.neighborLink.neighbor.target.anchorPoint));
            return this.withLayout(this.protoLayout.updateNeighbor(shape.neighborLink.neighbor, rotatedNeighbor));
        }
        return this.withLayout(this.protoLayout.rotate(shape.shape, by));
    }
    changeAnchorPoint(source, target, targetIsChild) {
        return this.withLayout(this.protoLayout.changeAnchorPoint(source, target, targetIsChild));
    }
    addNeighbor(newNeighbor) {
        return this.withLayout(this.protoLayout.add(newNeighbor));
    }
    add(from, to) {
        // If adding a repeat from the root tile to another tile, switch the direction first
        if (this.protoLayout.hasTile(from.shapeInstance.shape) &&
            this.protoLayout.hasTile(to.shape)) {
            if (from.shapeInstance.shape === this.protoLayout.rootShape &&
                to.shape !== this.protoLayout.rootShape) {
                const idx = this.tiles.findIndex((t) => t.shape === to.shape);
                if (idx !== undefined) {
                    return this.add(AddPoint.createForEdge(idx, to, this.tiles.get(idx)), from.edge);
                }
            }
        }
        const [offset, result] = this.calculateAddOffset(from, to);
        if (offset === undefined || result === undefined) {
            return undefined;
        }
        if (result.hasCollisions()) {
            return undefined;
        }
        return result;
    }
    addShape(at, what) {
        const edgeReferences = what.edges
            .map((_, i) => what.edge(i))
            .filter((r) => !(at.edge.shape === what && r.index === at.edge.index));
        edgeReferences.sort((a, b) => {
            const d1 = Math.abs(a.edge.length - at.onEdgeLength);
            const d2 = Math.abs(b.edge.length - at.onEdgeLength);
            if (d1 === d2) {
                return a.index - b.index;
            }
            return d1 - d2;
        });
        let index = 0;
        let bestEdge = edgeReferences[index];
        if (bestEdge.edge.length > at.length) {
            // not enough space
            return undefined;
        }
        while (index < edgeReferences.length) {
            const result = this.add(at, bestEdge);
            if (result !== undefined) {
                return result;
            }
            index++;
            bestEdge = edgeReferences[index];
        }
        return undefined;
    }
    update(existingNeighbor, newNeighbor) {
        return this.withLayout(this.protoLayout.updateNeighbor(existingNeighbor, newNeighbor));
    }
    move(neighbor, by) {
        if (by.isZero()) {
            return this;
        }
        return this.withLayout(this.protoLayout.move(neighbor, by));
    }
    hasCollisions() {
        const firstTile = this.tiles.get(0);
        if (firstTile === undefined) {
            return false;
        }
        for (let i = 1; i < 64; i++) {
            const t = this.tiles.get(i);
            if (t === undefined || t.depth >= firstTile.segments.length) {
                return false;
            }
            if (t.collides) {
                return true;
            }
        }
        return false;
    }
    bisectCollision(nonCollidingNeighbor, collidingNeighborOffset) {
        // TODO: duplicated code from calculateAddOffsetForRepeat
        const nonCollidingOffset = nonCollidingNeighbor.offset.offset(this.protoLayout.gapSize);
        const collidingOffset = collidingNeighborOffset.offset(this.protoLayout.gapSize);
        let left = Math.min(nonCollidingOffset, collidingOffset);
        let right = Math.max(nonCollidingOffset, collidingOffset);
        const collidesLeft = left === collidingOffset;
        let collisionOffset = left;
        while (Math.abs(left - right) > TOLERANCE_LAYOUT_DISTANCE) {
            const center = (left + right) / 2;
            const c = this.move(nonCollidingNeighbor, new NeighborOffset(center - nonCollidingOffset)).hasCollisions();
            if (!collidesLeft) {
                if (c) {
                    right = center;
                }
                else {
                    left = center;
                }
                collisionOffset = left;
            }
            else {
                if (c) {
                    left = center;
                }
                else {
                    right = center;
                }
                collisionOffset = right;
            }
        }
        return new NeighborOffset(collisionOffset, collidesLeft ? 1 : -1);
    }
    autoRepeat() {
        return this.autoRepeatInternal();
    }
    freeEdges(shape) {
        return shape.shape.edges
            .map((_, i) => i)
            .filter((_, index) => index !== shape.neighborLink?.targetIndex);
    }
    allDescendantsOf(shape) {
        return this.protoLayout.allDescendantsOf(shape);
    }
    movableEdges(shape) {
        if (!shape.neighborLink) {
            return [];
        }
        return [shape.neighborLink.targetIndex];
    }
    isRoot(shape) {
        return shape.neighborLink === null;
    }
    isRepeatShape(shape) {
        return (shape !== undefined &&
            shape.shape === this.protoLayout.rootShape &&
            shape.depth === 1);
    }
    resize(newWidth, newHeight) {
        return new RealizedLayout(newWidth, newHeight, this.maxDepth, this.protoLayout, this.gap, this.groutColor, this.id, this.overrideAspectRatio, this.lockedDimensions, this.layoutGeometryId);
    }
    clone() {
        return new RealizedLayout(this.width, this.height, this.maxDepth, this.protoLayout, this.gap, this.groutColor, this.id, this.overrideAspectRatio, this.lockedDimensions, this.layoutGeometryId);
    }
    freeSegments(tile, edge, shapesToIgnore, range) {
        const o = this.edgeOccupancyLazy.get().get(tile);
        if (o === undefined) {
            return new EdgeOccupancy([], edge);
        }
        const segments = o[edge].freeSegments(tile.shape, shapesToIgnore, range);
        return new EdgeOccupancy(segments, edge);
    }
    withLayout(newBaseLayout) {
        return new RealizedLayout(this.width, this.height, this.maxDepth, newBaseLayout, this.gap, this.groutColor, this.id, this.overrideAspectRatio, this.lockedDimensions, this.layoutGeometryId);
    }
    autoRepeatInternal() {
        class RepeatScore {
            occupancyScore;
            expansionScore;
            orientationScore;
            constructor(occupancyScore, expansionScore, orientationScore) {
                this.occupancyScore = occupancyScore;
                this.expansionScore = expansionScore;
                this.orientationScore = orientationScore;
            }
            compare(other) {
                if (other.occupancyScore === this.occupancyScore) {
                    if (other.orientationScore === this.orientationScore) {
                        return other.expansionScore - this.expansionScore; // bigger is better
                    }
                    return this.orientationScore - other.orientationScore; // smaller is better
                }
                return this.occupancyScore - other.occupancyScore; // smaller is better
            }
            isBetterThan(other) {
                return this.compare(other) < 0;
            }
            toString() {
                return ("[occupancy=" +
                    this.occupancyScore +
                    ", expansion=" +
                    this.expansionScore +
                    ", orientation=" +
                    this.orientationScore +
                    "]");
            }
        }
        function hashEdge(e) {
            return e.shape.instanceId + "_" + e.edge.index;
        }
        function hashRepeat(from, to) {
            const h1 = hashEdge(from);
            const h2 = hashEdge(to);
            if (h1 < h2) {
                return h1 + "__" + h2;
            }
            else {
                return h2 + "__" + h1;
            }
        }
        const start = RealizedLayout.layoutCount;
        const rootTile = this.tiles.get(0);
        if (rootTile === undefined) {
            return this;
        }
        const rootOrientation = rootTile.orientationVector.normalized();
        const repeatEdges = rootTile.edges;
        let Collision;
        (function (Collision) {
            Collision[Collision["collision"] = 0] = "collision";
            Collision[Collision["noCollision"] = 1] = "noCollision";
        })(Collision || (Collision = {}));
        let bestLayout = this;
        let bestScore = new RepeatScore(this.occupancyScore, 0, 1);
        // Breadth first search through all possible repetitions with heuristics and early stopping
        const queue = [[this, bestScore, undefined, 0]];
        const processedLayoutHashes = new Map();
        processedLayoutHashes.set(this, new Map());
        while (queue.length > 0) {
            const [currentLayout, _, parentLayout, depth] = queue.shift();
            if (depth > rootTile.segments.length / 2 + 1) {
                continue;
            }
            const candidateLayouts = [];
            const pointsPerEdge = new Map();
            for (const addPoint of currentLayout.addPoints) {
                const h = hashEdge(addPoint.edge);
                pointsPerEdge.set(h, (pointsPerEdge.get(h) ?? 0) + 1);
            }
            const candidateRepeats = [];
            for (const addPoint of currentLayout.addPoints) {
                for (const repeatEdge of repeatEdges) {
                    // No repeat on tiny edges - these should not be present on well defined tiles, but we never know
                    if (repeatEdge.edge.length < this.protoLayout.gapSize) {
                        continue;
                    }
                    // If the edge cannot fit, just skip the repeat
                    if (repeatEdge.edge.length > addPoint.length + TOLERANCE_LAYOUT_DISTANCE) {
                        continue;
                    }
                    // Do not match curved to linear segments
                    if (repeatEdge.edge.segment instanceof LineSegment &&
                        !(addPoint.edge.edge.segment instanceof LineSegment)) {
                        continue;
                    }
                    // No repeats on itself
                    if (repeatEdge.isSameEdge(addPoint.edge)) {
                        continue;
                    }
                    candidateRepeats.push([addPoint, repeatEdge]);
                }
            }
            candidateRepeats.sort((a, b) => {
                // First prefer edges of the same length
                const d1 = Math.abs(a[0].onEdgeLength - a[1].edge.length);
                const d2 = Math.abs(b[0].onEdgeLength - b[1].edge.length);
                if (Math.abs(d1 - d2) < TOLERANCE_LAYOUT_DISTANCE) {
                    // Secondly prefer repeats that will not rotate the tile much
                    // The dot product will be -1 for repeats with 0 rotation, 0 for 90 degree rotations and 1 for 180 degree rotations
                    const dot1 = a[0].segment
                        .tangentAt(0.5)
                        .dot(a[1].edge.segment.tangentAt(0.5));
                    const dot2 = b[0].segment
                        .tangentAt(0.5)
                        .dot(b[1].edge.segment.tangentAt(0.5));
                    return 1 + dot1 - (1 + dot2);
                }
                return d1 - d2;
            });
            for (const [addPoint, repeatEdge] of candidateRepeats) {
                // Prune layouts that were already checked. This is not crucial for the algorithm to work
                // but it speeds up the search by 2-3 orders of magnitude.
                const repeatEdgeHash = hashEdge(repeatEdge);
                const hash = hashRepeat(addPoint.edge, repeatEdge);
                if (
                // parent layout says this repeat collides, we added new repeats so it definitely collides
                (parentLayout !== undefined &&
                    processedLayoutHashes.get(parentLayout).get(hash) ===
                        Collision.collision) ||
                    // we checked a symmetric repetition (A -> B, now we see B -> A), safe to skip
                    (pointsPerEdge.get(repeatEdgeHash) === 1 &&
                        processedLayoutHashes.get(currentLayout).get(hash) ===
                            Collision.noCollision)) {
                    continue;
                }
                const newLayout = currentLayout.add(addPoint, repeatEdge);
                if (!newLayout) {
                    // repeat contains collision
                    processedLayoutHashes
                        .get(currentLayout)
                        .set(hash, Collision.collision);
                    continue;
                }
                processedLayoutHashes
                    .get(currentLayout)
                    .set(hash, Collision.noCollision);
                processedLayoutHashes.set(newLayout, new Map());
                const repeatedTile = newLayout.findShapeInstanceByNeighbor(addPoint.edge, repeatEdge, 1);
                let orientationScore = 0;
                if (repeatedTile !== undefined) {
                    orientationScore =
                        1 -
                            repeatedTile.orientationVector.normalized().dot(rootOrientation);
                }
                const score = new RepeatScore(newLayout.occupancyScore, newLayout.expansionScore, orientationScore);
                // Early stopping when we found the perfect layout
                if (newLayout.addPoints.length === 0 &&
                    score.isBetterThan(bestScore) &&
                    Math.abs(orientationScore) < 1e-5) {
                    console.log("Early stopping search, found the best layout after testing " +
                        (RealizedLayout.layoutCount - start) +
                        " layouts");
                    return newLayout;
                }
                candidateLayouts.push([newLayout, score]);
            }
            // queue from most to least promising to increase the chance of finding the right layout quickly
            candidateLayouts.sort((a, b) => a[1].compare(b[1]));
            for (const [candidate, score] of candidateLayouts) {
                if (score.isBetterThan(bestScore)) {
                    if (candidate.addPoints.length === 0) {
                        return candidate;
                    }
                    bestScore = score;
                    bestLayout = candidate;
                }
                queue.push([candidate, score, currentLayout, depth + 1]);
            }
            // Hard stop here to avoid searching too big layout spaces when no repeats are possible
            if (RealizedLayout.layoutCount - start > 256) {
                return bestLayout;
            }
        }
        return bestLayout;
    }
    findShapeInstanceByNeighbor(from, to, depth = 1) {
        const idx = this.tiles.findIndex((s) => s.depth === depth &&
            (s.neighborLink?.neighbor.source.isSameEdge(from) || false) &&
            (s.neighborLink?.neighbor.target.isSameEdge(to) || false));
        if (idx !== undefined) {
            return this.tiles.get(idx);
        }
        return undefined;
    }
    /**
     * Tries to find an offset for the given tile that avoids collisions
     * @param from the add button location
     * @param to edge on the target tile that will be added to the layout
     */
    calculateAddOffset(from, to) {
        const range = from.range;
        const sourceEdgeLength = from.edge.edge.length;
        const targetEdgeLength = to.edge.length;
        let resultOffset = new NeighborOffset(from.location);
        let resultLayout;
        const sourceAnchorPoint = from.edge.anchorPoint;
        const targetAnchorPoint = to.anchorPoint;
        resultOffset = new NeighborOffset((sourceAnchorPoint * sourceEdgeLength / 2) - (targetAnchorPoint * targetEdgeLength / 2));
        if (targetEdgeLength + 2 * this.protoLayout.gapSize - 1e-5 >
            range[1] - range[0]) {
            resultOffset = undefined;
        }
        else if (from.location - targetEdgeLength / 2 - this.protoLayout.gapSize <
            range[0]) {
            resultOffset = new NeighborOffset(range[0] + targetEdgeLength / 2, 1);
        }
        else if (from.location + targetEdgeLength / 2 + this.protoLayout.gapSize >
            range[1]) {
            resultOffset = new NeighborOffset(range[1] - targetEdgeLength / 2, -1);
        }
        if (this.protoLayout.hasTile(to.shape) && this.protoLayout.shapeCount > 1) {
            const layout = this.addNeighbor(new Neighbor(from.edge, to, resultOffset));
            if (layout.hasCollisions() || resultOffset === undefined) {
                [resultOffset, resultLayout] = this.calculateAddOffsetForRepeat(from, to);
            }
            else {
                resultLayout = layout;
            }
        }
        return resultOffset === undefined
            ? [undefined, undefined]
            : [
                resultOffset,
                resultLayout ??
                    this.addNeighbor(new Neighbor(from.edge, to, resultOffset)),
            ];
    }
    calculateAddOffsetForRepeat(from, to) {
        const range = from.onEdgeRange;
        const sourceEdgeLength = from.edge.edge.length;
        const targetEdgeLength = to.edge.length;
        let left = range[0] - targetEdgeLength / 2;
        let right = range[1] + targetEdgeLength / 2;
        const neighbor = new Neighbor(from.edge, to, new NeighborOffset(from.location));
        const leftLayout = this.addNeighbor(neighbor.withOffset(new NeighborOffset(left)));
        const rightLayout = this.addNeighbor(neighbor.withOffset(new NeighborOffset(right)));
        const collidesLeft = leftLayout.hasCollisions();
        const collidesRight = rightLayout.hasCollisions();
        if (collidesLeft && collidesRight) {
            // Collides on both sides, try center and if that fails, the tile probably cannot be placed
            if (from.length >= to.edge.length) {
                const centerLayout = this.addNeighbor(neighbor);
                if (!centerLayout.hasCollisions()) {
                    return [neighbor.offset, centerLayout];
                }
            }
            return [undefined, undefined];
        }
        if (!collidesLeft && !collidesRight) {
            const o = new NeighborOffset((left + right) / 2);
            return [o, this.addNeighbor(neighbor.withOffset(o))];
        }
        let collisionOffset = left;
        let collisionLayout = leftLayout;
        while (Math.abs(left - right) > TOLERANCE_LAYOUT_DISTANCE) {
            const center = (left + right) / 2;
            collisionLayout = this.addNeighbor(neighbor.withOffset(new NeighborOffset(center)));
            const c = collisionLayout.hasCollisions();
            if (collidesRight) {
                if (c) {
                    right = center;
                }
                else {
                    left = center;
                }
                collisionOffset = left;
            }
            else {
                if (c) {
                    left = center;
                }
                else {
                    right = center;
                }
                collisionOffset = right;
            }
        }
        let resultOffset = new NeighborOffset(collisionOffset);
        if (collidesLeft && !collidesRight) {
            let newOffset = collisionOffset + this.gapSize;
            if (Math.abs(newOffset + (sourceEdgeLength - targetEdgeLength) / 2) / targetEdgeLength < 0.01) {
                newOffset = (sourceEdgeLength - targetEdgeLength) / 2;
                resultOffset = new NeighborOffset(newOffset);
            }
            else {
                resultOffset = new NeighborOffset(collisionOffset, 1);
            }
            // resultOffset = new NeighborOffset(collisionOffset, 1);
            collisionLayout = this.addNeighbor(neighbor.withOffset(resultOffset));
        }
        else if (collidesRight && !collidesLeft) {
            // resultOffset = new NeighborOffset(collisionOffset, -1);
            let newOffset = collisionOffset - this.gapSize;
            if (Math.abs(newOffset - (sourceEdgeLength - targetEdgeLength) / 2) / targetEdgeLength < 0.01) {
                newOffset = (sourceEdgeLength - targetEdgeLength) / 2;
                resultOffset = new NeighborOffset(newOffset);
            }
            else {
                resultOffset = new NeighborOffset(collisionOffset, -1);
            }
            collisionLayout = this.addNeighbor(neighbor.withOffset(resultOffset));
        }
        return [resultOffset, collisionLayout];
    }
    segmentOccupancy(segment, instance, edgeIndex, margin) {
        const r = Array();
        let j = 0;
        for (const s of instance.polygon.segments) {
            if (Math.abs(segment.tangentAt(0.5).dot(s.tangentAt(0.5))) < 0.9) {
                j++;
                continue;
            }
            const t1 = segment.closestPoint(s.start);
            const t2 = segment.closestPoint(s.end);
            const t3 = s.closestPoint(segment.start);
            const t4 = s.closestPoint(segment.end);
            const minDist = Math.min(t1.distance, t2.distance, t3.distance, t4.distance);
            if (minDist <= margin && Math.abs(t1.t - t2.t) > TOLERANCE_LAYOUT_DISTANCE) {
                if (t1.t < t2.t) {
                    r.push(new EdgeOccupancySegment(instance.shape, [t1.t, t2.t], edgeIndex));
                }
                else {
                    r.push(new EdgeOccupancySegment(instance.shape, [t2.t, t1.t], edgeIndex));
                }
            }
            j++;
        }
        r.sort((a, b) => a.range[0] - b.range[0]);
        return r;
    }
    calculateOccupancy(margin) {
        const result = new Map();
        const firstTile = this.tiles.get(0);
        if (this.rootShape === null || !firstTile) {
            return result;
        }
        const shapes = this.tiles.takeWhile((t) => t.depth === 0);
        const toCheckShapes = this.tiles.takeWhile((t) => t.depth < firstTile.segments.length && !t.collides);
        const aabbTree = new AABBTree();
        for (const otherInstance of toCheckShapes) {
            aabbTree.add(otherInstance, otherInstance.bounds.expanded(margin * 2));
        }
        for (const instance of shapes) {
            let i = 0;
            const occupied = new Array(instance.segments.length)
                .fill(new EdgeOccupancy([], 0))
                .map((_, idx) => new EdgeOccupancy([], idx));
            for (const segment of instance.segments) {
                const segmentBounds = segment.bounds;
                const relevantShapes = aabbTree.getIntersectingShapes(segmentBounds, segmentBounds, (a, b) => true);
                for (const otherInstance of relevantShapes) {
                    if (otherInstance === instance) {
                        continue;
                    }
                    const o = this.segmentOccupancy(segment, otherInstance, i, margin);
                    occupied[i].segments.push(...o);
                    occupied[i].segments.sort((a, b) => a.range[1] - b.range[1]);
                }
                i++;
            }
            result.set(instance, occupied);
        }
        return result;
    }
    /**
     * Computes the length of unoccupied edges in this layout. This is used for auto repeat,
     * layouts with smaller occupancy score tile the plane better.
     */
    get occupancyScore() {
        if (this.addPoints.length === 0) {
            return 0;
        }
        return this.addPoints.map((p) => p.onEdgeLength).reduce((a, b) => a + b);
    }
    /**
     * How quickly this layout expands in area. The bigger this number, the better.
     */
    get expansionScore() {
        const shapes = this.tiles.takeWhile((t) => t.depth <= 3);
        const bbox = BoundingBox.fromBoundingBoxes(shapes.map((s) => s.bounds));
        return bbox.radius;
    }
    calculateAddPoints(margin) {
        const occupancy = this.edgeOccupancyLazy.get();
        const result = [];
        const baseShapes = this.tiles.takeWhile((t) => t.depth === 0);
        let index = 0;
        for (const baseShape of baseShapes) {
            const shapeOccupancy = occupancy.get(baseShape);
            const freeSegments = shapeOccupancy.flatMap((o) => o.freeSegments(baseShape.shape, undefined, [-this.width, this.width]));
            const points = freeSegments.flatMap((s) => {
                const fullRange = s.rangeInAbsoluteUnits;
                const clampedRange = s.rangeInAbsoluteUnits;
                if (clampedRange[1] - clampedRange[0] <= 2 * margin ||
                    s.sourceEdgeLength <= 2 * margin) {
                    return [];
                }
                clampedRange[0] = Math.max(clampedRange[0] + margin, 0);
                clampedRange[1] = Math.min(clampedRange[1] - margin, s.sourceEdgeLength);
                if (clampedRange[1] - clampedRange[0] <= margin) {
                    return [];
                }
                const clampedRangeAsOffset = [
                    clampedRange[0] - 0.5 * s.sourceEdgeLength,
                    clampedRange[1] - 0.5 * s.sourceEdgeLength,
                ];
                const fullRangeAsOffset = [
                    fullRange[0] - 0.5 * s.sourceEdgeLength,
                    fullRange[1] - 0.5 * s.sourceEdgeLength,
                ];
                const center = (clampedRangeAsOffset[0] + clampedRangeAsOffset[1]) * 0.5;
                const addPoint = new AddPoint(index, baseShape.edge(s.edgeIndex), baseShape, center, fullRangeAsOffset, clampedRangeAsOffset);
                return [addPoint];
            });
            result.push(...points);
            index++;
        }
        return result;
    }
    calcOverrideAspectRatio(tile, shapeIndex, width, height, geometry, baseShapes) {
        const svgPath = JSON.parse(geometry.svg_path);
        const shapeToOverride = svgPath.shape_index[shapeIndex];
        if (!shapeToOverride)
            return;
        const dimensionsShape = Array.isArray(svgPath.dimensions) && svgPath.dimensions.length > shapeIndex ? svgPath.dimensions[shapeIndex] : undefined;
        // const baseShape = baseShapes.find((bs) => bs.tileId === shapeToOverride);
        const geometryShape = geometry.tile_shapes.find((gs) => gs.id === shapeToOverride);
        let widthMultiplyFactor = 1, heightMultiplyFactor = 1;
        if (dimensionsShape) {
            widthMultiplyFactor = width / dimensionsShape[0];
            heightMultiplyFactor = height / dimensionsShape[1];
        }
        else if (geometryShape) {
            widthMultiplyFactor = width / geometryShape.default_width;
            heightMultiplyFactor = height / geometryShape.default_height;
        }
        // else if (baseShape)
        // {
        //   widthMultiplyFactor  = width  / baseShape.default_width;
        //   heightMultiplyFactor = height / baseShape.default_height;
        // }
        let search = this.overrideAspectRatio.find(e => e.shapeIndex === shapeIndex);
        if (search) {
            search.tile = tile;
            search.widthMultiplyFactor = widthMultiplyFactor;
            search.heightMultiplyFactor = heightMultiplyFactor;
        }
        else {
            this.overrideAspectRatio.push({
                shapeIndex: shapeIndex,
                tile: tile,
                widthMultiplyFactor: widthMultiplyFactor,
                heightMultiplyFactor: heightMultiplyFactor
            });
        }
    }
    getMultiplyFactors(geometry, shapeIndex) {
        const svgPath = JSON.parse(geometry.svg_path);
        const neighbors = svgPath.neighbors;
        let widthMultiplyFactor = 1, heightMultiplyFactor = 1;
        let allShapesEqual = svgPath.shape_index.every(e => e === svgPath.shape_index[0]);
        // first replaced tile has priority over others
        const firstReplacedTile = this.overrideAspectRatio[0];
        // check if is a replaced tile or a base shape
        const isReplacedTile = this.overrideAspectRatio.find(e => e.shapeIndex === shapeIndex);
        if (isReplacedTile)
            return {
                widthMultiplyFactor: isReplacedTile.widthMultiplyFactor,
                heightMultiplyFactor: isReplacedTile.heightMultiplyFactor
            };
        // if the layout is composed of tiles with the same shape multiplication factors are equal
        if (allShapesEqual) {
            widthMultiplyFactor = firstReplacedTile.widthMultiplyFactor;
            heightMultiplyFactor = firstReplacedTile.heightMultiplyFactor;
        }
        // if the shape has 4 sides we must multiply either width or height based on the anchoring side
        else if (firstReplacedTile.tile.path.segmentCount === 4) {
            let asChild, asParent;
            // for (const neighbor of neighbors)
            // {
            //   // search as child
            //   asChild = neighbor.children.find(child =>
            //     child.source.shapeIndex === firstReplacedTile.shapeIndex &&
            //     child.target.shapeIndex === shapeIndex);
            //   if (asChild)
            //     break;
            //   // search as parent
            //   asParent = neighbor.parents.find(parent =>
            //     parent.source.shapeIndex === shapeIndex &&
            //     parent.target.shapeIndex === firstReplacedTile.shapeIndex);
            //   if (asParent)
            //     break;
            // }
            let neighbor = neighbors[firstReplacedTile.shapeIndex];
            // search as parent
            asParent = neighbor?.children.find(child => child.source.shapeIndex === firstReplacedTile.shapeIndex &&
                child.target.shapeIndex === shapeIndex);
            // search as child
            if (!asParent) {
                asChild = neighbor?.parents.find(parent => parent.source.shapeIndex === firstReplacedTile.shapeIndex &&
                    parent.target.shapeIndex === shapeIndex);
            }
            // tile is not a direct parent or child of the replaced tile, recursive search in the other tiles
            if (!asChild && !asParent) {
                // neighbor = neighbors[firstReplacedTile.shapeIndex];
                // asChild = neighbor?.children.find(child =>
                //   child.source.shapeIndex === firstReplacedTile.shapeIndex &&
                //   child.target.shapeIndex === shapeIndex);
            }
            // edgeIndex === 0 -> left anchor point, edgeIndex === 2 -> right anchor point
            // if (asChild?.source.edgeIndex  === 0 || asChild?.source.edgeIndex  === 2 ||
            //     asParent?.source.edgeIndex === 0 || asParent?.source.edgeIndex === 2)
            // {
            //   widthMultiplyFactor  = firstReplacedTile.heightMultiplyFactor;
            //   heightMultiplyFactor = firstReplacedTile.heightMultiplyFactor;
            // }
            // // edgeIndex === 1 -> bottom anchor point, edgeIndex === 3 -> top anchor point
            // if (asChild?.source.edgeIndex  === 1 || asChild?.source.edgeIndex  === 3 ||
            //     asParent?.source.edgeIndex === 1 || asParent?.source.edgeIndex === 3)
            // {
            //   widthMultiplyFactor  = firstReplacedTile.widthMultiplyFactor;
            //   heightMultiplyFactor = firstReplacedTile.widthMultiplyFactor;
            // }
            widthMultiplyFactor = firstReplacedTile.widthMultiplyFactor;
            heightMultiplyFactor = firstReplacedTile.heightMultiplyFactor;
        }
        // otherwise we calculate the average between width and height
        else {
            widthMultiplyFactor = (firstReplacedTile.widthMultiplyFactor + firstReplacedTile.heightMultiplyFactor) / 2;
            heightMultiplyFactor = widthMultiplyFactor;
        }
        return { widthMultiplyFactor: widthMultiplyFactor, heightMultiplyFactor: heightMultiplyFactor };
    }
    // this is a workaround for xstate-inspect to avoid huge JSON dump
    // we have a type recursion in the layout graph and the JSON library is not capable of serializing it reasonably
    toJSON() {
        return {
            debugOnly: "this is meant for debug ONLY!!",
            tilesCount: this.tiles.length,
            addPointsCount: this.addPoints.length,
        };
    }
    getGraph() {
        const graph = this.protoLayout.getGraph();
        const shapes = graph.keySeq().toArray();
        const neighbors = graph.valueSeq().toArray();
        const shapeIds = shapes.map((s) => s.shapeId);
        const shapeDimensions = shapes.map((s) => [s.width, s.height]);
        const tileIds = shapes.map((s) => s.tileId);
        return {
            svg_path: {
                shape_index: shapeIds,
                dimensions: shapeDimensions,
                locked_dimensions: this.lockedDimensions,
                neighbors: neighbors.map((v) => v.toJSON(shapes)),
            },
            tile_shapes: [...new Set(shapeIds)],
            num_shapes: new Set(shapeIds).size,
            min_gap_size: this.gapSize,
            tiles: tileIds,
        };
    }
}
