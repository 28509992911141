/**
 * Based on kld-intersections (https://github.com/thelonious/kld-intersections)
 */
import Vector2 from "./Vector2";
import Polynomial from "./Polynomial";
import { ArcSegment, QuadraticBezierSegment, CubicBezierSegment, LineSegment, normalizeAngle, PolyBezierSegment, } from "./Path";
const TWO_PI = 2.0 * Math.PI;
const UNIT_X = new Vector2(1, 0);
/**
 *  bezout
 *
 *  This code is based on MgcIntr2DElpElp.cpp written by David Eberly.  His
 *  code along with many other excellent examples are avaiable at his site:
 *  http://www.magic-software.com
 */
function bezout(e1, e2) {
    const AB = e1[0] * e2[1] - e2[0] * e1[1];
    const AC = e1[0] * e2[2] - e2[0] * e1[2];
    const AD = e1[0] * e2[3] - e2[0] * e1[3];
    const AE = e1[0] * e2[4] - e2[0] * e1[4];
    const AF = e1[0] * e2[5] - e2[0] * e1[5];
    const BC = e1[1] * e2[2] - e2[1] * e1[2];
    const BE = e1[1] * e2[4] - e2[1] * e1[4];
    const BF = e1[1] * e2[5] - e2[1] * e1[5];
    const CD = e1[2] * e2[3] - e2[2] * e1[3];
    const DE = e1[3] * e2[4] - e2[3] * e1[4];
    const DF = e1[3] * e2[5] - e2[3] * e1[5];
    const BFpDE = BF + DE;
    const BEmCD = BE - CD;
    return new Polynomial(AB * BC - AC * AC, AB * BEmCD + AD * BC - 2 * AC * AE, AB * BFpDE + AD * BEmCD - AE * AE - 2 * AC * AF, AB * DF + AD * BFpDE - 2 * AE * AF, AD * DF - AF * AF);
}
function restrictPointsToArc(intersections, center, radiusX, radiusY, startRadians, endRadians) {
    if (intersections.points.length === 0) {
        return intersections;
    }
    const resultPoints = [];
    // swap if end is lower, so start is always the lower one
    if (endRadians < startRadians) {
        [startRadians, endRadians] = [endRadians, startRadians];
    }
    // move everything to the positive domain, simultaneously
    if (startRadians < 0 || endRadians < 0) {
        startRadians += TWO_PI;
        endRadians += TWO_PI;
    }
    for (const p of intersections.points) {
        let a = normalizeAngle(UNIT_X.angleBetween(Vector2.fromPoints(center, p)));
        // a angle smaller than start, it may still be between
        // this happens if end > TWO_PI
        if (a < startRadians) {
            a += TWO_PI;
        }
        if (startRadians <= a && a <= endRadians) {
            resultPoints.push(p);
        }
    }
    if (resultPoints.length > 0) {
        return new Intersection("Intersection", resultPoints);
    }
    return new Intersection("No Intersection", []);
}
function closePolygon(points) {
    const copy = points.slice();
    copy.push(points[0]);
    return copy;
}
function swap(fn) {
    return (x1, x2) => fn(x2, x1);
}
class Intersection {
    status;
    points;
    static intersectSegments(s1, s2) {
        if (!s1.bounds.intersects(s2.bounds)) {
            return new Intersection("No Intersection", []);
        }
        const pairs = [
            [[LineSegment, LineSegment], Intersection.intersectLineLine],
            [[LineSegment, ArcSegment], swap(Intersection.intersectArcLine)],
            [
                [LineSegment, QuadraticBezierSegment],
                swap(Intersection.intersectBezier2Line),
            ],
            [
                [LineSegment, CubicBezierSegment],
                swap(Intersection.intersectBezier3Line),
            ],
            [
                [LineSegment, PolyBezierSegment],
                swap(Intersection.intersectPolyBezierLine),
            ],
            [[ArcSegment, LineSegment], Intersection.intersectArcLine],
            [[ArcSegment, ArcSegment], Intersection.intersectArcArc],
            [[ArcSegment, QuadraticBezierSegment], Intersection.intersectArcBezier2],
            [[ArcSegment, CubicBezierSegment], Intersection.intersectArcBezier3],
            [
                [ArcSegment, PolyBezierSegment],
                swap(Intersection.intersectPolyBezierArc),
            ],
            [
                [QuadraticBezierSegment, LineSegment],
                Intersection.intersectBezier2Line,
            ],
            [
                [QuadraticBezierSegment, ArcSegment],
                swap(Intersection.intersectArcBezier2),
            ],
            [
                [QuadraticBezierSegment, QuadraticBezierSegment],
                Intersection.intersectBezier2Bezier2,
            ],
            [
                [QuadraticBezierSegment, CubicBezierSegment],
                Intersection.intersectBezier2Bezier3,
            ],
            [
                [QuadraticBezierSegment, PolyBezierSegment],
                swap(Intersection.intersectPolyBezierBezier2),
            ],
            [[CubicBezierSegment, LineSegment], Intersection.intersectBezier3Line],
            [
                [CubicBezierSegment, ArcSegment],
                swap(Intersection.intersectArcBezier3),
            ],
            [
                [CubicBezierSegment, QuadraticBezierSegment],
                swap(Intersection.intersectBezier2Bezier3),
            ],
            [
                [CubicBezierSegment, CubicBezierSegment],
                swap(Intersection.intersectBezier3Bezier3),
            ],
            [
                [CubicBezierSegment, PolyBezierSegment],
                swap(Intersection.intersectPolyBezierBezier3),
            ],
        ];
        for (const pair of pairs) {
            if (s1 instanceof pair[0][0] && s2 instanceof pair[0][1]) {
                return pair[1](s1, s2);
            }
        }
        throw new Error("Unknown pair for intersection: " + s1 + " and " + s2);
    }
    static intersectArcLine(arc, line) {
        const e = Intersection.intersectEllipseLine(arc.canonical.center, arc.canonical.radiusX, arc.canonical.radiusY, line.start, line.end);
        return restrictPointsToArc(e, arc.canonical.center, arc.canonical.radiusX, arc.canonical.radiusY, arc.canonical.angleStartRad, arc.canonical.angleEndRad);
    }
    static intersectArcArc(arc1, arc2) {
        const e = Intersection.intersectEllipseEllipse(arc1.canonical.center, arc1.canonical.radiusX, arc1.canonical.radiusY, arc2.canonical.center, arc2.canonical.radiusX, arc2.canonical.radiusY);
        const e2 = restrictPointsToArc(e, arc1.canonical.center, arc1.canonical.radiusX, arc1.canonical.radiusY, arc1.canonical.angleStartRad, arc1.canonical.angleEndRad);
        return restrictPointsToArc(e2, arc2.canonical.center, arc2.canonical.radiusX, arc2.canonical.radiusY, arc2.canonical.angleStartRad, arc2.canonical.angleEndRad);
    }
    static intersectArcBezier2(arc, bezier) {
        const e = Intersection.intersectBezier2Ellipse(bezier, arc.canonical.center, arc.canonical.radiusX, arc.canonical.radiusY);
        return restrictPointsToArc(e, arc.canonical.center, arc.canonical.radiusX, arc.canonical.radiusY, arc.canonical.angleStartRad, arc.canonical.angleEndRad);
    }
    static intersectArcBezier3(arc, bezier) {
        const e = Intersection.intersectBezier3Ellipse(bezier, arc.canonical.center, arc.canonical.radiusX, arc.canonical.radiusY);
        return restrictPointsToArc(e, arc.canonical.center, arc.canonical.radiusX, arc.canonical.radiusY, arc.canonical.angleStartRad, arc.canonical.angleEndRad);
    }
    static intersectPolyBezierWithSegment(poly, otherSegment, fnQ, fnC) {
        const resultPoints = [];
        for (const segment of poly.segments) {
            if (segment instanceof QuadraticBezierSegment) {
                resultPoints.push(...fnQ(segment, otherSegment).points);
            }
            else if (segment instanceof CubicBezierSegment) {
                resultPoints.push(...fnC(segment, otherSegment).points);
            }
        }
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectPolyBezierLine(poly, line) {
        return Intersection.intersectPolyBezierWithSegment(poly, line, Intersection.intersectBezier2Line, Intersection.intersectBezier3Line);
    }
    static intersectPolyBezierArc(poly, arc) {
        return Intersection.intersectPolyBezierWithSegment(poly, arc, swap(Intersection.intersectArcBezier2), swap(Intersection.intersectArcBezier3));
    }
    static intersectPolyBezierBezier2(poly, bezier) {
        return Intersection.intersectPolyBezierWithSegment(poly, bezier, Intersection.intersectBezier2Bezier2, swap(Intersection.intersectBezier2Bezier3));
    }
    static intersectPolyBezierBezier3(poly, bezier) {
        return Intersection.intersectPolyBezierWithSegment(poly, bezier, Intersection.intersectBezier2Bezier3, Intersection.intersectBezier3Bezier3);
    }
    /**
     *  intersectBezier2Circle
     *
     *  @param {Vector2} p1
     *  @param {Vector2} p2
     *  @param {Vector2} p3
     *  @param {Vector2} c
     *  @param {number} r
     *  @returns {Intersection}
     */
    static intersectBezier2Circle(bezier, c, r) {
        return Intersection.intersectBezier2Ellipse(bezier, c, r, r);
    }
    static intersectBezier2Ellipse(bezier, ec, rx, ry) {
        const p1 = bezier.start;
        const p2 = bezier.controlPoint;
        const p3 = bezier.end;
        let a; // temporary variables
        // c2, c1, c0; // coefficients of quadratic
        const resultPoints = [];
        a = p2.mul(-2);
        const c2 = p1.add(a.add(p3));
        a = p1.mul(-2);
        const b = p2.mul(2);
        const c1 = a.add(b);
        const c0 = new Vector2(p1.x, p1.y);
        const rxrx = rx * rx;
        const ryry = ry * ry;
        const roots = new Polynomial(ryry * c2.x * c2.x + rxrx * c2.y * c2.y, 2 * (ryry * c2.x * c1.x + rxrx * c2.y * c1.y), ryry * (2 * c2.x * c0.x + c1.x * c1.x) +
            rxrx * (2 * c2.y * c0.y + c1.y * c1.y) -
            2 * (ryry * ec.x * c2.x + rxrx * ec.y * c2.y), 2 * (ryry * c1.x * (c0.x - ec.x) + rxrx * c1.y * (c0.y - ec.y)), ryry * (c0.x * c0.x + ec.x * ec.x) +
            rxrx * (c0.y * c0.y + ec.y * ec.y) -
            2 * (ryry * ec.x * c0.x + rxrx * ec.y * c0.y) -
            rxrx * ryry).getRoots();
        for (const t of roots) {
            if (0 <= t && t <= 1) {
                resultPoints.push(c2.mul(t * t).add(c1.mul(t).add(c0)));
            }
        }
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", resultPoints);
    }
    static intersectBezier2Line(bezier, line) {
        return Intersection.intersectBezier2LineRaw(bezier, line.start, line.end);
    }
    static intersectBezier2LineRaw(bezier, a1, a2) {
        const p1 = bezier.start;
        const p2 = bezier.controlPoint;
        const p3 = bezier.end;
        let a; // temporary variables
        // let c2, c1, c0; // coefficients of quadratic
        // cl; // c coefficient for normal form of line
        // n; // normal for normal form of line
        const min = a1.min(a2); // used to determine if point is on line segment
        const max = a1.max(a2); // used to determine if point is on line segment
        const resultPoints = [];
        let resultStatus = "No Intersection";
        a = p2.mul(-2);
        const c2 = p1.add(a.add(p3));
        a = p1.mul(-2);
        const b = p2.mul(2);
        const c1 = a.add(b);
        const c0 = new Vector2(p1.x, p1.y);
        // Convert line to normal form: ax + by + c = 0
        // Find normal to line: negative inverse of original line's slope
        const n = new Vector2(a1.y - a2.y, a2.x - a1.x);
        // Determine new c coefficient
        const cl = a1.x * a2.y - a2.x * a1.y;
        // Transform cubic coefficients to line's coordinate system and find roots
        // of cubic
        const roots = new Polynomial(n.dot(c2), n.dot(c1), n.dot(c0) + cl).getRoots();
        // Any roots in closed interval [0,1] are intersections on Bezier, but
        // might not be on the line segment.
        // Find intersections and calculate point coordinates
        for (const t of roots) {
            if (0 <= t && t <= 1) {
                // We're within the Bezier curve
                // Find point on Bezier
                const p4 = p1.lerp(p2, t);
                const p5 = p2.lerp(p3, t);
                const p6 = p4.lerp(p5, t);
                // See if point is on line segment
                // Had to make special cases for vertical and horizontal lines due
                // to slight errors in calculation of p6
                if (a1.x === a2.x) {
                    if (min.y <= p6.y && p6.y <= max.y) {
                        resultStatus = "Intersection";
                        resultPoints.push(p6);
                    }
                }
                else if (a1.y === a2.y) {
                    if (min.x <= p6.x && p6.x <= max.x) {
                        resultStatus = "Intersection";
                        resultPoints.push(p6);
                    }
                }
                else if (min.x <= p6.x &&
                    p6.x <= max.x &&
                    min.y <= p6.y &&
                    p6.y <= max.y) {
                    resultStatus = "Intersection";
                    resultPoints.push(p6);
                }
            }
        }
        return new Intersection(resultStatus, resultPoints);
    }
    static intersectBezier2Polygon(bezier, points) {
        return Intersection.intersectBezier2Polyline(bezier, closePolygon(points));
    }
    static intersectBezier2Polyline(bezier, points) {
        const p1 = bezier.start;
        const p2 = bezier.controlPoint;
        const p3 = bezier.end;
        const resultPoints = [];
        const { length: len } = points;
        for (let i = 0; i < len - 1; i++) {
            const a1 = points[i];
            const a2 = points[i + 1];
            const inter = Intersection.intersectBezier2LineRaw(bezier, a1, a2);
            resultPoints.push(...inter.points);
        }
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectBezier2Rectangle(bezier, r1, r2) {
        const p1 = bezier.start;
        const p2 = bezier.controlPoint;
        const p3 = bezier.end;
        const min = r1.min(r2);
        const max = r1.max(r2);
        const topRight = new Vector2(max.x, min.y);
        const bottomLeft = new Vector2(min.x, max.y);
        const inter1 = Intersection.intersectBezier2LineRaw(bezier, min, topRight);
        const inter2 = Intersection.intersectBezier2LineRaw(bezier, topRight, max);
        const inter3 = Intersection.intersectBezier2LineRaw(bezier, max, bottomLeft);
        const inter4 = Intersection.intersectBezier2LineRaw(bezier, bottomLeft, min);
        const resultPoints = [];
        resultPoints.push(...inter1.points);
        resultPoints.push(...inter2.points);
        resultPoints.push(...inter3.points);
        resultPoints.push(...inter4.points);
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectBezier2Bezier2(bezier1, bezier2) {
        const a1 = bezier1.start;
        const a2 = bezier1.controlPoint;
        const a3 = bezier1.end;
        const b1 = bezier2.start;
        const b2 = bezier2.controlPoint;
        const b3 = bezier2.end;
        const resultPoints = [];
        let a = a2.mul(-2);
        const c12 = a1.add(a.add(a3));
        a = a1.mul(-2);
        let b = a2.mul(2);
        const c11 = a.add(b);
        const c10 = new Vector2(a1.x, a1.y);
        a = b2.mul(-2);
        const c22 = b1.add(a.add(b3));
        a = b1.mul(-2);
        b = b2.mul(2);
        const c21 = a.add(b);
        const c20 = new Vector2(b1.x, b1.y);
        // bezout
        const aBez = c12.x * c11.y - c11.x * c12.y;
        const bBez = c22.x * c11.y - c11.x * c22.y;
        const c = c21.x * c11.y - c11.x * c21.y;
        const d = c11.x * (c10.y - c20.y) + c11.y * (-c10.x + c20.x);
        const e = c22.x * c12.y - c12.x * c22.y;
        const f = c21.x * c12.y - c12.x * c21.y;
        const g = c12.x * (c10.y - c20.y) + c12.y * (-c10.x + c20.x);
        // determinant
        const poly = new Polynomial(-e * e, -2 * e * f, aBez * bBez - f * f - 2 * e * g, aBez * c - 2 * f * g, aBez * d - g * g);
        const roots = poly.getRoots();
        for (const s of roots) {
            if (0 <= s && s <= 1) {
                const xp = new Polynomial(c12.x, c11.x, c10.x - c20.x - s * c21.x - s * s * c22.x);
                xp.simplifyEquals();
                const xRoots = xp.getRoots();
                const yp = new Polynomial(c12.y, c11.y, c10.y - c20.y - s * c21.y - s * s * c22.y);
                yp.simplifyEquals();
                const yRoots = yp.getRoots();
                if (xRoots.length > 0 && yRoots.length > 0) {
                    const TOLERANCE = 1e-4;
                    checkRoots: for (const xRoot of xRoots) {
                        if (0 <= xRoot && xRoot <= 1) {
                            // tslint:disable-next-line: prefer-for-of
                            for (let k = 0; k < yRoots.length; k++) {
                                if (Math.abs(xRoot - yRoots[k]) < TOLERANCE) {
                                    resultPoints.push(c22.mul(s * s).add(c21.mul(s).add(c20)));
                                    break checkRoots;
                                }
                            }
                        }
                    }
                }
            }
        }
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectBezier2Bezier3(bezier1, bezier2) {
        const a1 = bezier1.start;
        const a2 = bezier1.controlPoint;
        const a3 = bezier1.end;
        const b1 = bezier2.start;
        const b2 = bezier2.controlPoint1;
        const b3 = bezier2.controlPoint2;
        const b4 = bezier2.end;
        const resultPoints = [];
        let a = a2.mul(-2);
        const c12 = a1.add(a.add(a3));
        a = a1.mul(-2);
        let b = a2.mul(2);
        const c11 = a.add(b);
        const c10 = new Vector2(a1.x, a1.y);
        a = b1.mul(-1);
        b = b2.mul(3);
        let c = b3.mul(-3);
        let d = a.add(b.add(c.add(b4)));
        const c23 = new Vector2(d.x, d.y);
        a = b1.mul(3);
        b = b2.mul(-6);
        c = b3.mul(3);
        d = a.add(b.add(c));
        const c22 = new Vector2(d.x, d.y);
        a = b1.mul(-3);
        b = b2.mul(3);
        c = a.add(b);
        const c21 = new Vector2(c.x, c.y);
        const c20 = new Vector2(b1.x, b1.y);
        const c10x2 = c10.x * c10.x;
        const c10y2 = c10.y * c10.y;
        const c11x2 = c11.x * c11.x;
        const c11y2 = c11.y * c11.y;
        const c12x2 = c12.x * c12.x;
        const c12y2 = c12.y * c12.y;
        const c20x2 = c20.x * c20.x;
        const c20y2 = c20.y * c20.y;
        const c21x2 = c21.x * c21.x;
        const c21y2 = c21.y * c21.y;
        const c22x2 = c22.x * c22.x;
        const c22y2 = c22.y * c22.y;
        const c23x2 = c23.x * c23.x;
        const c23y2 = c23.y * c23.y;
        const poly = new Polynomial(-2 * c12.x * c12.y * c23.x * c23.y + c12x2 * c23y2 + c12y2 * c23x2, -2 * c12.x * c12.y * c22.x * c23.y -
            2 * c12.x * c12.y * c22.y * c23.x +
            2 * c12y2 * c22.x * c23.x +
            2 * c12x2 * c22.y * c23.y, -2 * c12.x * c21.x * c12.y * c23.y -
            2 * c12.x * c12.y * c21.y * c23.x -
            2 * c12.x * c12.y * c22.x * c22.y +
            2 * c21.x * c12y2 * c23.x +
            c12y2 * c22x2 +
            c12x2 * (2 * c21.y * c23.y + c22y2), 2 * c10.x * c12.x * c12.y * c23.y +
            2 * c10.y * c12.x * c12.y * c23.x +
            c11.x * c11.y * c12.x * c23.y +
            c11.x * c11.y * c12.y * c23.x -
            2 * c20.x * c12.x * c12.y * c23.y -
            2 * c12.x * c20.y * c12.y * c23.x -
            2 * c12.x * c21.x * c12.y * c22.y -
            2 * c12.x * c12.y * c21.y * c22.x -
            2 * c10.x * c12y2 * c23.x -
            2 * c10.y * c12x2 * c23.y +
            2 * c20.x * c12y2 * c23.x +
            2 * c21.x * c12y2 * c22.x -
            c11y2 * c12.x * c23.x -
            c11x2 * c12.y * c23.y +
            c12x2 * (2 * c20.y * c23.y + 2 * c21.y * c22.y), 2 * c10.x * c12.x * c12.y * c22.y +
            2 * c10.y * c12.x * c12.y * c22.x +
            c11.x * c11.y * c12.x * c22.y +
            c11.x * c11.y * c12.y * c22.x -
            2 * c20.x * c12.x * c12.y * c22.y -
            2 * c12.x * c20.y * c12.y * c22.x -
            2 * c12.x * c21.x * c12.y * c21.y -
            2 * c10.x * c12y2 * c22.x -
            2 * c10.y * c12x2 * c22.y +
            2 * c20.x * c12y2 * c22.x -
            c11y2 * c12.x * c22.x -
            c11x2 * c12.y * c22.y +
            c21x2 * c12y2 +
            c12x2 * (2 * c20.y * c22.y + c21y2), 2 * c10.x * c12.x * c12.y * c21.y +
            2 * c10.y * c12.x * c21.x * c12.y +
            c11.x * c11.y * c12.x * c21.y +
            c11.x * c11.y * c21.x * c12.y -
            2 * c20.x * c12.x * c12.y * c21.y -
            2 * c12.x * c20.y * c21.x * c12.y -
            2 * c10.x * c21.x * c12y2 -
            2 * c10.y * c12x2 * c21.y +
            2 * c20.x * c21.x * c12y2 -
            c11y2 * c12.x * c21.x -
            c11x2 * c12.y * c21.y +
            2 * c12x2 * c20.y * c21.y, -2 * c10.x * c10.y * c12.x * c12.y -
            c10.x * c11.x * c11.y * c12.y -
            c10.y * c11.x * c11.y * c12.x +
            2 * c10.x * c12.x * c20.y * c12.y +
            2 * c10.y * c20.x * c12.x * c12.y +
            c11.x * c20.x * c11.y * c12.y +
            c11.x * c11.y * c12.x * c20.y -
            2 * c20.x * c12.x * c20.y * c12.y -
            2 * c10.x * c20.x * c12y2 +
            c10.x * c11y2 * c12.x +
            c10.y * c11x2 * c12.y -
            2 * c10.y * c12x2 * c20.y -
            c20.x * c11y2 * c12.x -
            c11x2 * c20.y * c12.y +
            c10x2 * c12y2 +
            c10y2 * c12x2 +
            c20x2 * c12y2 +
            c12x2 * c20y2);
        const roots = poly.getRootsInInterval(0, 1);
        for (const s of roots) {
            const xRoots = new Polynomial(c12.x, c11.x, c10.x - c20.x - s * c21.x - s * s * c22.x - s * s * s * c23.x).getRoots();
            const yRoots = new Polynomial(c12.y, c11.y, c10.y - c20.y - s * c21.y - s * s * c22.y - s * s * s * c23.y).getRoots();
            if (xRoots.length > 0 && yRoots.length > 0) {
                const TOLERANCE = 1e-4;
                checkRoots: for (const xRoot of xRoots) {
                    if (0 <= xRoot && xRoot <= 1) {
                        // tslint:disable-next-line: prefer-for-of
                        for (let k = 0; k < yRoots.length; k++) {
                            if (Math.abs(xRoot - yRoots[k]) < TOLERANCE) {
                                resultPoints.push(c23
                                    .mul(s * s * s)
                                    .add(c22.mul(s * s).add(c21.mul(s).add(c20))));
                                break checkRoots;
                            }
                        }
                    }
                }
            }
        }
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectBezier3Bezier3(bezier1, bezier2) {
        const a1 = bezier1.start;
        const a2 = bezier1.controlPoint1;
        const a3 = bezier1.controlPoint2;
        const a4 = bezier1.end;
        const b1 = bezier2.start;
        const b2 = bezier2.controlPoint1;
        const b3 = bezier2.controlPoint2;
        const b4 = bezier2.end;
        // c13, c12, c11, c10; // coefficients of cubic
        // c23, c22, c21, c20; // coefficients of cubic
        const resultPoints = [];
        // Calculate the coefficients of cubic polynomial
        let a = a1.mul(-1);
        let b = a2.mul(3);
        let c = a3.mul(-3);
        let d = a.add(b.add(c.add(a4)));
        const c13 = new Vector2(d.x, d.y);
        a = a1.mul(3);
        b = a2.mul(-6);
        c = a3.mul(3);
        d = a.add(b.add(c));
        const c12 = new Vector2(d.x, d.y);
        a = a1.mul(-3);
        b = a2.mul(3);
        c = a.add(b);
        const c11 = new Vector2(c.x, c.y);
        const c10 = new Vector2(a1.x, a1.y);
        a = b1.mul(-1);
        b = b2.mul(3);
        c = b3.mul(-3);
        d = a.add(b.add(c.add(b4)));
        const c23 = new Vector2(d.x, d.y);
        a = b1.mul(3);
        b = b2.mul(-6);
        c = b3.mul(3);
        d = a.add(b.add(c));
        const c22 = new Vector2(d.x, d.y);
        a = b1.mul(-3);
        b = b2.mul(3);
        c = a.add(b);
        const c21 = new Vector2(c.x, c.y);
        const c20 = new Vector2(b1.x, b1.y);
        // bezout
        const ap = c13.x * c12.y - c12.x * c13.y;
        const bp = c13.x * c11.y - c11.x * c13.y;
        const c0 = c13.x * c10.y - c10.x * c13.y + c20.x * c13.y - c13.x * c20.y;
        const c1 = c21.x * c13.y - c13.x * c21.y;
        const c2 = c22.x * c13.y - c13.x * c22.y;
        const c3 = c23.x * c13.y - c13.x * c23.y;
        const dp = c13.x * c11.y - c11.x * c13.y;
        const e0 = c13.x * c10.y +
            c12.x * c11.y -
            c11.x * c12.y -
            c10.x * c13.y +
            c20.x * c13.y -
            c13.x * c20.y;
        const e1 = c21.x * c13.y - c13.x * c21.y;
        const e2 = c22.x * c13.y - c13.x * c22.y;
        const e3 = c23.x * c13.y - c13.x * c23.y;
        const f0 = c12.x * c10.y - c10.x * c12.y + c20.x * c12.y - c12.x * c20.y;
        const f1 = c21.x * c12.y - c12.x * c21.y;
        const f2 = c22.x * c12.y - c12.x * c22.y;
        const f3 = c23.x * c12.y - c12.x * c23.y;
        const g0 = c13.x * c10.y - c10.x * c13.y + c20.x * c13.y - c13.x * c20.y;
        const g1 = c21.x * c13.y - c13.x * c21.y;
        const g2 = c22.x * c13.y - c13.x * c22.y;
        const g3 = c23.x * c13.y - c13.x * c23.y;
        const h0 = c12.x * c10.y - c10.x * c12.y + c20.x * c12.y - c12.x * c20.y;
        const h1 = c21.x * c12.y - c12.x * c21.y;
        const h2 = c22.x * c12.y - c12.x * c22.y;
        const h3 = c23.x * c12.y - c12.x * c23.y;
        const i0 = c11.x * c10.y - c10.x * c11.y + c20.x * c11.y - c11.x * c20.y;
        const i1 = c21.x * c11.y - c11.x * c21.y;
        const i2 = c22.x * c11.y - c11.x * c22.y;
        const i3 = c23.x * c11.y - c11.x * c23.y;
        // determinant
        const poly = new Polynomial(-c3 * e3 * g3, -c3 * e3 * g2 - c3 * e2 * g3 - c2 * e3 * g3, -c3 * e3 * g1 -
            c3 * e2 * g2 -
            c2 * e3 * g2 -
            c3 * e1 * g3 -
            c2 * e2 * g3 -
            c1 * e3 * g3, 
        // tslint:disable-next-line: max-line-length
        -c3 * e3 * g0 -
            c3 * e2 * g1 -
            c2 * e3 * g1 -
            c3 * e1 * g2 -
            c2 * e2 * g2 -
            c1 * e3 * g2 -
            c3 * e0 * g3 -
            c2 * e1 * g3 -
            c1 * e2 * g3 -
            c0 * e3 * g3 +
            bp * f3 * g3 +
            c3 * dp * h3 -
            ap * f3 * h3 +
            ap * e3 * i3, 
        // tslint:disable-next-line: max-line-length
        -c3 * e2 * g0 -
            c2 * e3 * g0 -
            c3 * e1 * g1 -
            c2 * e2 * g1 -
            c1 * e3 * g1 -
            c3 * e0 * g2 -
            c2 * e1 * g2 -
            c1 * e2 * g2 -
            c0 * e3 * g2 +
            bp * f3 * g2 -
            c2 * e0 * g3 -
            c1 * e1 * g3 -
            c0 * e2 * g3 +
            bp * f2 * g3 +
            c3 * dp * h2 -
            ap * f3 * h2 +
            c2 * dp * h3 -
            ap * f2 * h3 +
            ap * e3 * i2 +
            ap * e2 * i3, 
        // tslint:disable-next-line: max-line-length
        -c3 * e1 * g0 -
            c2 * e2 * g0 -
            c1 * e3 * g0 -
            c3 * e0 * g1 -
            c2 * e1 * g1 -
            c1 * e2 * g1 -
            c0 * e3 * g1 +
            bp * f3 * g1 -
            c2 * e0 * g2 -
            c1 * e1 * g2 -
            c0 * e2 * g2 +
            bp * f2 * g2 -
            c1 * e0 * g3 -
            c0 * e1 * g3 +
            bp * f1 * g3 +
            c3 * dp * h1 -
            ap * f3 * h1 +
            c2 * dp * h2 -
            ap * f2 * h2 +
            c1 * dp * h3 -
            ap * f1 * h3 +
            ap * e3 * i1 +
            ap * e2 * i2 +
            ap * e1 * i3, 
        // tslint:disable-next-line: max-line-length
        -c3 * e0 * g0 -
            c2 * e1 * g0 -
            c1 * e2 * g0 -
            c0 * e3 * g0 +
            bp * f3 * g0 -
            c2 * e0 * g1 -
            c1 * e1 * g1 -
            c0 * e2 * g1 +
            bp * f2 * g1 -
            c1 * e0 * g2 -
            c0 * e1 * g2 +
            bp * f1 * g2 -
            c0 * e0 * g3 +
            bp * f0 * g3 +
            c3 * dp * h0 -
            ap * f3 * h0 +
            c2 * dp * h1 -
            ap * f2 * h1 +
            c1 * dp * h2 -
            ap * f1 * h2 +
            c0 * dp * h3 -
            ap * f0 * h3 +
            ap * e3 * i0 +
            ap * e2 * i1 +
            ap * e1 * i2 -
            bp * dp * i3 +
            ap * e0 * i3, 
        // tslint:disable-next-line: max-line-length
        -c2 * e0 * g0 -
            c1 * e1 * g0 -
            c0 * e2 * g0 +
            bp * f2 * g0 -
            c1 * e0 * g1 -
            c0 * e1 * g1 +
            bp * f1 * g1 -
            c0 * e0 * g2 +
            bp * f0 * g2 +
            c2 * dp * h0 -
            ap * f2 * h0 +
            c1 * dp * h1 -
            ap * f1 * h1 +
            c0 * dp * h2 -
            ap * f0 * h2 +
            ap * e2 * i0 +
            ap * e1 * i1 -
            bp * dp * i2 +
            ap * e0 * i2, 
        // tslint:disable-next-line: max-line-length
        -c1 * e0 * g0 -
            c0 * e1 * g0 +
            bp * f1 * g0 -
            c0 * e0 * g1 +
            bp * f0 * g1 +
            c1 * dp * h0 -
            ap * f1 * h0 +
            c0 * dp * h1 -
            ap * f0 * h1 +
            ap * e1 * i0 -
            bp * dp * i1 +
            ap * e0 * i1, -c0 * e0 * g0 +
            bp * f0 * g0 +
            c0 * dp * h0 -
            ap * f0 * h0 -
            bp * dp * i0 +
            ap * e0 * i0);
        poly.simplifyEquals();
        const roots = poly.getRootsInInterval(0, 1);
        for (const s of roots) {
            const xp = new Polynomial(c13.x, c12.x, c11.x, c10.x - c20.x - s * c21.x - s * s * c22.x - s * s * s * c23.x);
            xp.simplifyEquals();
            const xRoots = xp.getRoots();
            const yp = new Polynomial(c13.y, c12.y, c11.y, c10.y - c20.y - s * c21.y - s * s * c22.y - s * s * s * c23.y);
            yp.simplifyEquals();
            const yRoots = yp.getRoots();
            if (xRoots.length > 0 && yRoots.length > 0) {
                const TOLERANCE = 1e-4;
                checkRoots: for (const xRoot of xRoots) {
                    if (0 <= xRoot && xRoot <= 1) {
                        // tslint:disable-next-line: prefer-for-of
                        for (let k = 0; k < yRoots.length; k++) {
                            if (Math.abs(xRoot - yRoots[k]) < TOLERANCE) {
                                resultPoints.push(c23
                                    .mul(s * s * s)
                                    .add(c22.mul(s * s).add(c21.mul(s).add(c20))));
                                break checkRoots;
                            }
                        }
                    }
                }
            }
        }
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectBezier3Circle(bezier, c, r) {
        return Intersection.intersectBezier3Ellipse(bezier, c, r, r);
    }
    static intersectBezier3Ellipse(bezier, ec, rx, ry) {
        const p1 = bezier.start;
        const p2 = bezier.controlPoint1;
        const p3 = bezier.controlPoint2;
        const p4 = bezier.end;
        // c3, c2, c1, c0; // coefficients of cubic
        const resultPoints = [];
        // Calculate the coefficients of cubic polynomial
        let a = p1.mul(-1);
        let b = p2.mul(3);
        let c = p3.mul(-3);
        let d = a.add(b.add(c.add(p4)));
        const c3 = new Vector2(d.x, d.y);
        a = p1.mul(3);
        b = p2.mul(-6);
        c = p3.mul(3);
        d = a.add(b.add(c));
        const c2 = new Vector2(d.x, d.y);
        a = p1.mul(-3);
        b = p2.mul(3);
        c = a.add(b);
        const c1 = new Vector2(c.x, c.y);
        const c0 = new Vector2(p1.x, p1.y);
        const rxrx = rx * rx;
        const ryry = ry * ry;
        const poly = new Polynomial(c3.x * c3.x * ryry + c3.y * c3.y * rxrx, 2 * (c3.x * c2.x * ryry + c3.y * c2.y * rxrx), 2 * (c3.x * c1.x * ryry + c3.y * c1.y * rxrx) +
            c2.x * c2.x * ryry +
            c2.y * c2.y * rxrx, 2 * c3.x * ryry * (c0.x - ec.x) +
            2 * c3.y * rxrx * (c0.y - ec.y) +
            2 * (c2.x * c1.x * ryry + c2.y * c1.y * rxrx), 2 * c2.x * ryry * (c0.x - ec.x) +
            2 * c2.y * rxrx * (c0.y - ec.y) +
            c1.x * c1.x * ryry +
            c1.y * c1.y * rxrx, 2 * c1.x * ryry * (c0.x - ec.x) + 2 * c1.y * rxrx * (c0.y - ec.y), c0.x * c0.x * ryry -
            2 * c0.y * ec.y * rxrx -
            2 * c0.x * ec.x * ryry +
            c0.y * c0.y * rxrx +
            ec.x * ec.x * ryry +
            ec.y * ec.y * rxrx -
            rxrx * ryry);
        const roots = poly.getRootsInInterval(0, 1);
        for (const t of roots) {
            resultPoints.push(c3.mul(t * t * t).add(c2.mul(t * t).add(c1.mul(t).add(c0))));
        }
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectBezier3Line(bezier, line) {
        return Intersection.intersectBezier3LineRaw(bezier, line.start, line.end);
    }
    /**
     *  intersectBezier3Line
     *
     *  Many thanks to Dan Sunday at SoftSurfer.com.  He gave me a very thorough
     *  sketch of the algorithm used here.  Without his help, I'm not sure when I
     *  would have figured out this intersection problem.
     */
    static intersectBezier3LineRaw(bezier, a1, a2) {
        const p1 = bezier.start;
        const p2 = bezier.controlPoint1;
        const p3 = bezier.controlPoint2;
        const p4 = bezier.end;
        // c3, c2, c1, c0; // coefficients of cubic
        // cl; // c coefficient for normal form of line
        // n; // normal for normal form of line
        const min = a1.min(a2); // used to determine if point is on line segment
        const max = a1.max(a2); // used to determine if point is on line segment
        const resultPoints = [];
        // Start with Bezier using Bernstein polynomials for weighting functions:
        //     (1-t^3)P1 + 3t(1-t)^2P2 + 3t^2(1-t)P3 + t^3P4
        //
        // Expand and collect terms to form linear combinations of original Bezier
        // controls.  This ends up with a vector cubic in t:
        //     (-P1+3P2-3P3+P4)t^3 + (3P1-6P2+3P3)t^2 + (-3P1+3P2)t + P1
        //             /\                  /\                /\       /\
        //             ||                  ||                ||       ||
        //             c3                  c2                c1       c0
        // Calculate the coefficients
        let a = p1.mul(-1);
        let b = p2.mul(3);
        let c = p3.mul(-3);
        let d = a.add(b.add(c.add(p4)));
        const c3 = new Vector2(d.x, d.y);
        a = p1.mul(3);
        b = p2.mul(-6);
        c = p3.mul(3);
        d = a.add(b.add(c));
        const c2 = new Vector2(d.x, d.y);
        a = p1.mul(-3);
        b = p2.mul(3);
        c = a.add(b);
        const c1 = new Vector2(c.x, c.y);
        const c0 = new Vector2(p1.x, p1.y);
        // Convert line to normal form: ax + by + c = 0
        // Find normal to line: negative inverse of original line's slope
        const n = new Vector2(a1.y - a2.y, a2.x - a1.x);
        // Determine new c coefficient
        const cl = a1.x * a2.y - a2.x * a1.y;
        // ?Rotate each cubic coefficient using line for new coordinate system?
        // Find roots of rotated cubic
        const roots = new Polynomial(n.dot(c3), n.dot(c2), n.dot(c1), n.dot(c0) + cl).getRoots();
        // Any roots in closed interval [0,1] are intersections on Bezier, but
        // might not be on the line segment.
        // Find intersections and calculate point coordinates
        for (const t of roots) {
            if (0 <= t && t <= 1) {
                // We're within the Bezier curve
                // Find point on Bezier
                const p5 = p1.lerp(p2, t);
                const p6 = p2.lerp(p3, t);
                const p7 = p3.lerp(p4, t);
                const p8 = p5.lerp(p6, t);
                const p9 = p6.lerp(p7, t);
                const p10 = p8.lerp(p9, t);
                // See if point is on line segment
                // Had to make special cases for vertical and horizontal lines due
                // to slight errors in calculation of p10
                if (a1.x === a2.x) {
                    if (min.y <= p10.y && p10.y <= max.y) {
                        resultPoints.push(p10);
                    }
                }
                else if (a1.y === a2.y) {
                    if (min.x <= p10.x && p10.x <= max.x) {
                        resultPoints.push(p10);
                    }
                }
                else if (min.x <= p10.x &&
                    p10.x <= max.x &&
                    min.y <= p10.y &&
                    p10.y <= max.y) {
                    resultPoints.push(p10);
                }
            }
        }
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", resultPoints);
    }
    static intersectBezier3Polygon(bezier, points) {
        return Intersection.intersectBezier3Polyline(bezier, closePolygon(points));
    }
    static intersectBezier3Polyline(bezier, points) {
        const resultPoints = [];
        const { length: len } = points;
        for (let i = 0; i < len - 1; i++) {
            const a1 = points[i];
            const a2 = points[i + 1];
            const inter = Intersection.intersectBezier3LineRaw(bezier, a1, a2);
            resultPoints.push(...inter.points);
        }
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectBezier3Rectangle(bezier, r1, r2) {
        const min = r1.min(r2);
        const max = r1.max(r2);
        const topRight = new Vector2(max.x, min.y);
        const bottomLeft = new Vector2(min.x, max.y);
        const inter1 = Intersection.intersectBezier3LineRaw(bezier, min, topRight);
        const inter2 = Intersection.intersectBezier3LineRaw(bezier, topRight, max);
        const inter3 = Intersection.intersectBezier3LineRaw(bezier, max, bottomLeft);
        const inter4 = Intersection.intersectBezier3LineRaw(bezier, bottomLeft, min);
        const resultPoints = [];
        resultPoints.push(...inter1.points);
        resultPoints.push(...inter2.points);
        resultPoints.push(...inter3.points);
        resultPoints.push(...inter4.points);
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectCircleCircle(c1, r1, c2, r2) {
        // Determine minimum and maximum radii where circles can intersect
        const rMax = r1 + r2;
        const rMin = Math.abs(r1 - r2);
        // Determine actual distance between circle circles
        const cDist = c1.distance(c2);
        if (cDist > rMax) {
            return new Intersection("Outside", []);
        }
        else if (cDist < rMin) {
            return new Intersection("Inside", []);
        }
        else {
            const resultPoints = [];
            const a = (r1 * r1 - r2 * r2 + cDist * cDist) / (2 * cDist);
            const h = Math.sqrt(r1 * r1 - a * a);
            const p = c1.lerp(c2, a / cDist);
            const b = h / cDist;
            resultPoints.push(new Vector2(p.x - b * (c2.y - c1.y), p.y + b * (c2.x - c1.x)));
            resultPoints.push(new Vector2(p.x + b * (c2.y - c1.y), p.y - b * (c2.x - c1.x)));
            return new Intersection("Intersection", resultPoints);
        }
    }
    static intersectCircleEllipse(cc, r, ec, rx, ry) {
        return Intersection.intersectEllipseEllipse(cc, r, r, ec, rx, ry);
    }
    static intersectCircleLine(c, r, a1, a2) {
        const a = (a2.x - a1.x) * (a2.x - a1.x) + (a2.y - a1.y) * (a2.y - a1.y);
        const b = 2 * ((a2.x - a1.x) * (a1.x - c.x) + (a2.y - a1.y) * (a1.y - c.y));
        const cc = c.x * c.x +
            c.y * c.y +
            a1.x * a1.x +
            a1.y * a1.y -
            2 * (c.x * a1.x + c.y * a1.y) -
            r * r;
        const deter = b * b - 4 * a * cc;
        if (deter < 0) {
            return new Intersection("Outside", []);
        }
        else if (deter === 0) {
            return new Intersection("Tangent", []);
            // NOTE: should calculate this point
        }
        else {
            const e = Math.sqrt(deter);
            const u1 = (-b + e) / (2 * a);
            const u2 = (-b - e) / (2 * a);
            if ((u1 < 0 || u1 > 1) && (u2 < 0 || u2 > 1)) {
                if ((u1 < 0 && u2 < 0) || (u1 > 1 && u2 > 1)) {
                    return new Intersection("Outside", []);
                }
                else {
                    return new Intersection("Inside", []);
                }
            }
            else {
                const resultPoints = [];
                if (0 <= u1 && u1 <= 1) {
                    resultPoints.push(a1.lerp(a2, u1));
                }
                if (0 <= u2 && u2 <= 1) {
                    resultPoints.push(a1.lerp(a2, u2));
                }
                return new Intersection("Intersection", resultPoints);
            }
        }
    }
    static intersectCirclePolygon(c, r, points) {
        return Intersection.intersectCirclePolyline(c, r, closePolygon(points));
    }
    static intersectCirclePolyline(c, r, points) {
        const resultPoints = [];
        const { length: len } = points;
        let inter;
        for (let i = 0; i < len - 1; i++) {
            const a1 = points[i];
            const a2 = points[i + 1];
            inter = Intersection.intersectCircleLine(c, r, a1, a2);
            resultPoints.push(...inter.points);
        }
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        else {
            return new Intersection(inter?.status ?? "No Intersection", []);
        }
    }
    static intersectCircleRectangle(c, r, r1, r2) {
        const min = r1.min(r2);
        const max = r1.max(r2);
        const topRight = new Vector2(max.x, min.y);
        const bottomLeft = new Vector2(min.x, max.y);
        const inter1 = Intersection.intersectCircleLine(c, r, min, topRight);
        const inter2 = Intersection.intersectCircleLine(c, r, topRight, max);
        const inter3 = Intersection.intersectCircleLine(c, r, max, bottomLeft);
        const inter4 = Intersection.intersectCircleLine(c, r, bottomLeft, min);
        const resultPoints = [];
        resultPoints.push(...inter1.points);
        resultPoints.push(...inter2.points);
        resultPoints.push(...inter3.points);
        resultPoints.push(...inter4.points);
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        else {
            return new Intersection(inter1.status ?? "No Intersection", []);
        }
    }
    /**
     *  intersectEllipseEllipse
     *
     *  This code is based on MgcIntr2DElpElp.cpp written by David Eberly.  His
     *  code along with many other excellent examples are avaiable at his site:
     *  http://www.magic-software.com
     *
     *  NOTE: Rotation will need to be added to this function
     */
    static intersectEllipseEllipse(c1, rx1, ry1, c2, rx2, ry2) {
        const a = [
            ry1 * ry1,
            0,
            rx1 * rx1,
            -2 * ry1 * ry1 * c1.x,
            -2 * rx1 * rx1 * c1.y,
            ry1 * ry1 * c1.x * c1.x + rx1 * rx1 * c1.y * c1.y - rx1 * rx1 * ry1 * ry1,
        ];
        const b = [
            ry2 * ry2,
            0,
            rx2 * rx2,
            -2 * ry2 * ry2 * c2.x,
            -2 * rx2 * rx2 * c2.y,
            ry2 * ry2 * c2.x * c2.x + rx2 * rx2 * c2.y * c2.y - rx2 * rx2 * ry2 * ry2,
        ];
        const yPoly = bezout(a, b);
        const yRoots = yPoly.getRoots();
        const epsilon = 1e-3;
        const norm0 = (a[0] * a[0] + 2 * a[1] * a[1] + a[2] * a[2]) * epsilon;
        const norm1 = (b[0] * b[0] + 2 * b[1] * b[1] + b[2] * b[2]) * epsilon;
        const resultPoints = [];
        // tslint:disable-next-line: prefer-for-of
        for (let y = 0; y < yRoots.length; y++) {
            const xPoly = new Polynomial(a[0], a[3] + yRoots[y] * a[1], a[5] + yRoots[y] * (a[4] + yRoots[y] * a[2]));
            const xRoots = xPoly.getRoots();
            // tslint:disable-next-line: prefer-for-of
            for (let x = 0; x < xRoots.length; x++) {
                let tst = (a[0] * xRoots[x] + a[1] * yRoots[y] + a[3]) * xRoots[x] +
                    (a[2] * yRoots[y] + a[4]) * yRoots[y] +
                    a[5];
                if (Math.abs(tst) < norm0) {
                    tst =
                        (b[0] * xRoots[x] + b[1] * yRoots[y] + b[3]) * xRoots[x] +
                            (b[2] * yRoots[y] + b[4]) * yRoots[y] +
                            b[5];
                    if (Math.abs(tst) < norm1) {
                        resultPoints.push(new Vector2(xRoots[x], yRoots[y]));
                    }
                }
            }
        }
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    /**
     *  intersectEllipseLine
     *
     *  NOTE: Rotation will need to be added to this function
     */
    static intersectEllipseLine(c, rx, ry, a1, a2) {
        const orign = new Vector2(a1.x, a1.y);
        const dir = Vector2.fromPoints(a1, a2);
        const center = new Vector2(c.x, c.y);
        const diff = orign.sub(center);
        const mDir = new Vector2(dir.x / (rx * rx), dir.y / (ry * ry));
        const mDiff = new Vector2(diff.x / (rx * rx), diff.y / (ry * ry));
        const a = dir.dot(mDir);
        const b = dir.dot(mDiff);
        const cp = diff.dot(mDiff) - 1.0;
        const d = b * b - a * cp;
        if (d < 0) {
            return new Intersection("Outside", []);
        }
        else if (d > 0) {
            const root = Math.sqrt(d); // eslint-disable-line no-shadow
            const tA = (-b - root) / a;
            const tB = (-b + root) / a;
            if ((tA < 0 || 1 < tA) && (tB < 0 || 1 < tB)) {
                if ((tA < 0 && tB < 0) || (tA > 1 && tB > 1)) {
                    return new Intersection("Outside", []);
                }
                else {
                    return new Intersection("Inside", []);
                }
            }
            else {
                const resultPoints = [];
                if (0 <= tA && tA <= 1) {
                    resultPoints.push(a1.lerp(a2, tA));
                }
                if (0 <= tB && tB <= 1) {
                    resultPoints.push(a1.lerp(a2, tB));
                }
                return new Intersection("Intersection", resultPoints);
            }
        }
        else {
            const t = -b / a;
            if (0 <= t && t <= 1) {
                return new Intersection("Intersection", [a1.lerp(a2, t)]);
            }
            else {
                return new Intersection("Outside", []);
            }
        }
    }
    static intersectEllipsePolygon(c, rx, ry, points) {
        return Intersection.intersectEllipsePolyline(c, rx, ry, closePolygon(points));
    }
    static intersectEllipsePolyline(c, rx, ry, points) {
        const resultPoints = [];
        const { length: len } = points;
        for (let i = 0; i < len - 1; i++) {
            const b1 = points[i];
            const b2 = points[i + 1];
            const inter = Intersection.intersectEllipseLine(c, rx, ry, b1, b2);
            resultPoints.push(...inter.points);
        }
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectEllipseRectangle(c, rx, ry, r1, r2) {
        const min = r1.min(r2);
        const max = r1.max(r2);
        const topRight = new Vector2(max.x, min.y);
        const bottomLeft = new Vector2(min.x, max.y);
        const inter1 = Intersection.intersectEllipseLine(c, rx, ry, min, topRight);
        const inter2 = Intersection.intersectEllipseLine(c, rx, ry, topRight, max);
        const inter3 = Intersection.intersectEllipseLine(c, rx, ry, max, bottomLeft);
        const inter4 = Intersection.intersectEllipseLine(c, rx, ry, bottomLeft, min);
        const resultPoints = [];
        resultPoints.push(...inter1.points);
        resultPoints.push(...inter2.points);
        resultPoints.push(...inter3.points);
        resultPoints.push(...inter4.points);
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectLineLine(line1, line2) {
        const a1 = line1.start;
        const a2 = line1.end;
        const b1 = line2.start;
        const b2 = line2.end;
        return Intersection.intersectLineLineRaw(a1, a2, b1, b2);
    }
    static intersectLineLineRaw(a1, a2, b1, b2) {
        const uaT = (b2.x - b1.x) * (a1.y - b1.y) - (b2.y - b1.y) * (a1.x - b1.x);
        const ubT = (a2.x - a1.x) * (a1.y - b1.y) - (a2.y - a1.y) * (a1.x - b1.x);
        const uB = (b2.y - b1.y) * (a2.x - a1.x) - (b2.x - b1.x) * (a2.y - a1.y);
        if (uB !== 0) {
            const ua = uaT / uB;
            const ub = ubT / uB;
            if (0 <= ua && ua <= 1 && 0 <= ub && ub <= 1) {
                const resultPoints = [];
                resultPoints.push(new Vector2(a1.x + ua * (a2.x - a1.x), a1.y + ua * (a2.y - a1.y)));
                return new Intersection("Intersection", resultPoints);
            }
            else {
                return new Intersection("No Intersection", []);
            }
        }
        else if (uaT === 0 || ubT === 0) {
            return new Intersection("Coincident", []);
        }
        else {
            return new Intersection("Parallel", []);
        }
    }
    static intersectLinePolygon(a1, a2, points) {
        return Intersection.intersectLinePolyline(a1, a2, closePolygon(points));
    }
    static intersectLinePolyline(a1, a2, points) {
        const resultPoints = [];
        const { length: len } = points;
        for (let i = 0; i < len - 1; i++) {
            const b1 = points[i];
            const b2 = points[i + 1];
            const inter = Intersection.intersectLineLineRaw(a1, a2, b1, b2);
            resultPoints.push(...inter.points);
        }
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectLineRectangle(a1, a2, r1, r2) {
        const min = r1.min(r2);
        const max = r1.max(r2);
        const topRight = new Vector2(max.x, min.y);
        const bottomLeft = new Vector2(min.x, max.y);
        const inter1 = Intersection.intersectLineLineRaw(min, topRight, a1, a2);
        const inter2 = Intersection.intersectLineLineRaw(topRight, max, a1, a2);
        const inter3 = Intersection.intersectLineLineRaw(max, bottomLeft, a1, a2);
        const inter4 = Intersection.intersectLineLineRaw(bottomLeft, min, a1, a2);
        const resultPoints = [];
        resultPoints.push(...inter1.points);
        resultPoints.push(...inter2.points);
        resultPoints.push(...inter3.points);
        resultPoints.push(...inter4.points);
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectPolygonPolygon(points1, points2) {
        return Intersection.intersectPolylinePolyline(closePolygon(points1), closePolygon(points2));
    }
    static intersectPolygonPolyline(points1, points2) {
        return Intersection.intersectPolylinePolyline(closePolygon(points1), points2);
    }
    static intersectPolygonRectangle(points, r1, r2) {
        return Intersection.intersectPolylineRectangle(closePolygon(points), r1, r2);
    }
    static intersectPolylinePolyline(points1, points2) {
        const resultPoints = [];
        const { length: len } = points1;
        for (let i = 0; i < len - 1; i++) {
            const a1 = points1[i];
            const a2 = points1[i + 1];
            const inter = Intersection.intersectLinePolyline(a1, a2, points2);
            resultPoints.push(...inter.points);
        }
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectPolylineRectangle(points, r1, r2) {
        const min = r1.min(r2);
        const max = r1.max(r2);
        const topRight = new Vector2(max.x, min.y);
        const bottomLeft = new Vector2(min.x, max.y);
        const inter1 = Intersection.intersectLinePolyline(min, topRight, points);
        const inter2 = Intersection.intersectLinePolyline(topRight, max, points);
        const inter3 = Intersection.intersectLinePolyline(max, bottomLeft, points);
        const inter4 = Intersection.intersectLinePolyline(bottomLeft, min, points);
        const resultPoints = [];
        resultPoints.push(...inter1.points);
        resultPoints.push(...inter2.points);
        resultPoints.push(...inter3.points);
        resultPoints.push(...inter4.points);
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectRectangleRectangle(a1, a2, b1, b2) {
        const min = a1.min(a2);
        const max = a1.max(a2);
        const topRight = new Vector2(max.x, min.y);
        const bottomLeft = new Vector2(min.x, max.y);
        const inter1 = Intersection.intersectLineRectangle(min, topRight, b1, b2);
        const inter2 = Intersection.intersectLineRectangle(topRight, max, b1, b2);
        const inter3 = Intersection.intersectLineRectangle(max, bottomLeft, b1, b2);
        const inter4 = Intersection.intersectLineRectangle(bottomLeft, min, b1, b2);
        const resultPoints = [];
        resultPoints.push(...inter1.points);
        resultPoints.push(...inter2.points);
        resultPoints.push(...inter3.points);
        resultPoints.push(...inter4.points);
        if (resultPoints.length > 0) {
            return new Intersection("Intersection", resultPoints);
        }
        return new Intersection("No Intersection", []);
    }
    static intersectRayRay(a1, a2, b1, b2) {
        const uaT = (b2.x - b1.x) * (a1.y - b1.y) - (b2.y - b1.y) * (a1.x - b1.x);
        const ubT = (a2.x - a1.x) * (a1.y - b1.y) - (a2.y - a1.y) * (a1.x - b1.x);
        const uB = (b2.y - b1.y) * (a2.x - a1.x) - (b2.x - b1.x) * (a2.y - a1.y);
        if (uB !== 0) {
            const ua = uaT / uB;
            const resultPoints = [];
            resultPoints.push(new Vector2(a1.x + ua * (a2.x - a1.x), a1.y + ua * (a2.y - a1.y)));
            return new Intersection("Intersection", resultPoints);
        }
        else if (uaT === 0 || ubT === 0) {
            return new Intersection("Coincident", []);
        }
        else {
            return new Intersection("Parallel", []);
        }
    }
    static cubicBezierPointDistance(bezierPoints, pt) {
        const a = Vector2.linearCombination(bezierPoints, [-1, 3, -3, 1]);
        const b = Vector2.linearCombination(bezierPoints, [3, -6, 3]);
        const c = Vector2.linearCombination(bezierPoints, [-3, 3]);
        const d = bezierPoints[0];
        const derivative = new Polynomial(6 * (a.x * a.x + a.y * a.y), 10 * (a.x * b.x + a.y * b.y), 4 * (2 * (a.x * c.x + a.y * c.y) + b.x * b.x + b.y * b.y), 6 * (a.x * (d.x - pt.x) + b.x * c.x + a.y * (d.y - pt.y) + b.y * c.y), 2 *
            (2 * (b.x * d.x - b.x * pt.x + b.y * d.y - b.y * pt.y) +
                c.x * c.x +
                c.y * c.y), 2 * (c.x * d.x - c.x * pt.x + c.y * d.y - c.y * pt.y));
        // const secondDerivative = new Polynomial(
        //     30 * (a.x * a.x + a.y * a.y),
        //     40 * (a.x * b.x + a.y * b.y),
        //     12 * (2 * a.x * c.x + b.x * b.x + 2 * a.y * c.y + b.y * b.y),
        //     12 * (a.x * (d.x - pt.x) + b.x * c.x + a.y * (d.y - pt.y) + b.y * c.y),
        //     2 * (2 * (b.x * d.x - b.x * pt.x + b.y * d.y - b.y * pt.y) + c.x * c.x + c.y * c.y),
        // );
        const droots = derivative.getRootsInInterval(0, 1);
        let minDist2 = bezierPoints[0].distanceSquared(pt);
        let closestPoint = bezierPoints[0];
        let closestT = 0;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < droots.length; i++) {
            const t1 = droots[i];
            if (t1 <= 0 || t1 >= 1) {
                continue;
            }
            const t2 = t1 * t1;
            const t3 = t1 * t2;
            const candidate = Vector2.linearCombination([a, b, c, d], [t3, t2, t1, 1]);
            const dist2 = candidate.distanceSquared(pt);
            if (dist2 < minDist2) {
                minDist2 = dist2;
                closestPoint = candidate;
                closestT = t1;
            }
        }
        const lastDist = bezierPoints[3].distanceSquared(pt);
        if (lastDist < minDist2) {
            minDist2 = lastDist;
            closestPoint = bezierPoints[3];
            closestT = 1;
        }
        return {
            t: closestT,
            distance: Math.sqrt(minDist2),
            point: closestPoint,
        };
    }
    constructor(status, points) {
        this.status = status;
        this.points = points;
    }
}
export default Intersection;
