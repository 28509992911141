import * as THREE from 'three';
const _instanceLocalMatrix = /*@__PURE__*/ new THREE.Matrix4();
const _instanceWorldMatrix = /*@__PURE__*/ new THREE.Matrix4();
const _box3 = /*@__PURE__*/ new THREE.Box3();
const _identity = /*@__PURE__*/ new THREE.Matrix4();
const _mesh = /*@__PURE__*/ new THREE.Mesh();
const _sphere = /*@__PURE__*/ new THREE.Sphere();
const vertexShader = () => {
    return `
    precision highp float;
    precision highp int;
    uniform mat4 modelViewMatrix;
		uniform mat4 projectionMatrix;
    attribute vec3 position;
    attribute vec3 ref;
    attribute mat4 matrix;
    attribute vec4 color;
    attribute float vertexAlpha;
    uniform float zoom;
		varying vec4 vColor;
    void main() {
      vColor = vec4(color.r, color.g, color.b, vertexAlpha);
      gl_Position = projectionMatrix * modelViewMatrix * matrix * vec4( ref + (ref - position) * zoom, 1.0 );
    }`;
};
const fragmentShader = () => {
    return `
    precision highp float;
    precision highp int;
    varying vec4 vColor;
    void main() {
      gl_FragColor = vColor;
    }`;
};
const LEFT_LINE = 0;
const RIGHT_LINE = 1;
const LEFT_SMOOTH_LINE = 2;
const RIGHT_SMOOTH_LINE = 3;
const LINE_WIDTH = 0.008;
const BLUR_WIDTH = 0.004;
const COLOR_LINE = new THREE.Color(0.7, 0.7, 0.7);
export class InstancedLine extends THREE.Object3D {
    count;
    boundingBox;
    boundingSphere;
    geometry;
    blur = true;
    instanceMatrix;
    instanceColor;
    curve;
    upVector = new THREE.Vector3(0.0, 0.0, 1.0);
    constructor(curve, matrix, resolution = 1) {
        super();
        this.curve = curve;
        this.geometry = new THREE.InstancedBufferGeometry();
        const count = matrix.length;
        this.count = count;
        this.geometry.instanceCount = count; // set so its initalized for dat.GUI, will be set in first draw otherwise
        this.instanceColor = null;
        this.instanceMatrix = new THREE.InstancedBufferAttribute(new Float32Array(count * 16), 16);
        this.boundingBox = null;
        this.boundingSphere = null;
        const lineVertices = this.curve.getPoints(resolution).map((vertex) => new THREE.Vector3(vertex.x, vertex.y, 0));
        let lineShapeVertices = this.updateLineShapeVertices(lineVertices, resolution);
        const points = [];
        const refPoints = [];
        for (let i = 0; i < lineShapeVertices.length - 1; i++) {
            points.push(lineShapeVertices[i][LEFT_LINE]);
            points.push(lineShapeVertices[i + 1][LEFT_LINE]);
            points.push(lineShapeVertices[i][RIGHT_LINE]);
            points.push(lineShapeVertices[i][RIGHT_LINE]);
            points.push(lineShapeVertices[i + 1][LEFT_LINE]);
            points.push(lineShapeVertices[i + 1][RIGHT_LINE]);
            refPoints.push(lineVertices[i]);
            refPoints.push(lineVertices[i + 1]);
            refPoints.push(lineVertices[i]);
            refPoints.push(lineVertices[i]);
            refPoints.push(lineVertices[i + 1]);
            refPoints.push(lineVertices[i + 1]);
            if (this.blur) {
                // left blur
                points.push(lineShapeVertices[i][LEFT_LINE]);
                points.push(lineShapeVertices[i][LEFT_SMOOTH_LINE]);
                points.push(lineShapeVertices[i + 1][LEFT_SMOOTH_LINE]);
                points.push(lineShapeVertices[i][LEFT_LINE]);
                points.push(lineShapeVertices[i + 1][LEFT_SMOOTH_LINE]);
                points.push(lineShapeVertices[i + 1][LEFT_LINE]);
                // right blur
                points.push(lineShapeVertices[i][RIGHT_LINE]);
                points.push(lineShapeVertices[i + 1][RIGHT_LINE]);
                points.push(lineShapeVertices[i][RIGHT_SMOOTH_LINE]);
                points.push(lineShapeVertices[i][RIGHT_SMOOTH_LINE]);
                points.push(lineShapeVertices[i + 1][RIGHT_LINE]);
                points.push(lineShapeVertices[i + 1][RIGHT_SMOOTH_LINE]);
                refPoints.push(lineVertices[i]);
                refPoints.push(lineVertices[i]);
                refPoints.push(lineVertices[i + 1]);
                refPoints.push(lineVertices[i]);
                refPoints.push(lineVertices[i + 1]);
                refPoints.push(lineVertices[i + 1]);
                refPoints.push(lineVertices[i]);
                refPoints.push(lineVertices[i + 1]);
                refPoints.push(lineVertices[i]);
                refPoints.push(lineVertices[i]);
                refPoints.push(lineVertices[i + 1]);
                refPoints.push(lineVertices[i + 1]);
            }
        }
        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(points.map((point) => [point.x, point.y, 0]).flat(), 3));
        this.geometry.setAttribute('ref', new THREE.Float32BufferAttribute(refPoints.map((point) => [point.x, point.y, 0]).flat(), 3));
        const vertexAlphas = [];
        for (let i = 0; i < lineShapeVertices.length - 1; i++) {
            vertexAlphas.push(this.blur ? [1, 1, 1, 1, 1, 1, 1, 0, 0, 1, 0, 1, 1, 1, 0, 0, 1, 0] : [1, 1, 1, 1, 1, 1]);
        }
        this.geometry.setAttribute('vertexAlpha', new THREE.Float32BufferAttribute(vertexAlphas.flat(), 1));
        for (let i = 0; i < count; i++) {
            this.setMatrixAt(i, matrix[i]);
            this.setColorAt(i, COLOR_LINE);
        }
    }
    updateLineShapeVertices(lineVertices, resolution) {
        let distancePrevious, distanceCurrent;
        var vectorCurrent = new THREE.Vector3();
        var vectorPrevious = new THREE.Vector3();
        var vectorSide = new THREE.Vector3();
        var vectorSidePrevious = new THREE.Vector3();
        var vectorSideCopy = new THREE.Vector3();
        const lineShapeVertices = [];
        for (let i = 0; i <= resolution; i++) {
            const vertices = [];
            vertices[LEFT_LINE] = new THREE.Vector3();
            vertices[RIGHT_LINE] = new THREE.Vector3();
            if (this.blur) {
                vertices[LEFT_SMOOTH_LINE] = new THREE.Vector3();
                vertices[RIGHT_SMOOTH_LINE] = new THREE.Vector3();
            }
            lineShapeVertices[i] = vertices;
        }
        for (let i = 0; i <= resolution; i++) {
            lineShapeVertices[i][LEFT_LINE].copy(lineVertices[i]);
            lineShapeVertices[i][RIGHT_LINE].copy(lineVertices[i]);
            if (this.blur) {
                lineShapeVertices[i][LEFT_SMOOTH_LINE].copy(lineVertices[i]);
                lineShapeVertices[i][RIGHT_SMOOTH_LINE].copy(lineVertices[i]);
            }
            // previous point to current point ---------------------------------
            distancePrevious = 0.0;
            if (i > 0) {
                vectorPrevious.copy(lineVertices[i - 1]);
                vectorPrevious.sub(lineVertices[i]);
                distancePrevious = vectorPrevious.length();
            }
            else {
                vectorPrevious.copy(new THREE.Vector3(0, 0, 0));
            }
            if (distancePrevious > 0.0) {
                vectorPrevious.multiplyScalar(1.0 / distancePrevious); // normalize
            }
            // current point to next point -------------------------------------
            if (i === resolution) {
                vectorCurrent.copy(lineVertices[i]);
                vectorCurrent.sub(lineVertices[i - 1]);
            }
            else {
                vectorCurrent.copy(lineVertices[i + 1]);
                vectorCurrent.sub(lineVertices[i]);
            }
            distanceCurrent = vectorCurrent.length();
            if (distanceCurrent > 0.0) {
                vectorCurrent.multiplyScalar(1.0 / distanceCurrent); // normalize
            }
            if (i === resolution) {
                vectorSide.copy(vectorCurrent);
                vectorSide.set(-vectorSide.y, vectorSide.x, vectorSide.z);
                vectorSidePrevious.copy(lineShapeVertices[resolution - 1][LEFT_LINE]);
                vectorSidePrevious.sub(lineVertices[resolution - 1]);
                if (vectorSide.dot(vectorSidePrevious) < 0) {
                    vectorSide.negate();
                }
            }
            else {
                vectorSide.copy(vectorCurrent);
                vectorSide.add(vectorPrevious);
                if ((distanceCurrent === 0 && distancePrevious === 0) || i === 0) {
                    if (distanceCurrent === 0) {
                        vectorCurrent.set(1, 0, 0);
                    }
                    vectorSide.copy(lineVertices[i + 1]);
                    vectorSide.sub(lineVertices[i]);
                    vectorSide.set(-vectorSide.y, vectorSide.x, vectorSide.z);
                }
                else {
                    // generate sideVector from upVector, if the sideVector could not be calculated from angle-bisection
                    if (vectorSide.lengthSq() < 0.000001) {
                        vectorSide.copy(this.upVector);
                        vectorSide.cross(vectorCurrent);
                    }
                    vectorSidePrevious.copy(lineShapeVertices[i - 1][LEFT_LINE]);
                    vectorSidePrevious.sub(lineVertices[i - 1]);
                    if (vectorSide.dot(vectorSidePrevious) < 0) {
                        vectorSide.negate();
                    }
                }
            }
            vectorSide.normalize();
            vectorSideCopy.copy(vectorSide);
            vectorSide.multiplyScalar(LINE_WIDTH / 2.0);
            lineShapeVertices[i][LEFT_LINE].add(vectorSide);
            lineShapeVertices[i][RIGHT_LINE].sub(vectorSide);
            if (this.blur) {
                vectorSideCopy.multiplyScalar(LINE_WIDTH / 2.0 + BLUR_WIDTH);
                lineShapeVertices[i][LEFT_SMOOTH_LINE].add(vectorSideCopy);
                lineShapeVertices[i][RIGHT_SMOOTH_LINE].sub(vectorSideCopy);
            }
        }
        return lineShapeVertices;
    }
    computeBoundingBox() {
        const geometry = this.geometry;
        const count = this.count;
        if (this.boundingBox === null) {
            this.boundingBox = new THREE.Box3();
        }
        if (geometry.boundingBox === null) {
            geometry.computeBoundingBox();
        }
        this.boundingBox.makeEmpty();
        for (let i = 0; i < count; i++) {
            this.getMatrixAt(i, _instanceLocalMatrix);
            _box3.copy(geometry.boundingBox).applyMatrix4(_instanceLocalMatrix);
            this.boundingBox.union(_box3);
        }
    }
    computeBoundingSphere() {
        const geometry = this.geometry;
        const count = this.count;
        if (this.boundingSphere === null) {
            this.boundingSphere = new THREE.Sphere();
        }
        if (geometry.boundingSphere === null) {
            geometry.computeBoundingSphere();
        }
        this.boundingSphere.makeEmpty();
        for (let i = 0; i < count; i++) {
            this.getMatrixAt(i, _instanceLocalMatrix);
            _sphere.copy(geometry.boundingSphere).applyMatrix4(_instanceLocalMatrix);
            this.boundingSphere.union(_sphere);
        }
    }
    getMatrixAt(index, matrix) {
        matrix.fromArray(this.instanceMatrix.array, index * 16);
    }
    setMatrixAt(index, matrix) {
        matrix.toArray(this.instanceMatrix.array, index * 16);
    }
    getColorAt(index, color) {
        color.fromArray(this.instanceColor.array, index * 3);
    }
    setColorAt(index, color) {
        if (this.instanceColor === null) {
            this.instanceColor = new THREE.InstancedBufferAttribute(new Float32Array(this.count * 3), 3);
        }
        color.toArray(this.instanceColor.array, index * 3);
    }
    getMesh(zoom, stencilBufferReference) {
        this.geometry.setAttribute('matrix', this.instanceMatrix);
        this.geometry.setAttribute('color', this.instanceColor);
        this.computeBoundingBox();
        this.computeBoundingSphere();
        this.geometry.boundingBox = this.boundingBox;
        this.geometry.boundingSphere = this.boundingSphere;
        const material = new THREE.RawShaderMaterial({
            vertexShader: vertexShader(),
            fragmentShader: fragmentShader(),
            side: THREE.DoubleSide,
            depthTest: false,
            transparent: true
        });
        material.stencilWrite = true;
        material.stencilRef = stencilBufferReference;
        material.stencilFunc = THREE.EqualStencilFunc;
        material.uniforms = {
            zoom: { value: zoom }
        };
        material.needsUpdate = true;
        return new THREE.Mesh(this.geometry, material);
    }
}
