<script lang="ts">
  import TileRenderer from "../base/TileRenderer.svelte";

  import { drawState, drawSend } from "../../layout.store";
  import type Shape from "../../model/tile/Shape";
  import Matrix2D from "../../model/tile/Matrix2D";
  import { evalTileTextureId, isCollisionTile } from "../../tools/LayoutTools";
  import { DEFAULT_TILE_FILL_COLOR } from "src/global/variable";
  import { currentUser } from "src/store";

  export let shape: Shape;
  export let transform: Matrix2D = Matrix2D.createIdentity();
  export let tileID: number;
  export let depth = 0;
  export let isEditable = true;
  export let selectSnapTile: Function = () => {};

  $: debugRender = $drawState.matches("layoutDesign.showLayout.layoutDebugState.enabled");
  $: normalRender =
    $drawState.matches("layoutDesign.showLayout.layoutState.idle") && !debugRender;

  $: layout = $drawState.context.layoutContext.layout;
  $: isAdmin = $currentUser?.is_admin === 1;
  $: isProtoTile = depth === 0;
  $: isRepeatFirstChild = layout.isRepeatShape(layout.tiles.get(tileID));
  $: hasCollision = isCollisionTile(layout, tileID);
  $: isSelected = $drawState.context.layoutContext.selectedTileID == tileID;
  $: isInteractive = isProtoTile || isRepeatFirstChild;
  $: textureId = evalTileTextureId(shape);
  $: textured = textureId !== undefined;

  $: isHovered = false;
  $: fillColor = evalFillColor(textured, hasCollision);
  $: strokeColor = evalStrokeColor(textured, hasCollision);
  $: strokeWidth = evalStrokeWidth(textured);
  $: outlineStrokeColor = evalOutlineStrokeColor(
    isEditable && isHovered,
    isEditable && isSelected,
    isEditable && isProtoTile && isAdmin,
    isEditable && isInteractive,
    textured
  );
  $: outlineStrokeWidth = evalOutlineStrokeWidth(
    isEditable && isHovered,
    isEditable && isSelected,
    isEditable && isInteractive,
    textured
  );
  $: opacity = evalOpacity(
    $drawState.matches("layoutDesign.showLayout.layoutState.showRepeatConnectPoints"),
    isEditable && isProtoTile,
    !isEditable || isProtoTile //isInteractive
  );
  let label: string | undefined = undefined;

  function evalFillColor(textured: boolean, collision: boolean) {
    if (collision) return "none";
    if (!textured) return DEFAULT_TILE_FILL_COLOR;
    return undefined;
  }

  function evalStrokeColor(textured: boolean, collision: boolean) {
    if (collision) return "#FF0000";
    if (!textured) return "#555555";
    return undefined;
  }

  function evalStrokeWidth(textured: boolean) {
    return !textured ? 0.01 : undefined;
  }

  function evalOutlineStrokeColor(
    hovered: boolean,
    selected: boolean,
    proto: boolean,
    interactive: boolean,
    textured: boolean,
  ) {
    if ($drawState.matches("main.savePreview") || $drawState.matches("main.printPreview") || $drawState.matches("main.printing")) return "#EE6352";
    if (hovered) return "#FAC05E";
    if (selected) return "#008000";
    if (proto) return "#0000C0";
    // if (interactive) return "#EE6352";
    // if (textured) return "#555555";
    return undefined;
  }

  function evalOutlineStrokeWidth(
    hovered: boolean,
    selected: boolean,
    interactive: boolean,
    textured: boolean
  ) {
    if (textured)
      return 0.01;

    return hovered || selected || interactive ? 0.03 : undefined;
  }

  function evalOpacity(
    connections: boolean,
    protoTile: boolean,
    interactive: boolean
  ) {
    if (connections && !protoTile) return 0.7;
    if (!interactive) return 0.6;
    return undefined;
  }

  // forwarded events does not have proper typing for unknown reason, bug..?
  // and svelte html template does not recognize type casting
  function handlePanStart(event: any) {
    if (isEditable)
      drawSend({
        type: "DRAG_START",
        pt: (event as CustomEvent<PanStartEvent>).detail,
        tileID,
      });
  }
  function handlePanMove(event: any) {
    if (isEditable)
      drawSend({
        type: "DRAG_MOVE",
        pt: (event as CustomEvent<PanMoveEvent>).detail.coor,
      });
  }
  function handlePanEnd(event: any) {
    if (isEditable)
      drawSend({
        type: "DRAG_END",
        pt: (event as CustomEvent<PanEndEvent>).detail.coor,
      });
  }

  function handleClick() {
    if (isInteractive && isEditable) drawSend({ type: "SELECT_TILE", tileID });
  }

  function mouseDone(e) {
    if (!isEditable) selectSnapTile(e, tileID);
  }
</script>

<TileRenderer
  id={`tile_${tileID}`}
  svgPath={shape.path.toSvgPath()}
  transform={transform.asSVGMatrixString()}
  {shape}
  {textureId}
  {fillColor}
  {strokeColor}
  {strokeWidth}
  {outlineStrokeColor}
  {outlineStrokeWidth}
  passive={!isInteractive}
  {opacity}
  {label}
  on:mousedown={(e) => mouseDone(e)}
  on:mouseenter={() => (isHovered = isInteractive ? true : false)}
  on:mouseleave={() => (isHovered = false)}
  on:panstart={handlePanStart}
  on:panmove={handlePanMove}
  on:panend={handlePanEnd}
  on:click={handleClick}
/>
