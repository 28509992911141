<script lang="ts">
  import ColorPicker from 'svelte-awesome-color-picker';
  import Input from "./Input.svelte";
  import Dropdown from './Dropdown.svelte';

  export let hex = "#FFFFFF";
  export let onChange = undefined;

  $: {
    if( onChange ) onChange(hex);
  }
</script>

<Dropdown align="right" shadow={false} dropdownWidth="275px" dropClassName="mt-2">
  <Input slot="button" inputProps="w-40 uppercase" value={hex} on:input={e => hex = e.target.value}>
    <div slot="icon" class="w-6 h-6 rounded-full cursor-pointer" style={`background-color: ${hex}`} />
  </Input>
  <ColorPicker isOpen={true} isInput={false} isTextInput={false} bind:hex  />
</Dropdown>
