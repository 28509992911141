<script lang="ts">
    import Pagination from 'tui-pagination';
    import 'tui-pagination/dist/tui-pagination.css';
    import { onMount } from 'svelte';

    export let onPageChange: Function = () => {};
    export let totalItems: number = 0;
    export let itemsPerPage: number = 10;
    export let currentPage: number = 1;

    let resetPage: boolean = false;

    export function resetPagination (count?: number) {
        resetPage = true;
        pagination.movePageTo(1);
        pagination.reset(count ?? totalItems);
        currentPage = 1;
    };
    
    let container;
    let pagination;

    onMount(() => {
        pagination = new Pagination(container, {
            totalItems: totalItems,
            itemsPerPage: itemsPerPage,
        //  visiblePages: 10,
            page: currentPage,
            centerAlign: true,
        //  firstItemClassName: 'tui-first-child',
        //  lastItemClassName: 'tui-last-child',
            usageStatistics: false
        });

        pagination.on('afterMove', (event) => {
            currentPage = event.page;
            if( resetPage ) {
                resetPage = false
                return;
            }
            onPageChange();
        });
    });

</script>
  
<div bind:this={container} id="tui-pagination-container" class="tui-pagination"></div>