<script lang="ts">
  import { _ } from "../../services/i18n";
  import { accessToken, activePath, currentUser, selectedRoom, cachedUserLayouts } from "src/store";
  import Dropdown from "../base/Dropdown.svelte";
  import { authorizeUrl, revokeToken } from "src/services/api";
  import router from "page";
  import routes, { ROUTE } from "../../router";

  export let path : string;

  const login = () => {
    activePath.set(path);
    window.open(
      authorizeUrl(),
      "_self",
      `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes`
    );
  };

  const logout = () => {
    if ($accessToken)
    {
      revokeToken($accessToken)
        .then((_) => {
          accessToken.set("");
          currentUser.set(null);
          selectedRoom.set(undefined);
          cachedUserLayouts.set(new Map());

          if (location.pathname === routes[ROUTE.HOME].path)
            window.location.reload();
          else
            router.redirect(routes[ROUTE.HOME].path)
        })
        .catch((e) => {
          console.log("logout failed.", e);
        });
    }
  };
</script>

{#if !$currentUser}
  <button class="px-2.5 h-15 flex items-center gap-2 justify-center border-0" on:click={login}>
    <i class="fa fa-arrow-right-to-bracket font-black text-secondary-500" />
    {$_("action_menu.login")}
  </button>
{:else}
  <Dropdown 
    class="flex item-center border-0" 
    dropClassName="mt-16 mr-2.5"
    align="right"
    fitContent
  > 
    <button slot="button" class="px-2.5 gap-2 h-15 flex items-center justify-center border-0">
      {#if !!$currentUser.image}
        <img
          src={$currentUser.image}
          class="w-10 h-10 rounded-full"
          alt={`${$currentUser.first_name}`}
        />
      {:else}
        <div class="flex items-center justify-center w-10 h-10 rounded-full bg-secondary-500 text-white">
          {$currentUser.first_name?.length ? $currentUser.first_name[0] : ''}{$currentUser.last_name?.length ? $currentUser.last_name[0] : ''}
        </div>
      {/if}
      <div class="text-xl">
        {$currentUser.first_name}
        {$currentUser.last_name}
      </div>
      <i
        class="fa fa-caret-down font-black text-secondary-500 text-xs"
      />
    </button>
    <li class="border-t border-gray-200">
      <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded" on:click={logout}>
        <div class="w-9 h-9 flex items-center justify-center flex-shrink-0">
          <i class="fa-solid fa-arrow-right-from-bracket text-xl font-black text-secondary-500"/>
        </div>
        <div class="flex-grow text-left text-[13px]">{$_("action_menu.logout")}</div>
      </button>
    </li>
  </Dropdown>
{/if}
