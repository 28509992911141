<script lang="ts">
  import Input from "./Input.svelte";
  import Dropdown from "./Dropdown.svelte";
  export let className = "";
  export let label = "";
  export let inputProps = "";
  export let options: { value: any; label: string }[] = [];
  export { className as class };
  export let value;
  export let index = 0;

  let dropdownShow = false;

  const handleClickDropdown = () => {
    dropdownShow = !dropdownShow;
  };

  const handleClickItem = (v, i) => {
    value = v.value;
    index = i;
  };

  $: inputValue = options.find((v) => v.value === value)?.label || '';
</script>

<div class={className}>
  <Dropdown bind:forceToggle={dropdownShow} fitContent>
    <Input
      slot="button"
      {label}
      value={inputValue}
      icon="fa-solid fa-caret-down"
      iconProps="cursor-pointer"
      {inputProps}
      onClick={handleClickDropdown}
      readonly
    />
    {#each options as option, index}
      <li>
        <button
          class="btn-default flex items-center px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded"
          on:click={() => handleClickItem(option, index)}
        >
          <div class="flex-grow text-left text-[13px]">{option.label}</div>
        </button>
      </li>
    {/each}
  </Dropdown>
</div>
