<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import Notifications from 'svelte-notifications';
  import axios from "axios";
  import router from "page";
  import routes, { ROUTE } from "./router";
  import DrawPane from "./components/DrawPane.svelte";
  import LayoutDesigner from "./components/LayoutDesigner.svelte";
  import { drawState } from "./layout.store";
  import { accessToken, expireTime, currentUser, loadSuccess, activePath } from "./store";
  import { SUPPORTED_LOCALIZATIONS } from "./services/locales";
  import { getUser } from "./services/api";
  import { DEFAULT_ACCESS_TOKEN, QUERY_PARAMS } from "./global/variable";
  // import socket, { subscribeDownloadChannel } from "./services/websocket";
  import queryString from 'query-string';
  import { get } from "svelte/store";
  import { localeStore } from "./store";
  import { PAGES } from "./global/types";

  let currentPage : PAGES = PAGES.NEW_PROJECT_STEP_1;
  let browserZoomLevel : number = 100;
  
  const requestInterceptor = axios.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `Bearer ${
        $accessToken || DEFAULT_ACCESS_TOKEN
      }`;
      const locale = get(localeStore)
      config.headers["Accept-Language"] = locale.length > 0 ? locale : SUPPORTED_LOCALIZATIONS.en;
      config.headers["Access-Control-Allow-Origin"] = "*";

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const responseInterceptor = axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        accessToken.set("");
        currentUser.set(null);
      }
      return Promise.reject(error);
    }
  );

  $: showTileLayout = $drawState.matches("tileLayoutTool.editTile");

  $: if ($accessToken) {
    if ($expireTime > Date.now()) getCurrentUser();
  }

  const getCurrentUser = async () => {
    //get current user
    const userRes = await getUser();
    const userJSON = await userRes.data;
    currentUser.set(userJSON);
    // subscribeDownloadChannel(userJSON.id)
  };

  const login = async (action) => {
    const url = new URL(window.location.href.replace("#", "?"));
    const searchParams = new URLSearchParams(url.search);

    if (searchParams?.get(QUERY_PARAMS.ACCESS_TOKEN)) {
      accessToken.set(searchParams.get(QUERY_PARAMS.ACCESS_TOKEN));
      // refreshToken.set(accessTokenJSON.refresh_token);
      const now = new Date();
      now.setSeconds(
        now.getSeconds() + Number(searchParams.get(QUERY_PARAMS.TOKEN_EXPIRY) ?? 0)
      );
      expireTime.set(now.getTime());
      const path = get(activePath) ?? routes[ROUTE.HOME].path;

      if (action)
      {
        router.redirect(`${routes[ROUTE.BOARD].path}` +
                        `${action ? `/${action}` : ""}` +
                        `?${QUERY_PARAMS.LOCALE}=${!!get(localeStore) ? get(localeStore) : SUPPORTED_LOCALIZATIONS.en}`);

      }
      else
        router.redirect(`${path}?${QUERY_PARAMS.LOCALE}=${!!get(localeStore) ? get(localeStore) : SUPPORTED_LOCALIZATIONS.en}`);
    }
  };

  routes.forEach((route, index) => {
    router(
      route.path,
      (ctx, next) => {
        const queries = queryString.parse(ctx.querystring);
        ctx.queries = queries;
        if (queries[QUERY_PARAMS.LOCALE] )
          localeStore.set(queries[QUERY_PARAMS.LOCALE].toString())
        next();
      },
      (ctx) => {
        if (index === ROUTE.AUTH_CALLBACK || index === ROUTE.AUTH_CALLBACK_ACTION)
          login(ctx.params?.action);

        else if (index === ROUTE.OTHER)
          router.redirect(routes[ROUTE.NEW_PROJECT_STEP_1].path);

        else if (index === ROUTE.HOME)
          router.redirect(routes[ROUTE.NEW_PROJECT_STEP_1].path);

        else if (
          !ctx.queries[QUERY_PARAMS.LOCALE] ||
          !Object.values(SUPPORTED_LOCALIZATIONS).includes(ctx.queries[QUERY_PARAMS.LOCALE])
        )
        {
          const locale = $localeStore?.length ? $localeStore : navigator.language;

          if (Object.values(SUPPORTED_LOCALIZATIONS).includes(locale))
            ctx.queries[QUERY_PARAMS.LOCALE] = locale;
          else
            ctx.queries[QUERY_PARAMS.LOCALE] = SUPPORTED_LOCALIZATIONS.en;

          const queryUrl = queryString.stringify(ctx.queries);
          router.redirect(ctx.pathname + `?${queryUrl}`)
        }
        else if (index === ROUTE.BOARD || index === ROUTE.BOARD_ACTION)
          currentPage = PAGES.BOARD;

        else if (index === ROUTE.NEW_PROJECT_STEP_1)
          currentPage = PAGES.NEW_PROJECT_STEP_1;

        else if (index === ROUTE.NEW_PROJECT_STEP_2)
          currentPage = PAGES.NEW_PROJECT_STEP_2;

        else if (index === ROUTE.PROJECTS_LIST)
        {
          if (!get(currentUser))
            router.redirect(routes[ROUTE.NEW_PROJECT_STEP_1].path);
          else
            currentPage = PAGES.PROJECTS_LIST;
        }
      }
    );
  });
  router.start();

  const handleResize = () => {
    browserZoomLevel = Math.round(window.devicePixelRatio * 100);
  }

  onMount(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  });
</script>
<div inert={!$loadSuccess || null} class="home font-primary">
  <header>Project</header>
  <Notifications>
  {#if requestInterceptor > -1}
    {#key browserZoomLevel}
      <DrawPane page={currentPage}/>
    {/key}
    {#if showTileLayout}
    <div
      class="tile-edit z-20"
    >
      <LayoutDesigner />
    </div>
    {/if}
    <a class="" rel="noreferrer" target="_blank" href="https://wa.me/+390424567729">
      <button class={`fixed bottom-4 flex items-center justify-center w-16 h-16 rounded-full bg-green-500 hover:bg-teal-600 text-white transition-all duration-150 shadow-md hover:cursor-pointer hover:shadow-xl focus:outline-none`}
        style={`right: ${currentPage === PAGES.BOARD ? '72px' : '16px'}; z-index: 9`}
      >
        <div class="pointer-events-none opacity-60 m-0 absolute inset-0 transform-gpu ios-flicker-fix animate-ping-md rounded-full bg-green-500"></div>
        <i class="fab fa-whatsapp text-4xl" />
      </button>
    </a>
  {:else}
    <div>Loading ...</div>
  {/if}
  </Notifications>
  <footer>By Who</footer>
</div>

<style scoped>
  .home {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .tile-edit {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: white;
  }
  header {
    position: absolute;
    display: none;
  }
  footer {
    position: absolute;
    display: none;
  }
  @media print {
    header {
      display: block;
      left: 16pt;
      top: 16pt;
    }
    footer {
      display: block;
      right: 16pt;
      bottom: 16pt;
    }
  }
</style>
