<script lang="ts">
  import { drawSend, drawState } from "../../layout.store";
  import PlusFilledIcon from "../icons/PlusFilledIcon.svelte";
  import PlusIcon from "../icons/PlusIcon.svelte";
  import IconSvg from "../base/IconSVG.svelte";

  type AddButtonPosition = {
    addPointID: number;
    x: number;
    y: number;
    edgeIndex: number;
    edgeLength: number;
  };
  let addButtonPositions: Array<AddButtonPosition> = [];
  $: {
    let layout = $drawState.context.layoutContext.layout;
    if (layout) {
      addButtonPositions = layout.addPoints.map((addPoint, idx) => {
        const p = addPoint.segment.pointAt(addPoint.locationAsSegmentParameter);
        return {
          addPointID: idx,
          x: p.x,
          y: p.y,
          edgeIndex: addPoint.edge.index,
          edgeLength: addPoint.onEdgeLength,
        };
      });
    }
  }
</script>

{#if $drawState.matches("layoutDesign.noLayout")}
  <IconSvg on:click={() => drawSend({ type: "ADD_ROOT_TILE" })}>
    <PlusFilledIcon />
  </IconSvg>
{/if}
{#if $drawState.matches("layoutDesign.showLayout.layoutState.idle")}
  {#each addButtonPositions as { addPointID, x, y, edgeIndex, edgeLength } (`${addPointID}-${x}-${y}`)}
    <IconSvg
      {x}
      {y}
      name="Edge index: {edgeIndex}, edge length: {edgeLength}"
      on:click={() => drawSend({ type: "ADD_TILE_TO_EDGE", addPoint: addPointID })}
    >
      <PlusIcon />
    </IconSvg>
  {/each}
{/if}
