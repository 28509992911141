import BoundingBox from "./BoundingBox";
import Vector2 from "./Vector2";
function compareDouble(n1, n2, tolerance = 1e-5) {
    if (Math.abs(n1 - n2) <= tolerance) {
        return 0;
    }
    else if (n1 > n2) {
        return +1;
    }
    else {
        return -1;
    }
}
class KDTreeNode {
    key;
    value;
    leftBelow = null;
    rightAbove = null;
    rect;
    userValue;
    constructor(key, value, rect, userValue) {
        this.key = key;
        this.value = value;
        this.rect = rect;
        this.userValue = userValue;
    }
}
export class KdTree {
    tolerance;
    get size() {
        return this.internalSize;
    }
    get isEmpty() {
        return this.size === 0;
    }
    root = null;
    internalSize = 0;
    constructor(tolerance = 1e-5) {
        this.tolerance = tolerance;
    }
    /**
     * Insert a new point to the KDTree
     * @param key key to insert
     * @returns true if the key was inserted, false if it already exists in the tree
     */
    insert(key, value) {
        // the point is already in the tree
        if (this.get(key) !== null) {
            return false;
        }
        // this.doInsert(this.root, null, 0, 0, 1, 1, 0, key, value);
        let node = this.root;
        let parentNode = null;
        let minX = 0;
        let minY = 0;
        let maxX = 1;
        let maxY = 1;
        let level = 0;
        let cmp = 0;
        const MAX_CALL = 1e+5;
        while (node !== null && level <= MAX_CALL) {
            const vertical = level % 2 === 0;
            const keyToUse = vertical ? key.x : key.y;
            cmp = compareDouble(keyToUse, node.key, this.tolerance);
            parentNode = node;
            if (cmp < 0) {
                if (vertical) {
                    maxX = node.value.x;
                }
                else {
                    maxY = node.value.y;
                }
                node = node.leftBelow;
                level++;
            }
            else {
                if (vertical) {
                    minX = node.value.x;
                }
                else {
                    minY = node.value.y;
                }
                node = node.rightAbove;
                level++;
            }
        }
        if (node === null) {
            this.internalSize++;
            const vertical = level % 2 === 0;
            const keyToUse = vertical ? key.x : key.y;
            const rect = new BoundingBox(new Vector2(minX, minY), new Vector2(maxX, maxY));
            node = new KDTreeNode(keyToUse, key, rect, value);
            if (parentNode === null) {
                this.root = node;
            }
            else if (cmp < 0) {
                parentNode.leftBelow = node;
            }
            else {
                parentNode.rightAbove = node;
            }
        }
    }
    get(key, tolerance = this.tolerance) {
        let node = this.root;
        let level = 0;
        while (node != null) {
            if (node.value.equalTo(key, tolerance)) {
                return node.userValue;
            }
            const keyToCompare = level % 2 === 0 ? key.x : key.y;
            const cmp = compareDouble(keyToCompare, node.key, tolerance);
            if (cmp < 0) {
                node = node.leftBelow;
            }
            else {
                node = node.rightAbove;
            }
            level++;
        }
        return null;
    }
    contains(p) {
        return this.get(p) != null;
    }
    range(rect) {
        const list = [];
        return this.doRange(this.root, rect, list);
    }
    nearest(p) {
        if (this.root === null) {
            return null;
        }
        const result = this.doNearest(this.root, this.root, 0, p);
        return [result.value, result.userValue];
    }
    doRange(node, rect, list) {
        if (node == null) {
            return list;
        }
        if (!node.rect.intersects(rect)) {
            return list;
        }
        if (rect.contains(node.value)) {
            list.push(node.value);
        }
        this.doRange(node.leftBelow, rect, list);
        this.doRange(node.rightAbove, rect, list);
        return list;
    }
    doNearest(node, nearest, level, p) {
        if (node == null) {
            return nearest;
        }
        const distanceToNearestDiscovered = nearest.value.distanceSquared(p);
        const distanceToCurrentNodeBoundingBoxangle = node.rect.distanceSquared(p);
        if (distanceToNearestDiscovered > distanceToCurrentNodeBoundingBoxangle) {
            if (distanceToNearestDiscovered > node.value.distanceSquared(p)) {
                nearest = node;
            }
            const vertical = level % 2 === 0;
            if (vertical) {
                if (p.x - node.value.x < 0) {
                    nearest = this.doNearest(node.leftBelow, nearest, level + 1, p);
                    nearest = this.doNearest(node.rightAbove, nearest, level + 1, p);
                }
                else {
                    nearest = this.doNearest(node.rightAbove, nearest, level + 1, p);
                    nearest = this.doNearest(node.leftBelow, nearest, level + 1, p);
                }
            }
            else {
                if (p.y - node.value.y < 0) {
                    nearest = this.doNearest(node.leftBelow, nearest, level + 1, p);
                    nearest = this.doNearest(node.rightAbove, nearest, level + 1, p);
                }
                else {
                    nearest = this.doNearest(node.rightAbove, nearest, level + 1, p);
                    nearest = this.doNearest(node.leftBelow, nearest, level + 1, p);
                }
            }
        }
        return nearest;
    }
}
