<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { _ } from "src/services/i18n";
  import type { CategoryElement } from "src/model/CategoryElement";
  import { BuildingJson } from "src/global/variable";

  export let categories: CategoryElement[] = [];

  const getBuildingElement = (id: number) => {
    const item = BuildingJson.find((i) => i.ids.includes(id));
    return item ?? undefined;
  };

  $: categoriesWithBuilding = categories.map((category) => ({
    ...category,
    building: getBuildingElement(category.id),
  }));

  const dispatch = createEventDispatcher();

  const handleSelectCategory = (category?: CategoryElement) => {
    dispatch("select", { category });
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="w-full flex flex-col gap-2.5">
  {#each categoriesWithBuilding as category, i}
    {#if i !== 0}
      <div class="w-full border-b border-gray-200" />
    {/if}
    <div
      class="py-3 cursor-pointer flex items-center text-black gap-2.5"
      on:click={() => handleSelectCategory(category)}
    >
      <!-- <div class="w-9 text-center">
        {#if category.building}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${category.building.width + 10} ${
              category.building.height + 10
            }`}
            width="20px"
            height="20px"
          >
            <g stroke="black">
              {@html category.building.path}
            </g>
          </svg>
        {/if}
      </div> -->
      <div class="text-lg font-bold">
        {category.name}
      </div>
      <div class="w-9 text-center ml-auto">
        <i class="fa-solid fa-chevron-right text-xl text-secondary-500" />
      </div>
    </div>
  {/each}
</div>
