import { writable } from "svelte/store";
import { SIDE_MENUS, TOOLS } from "../global/types";
import { setupI18n } from "../services/i18n";
import { SUPPORTED_LOCALIZATIONS } from "../services/locales";
export const currentTool = writable(TOOLS.SELECTION);
export const editTool = writable(undefined);
export const linkTool = writable(true);
export const localeStore = writable('');
export const viewBox = writable({ x: 0, y: 0, w: 0, h: 0 });
export const savedViewBox = writable({ x: 0, y: 0, w: 0, h: 0 });
// side menu
export const visibleSideMenu = writable(false);
export const activeMenu = writable(undefined);
export const selectedRoom = writable(undefined);
export const editSegment = writable(undefined);
export const previewImage = writable(undefined);
export const recentLayouts = writable([]);
export const cachedUserLayouts = writable(new Map());
export const cachedTileImages = writable(new Map());
export const errMissingParts = writable({ layout: [], tile: [] });
export const homeShape = writable(0);
export const accessToken = writable(localStorage.accessToken ?? "");
export const refreshToken = writable(localStorage.refreshToken ?? "");
export const expireTime = writable(localStorage.expireTime ? Number(localStorage.expireTime) : Date.now());
export const activePath = writable(localStorage.activePath ?? "");
export const currentUser = writable(null);
export const loadSuccess = writable(false);
accessToken.subscribe((n) => (localStorage.accessToken = n));
refreshToken.subscribe((n) => (localStorage.refreshToken = n));
expireTime.subscribe((n) => (localStorage.expireTime = n));
activePath.subscribe((n) => (localStorage.activePath = n));
export const tileCountInfo = writable();
localeStore.subscribe((locale) => setupI18n({ withLocale: locale ?? SUPPORTED_LOCALIZATIONS.en }));
