<script lang="ts">
  // first path is there just to fill the inside of the icon with transparent/non-visible area
  // without it, pointer-events would be triggered only when cursor over path, not inside the icon bounding box
  // pointer-events: bounding-box works only in Chrome browser and is unsupported in others
</script>

<path d="M5 19h14V5H5v14zm2-8h4V7h2v4h4v2h-4v4h-2v-4H7v-2z" fill="none" />
<path
  d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zm-8-2h2v-4h4v-2h-4V7h-2v4H7v2h4z"
/>
