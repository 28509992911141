<script lang="ts">
  import type { TileInfoResponse } from "src/global/types";
  import Loading from "../util/Loading.svelte";

  export let tile: TileInfoResponse | undefined = undefined;
  export let loading: boolean = false;
  export let onClick: () => void

  $: rotation = tile?.rotation === 90 ? "-rotate-90" : tile?.rotation === 180 ? "rotate-180" : tile?.rotation === 270 ? "rotate-90" : ""
</script>
  
<div 
  class="group flex flex-col items-stretch justify-start rounded-md ring-2 ring-inset ring-gray-100 hover:ring-primary-500 p-1 cursor-pointer bg-white h-full"
  on:click={onClick}
  on:keyup|preventDefault|stopPropagation={(event) => {
    if (event.key === " " || event.key === "Enter")
      onClick()
  }}
>
  <div class="w-full p-2">
    <div class="relative aspect-w-1 aspect-h-1 h-full overflow-hidden rounded-md bg-white">
      {#if tile}
        {#if tile.images.length === 1}
          <img 
            src={tile.images[0]} 
            class={`absolute inset-0 h-full w-full transform-gpu transition-transform duration-300 scale-100 transition-all group-hover:scale-110 object-contain translate-x-0 ${rotation}`} 
            alt={tile.name}
          />
        {:else if tile.images.length > 1}
          <img 
            src={tile.images[0]} 
            class={`absolute inset-0 h-full w-full transform transition-transform duration-300 object-contain translate-x-0 group-hover:-translate-x-full ${rotation}`} 
            alt={tile.name}
          />
          <img 
            src={tile.images[1]} 
            class={`absolute inset-0 h-full w-full transform transition-transform duration-300 object-contain translate-x-full group-hover:translate-x-0 ${rotation}`} 
            alt={tile.name}
          />
        {/if}
      {:else}
        <div class="absolute inset-0 h-full w-full transform-gpu transition-transform duration-300 scale-90 transition-all group-hover:scale-100 object-contain translate-x-0">
          <slot name="image" />
        </div>
      {/if}
      {#if loading}
        <div class="flex w-full h-full justify-center items-center backdrop-filter backdrop-blur">
          <Loading />
        </div>
      {/if}
    </div>
  </div>
  {#if tile}
    <div class="px-2 text-base font-semibold text-gray-900 group-hover:text-primary-700 truncate pointer-events-none">
      {tile.producer.name}
    </div>
    <div class="flex flex-row p-2 flex-1">
      <p class="text-sm text-gray-400  line-clamp-2">{tile.collection.name} - {tile.name} ({tile.width}x{tile.height})</p>
    </div>
  {:else}
    <div class="p-2 text-base font-semibold text-gray-900 group-hover:text-primary-700 truncate pointer-events-none mt-auto">
      <slot name="name" />
    </div>
  {/if}
</div>