<script lang="ts">
  export let className = "";
  export let title = "";
  export let icon = undefined;
  export let variant: "primary" | "outline-secondary" | "secondary" = "primary";
  export let disabled: boolean = false;

  export { className as class };

  let btnStyles = "";
  let iconStyles = "";
  let textStyles = "";

  $: {
    switch (variant) {
      case "primary":
        btnStyles = `border-2 transition-colors duration-300
                     ${disabled ? 'bg-primary-300 border-primary-300 cursor-default' : 'border-primary-500 bg-primary-500'}`;
        iconStyles = `text-white ${disabled ? 'cursor-default' : ''}`;
        textStyles = `text-white ${disabled ? 'cursor-default' : ''}`;
        break;
      case "outline-secondary":
        btnStyles = `border-2 transition-colors duration-300
                     ${disabled ? 'border-gray-300 cursor-default' : 'hover:bg-secondary-500 hover:border-secondary-500 border-secondary-500'}`;
        iconStyles = `transition-colors duration-300 ${disabled ? 'text-gray-300 cursor-default' : 'text-secondary-500 group-hover:text-white'}`;
        textStyles = `transition-colors duration-300 ${disabled ? 'text-gray-300 cursor-default' : 'text-secondary-500 group-hover:text-white'}`;
        break;
      case "secondary":
        btnStyles = `border-2 transition-colors duration-300
                     ${disabled ? 'bg-secondary-300 border-secondary-300 cursor-default' : 'border-secondary-500 bg-secondary-500'}`;
        iconStyles = `transition-colors duration-300 text-white ${disabled ? 'cursor-default' : 'group-hover:text-primary-500'}`;
        textStyles = `transition-colors duration-300 text-white ${disabled ? 'cursor-default' : 'group-hover:text-primary-500'}`;
        break;
    }
  }
</script>

<button
  class={`${className} ${btnStyles} flex items-center gap-2.5 rounded-xl py-1 px-3 h-12 group`}
  disabled={disabled}
  on:click
>
  {#if icon}
    <div class="w-9 h-9 flex items-center justify-center flex-shrink-0">
      <i class="{icon} {iconStyles}" />
    </div>
  {/if}
  <div class={`${textStyles} w-full text-center font-bold`}>
    {title}
  </div>
</button>
