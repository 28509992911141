<script lang="ts">
  import { activeMenu, editSegment, selectedRoom, visibleSideMenu } from "src/store";
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import Header from "./Header.svelte";
  import Button from "../../base/Button.svelte";
  import { isDoor, isLine, createDoor, getMetricWithUnit, getNewSegmentName, isArc } from "src/helpers";
  import { DOORTYPE, Line, type Door } from "src/model";
  import WallInfo from "./WallInfo.svelte";
  import DoorInfo from "./DoorInfo.svelte";
  import SegmentList from "./SegmentList.svelte";
  import { SEGMENT_LIST_TYPE, SIDE_MENUS } from "src/global/types";
  import { onMount, onDestroy } from "svelte";
  import { METRIC_UNITS } from "src/global/variable";
  
  enum WALL_VIEW {
    INDEX,
    WALL_DETAIL,
    ADD_DOOR,
    EDIT_DOOR,
    ADD_WINDOW,
    EDIT_WINDOW,
  }

  let routes : WALL_VIEW[] = [WALL_VIEW.INDEX];

  let currentView: WALL_VIEW = WALL_VIEW.INDEX;
  let headerInfo = {
    title: "",
    info: "",
    path: "",
    editable: false,
  };
  let currentWall: Line;

  let unsubscribe
  onMount(() => {
    unsubscribe = visibleSideMenu.subscribe((visible) => {
      if( $activeMenu !== SIDE_MENUS.WALL )
        return;

      if( !visible ) {
        handleBack();
        routes = [WALL_VIEW.INDEX];
        currentView = WALL_VIEW.INDEX;
        currentWall = undefined;
      } else if ($editSegment) {
        if( isLine($editSegment) || isArc($editSegment) ) {
          currentWall = $editSegment as Line;
          routes = [WALL_VIEW.INDEX, WALL_VIEW.WALL_DETAIL];
          currentView = WALL_VIEW.WALL_DETAIL
        } else {
          routes = [WALL_VIEW.INDEX];
          currentView = WALL_VIEW.INDEX;
          currentWall = undefined;
        }
        editSegment.set(undefined);
      }
    })
  })
  
  $: walls = $drawState.context.current.segments.filter((segment) =>
    isLine(segment) && !(segment as Line).isDashed()
  ).sort((a, b) => a.name.localeCompare(b.name)) as Line[];
  
  const handleSelectWall = (e: CustomEvent) => {
    currentWall = e.detail.segment;
    handleNext(WALL_VIEW.WALL_DETAIL);
  };

  const handleNext = (view: WALL_VIEW) => {
    currentView = view;
    routes.push(currentView);
  };

  const handleBack = () => {
    drawSend({
      type: "CONFIRM_UPDATE",
    });
    routes.pop();
    if (routes.length) currentView = routes[routes.length - 1];
  };

  const handleReset = () => {
    drawSend({
      type: "CANCEL_UPDATE",
    });
  }

  const handleSelectDoor = (e: CustomEvent) => {
    let currentDoor = e.detail.segment;
    if (!currentDoor) {
      const line = isLine(currentWall) ? (currentWall as Line) : undefined;
      const doorName = getNewSegmentName($_("segment.door"), $drawState.context.current.segments)
      currentDoor = createDoor(line, DOORTYPE.DOOR, doorName);

      drawSend({
        type: "CREATE_BUILDING_PART",
        segment: currentDoor,
      });
    }
    drawSend({
      type: "ENTER_SELECT",
      segment: currentDoor,
    });
    handleNext(e.detail.segment ? WALL_VIEW.EDIT_DOOR : WALL_VIEW.ADD_DOOR);
  };

  const handleSelectWindow = (e: CustomEvent) => {
    let currentWindow = e.detail.segment;
    if (!currentWindow) {
      const line = isLine(currentWall) ? (currentWall as Line) : undefined;
      const windowName = getNewSegmentName($_("segment.window"), $drawState.context.current.segments)
      currentWindow = createDoor(line, DOORTYPE.WINDOW, windowName);

      drawSend({
        type: "CREATE_BUILDING_PART",
        segment: currentWindow,
      });
    }
    drawSend({
      type: "ENTER_SELECT",
      segment: currentWindow,
    });
    handleNext(e.detail.segment ? WALL_VIEW.EDIT_WINDOW : WALL_VIEW.ADD_WINDOW);
  };

  $: {
    switch (currentView) {
      case WALL_VIEW.INDEX:
        headerInfo = {
          title: $_("side_menu.walls"),
          info: "",
          path: "",
          editable: false
        };
        break;
      case WALL_VIEW.WALL_DETAIL:
        headerInfo = {
          title: currentWall.getName(),
          info: getMetricWithUnit(
            currentWall.getLineLength(),
            $drawState.context.currentMetricUnit
          ).toString(),
          path: `${$_("side_menu.walls")} > ${currentWall.getName()}`,
          editable: true,
        };
        break;
      case WALL_VIEW.ADD_DOOR:
      case WALL_VIEW.EDIT_DOOR:
        headerInfo = {
          title: $drawState.context.dragContext.selectedObject.getName(),
          info: "",
          path: `${$_("side_menu.walls")} > ${currentWall.getName()} > ${currentView === WALL_VIEW.ADD_DOOR ? $_("side_menu.door.add_door") : $_("side_menu.door.edit_door")}`,
          editable: true
        };
        break;
      case WALL_VIEW.ADD_WINDOW:
      case WALL_VIEW.EDIT_WINDOW:
        headerInfo = {
          title: $drawState.context.dragContext.selectedObject.getName(),
          info: "",
          path: `${$_("side_menu.walls")} > ${currentWall.getName()} > ${currentView === WALL_VIEW.ADD_WINDOW ? $_("side_menu.door.add_window") : $_("side_menu.door.edit_window")}`,
          editable: true
        };
        break;
    }
  }

  const handleTitleChange = (e) => {
    switch(currentView) {
      case WALL_VIEW.WALL_DETAIL:
        currentWall.name = e.detail.value;
        drawSend({
          type: "CHANGE_NAME",
          segment: currentWall,
          name: e.detail.value,
        });
        break;
      case WALL_VIEW.ADD_DOOR:
      case WALL_VIEW.EDIT_DOOR:
      case WALL_VIEW.ADD_WINDOW:
      case WALL_VIEW.EDIT_WINDOW:
        drawSend({
          type: "CHANGE_NAME",
          name: e.detail.value,
        });
      break;
    }
  }

  onDestroy(() => {
    if( unsubscribe ) unsubscribe();
  })

</script>

<div class="flex flex-col h-full">
  <Header {...headerInfo} on:change={handleTitleChange} />

  {#if currentView !== WALL_VIEW.INDEX}
    <div class="flex items-center gap-1 px-3 hover:text-primary-500 cursor-pointer" on:click={handleBack}>
      <i class="fa-regular fa-chevron-left text-sm" />
      <span class="font-medium text-sm">{$_("side_menu.back")}</span>
    </div>
  {/if}
  <div class="w-full flex flex-col flex-1 p-3 overflow-auto gap-4">
    {#if currentView === WALL_VIEW.INDEX}
      <SegmentList type={SEGMENT_LIST_TYPE.LINE} segments={walls} on:select={handleSelectWall} />
    {:else if currentView === WALL_VIEW.WALL_DETAIL}
      <WallInfo 
        wall={currentWall}
        on:door={handleSelectDoor}
        on:window={handleSelectWindow}
      />
    {:else if currentView === WALL_VIEW.ADD_DOOR || currentView === WALL_VIEW.EDIT_DOOR}
      <DoorInfo type={DOORTYPE.DOOR} on:back={handleBack}/>
    {:else if currentView === WALL_VIEW.ADD_WINDOW || currentView === WALL_VIEW.EDIT_WINDOW}
      <DoorInfo type={DOORTYPE.WINDOW} on:back={handleBack}/>
    {/if}
    {#if currentView !== WALL_VIEW.INDEX}
      <div class="flex items-center mt-auto">
        {#if currentView === WALL_VIEW.ADD_DOOR || currentView === WALL_VIEW.EDIT_DOOR || currentView === WALL_VIEW.ADD_WINDOW || currentView === WALL_VIEW.EDIT_WINDOW}
          <Button
            variant="outline-secondary"
            title={$_("side_menu.reset")}
            class="w-wide-btn ml-auto"
            on:click={handleReset}
          />
        {/if}
      </div>
    {/if}
  </div>
</div>
