import Edge from "./Edge";
import Vector2 from "./Vector2";
import EdgeReference, { ANCHOR_POINTS } from "./EdgeReference";
import { Path, LineSegment } from "./Path";
import { TOLERANCE_DISTANCE } from "src/global/variable";
export default class Shape {
    path;
    shapeId;
    tileId;
    name;
    tileData;
    slug;
    width;
    height;
    rotation;
    constructor(path, shapeId, tileId, name = "Shape(" + path.segmentCount + ")", tileData, slug, width, height, rotation) {
        this.path = path;
        this.shapeId = shapeId;
        this.tileId = tileId;
        this.name = name;
        this.tileData = tileData;
        this.slug = slug;
        this.width = width;
        this.height = height;
        this.rotation = rotation;
        this.edges = [];
        for (let i = 0; i < path.segments.length; i++) {
            this.edges.push(new Edge(i, path.segments[i]));
        }
        this.boundingBox = path.bounds;
        this.center = path.center;
        this.orientation = new Vector2(0, -this.boundingBox.max.sub(this.boundingBox.min).mul(0.5).y);
        if (this.rotation === undefined)
            this.rotation = 0;
    }
    static fromPoints(points, tileData) {
        const center = points.reduce((a, b) => a.add(b)).mul(1.0 / points.length);
        const centeredPoints = points.map((p) => p.sub(center));
        const edges = new Array();
        for (let i = 0; i < centeredPoints.length; i++) {
            edges.push(new LineSegment(centeredPoints[i], centeredPoints[(i + 1) % centeredPoints.length]));
        }
        return new Shape(new Path(edges), undefined, undefined, undefined, tileData ?? {}, undefined, undefined, undefined, undefined);
    }
    static createRegular(sides, internalGap = 0) {
        const nameBySides = [
            "Point",
            "Line",
            "Triangle",
            "Square",
            "Pentagon",
            "Hexagon",
            "Heptagon",
            "Octagon",
            "Nonagon",
            "Decagon",
            "Hendecagon",
            "Dodecagon",
            "Tridecagon",
            "Tetradecagon",
            "Pentadecagon",
            "Hexadecagon",
            "Heptadecagon",
            "Octadecagon",
            "Nonadecagon",
            "Icosadecagon",
        ];
        const angle = (2 * Math.PI) / sides;
        let rotation = -Math.PI / 2;
        if (sides % 2 === 0) {
            rotation += angle / 2;
        }
        const angles = new Array();
        for (let sideIdx = 0; sideIdx < sides; sideIdx++) {
            angles.push(angle * sideIdx + rotation);
        }
        const d = 0.5 / Math.sin(angle / 2);
        const points = angles.map((a) => new Vector2(Math.cos(a) * d, Math.sin(a) * d));
        const edges = [];
        for (let pIdx = 0; pIdx < points.length; pIdx++) {
            const pt = points[pIdx];
            const nextPt = points[(pIdx + 1) % points.length];
            edges.push(new LineSegment(pt, nextPt));
        }
        const path = new Path(edges).offset(-internalGap / 2);
        return new Shape(path, undefined, undefined, sides < nameBySides.length
            ? nameBySides[sides]
            : "RegularShape(" + sides + ")", {}, undefined, undefined, undefined, undefined);
    }
    static globalInstanceId = 0;
    instanceId = Shape.globalInstanceId++;
    boundingBox;
    center;
    orientation;
    edges;
    static isBaseShape(shapeId, tileId) {
        return shapeId === tileId;
    }
    isBaseShape() {
        return this.shapeId === this.tileId;
    }
    outsetSegment(index, outsetBy) {
        const offsetPoints = this.path.offsetPoints(outsetBy);
        return offsetPoints.offsetSegment(index);
    }
    edge(index, offset, anchorPoint) {
        let i = index % this.edges.length;
        if (i < 0) {
            i += this.edges.length;
        }
        return new EdgeReference(this.edges[i], this, offset, anchorPoint);
    }
    equalPoints(other, eps = TOLERANCE_DISTANCE) {
        if (other == null || this.edges.length !== other.edges.length) {
            return false;
        }
        for (let i = 0; i < this.edges.length; i++) {
            if (!this.edges[i].from.equalTo(other.edges[i].from, eps)) {
                return false;
            }
        }
        return true;
    }
    clone() {
        return new Shape(this.path, this.shapeId, this.tileId, this.name, this.tileData, this.slug, this.width, this.height, this.rotation);
    }
    debugString() {
        return this.name + " #" + this.instanceId;
    }
}
