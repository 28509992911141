import { Pointer } from "./Pointer";
import { Segment, SegmentClass } from "./Segment";
import { dist, getCrossedPointBetweenTwoLines, getHelperPath, getHelperTextAngle, getNewSegmentName, getParentClosedArea, getRemovedBuiltInSegments, getSegmentsOfPoint, getTextPointer, isInShape, isLine, translatePointerWithSegments, } from "../helpers";
import { v4 as uuidv4 } from "uuid";
import { TOLERANCE_DISTANCE } from "src/global/variable";
export var LINE_TYPE;
(function (LINE_TYPE) {
    LINE_TYPE["SOLID"] = "solid";
    LINE_TYPE["DASHED"] = "dashed";
})(LINE_TYPE || (LINE_TYPE = {}));
export class Line extends Segment {
    startPointer;
    endPointer;
    type;
    parentId;
    id;
    zIndex;
    height;
    thick;
    name;
    constructor(startPointer, endPointer, type = LINE_TYPE.SOLID, parentId = 0, id = uuidv4(), zIndex = 0, height = 0, thick = 0, name = "") {
        super(startPointer, endPointer, id, zIndex, name);
        this.startPointer = startPointer;
        this.endPointer = endPointer;
        this.type = type;
        this.parentId = parentId;
        this.id = id;
        this.zIndex = zIndex;
        this.height = height;
        this.thick = thick;
        this.name = name;
        this.class = SegmentClass.LINE;
    }
    generatePath(isFromShape = false, startPointer = undefined, step = 0) {
        const path = ((isFromShape ? "" : "M") +
            (startPointer ?? this.startPointer).x +
            "," +
            (startPointer ?? this.startPointer).y +
            " " +
            "L" +
            (!startPointer || startPointer.equals(this.startPointer)
                ? this.endPointer
                : this.startPointer).x +
            "," +
            (!startPointer || startPointer.equals(this.startPointer)
                ? this.endPointer
                : this.startPointer).y);
        if (!isFromShape && step > 0) {
            const p = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            p.setAttribute('d', path);
            const count = Math.ceil(p.getTotalLength() / step);
            const len = p.getTotalLength() + 1.5;
            const points = Array.from(Array(count), (_, i) => i + 1).map((i) => {
                return p.getPointAtLength(len * i / count);
            });
            const initialOffset = p.getPointAtLength(0);
            return `M ${initialOffset.x} ${initialOffset.y} ${points
                .map(({ x, y }) => `L ${x} ${y}`)
                .join(' ')}`;
        }
        return path;
    }
    getPointsOfBuilding(buildingParts) {
        let points = [];
        buildingParts.forEach((building) => {
            const [pointer1, pointer2, pointer3, pointer4] = building.getRectPoints();
            if (this.isInSegment(pointer1, true, TOLERANCE_DISTANCE))
                points.push(pointer1);
            if (this.isInSegment(pointer2, true, TOLERANCE_DISTANCE))
                points.push(pointer2);
            if (this.isInSegment(pointer3, true, TOLERANCE_DISTANCE))
                points.push(pointer3);
            if (this.isInSegment(pointer4, true, TOLERANCE_DISTANCE))
                points.push(pointer4);
        });
        const sortOrder = this.startPointer.isGreater(this.endPointer) ? -1 : 1;
        if (points.length === 0)
            return [];
        points.sort((a, b) => {
            if (a.x === b.x)
                return sortOrder * (a.y - b.y);
            return sortOrder * (a.x - b.x);
        });
        points = [this.startPointer, ...points, this.endPointer];
        const result = [this.startPointer];
        for (let i = 1; i < points.length; i++) {
            if (dist(points[i - 1], points[i]) < 2)
                continue;
            result.push(points[i]);
        }
        return result;
    }
    generateHelperPath(buildingParts) {
        if (this.startPointer.equals(this.endPointer)) {
            return [];
        }
        const helperPaths = [];
        const points = this.getPointsOfBuilding(buildingParts);
        if (points.length > 2) {
            for (let i = 0; i < points.length - 1; i++)
                helperPaths.push(getHelperPath(points[i], points[i + 1]));
        }
        helperPaths.push(getHelperPath(this.startPointer, this.endPointer, points.length > 2 ? 28 : 7));
        return helperPaths;
    }
    generateHelperTextData(buildingParts) {
        const helperTexts = [];
        const points = this.getPointsOfBuilding(buildingParts);
        if (points.length > 2) {
            for (let i = 0; i < points.length - 1; i++) {
                const textPointer = getTextPointer(points[i], points[i + 1]);
                helperTexts.push({
                    x: textPointer.x,
                    y: textPointer.y,
                    angle: getHelperTextAngle(points[i], points[i + 1]),
                    length: dist(points[i], points[i + 1]),
                });
            }
        }
        const textPointer = getTextPointer(this.startPointer, this.endPointer, points.length > 2 ? 16 : 7);
        helperTexts.push({
            x: textPointer.x,
            y: textPointer.y,
            angle: getHelperTextAngle(this.startPointer, this.endPointer),
            length: this.getLineLength(),
        });
        return helperTexts;
    }
    getRealTransition(dx, dy, svgSize) {
        return { dx, dy };
        // const [leftPointer, rightPointer] =
        //   this.startPointer.x < this.endPointer.x
        //     ? [this.startPointer, this.endPointer]
        //     : [this.endPointer, this.startPointer];
        // const [topPointer, bottomPointer] =
        //   this.startPointer.y < this.endPointer.y
        //     ? [this.startPointer, this.endPointer]
        //     : [this.endPointer, this.startPointer];
        // let realDx, realDy;
        // if (dx > 0) {
        //   realDx =
        //     rightPointer.x + dx < svgSize.w ? dx : svgSize.w - rightPointer.x;
        // } else {
        //   realDx = leftPointer.x + dx > 0 ? dx : -leftPointer.x;
        // }
        // if (dy > 0) {
        //   realDy =
        //     bottomPointer.y + dy < svgSize.h ? dy : svgSize.h - bottomPointer.y;
        // } else {
        //   realDy = topPointer.y + dy > 0 ? dy : -topPointer.y;
        // }
        // return { dx: realDx, dy: realDy };
    }
    getLineAngle() {
        return Math.atan2(-(this.endPointer.y - this.startPointer.y), this.endPointer.x - this.startPointer.x);
    }
    getLineLength() {
        return dist(this.endPointer, this.startPointer);
    }
    translate(dx, dy, segments) {
        const origStart = this.startPointer.translate(0, 0);
        const origEnd = this.endPointer.translate(0, 0);
        let startPointer = translatePointerWithSegments(dx, dy, this.startPointer, getRemovedBuiltInSegments(segments, this), this.isDashed());
        let endPointer = translatePointerWithSegments(dx, dy, this.endPointer, getRemovedBuiltInSegments(segments, this), this.isDashed());
        if (this.isDashed() && this.parentId) {
            const closedArea = getParentClosedArea(segments, this.parentId);
            if (closedArea) {
                let inPointer, outPointer;
                if (!isInShape(startPointer, closedArea.shape)) {
                    outPointer = startPointer;
                }
                else {
                    inPointer = startPointer;
                }
                if (!isInShape(endPointer, closedArea.shape)) {
                    outPointer = endPointer;
                }
                else {
                    inPointer = endPointer;
                }
                if (!inPointer) {
                    startPointer = translatePointerWithSegments(-dx, -dy, startPointer, getRemovedBuiltInSegments(segments, this), this.isDashed());
                    endPointer = translatePointerWithSegments(-dx, -dy, endPointer, getRemovedBuiltInSegments(segments, this), this.isDashed());
                }
                else if (outPointer) {
                    let minLength = dist(inPointer, outPointer);
                    const ownedSegments = getSegmentsOfPoint(segments, outPointer);
                    let newPointer = outPointer;
                    closedArea.shape.results.forEach((segment) => {
                        if (!isLine(segment))
                            return;
                        const line = segment;
                        if (line.isDashed())
                            return;
                        const crossed = getCrossedPointBetweenTwoLines(this, line);
                        if (crossed) {
                            const newlineLen = dist(inPointer, crossed);
                            if (newlineLen < minLength && newlineLen > 0) {
                                newPointer = crossed;
                            }
                        }
                    });
                    ownedSegments.forEach((item) => {
                        if (item.startPointer.equals(outPointer)) {
                            item.startPointer = newPointer;
                        }
                        else if (item.endPointer.equals(outPointer)) {
                            item.endPointer = newPointer;
                        }
                    });
                    endPointer = inPointer === startPointer ? newPointer : inPointer;
                    startPointer = inPointer === startPointer ? inPointer : newPointer;
                }
                /* temporary not use
                // move control point
                let segmentsOnPoint = getSegmentsOfPoint(segments, origStart).filter((v) => v.id !== this.id);
                if( segmentsOnPoint.length === 2 && isLine(segmentsOnPoint[0]) && isLine(segmentsOnPoint[1]) ) {
                  const line1 = segmentsOnPoint[0] as Line;
                  const line2 = segmentsOnPoint[1] as Line;
                  
                  let angle1 = line1.getLineAngle()
                  let angle2 = line2.getLineAngle()
                  if( angle1 < 0 ) angle1 += Math.PI
                  if( angle2 < 0 ) angle2 += Math.PI
        
                  if(!line1.isDashed() && !line2.isDashed() && angle1 === angle2) {
                    let extendLine = segments.find((v) => v.id === line1.id)
                    if( extendLine ) {
                      if( extendLine.startPointer.equals(origStart) ) {
                        extendLine.startPointer = startPointer.translate(0, 0);
                      } else if( extendLine.endPointer.equals(origStart) ) {
                        extendLine.endPointer = startPointer.translate(0, 0);
                      }
                    }
        
                    extendLine = segments.find((v) => v.id === line2.id)
                    if( extendLine ) {
                      if( extendLine.startPointer.equals(origStart) ) {
                        extendLine.startPointer = startPointer.translate(0, 0);
                      } else if( extendLine.endPointer.equals(origStart) ) {
                        extendLine.endPointer = startPointer.translate(0, 0);
                      }
                    }
                  }
                }
                
                segmentsOnPoint = getSegmentsOfPoint(segments, origEnd).filter((v) => v.id !== this.id);
                if( segmentsOnPoint.length === 2 && isLine(segmentsOnPoint[0]) && isLine(segmentsOnPoint[1]) ) {
                  const line1 = segmentsOnPoint[0] as Line;
                  const line2 = segmentsOnPoint[1] as Line;
        
                  let angle1 = line1.getLineAngle()
                  let angle2 = line2.getLineAngle()
                  if( angle1 < 0 ) angle1 += Math.PI
                  if( angle2 < 0 ) angle2 += Math.PI
        
                  if(!line1.isDashed() && !line2.isDashed() && angle1 === angle2) {
                    let extendLine = segments.find((v) => v.id === line1.id)
                    if( extendLine ) {
                      if( extendLine.startPointer.equals(origEnd) ) {
                        extendLine.startPointer = endPointer.translate(0, 0);
                      } else if( extendLine.endPointer.equals(origEnd) ) {
                        extendLine.endPointer = endPointer.translate(0, 0);
                      }
                    }
        
                    extendLine = segments.find((v) => v.id === line2.id)
                    if( extendLine ) {
                      if( extendLine.startPointer.equals(origEnd) ) {
                        extendLine.startPointer = endPointer.translate(0, 0);
                      } else if( extendLine.endPointer.equals(origEnd) ) {
                        extendLine.endPointer = endPointer.translate(0, 0);
                      }
                    }
                  }
                }
                */
            }
        }
        this.startPointer = startPointer;
        this.endPointer = endPointer;
    }
    splitLine(count, segments) {
        const splitedLineArray = Array();
        const dx = (this.endPointer.x - this.startPointer.x) / count;
        const dy = (this.endPointer.y - this.startPointer.y) / count;
        const newSegments = [...segments];
        for (let i = 0; i < count; i++) {
            const startPointer = new Pointer(this.startPointer.x + i * dx, this.startPointer.y + i * dy);
            const endPointer = i < count - 1 ?
                new Pointer(this.startPointer.x + (i + 1) * dx, this.startPointer.y + (i + 1) * dy) :
                new Pointer(this.endPointer.x, this.endPointer.y);
            const line = new Line(startPointer, endPointer);
            line.name = getNewSegmentName(this.name + "-", newSegments);
            newSegments.push(line);
            splitedLineArray.push(line);
        }
        return splitedLineArray;
    }
    getLeftPointer() {
        if (this.startPointer.x === this.endPointer.x) {
            return this.startPointer.y <= this.endPointer.y
                ? this.startPointer
                : this.endPointer;
        }
        if (this.startPointer.x <= this.endPointer.x) {
            return this.startPointer;
        }
        return this.endPointer;
    }
    getRightPointer() {
        if (this.startPointer.x === this.endPointer.x) {
            return this.startPointer.y <= this.endPointer.y
                ? this.endPointer
                : this.startPointer;
        }
        if (this.startPointer.x <= this.endPointer.x) {
            return this.endPointer;
        }
        return this.startPointer;
    }
    getCenterPointer() {
        return new Pointer((this.startPointer.x + this.endPointer.x) / 2, (this.startPointer.y + this.endPointer.y) / 2);
    }
    getPointsArray() {
        return [];
    }
    isInSegment(pointer, acceptInPath = true, tolerance = TOLERANCE_DISTANCE / 2) {
        if (pointer.x < (Math.min(this.startPointer.x, this.endPointer.x) - tolerance) ||
            pointer.x > (Math.max(this.startPointer.x, this.endPointer.x) + tolerance) ||
            pointer.y < (Math.min(this.startPointer.y, this.endPointer.y) - tolerance) ||
            pointer.y > (Math.max(this.startPointer.y, this.endPointer.y) + tolerance))
            return false;
        const pointerY = Math.tan(Math.atan2(this.endPointer.y - this.startPointer.y, this.endPointer.x - this.startPointer.x)) *
            (pointer.x - this.startPointer.x) +
            this.startPointer.y;
        const pointerX = Math.tan(Math.atan2(this.endPointer.x - this.startPointer.x, this.endPointer.y - this.startPointer.y)) *
            (pointer.y - this.startPointer.y) +
            this.startPointer.x;
        if (acceptInPath && Math.abs(pointer.x - this.startPointer.x) < tolerance)
            return Math.abs(pointerX - pointer.x) < tolerance;
        if (acceptInPath)
            return Math.abs(pointerY - pointer.y) < tolerance;
        return (pointer.x >= Math.min(this.startPointer.x, this.endPointer.x) &&
            pointer.x <= Math.max(this.startPointer.x, this.endPointer.x));
    }
    clone() {
        return new Line(new Pointer(this.startPointer.x, this.startPointer.y), new Pointer(this.endPointer.x, this.endPointer.y), this.type, this.parentId, this.id, this.zIndex, this.height, this.thick, this.name);
    }
    reverse() {
        return new Line(this.endPointer, this.startPointer, this.type, this.parentId, this.id, this.zIndex, this.height, this.thick, this.name);
    }
    isDashed() {
        return this.type === LINE_TYPE.DASHED;
    }
    toJSON() {
        return {
            ...super.toJSON(),
            type: this.type,
            parentId: this.parentId,
            height: this.height,
            thick: this.thick,
        };
    }
}
