<script lang="ts">
  import { drawSend, drawState } from "../../layout.store";
  import ConnectionPoint from "../icons/ConnectionPoint.svelte";
  import IconSvg from "../base/IconSVG.svelte";
  import PlusFilledIcon from "../icons/PlusFilledIcon.svelte";

  import type { ConnectionPointsResultType } from "../../tools/LayoutTools";
  import { evalRepeatConnectionPoints } from "../../tools/LayoutTools";

  let connectPoints: ConnectionPointsResultType;
  $: {
    connectPoints = evalRepeatConnectionPoints(
      $drawState.context.layoutContext.addTileContext.initLayout,
      $drawState.context.layoutContext.addTileContext.addPoint
    );
  }
</script>

{#if $drawState.matches("layoutDesign.showLayout.layoutState.showRepeatConnectPoints")}
  {#each connectPoints.pts as { x, y, rotation, edge }}
    <IconSvg
      {x}
      {y}
      rotationDeg={rotation}
      on:mouseover={() => drawSend({ type: "SHOW_REPEAT_PREVIEW", edge })}
      on:mouseleave={() => drawSend({ type: "HIDE_REPEAT_PREVIEW" })}
      on:click={() => drawSend({ type: "REPEAT_CONNECTION_SELECTION", edge })}
    >
      <ConnectionPoint />
    </IconSvg>
  {/each}
  {#if connectPoints.originPt}
    <IconSvg
      x={connectPoints.originPt.x}
      y={connectPoints.originPt.y}
      passive={true}
    >
      <PlusFilledIcon />
    </IconSvg>
  {/if}
{/if}
