export default class LazyArray {
    generator;
    backingArray = [];
    finished = false;
    constructor(generator) {
        this.generator = generator;
    }
    get(index) {
        while (index >= this.backingArray.length) {
            const next = this.generator.next();
            if (next.done) {
                this.finished = true;
                return undefined;
            }
            this.backingArray.push(next.value);
        }
        return this.backingArray[index];
    }
    takeWhile(cond) {
        let i = 0;
        let v = this.get(i);
        const result = new Array();
        while (v !== undefined && cond(v)) {
            i++;
            result.push(v);
            v = this.get(i);
        }
        return result;
    }
    some(cond) {
        let i = 0;
        let result = false;
        while (!result) {
            const v = this.get(i);
            if (v === undefined) {
                break;
            }
            result = result || cond(v);
            i++;
        }
        return result;
    }
    findIndex(pred) {
        let i = 0;
        while (true) {
            const v = this.get(i);
            if (v === undefined) {
                return undefined;
            }
            if (pred(v)) {
                return i;
            }
            i++;
        }
    }
    map(mapper) {
        this.realizeAll();
        return this.backingArray.map(mapper);
    }
    get length() {
        this.realizeAll();
        return this.backingArray.length;
    }
    get isFullyRealized() {
        return this.finished;
    }
    get realizedLength() {
        return this.backingArray.length;
    }
    get all() {
        this.realizeAll();
        return this.backingArray;
    }
    realizeAll() {
        if (this.finished) {
            return;
        }
        let i = this.backingArray.length;
        while (!this.finished) {
            this.get(i);
            i++;
        }
    }
}
