<script lang="ts">
  import { activeMenu, editSegment, selectedRoom, visibleSideMenu } from "src/store";
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import Header from "./Header.svelte";
  import Button from "../../base/Button.svelte";
  import { isTileWrapper } from "src/helpers";
  import type { TileWrapper } from "src/model";
  import SegmentList from "./SegmentList.svelte";
  import { SEGMENT_LIST_TYPE, SIDE_MENUS } from "src/global/types";
  import AreaInfo from "./AreaInfo.svelte";
  import { onDestroy, onMount } from "svelte";
  
  enum AREA_VIEW {
    INDEX,
    AREA_DETAIL,
  }

  let routes : AREA_VIEW[] = [AREA_VIEW.INDEX];

  let currentView: AREA_VIEW = AREA_VIEW.INDEX;
  let headerInfo = {
    title: "",
    info: "",
    path: "",
    editable: false,
  };
  let currentArea: TileWrapper
  let unsubscribe
  onMount(() => {
    unsubscribe = visibleSideMenu.subscribe((visible) => {
      if( $activeMenu !== SIDE_MENUS.AREA )
        return;
      if( !visible ) {
        handleBack();
        routes = [AREA_VIEW.INDEX];
        currentView = AREA_VIEW.INDEX;
      } else if ($editSegment) {
        if( isTileWrapper($editSegment) ) {
          routes = [AREA_VIEW.INDEX, AREA_VIEW.AREA_DETAIL];
          currentView = AREA_VIEW.AREA_DETAIL
        } else {
          routes = [AREA_VIEW.INDEX];
          currentView = AREA_VIEW.INDEX;
        }
        editSegment.set(undefined);
      }
    })
  })
  
  $: areas = $drawState.context.current.segments.filter((segment) =>
    isTileWrapper(segment)
  ).sort((a, b) => a.name.localeCompare(b.name)) as TileWrapper[];
  
  const handleSelectArea = (e: CustomEvent) => {
    currentArea = e.detail.segment
    handleNext(AREA_VIEW.AREA_DETAIL);
  };

  const handleNext = (view: AREA_VIEW) => {
    currentView = view;
    routes.push(currentView);
  };

  const handleBack = () => {
    routes.pop()
    if( routes.length )
      currentView = routes[routes.length - 1]
  };

  $: {
    switch (currentView) {
      case AREA_VIEW.INDEX:
        headerInfo = {
          title: $_("side_menu.area.list_area"),
          info: "",
          path: "",
          editable: false,
        };
        break;
      case AREA_VIEW.AREA_DETAIL:
        headerInfo = {
          title: currentArea?.getName(),
          info: "",
          path: `${$_("side_menu.areas")} > ${$_("side_menu.area.edit_area")}`,
          editable: true,
        };
        break;
    }
  }

  const handleTitleChange = (e) => {
    switch(currentView) {
      case AREA_VIEW.AREA_DETAIL:
        currentArea.name = e.detail.value;
        drawSend({
          type: "CHANGE_NAME",
          name: e.detail.value,
        });
      break;
    }
  }

  onMount(() => {
    const selectedObj = $drawState.context.dragContext.selectedObject;
    if (selectedObj && isTileWrapper(selectedObj))
      currentArea = selectedObj as TileWrapper;
  })

  onDestroy(() => {
    if( unsubscribe ) unsubscribe();
  })

</script>

<div class="flex flex-col h-full">
  <Header {...headerInfo} on:change={handleTitleChange}/>

  {#if currentView !== AREA_VIEW.INDEX}
    <div class="flex items-center gap-1 px-3 hover:text-primary-500 cursor-pointer" on:click={handleBack}>
      <i class="fa-regular fa-chevron-left text-sm" />
      <span class="font-medium text-sm">{$_("side_menu.back")}</span>
    </div>
  {/if}
  <div class="w-full flex flex-col flex-1 p-3 overflow-auto gap-4">
    {#if currentView === AREA_VIEW.INDEX}
      <SegmentList type={SEGMENT_LIST_TYPE.AREA} segments={areas} on:select={handleSelectArea} />
    {:else if currentView === AREA_VIEW.AREA_DETAIL}
      <AreaInfo />
    {/if}
  </div>
</div>
