<svelte:options immutable={true} />

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  export let className = "";
  export let label = "";
  export let indeterminate: boolean = false;
  export let checked: boolean = false;
  export let disabled: boolean = false;

  export { className as class };

  const dispatch = createEventDispatcher();

  const handleClick = () => {
    if ( disabled ) return;
    checked = !checked;
    dispatch('change', checked)
  }
</script>

<div
  class={`${className} flex items-center justify-start text-sm group cursor-pointer`} 
  on:click={handleClick}
>
  <div class={`mr-2 flex h-4 w-4 items-center justify-center rounded-sm leading-none text-white ${checked || indeterminate ? 'bg-primary-500' : 'border-2 border-gray-200'} group-disabled:bg-gray-300`}>
    {#if indeterminate}
      <i class="fa fa-hyphen text-sm" style="line-height: 16px"/>
    {:else if checked}
      <i class="fa fa-check text-sm" style="line-height: 16px"/>
    {/if}
  </div>
  <div class="text-base py-2 lg:py-0.5">{label}</div>
</div>