<script lang="ts">
  import Button from "./base/Button.svelte";
  import { _ } from "../services/i18n";
  import router from "page";
  import routes, { ROUTE } from "../router";
  import { PROJECT_SHAPE } from "../global/variable";
  import { drawState, drawSend } from "src/layout.store";
  import { register } from 'swiper/element/bundle';
  import Input from "./base/Input.svelte";
  import Select from "./base/Select.svelte";
  import Login from "./toolbox/Login.svelte";
  import { PAGES } from "src/global/types";
  import { currentTool, homeShape, currentUser, loadSuccess, recentLayouts } from "src/store";
  import { getProjectsList } from "src/services/api";
  import ProjectCard from "./dialogs/ProjectCard.svelte";
  import Loading from "./util/Loading.svelte";
  import { debounce } from "lodash";
  import { onMount } from "svelte";
  import Pagination from "./base/Pagination.svelte";
  import LocaleSelect from "./toolbox/LocaleSelect.svelte";

  register();

  export let page : PAGES;
  export let open: Function;

  const DEFAULT_SIZE = '9';
  
  let swiperEl;
  let isSwiperBeginning;
  let isSwiperEnd;
  let projectName = '';
  let square = DEFAULT_SIZE;
  let typology;
  let projectsLoading: boolean = false;

  let pagination, itemsPerPage: number = 10, currentPage: number = 1;
  let textSearch;

  let recentProjectsList: {
    total: number,
    total_pages: number,
    projects: any[]
  };
  let projectsList: {
    total: number,
    total_pages: number,
    projects: any[]
  };

  const onSlideChange = (e) => {
    $homeShape = e.detail[0].activeIndex;
  }

  $: isSwiperBeginning = $homeShape === 0
  $: isSwiperEnd = $homeShape === PROJECT_SHAPE.length - 1

  let fileInput;
  let files : FileList;

  $: if (files) {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        open(JSON.parse(reader.result));
      }
      fileInput.value = null;
    };
    reader.readAsText(files[0]);
  }

  const handleFilterByName = debounce(e => {

    textSearch = e.target.value.length > 0 ? e.target.value : undefined;
    updateProjectsList();

  }, 300);

  const updateRecentProjectsList = () => {
    projectsLoading = true;
    let queryParams : { limit?: number, page?: number; textSearch?: string } = {
      limit: 10,
      page: 0,
      textSearch: textSearch
    };

    getProjectsList(queryParams).then((res) => updateProjectsListSuccess(res)).catch(() => projectsLoading = false)

    function updateProjectsListSuccess (res: any) {
      projectsLoading = false;
      recentProjectsList = res?.data;
    }
  }

  const updateProjectsList = debounce((resetPagination: boolean = true) => {
    projectsLoading = true;
    let queryParams : { limit?: number, page?: number; textSearch?: string } = {
      limit: itemsPerPage,
      page: resetPagination ? currentPage = 1 : currentPage,
      textSearch: textSearch
    };

    getProjectsList(queryParams).then((res) => updateProjectsListSuccess(res)).catch(() => projectsLoading = false)

    function updateProjectsListSuccess (res: any) {
      projectsLoading = false;
      projectsList = res?.data;
      if (resetPagination && pagination)
        pagination.resetPagination(projectsList?.total);
    }
  }, 500)

  const onClickNewProject = () => {
    drawSend({ type: "EMPTY_PROJECT" });
    // tick().then(() => drawSend({ type: "LOAD_SUCCESS" }) );
    router.show(routes[ROUTE.BOARD].path)
  }

  const goToHome = () => {
    router.show(routes[ROUTE.HOME].path)
  }

  const goToBoard = () => {
    router.show(routes[ROUTE.BOARD].path)
  }

  const showProjectsList = () => {
    updateProjectsList();
    router.show(routes[ROUTE.PROJECTS_LIST].path);
  }

  const handleQuickStart = (template, index) => {
    $homeShape = index;
    router.show(routes[ROUTE.NEW_PROJECT_STEP_2].path)
  }

  const handleCreateProject = () => {
    if( +square <= 0 ) return;

    drawSend({
      type: "CREATE_ROOM",
      projectName: projectName,
      roomType: PROJECT_SHAPE[$homeShape].name,
      roomSurface: +square
    });
    router.show(routes[ROUTE.BOARD].path)
    $currentTool = undefined;
    $recentLayouts = []
  }
  
  const Typology = [
    { label: 'Bed Room', value: 'bed-room' },
    { label: 'Drawing Room', value: 'drawing-room' },
  ]

  onMount(async () => {

    if (page === PAGES.PROJECTS_LIST)
      updateProjectsList();
  })

  $: {
    if( $currentUser )
      updateRecentProjectsList();
    else 
      recentProjectsList = {
        total: 0,
        total_pages: 0,
        projects: []
      }
  }

</script>
<div class="relative w-full min-h-full">
  {#if $drawState.matches("main.preload") || $drawState.matches("main.loading")}
  <div class="flex h-full">
    <img
      id="cursor-load"
      alt="cursor"
      src="/cursor-load.jpg"
    />
  </div>  
  {:else}
  <div class="flex h-full gap-4 lg:gap-16">
    <div class="left-panel flex flex-col flex-shrink-0 gap-4 h-full">
      <div class="flex flex-col mb-20 items-center">
        <img src="/logo-tilelook-verticale.svg" alt="logo"/>
        <div class="inline bg-primary-300 text-secondary-500 font-medium rounded-md py-1 px-2 -mt-6">
          BETA
        </div>
      </div>

      <div class="mt-20">
        <Button variant="outline-secondary" title={$_("home.new_project")} icon="fa-solid fa-add" class="menu-button mx-auto mb-4" on:click={onClickNewProject}/>
        {#if page !== PAGES.PROJECTS_LIST && $currentUser && $loadSuccess}
        <Button variant="outline-secondary" title={$_("home.open_project")} icon="fa-solid fa-folder" class="menu-button mx-auto mb-4" on:click={showProjectsList}/>
        {/if}
        <Button variant="outline-secondary" title={$_("home.go_to_board")} icon="fa-solid fa-ruler-triangle" class="menu-button mx-auto mb-4" on:click={goToBoard}/>
        {#if page !== PAGES.NEW_PROJECT_STEP_1}
        <Button variant="outline-secondary" title={$_("home.go_to_home")} icon="fa-solid fa-home" class="menu-button mx-auto mb-4" on:click={goToHome}/>
        {/if}
        <!-- <Button variant="outline-secondary" title={$_("home.import_project")} icon="fa-solid fa-file-import" class="menu-button mx-auto mb-4"/> -->
      </div>
      <div class="mt-auto">
        <!-- <Button variant="outline-secondary" title={$_("home.to_shop")} icon="fa-solid fa-arrow-right" class="menu-button mx-auto"/> -->
      </div>
    </div>
    <div class="right-panel flex flex-col flex-auto min-h-full pb-8">
      <div class="flex justify-end gap-3 mb-4">
        <LocaleSelect />
        <Login path={routes[ROUTE.HOME].path}/>
      </div>
      {#if page === PAGES.NEW_PROJECT_STEP_1}
      <div class="text-2xl font-bold mb-5">{$_("home.quick_start")}</div>
      <div class="grid 2xl:grid-cols-8 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4">
        {#each PROJECT_SHAPE as s, i}
          <div class="flex flex-col items-center">
            <div
              class="w-32 h-32 overflow-hidden cursor-pointer
              border-2 border-white rounded-xl p-2
              transition-colors duration-300
              hover:border-primary-500"
              on:click={() => handleQuickStart(s, i)}
              on:keydown={() => handleQuickStart(s, i)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={`-3 -3 ${s.width + 6} ${s.height + 6}`}
                stroke="black"
                stroke-width="3px"
                fill="white"
              >
                {@html s.path}
              </svg>
            </div>
            <div class="text-center text-sm mt-3">{s.name}</div>
          </div>
        {/each}
      </div>      
        {#if $currentUser && recentProjectsList && recentProjectsList.total > 0}
        <div class="w-full mt-16">
          <div class="text-2xl font-bold mb-5">{$_("home.recent_projects")}</div>
          <!-- <Input type="search" on:input={handleBrandFilter} value={brandFilter} icon="fa-solid fa-magnifying-glass" class="mb-2" fullWidth /> -->
          <ul
            role="listbox"
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-4 gap-y-4 pr-4 overflow-auto"
          >
            {#each recentProjectsList.projects as project (project.slug)}
              <li>
                <ProjectCard project={project} onLoad={(res) => open(res)} />
              </li>
            {/each}
          </ul>
        </div>
        {/if}
      {:else if page === PAGES.NEW_PROJECT_STEP_2}
        <div class="text-2xl font-bold mb-5">{$_("home.quick_start")}</div>
        <div class="flex gap-8 mt-8">
          <div class="flex items-center justify-center flex-auto">
            <div class="relative w-48">
              <div class="absolute top-24 -left-16 transform -translate-y-1/2">
                <i class={`fa fa-chevron-left font-black text-5xl cursor-pointer ${isSwiperBeginning ? 'text-gray-400' : 'text-secondary-500' }`} 
                          on:click={() => swiperEl.swiper.slidePrev()} on:keydown/>
              </div>
              <div class="absolute top-24 -right-16 transform -translate-y-1/2">
                <i class={`fa fa-chevron-right font-black text-5xl cursor-pointer ${isSwiperEnd ? 'text-gray-400' : 'text-secondary-500' }`} 
                          on:click={() => swiperEl.swiper.slideNext()} on:keydown/>
              </div>
              <swiper-container
                slides-per-view={1}
                space-between={10}
                centered-slides={true}
                initial-slide={$homeShape}
                on:slidechange={onSlideChange}
                bind:this={swiperEl}
              >
                {#each PROJECT_SHAPE as s}
                  <swiper-slide>
                    <div class="w-48 h-48 overflow-hidden mx-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={`-3 -3 ${s.width + 6} ${s.height + 6}`}
                        stroke="black"
                        stroke-width="3px"
                        fill="white"
                      >
                        {@html s.path}
                      </svg>
                    </div>
                    <div class="text-center text-sm mt-3">{s.name}</div>
                  </swiper-slide>
                {/each}
              </swiper-container>
            </div>
          </div>
          <div class="flex-auto">
            <Input label={$_("home.name")} class="mb-5" inputProps="w-home-input" value={projectName} on:input={(e) => projectName = e.target.value}/> 
            <Input label={$_("home.square")} type="number" class="mb-5" inputProps="w-home-input" value={square} on:input={(e) => square = e.target.value} >
              <div slot="icon" class="text-md font-bold">m²</div>
            </Input>
            <!-- <Select label={$_("home.typology")} class="mb-5" options={Typology} value={typology} inputProps="w-home-input"/> -->
            <Button variant="secondary" title={$_("home.create_project")} class="w-home-input" on:click={handleCreateProject}/>
          </div>
        </div>
      {:else if page === PAGES.PROJECTS_LIST}
        <div class="text-2xl font-bold mb-5">{$_("home.my_projects")}</div>
        <Input class="mb-4" type="search" on:input={handleFilterByName} value={textSearch ?? ''} placeholder={$_("home.search")} icon="fa-solid fa-magnifying-glass"/>            
        {#if projectsLoading}
          <div class="flex h-full justify-center items-center">
            <Loading />
          </div>
        {:else if projectsList && projectsList.total === 0}
          <div class="flex h-full justify-center items-center">
            <div class="text-lg font-bold">{$_("home.no_projects")}</div>
          </div>
        {:else if projectsList}
          <ul
            role="listbox"
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-4 gap-y-4 pr-4 overflow-auto"
          >
            {#each projectsList.projects as project (project.slug)}
              <li>
                <ProjectCard project={project} onLoad={(res) => open(res)} onDelete={() => updateProjectsList()} />
              </li>
            {/each}
          </ul>
          
          <Pagination bind:totalItems={projectsList.total} bind:itemsPerPage={itemsPerPage} bind:currentPage={currentPage}
                      bind:this={pagination} onPageChange={() => updateProjectsList(false)}></Pagination>
        
        {/if}
      {/if}
    </div>
  </div>
  {/if}
</div>

<style global>

  #cursor-load {
    position: absolute;
    width: 30px;
    height: 30px;
    cursor: none;
    pointer-events: none;
    user-select: none;
    left: 50%; 
    top: 50%; 
    transform: translate(-50%, -50%);
  }

  .left-panel {
    padding: 100px 35px 80px;
  }

  .right-panel {
    padding-right: 35px;
  }

  .menu-button {
    width: 280px
  }
</style>