<script lang="ts">
  import { drawState, drawSend } from "../../layout.store";
  import { Pointer, BuildingPart } from "../../model";
  import {
    convertPointerToViewBox,
    getBuildingElement,
    getHelperPath,
    makePointerInSvgBox,
    getParentLine,
    dist,
    getTextPointer,
    getHelperTextAngle,
    getMetricWithUnit,
  } from "../../helpers";
  import { currentTool } from "src/store";
  import { TOOLS } from "src/global/types";

  export let segment: BuildingPart;
  export let svgRef: any;
  export let svgSize: any;
  export let scale: number;

  $: selectedObject = $drawState.context.dragContext.selectedObject;
  $: buildingElement = getBuildingElement(segment.buildingId);
  $: snapInfos = segment.getSnapInfos($drawState.context.current.segments);

  const mouseDown = (e: any) => {
    if( $currentTool === TOOLS.EYE_DROPPER ) {
      return;
    }
    let pointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );
    pointer = convertPointerToViewBox(pointer, svgRef);
    pointer = makePointerInSvgBox(pointer, svgSize);
    drawSend({ type: "ENTER_SELECT", segment: segment });
    drawSend({
      type: "UPDATE_OFFSET",
      pointer,
    });
    drawSend({ type: "SHOW_LINE_TOOL" });
  };

  const segmentHovered = () => {
    if ($drawState.matches("main.drawingState")) return;
    drawSend({ type: "MOUSE_HOVER" });
  };

  const segmentMouseLeave = () => {
    if ($drawState.matches("main.drawingState")) return;
    drawSend({ type: "MOUSE_LEAVE" });
  };

  const getRotation = () => {
    return segment.rotation;
  };
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
{#if !!buildingElement}
<g>
  <g
    transform={`rotate(${snapInfos.deltaAngle} ${
      snapInfos.snapPointer?.x ?? 0
    } ${snapInfos.snapPointer?.y ?? 0}) translate(${
      snapInfos.distance.deltaX
    } ${snapInfos.distance.deltaY}) rotate(${getRotation()} ${
      segment.startPointer.x + segment.width / 2
    } ${segment.startPointer.y + segment.length / 2}) matrix(${
      segment.width / buildingElement.width
    }, 0, 0, ${segment.length / buildingElement.height}, ${
      segment.startPointer.x
    }, ${segment.startPointer.y - 0.75})`}
    class={`${
      selectedObject === segment ? "segment_path_selected" : ""
    } segment_path segment`}
    on:mousedown={(e) => mouseDown(e)}
    on:mouseover={() => segmentHovered()}
    on:mouseleave={() => segmentMouseLeave()}
  >
    {@html buildingElement.path}
  </g>
  {#if $drawState.matches("helper.showHelper") && selectedObject?.id === segment.id}
    {#if $drawState.matches("main.selectState.resizing")}
      <g
        transform={`rotate(${snapInfos.deltaAngle} ${
          snapInfos.snapPointer?.x ?? 0
        } ${snapInfos.snapPointer?.y ?? 0}) translate(${
          snapInfos.distance.deltaX
        } ${snapInfos.distance.deltaY})`}
      >
        {#each segment.generateHelperPath() as helperItem}
          <path
            d={helperItem}
            fill="none"
            stroke-linecap="round"
            stroke="grey"
            stroke-width="0.3px"
          />
        {/each}
        {#each segment.generateHelperTextData() as helperTextItem, j}
          <g>
            {#if helperTextItem}
              <text
                class="stroke-text"
                transform="translate({helperTextItem.x}, {helperTextItem.y}) rotate({helperTextItem.angle})"
                style={`font-size: ${16 / scale}px;`}
              >
                {getMetricWithUnit(
                  Math.round(helperTextItem.length * 100) / 100,
                  $drawState.context.currentMetricUnit,
                  false,
                  true
                )}
              </text>
            {/if}
          </g>
        {/each}
      </g>
    {/if}
  {/if}
</g>
{/if}

<style>
  text {
    font: 5px sans-serif;
    text-anchor: middle;
    pointer-events: none;
    user-select: none;
  }
</style>
