<script lang="ts">

  import { errMissingParts } from "src/store";
  import { get } from "svelte/store";
  import { getNotificationsContext } from 'svelte-notifications';

  const { addNotification } = getNotificationsContext();

  export const handleError = (
    msg : {
      layout?: number,
      tile?: number,
      finished?: boolean
    }
  ) => {

    let store = get(errMissingParts);

    if (msg.finished)
    {
      if (store.layout.length > 0 || store.tile.length > 0)
      {
        addNotification({
          type: 'error',
          removeAfter: 5000,
          text: "Some items in your drawing are missing",
          position: 'bottom-center',
        });

        if (store.layout.length)
          console.log("Missing layouts", store.layout);

        if (store.tile.length)
          console.log("Missing tiles", store.tile);
      }
    }
    else
    {
      if (msg.layout)
      {
        if (!store.layout.find((v) => v === msg.layout))
          store.layout.push(msg.layout);
      }
      else if( msg.tile )
      {
        if (!store.tile.find((v) => v === msg.tile))
          store.tile.push(msg.tile);
      }
    }
  }

</script>