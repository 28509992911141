<script lang="ts">
  import { afterUpdate, onMount, tick } from "svelte";
  import type { ContentColumns, TDocumentDefinitions } from "pdfmake/interfaces";
  import htmlToCanvas from 'html2canvas';
  import { merge, isEqual } from 'lodash';
  import { editTool, currentTool, accessToken, expireTime, visibleSideMenu, selectedRoom, previewImage, errMissingParts } from "../store";
  import ActionMenu from "./toolbox/ActionMenu.svelte";
  import EditToolbox from "./toolbox/EditToolbox.svelte";
  import SegmentSvg from "./buildings/SegmentSvg.svelte";
  import PointSvg from "./buildings/PointSvg.svelte";
  import DoorSvg from "./buildings/DoorSvg.svelte";
  import BuildingPartSvg from "./buildings/BuildingPartSvg.svelte";
  import TileWrapperSvg from "./buildings/TileWrapperSvg.svelte";
  import TransformIndicator from "./buildings/TransformIndicator.svelte";
  import ResizeIndicator from "./buildings/ResizeIndicator.svelte";
  import LayoutRendering from "./tiles/LayoutRendering.svelte";
  import HomePage from "./Home.svelte";
  import { PAGES } from "../global/types";
  import {
    Line,
    Arc,
    Pointer,
    Segment,
    Door,
    BuildingPart,
    TileWrapper,
    ClosedArea,
  } from "../model";
  import type { CategoryElement } from "../model/CategoryElement";
  import {
    checkValidMovePointer,
    checkValidMoveSegment,
    convertPointerToViewBox,
    makePointerInSvgBox,
    getGlobalHelperPath,
    getSegmentsOfPoint,
    convertPointerToClientBox,
    getShapeId,
    getShapePath,
    getTileLayouts,
    convertMousePointerToToolPosition,
    getMetricWithUnit,
    isBuildingPart,
    isLine,
    isDoor,
    isArc,
    isTileWrapper,
    castTileWrapper,
    getRoundedAngle,
    convertUnit,
    isClosedArea,
    rgbToHex,
    getShapeBoundingRect,
    getLayoutShapes,
    tileInShape,
    area,
    mergeBoundingRect,
    getBoundingRect,
    getSquareWithUnit,
  } from "../helpers";
  import {
    MAX_ZOOM_RATE,
    MIN_ZOOM_RATE,
    ELEMENTS_IDS,
    METRIC_UNITS,
    PRECISION_UNITS,
    DEFAULT_PAGE_HEIGHT,
    DEFAULT_PAGE_WIDTH,
    TILE_TRANSFORM_SCALE
  } from "../global/variable";
  import { TOOLS } from "src/global/types";
  import Toolbox from "./toolbox/Toolbox.svelte";
  import { drawSend, drawService, drawState, storedDrawState } from "../layout.store";
  import { _ } from "../services/i18n";
  import { createSampleShapes } from "src/model/tile/Tools";
  import { getElementCategories, getLayoutGeometries } from "src/services/api";
  import TileWrapperInfo from "./toolbox/TileWrapperInfo.svelte";
  import SidePanel from "./toolbox/SidePanel.svelte";
  import type { LayoutGeometry } from "../store/drawMachine"
  import Button from "./base/Button.svelte";
  import pdfMake from "../helpers/pdfMake"
  import SelectTile from "./dialogs/SelectTile.svelte";
  import WebGLRenderer from "src/model/WebGLRenderer";
  import { limitLayoutTileDepthForDrag } from "../tools/LayoutTools";
  import router from "page";
  import routes, { ROUTE } from "../router";
  import { getNotificationsContext } from 'svelte-notifications';
  import Notifications from "./util/Notifications.svelte";
  import Cursor from "./Cursor.svelte";
  import SplitToolbox from "./toolbox/SplitToolbox.svelte";
  import LineLengthToolbox from "./toolbox/LineLengthToolbox.svelte";
  
  export let page : PAGES = PAGES.NEW_PROJECT_STEP_1;

  let notifications: Notifications;
  let svgRef: any;
  let rootRef: any;
  let svgWrapperRef: any;
  let svgSize = { w: 0, h: 0 };
  let viewBox = { x: 0, y: 0, w: 0, h: 0 }
  let savedViewBox = null
  let scale = 1.0;
  let prevStateOfPrint: boolean = false;
  let prevStateOfSave: boolean = false;

  $: startPointer = $drawState.context.drawContext.startPointer;
  $: drawingObject = $drawState.context.drawContext.drawingObject;
  $: selectedObject = $drawState.context.dragContext.selectedObject;
  $: selectedCPObj = $drawState.context.dragContext.selectedCPObj;
  $: offset = $drawState.context.dragContext.offset;
  $: splitedLineArray = $drawState.context.splitContext.splitedLineArray;
  $: snapPointer = $drawState.context.snapContext.snapPointer;
  $: snapType = $drawState.context.snapContext.snapType;
  let segmentArray: Segment[] = [];
  let lineLength: string | number | null = $drawState.context.lineLength;

  let lineInputRef: any = undefined;
  let isFirstLengthInput = true;
  let bMoused = false;
  
  let eyedropperRef: any;
  let eyedropperZoomRef: any;
  let isEyeDropper = false;

  let categories: CategoryElement[];
  let showCursor: boolean = true;  
  let layout: WebGLRenderer = new WebGLRenderer();
  let tileWrappers: TileWrapper[] = [];

  $: loadingAsyncSegments = $drawState.context.loadingAsyncSegments;

  $: getGlobalHelpPath = (): string => {
    if (drawingObject) {
      return getGlobalHelperPath(
        segmentArray,
        [drawingObject],
        [drawingObject.endPointer],
        viewBox
      );
    } else if (selectedCPObj && bMoused) {
      return getGlobalHelperPath(
        segmentArray,
        getSegmentsOfPoint(segmentArray, selectedCPObj),
        [selectedCPObj],
        viewBox
      );
    } else if (selectedObject && isLine(selectedObject) && bMoused) {
      return getGlobalHelperPath(
        segmentArray,
        [selectedObject],
        [selectedObject.startPointer, selectedObject.endPointer],
        viewBox
      );
    }

    return "";
  };

  $: isEditArc =
    $editTool === TOOLS.EDIT_ARC &&
    $drawState.context.dragContext.selectedObject &&
    isArc($drawState.context.dragContext.selectedObject);

  $: isSplitMode =
    $editTool === TOOLS.EDIT_SPLIT &&
    $drawState.context.dragContext.selectedObject &&
    isLine($drawState.context.dragContext.selectedObject);

  $: {
    segmentArray = $drawState.context.current.segments
    .filter((s) => {
      if( !$drawState.matches("main.printing")) return true;
      if( !$selectedRoom ) return true;
      if( isTileWrapper(s) ) {
        return (s as TileWrapper).closedAreaId === $selectedRoom.id
      } else if( isLine(s) || isArc(s) ) {
        if( isLine(s) && (s as Line).parentId === $selectedRoom.id ) return true;
        return $selectedRoom.shape.results.includes(s)
      } else if( isDoor(s) ) {
        return $selectedRoom.shape.results.some((line) => line.id === (s as Door).parentId)
      } else if( isBuildingPart(s) ) {
        return (s as BuildingPart).closedAreaId === $selectedRoom.id
      }

      return false;
    })

    segmentArray.sort((a, b) => a.zIndex - b.zIndex)
    
    const _tileWrappers = segmentArray.filter((s) => isTileWrapper(s)) as TileWrapper[]
    if (!isEqual(tileWrappers, _tileWrappers) || _tileWrappers.some((tw) => tw.updateLayout || tw.update)) {
      _tileWrappers.forEach((tw) => { tw.update = false })
      tileWrappers = _tileWrappers;
    }
  }
  
  const setViewBox = (bounding: number[]) => {
    let w = bounding[2] * 1.2
    let h = bounding[3] * 1.2
    let centerX = bounding[0] + bounding[2] / 2
    let centerY = bounding[1] + bounding[3] / 2
    const newScale = Math.min((svgSize.w / w), (svgSize.h / h)) * window.devicePixelRatio;
    const currentRatio = w / h;
    const ratio = svgSize.w / svgSize.h;
    if (newScale > MAX_ZOOM_RATE) {
      w = svgSize.w * window.devicePixelRatio / MAX_ZOOM_RATE;
      h = w / ratio;
    } else if (newScale < MIN_ZOOM_RATE) {
      w = svgSize.w * window.devicePixelRatio / MIN_ZOOM_RATE;
      h = w / ratio;
    } else if( currentRatio < ratio ) {
      w = h * ratio;
    } else {
      h = w / ratio;
    }

    viewBox = {
      x: centerX - w / 2,
      y: centerY - h / 2,
      w: w,
      h: h,
    }
  }

  const changeAllInView = () => {
    const rooms = $drawState.context.current.segments.filter((segment) => isClosedArea(segment)) as ClosedArea[]
    savedViewBox = viewBox
    if( rooms.length > 0 ) {
      let bounding = getShapeBoundingRect(rooms[0].shape)
      for(let i = 1; i < rooms.length; i++)
        bounding = mergeBoundingRect(bounding, getShapeBoundingRect(rooms[i].shape));
      setViewBox(bounding);
    }
  }

  const resizeWindow = () => {
    if (!svgRef) return;
    const centerX = viewBox.x + viewBox.w / 2
    const centerY = viewBox.y + viewBox.h / 2

    const orgScale = svgSize.w / viewBox.w;

    viewBox = {
      x: centerX - svgRef.clientWidth / orgScale / 2,
      y: centerY - svgRef.clientHeight / orgScale / 2,
      w: svgRef.clientWidth / orgScale,
      h: svgRef.clientHeight / orgScale,
    }

    svgSize = {
      w: svgRef.clientWidth,
      h: svgRef.clientHeight,
    };

    zoomProcess(0);
    drawSend({
      type: "SET_SVG",
      svg: svgRef,
      svgSize: svgSize,
    });
  }

  $: {
    if( prevStateOfPrint !== $drawState.matches("main.printPreview") ) {
      prevStateOfPrint = $drawState.matches("main.printPreview");
      if( $drawState.matches("main.printPreview") )
        changeAllInView();
    }
  }

  $: {
    if( prevStateOfSave !== $drawState.matches("main.savePreview") ) {
      prevStateOfSave = $drawState.matches("main.savePreview");
      if( $drawState.matches("main.savePreview") ) {
        changeAllInView();
        savePreviewImage();
      }
    }
  }

  $: {
    if( page === PAGES.BOARD ) {
      changeAllInView();
    } else {
      drawSend({ type: "MOUSE_DOWN" });
      drawSend({ type: "CANCEL_DRAWING" });
      visibleSideMenu.set(false);
    }
  }

  // $: gridUnit = PRECISION_UNITS[$drawState.context.currentMetricUnit];
  // $: gridUnitScale =
  //   $drawState.context.currentMetricUnit === METRIC_UNITS[0] ? 1 : 2;
  $: gridUnit = PRECISION_UNITS[METRIC_UNITS[0]];
  $: gridUnitScale = 1;

  $: {
    scale = svgSize.w / viewBox.w;
  }

  //method
  const zoomProcess = (rate: number) => {
    const w = viewBox.w;
    const h = viewBox.h;
    const mx = svgRef.clientWidth / 2;
    const my = svgRef.clientHeight / 2;
    const dw = w * rate;
    const dh = h * rate;
    const dx = (dw * mx) / svgSize.w;
    const dy = (dh * my) / svgSize.h;
    const newScale = svgSize.w / (w - dw);
    if (newScale > MAX_ZOOM_RATE || newScale < MIN_ZOOM_RATE) {
      return;
    }
    viewBox = {
      x: viewBox.x + dx,
      y: viewBox.y + dy,
      w: viewBox.w - dw,
      h: viewBox.h - dh,
    };
  };

  const initSvg = () => {
    viewBox = {
      x: -1 * svgRef.clientWidth / 2,
      y: -1 * svgRef.clientHeight / 2,
      w: svgRef.clientWidth,
      h: svgRef.clientHeight,
    };
    svgSize = {
      w: svgRef.clientWidth,
      h: svgRef.clientHeight,
    };

    zoomProcess(0.6);
    drawSend({
      type: "SET_SVG",
      svg: svgRef,
      svgSize: svgSize,
    });
  };

  const getValidNode = (nodeList: CategoryElement[]) => {
    for (let index = 0; index < nodeList.length; index++) {
      const node = nodeList[index];

      if (node?.children?.length) {
        getValidNode(node.children);
      }
      if (!ELEMENTS_IDS.includes(node.id) && !node.children?.length) {
        nodeList.splice(index, 1);
        index--;
      }
    }
  };

  const deSelect = () => {
    if ($drawState.matches("lineTool.shown"))
      drawSend({ type: "HIDE_LINE_TOOL" });
    editTool.set(undefined);

    if ($currentTool === TOOLS.EDIT_ARC || $currentTool === TOOLS.EDIT_SPLIT) {
      currentTool.set(TOOLS.SELECTION);
    }
  };

  const updateMetric = (newUnit: string) => {
    drawSend({ type: "CHANGE_METRIC", newMetricUnit: newUnit });
  };

  const open = (saved) => {

    if (saved === undefined) return;

    errMissingParts.set({ layout: [], tile: [] });
    drawSend({ 
      type: "LOAD_PROJECT", 
      savedData: saved, 
      categories: categories, 
      onError: notifications?.handleError
    });
    // loadTiles();
    tick().then(checkAsyncSegments);

    router.show(routes[ROUTE.BOARD].path)
  };

  const undo = () => {
    if( !$drawState.context.past.length )
      return;

    deSelect();
    drawSend({ type: "UNDO" });
  };

  const redo = () => {
    if( !$drawState.context.future.length )
      return;

    deSelect();
    drawSend({ type: "REDO" });
  };

  const cancelDrawing = () => {
    if ($drawState.matches("main.drawingState")) {
      drawSend({ type: "CANCEL_DRAWING" });
      deSelect();
    }

    bMoused = false;
  };

  const zoomOut = () => {
    zoomProcess(-0.05);
  };

  const zoomIn = () => {
    zoomProcess(0.05);
  };

  const updateLinesWithSplit = () => {
    if (splitedLineArray.length > 0) {
      drawSend({ type: "CONFIRM" });
    }
  };

  const onInputLength = (e: any) => {
    if (e.keyCode === 13) {
      if (isEditArc) {
        drawSend({ type: "DRAG_END" });
        deSelect();
      } else {
        if (drawingObject) {
          const pointer = drawingObject.endPointer;
          drawSend({ type: "MOUSE_DOWN", pointer });
        }
      }
    } else if (isFirstLengthInput) {
      const key = Number(e.key);
      if (!isNaN(key) && e.key !== null && e.key !== " ") {
        lineLength = "";
      }
    }
    isFirstLengthInput = false;
  };

  const keyDown = (e: any) => {
    if (e.keyCode === 27) {
      //ESC
      currentTool.set(TOOLS.SELECTION);
    } else if (e.keyCode === 46) {
      // Delete
      if (!selectedObject) return;
      drawSend({ type: "DELETE" });
      visibleSideMenu.set(false);
    } else if (e.keyCode === 89 && e.ctrlKey) {
      // Ctrl + Y
      redo();
    } else if (e.keyCode === 90 && e.ctrlKey) {
      undo();
    }
  };

  const mouseDown = (e: any) => {
    if (!svgRef) return;
    updateLinesWithSplit();
    bMoused = true;
    const clientPointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );

    if (!clientPointer.x || !clientPointer.y) {
      deSelect();
      return;
    }

    let pointer = convertPointerToViewBox(clientPointer, svgRef);
    pointer = makePointerInSvgBox(pointer, svgSize);

    // if (
    //   pointer.x < 0 ||
    //   pointer.x > svgSize.w ||
    //   pointer.y < 0 ||
    //   pointer.y > svgSize.h
    // ) {
    //   drawSend({ type: "MOUSE_DOWN" });
    //   return;
    // }

    if ($currentTool !== TOOLS.POLYLINE && $currentTool !== TOOLS.DRAW_LINE) {
      if (e.target.classList.contains("resize-indicator")) {
        const id = e.target.id?.replace("indicator_", "");
        drawSend({ type: "RESIZING", pointer, index: Number(id) });
      } else if (
        !e.target.classList.contains("segment") &&
        !e.target.classList.contains("circle-pointer")
      ) {
        drawSend({ type: "MOUSE_DOWN", pointer: clientPointer });
      }
      return;
    }

    if (!startPointer) {
      drawSend({ type: "MOUSE_DOWN", pointer });
    } else {
      drawSend({ type: "MOUSE_DOWN" });
    }
  };
  
  const mouseDownEyeDropper = (e: any) => {
    drawSend({ type: "CANCEL_EYEDROPPER" });

    const clientPointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );
    const canvas = eyedropperRef;
    const context = canvas.getContext('2d');
    const imgData = context.getImageData(clientPointer.x, clientPointer.y, 1, 1);
    const pixels = imgData.data;
    
    const hex = "#" + ("000000" + rgbToHex(pixels[0], pixels[1], pixels[2])).slice(-6);
    drawSend({
      type: "CHANGE_GROUT_COLOR",
      newGroutColor: hex,
    });
    
    currentTool.set(TOOLS.SELECTION)
    return;
  }

  const mouseMove = async (e: any) => {
    isFirstLengthInput = true;
    if (!svgRef) return;
    if (bMoused) {
      if ($drawState.matches("lineTool.shown"))
        drawSend({ type: "HIDE_LINE_TOOL" });
    }
    const clientPointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );

    if (!clientPointer.x || !clientPointer.y) {
      return;
    }

    let pointer = convertPointerToViewBox(clientPointer, svgRef);
    pointer = makePointerInSvgBox(pointer, svgSize);
    
    if ($currentTool !== TOOLS.POLYLINE && $currentTool !== TOOLS.DRAW_LINE && $currentTool !== TOOLS.EYE_DROPPER) {
      if (bMoused) {
        if ($drawState.matches("main.selectState.resizing")) {
          if (!offset) return;

          drawSend({ type: "RESIZING", pointer });
          return;
        }
        if ($editTool === TOOLS.EDIT_ARC) {
          if (isArc(selectedObject)) {
            drawSend({ type: "CHANGE_ARC", pointer });
          }

          return;
        }
        if (selectedCPObj || selectedObject) {
          drawSend({ type: "DRAGGING", pointer });
        } else if (offset) {
          viewBox = {
            x: viewBox.x - (clientPointer.x - offset.x) / scale,
            y: viewBox.y - (clientPointer.y - offset.y) / scale,
            w: viewBox.w,
            h: viewBox.h,
          };
          //update mouse pointer and offset
          drawSend({
            type: "MOUSE_MOVE",
            offset: clientPointer,
            pointer: pointer,
          });
        }
      } else {
        //update mouse pointer
        drawSend({ type: "MOUSE_MOVE", pointer });
      }
      return;
    }
    if ($drawState.context.drawContext.lastPointer) {
      if (drawingObject) {
        drawSend({ type: "MOUSE_MOVE", pointer: pointer });
      } else {
        drawSend({ type: "MOUSE_MOVE", pointer: pointer });
        tick().then(() => lineInputRef?.focus());
      }
    } else {
      drawSend({ type: "MOUSE_MOVE", pointer: pointer });
    }
  };

  const mouseMoveEyeDropper = (e: any) => {
    const ORIG_R = 10;
    const RATIO = 4;
    const clientPointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );

    if (!clientPointer.x || !clientPointer.y) {
      return;
    }
    let pointer = convertPointerToViewBox(clientPointer, svgRef);
    pointer = makePointerInSvgBox(pointer, svgSize);

    const origW = ORIG_R * 2 + 1;
    const sourceCanvas = eyedropperRef;
    const targetCanvas = eyedropperZoomRef;
    const ctxSource = sourceCanvas.getContext('2d');
    const ctxTarget = targetCanvas.getContext('2d');

    const imgData = ctxSource.getImageData(pointer.x - ORIG_R, pointer.y - ORIG_R, origW, origW);
    const imgDataTarget = ctxTarget.createImageData(origW * RATIO, origW * RATIO);

    const data = imgData.data;
    for(let i = 0; i < origW * RATIO; i ++) {
      for(let j = 0; j < origW * RATIO; j ++) {
        for(let k = 0; k < 4; k++) {
          const x = Math.floor(j / RATIO);
          const y = Math.floor(i / RATIO);

          imgDataTarget.data[i * origW * RATIO * 4 + j * 4 + k] = data[y * origW * 4 + x * 4 + k];
        }
      }
    }
    ctxTarget.putImageData(imgDataTarget, 0, 0);
    // ctxTarget.drawImage(sourceCanvas, pointer.x - ORIG_R, pointer.y - ORIG_R, origW, origW, 
    //         0, 0, origW * 8, origW * 8);
    
    ctxTarget.strokeRect(0, 0, origW * RATIO, origW * RATIO);

    ctxTarget.beginPath();
    ctxTarget.moveTo(origW * RATIO / 2 - 10, origW * RATIO / 2 - 2)
    ctxTarget.lineTo(origW * RATIO / 2 - 2, origW * RATIO / 2 - 2)
    ctxTarget.lineTo(origW * RATIO / 2 - 2, origW * RATIO / 2 - 10)
    ctxTarget.stroke();

    ctxTarget.beginPath();
    ctxTarget.moveTo(origW * RATIO / 2 + 10, origW * RATIO / 2 + 2)
    ctxTarget.lineTo(origW * RATIO / 2 + 2, origW * RATIO / 2 + 2)
    ctxTarget.lineTo(origW * RATIO / 2 + 2, origW * RATIO / 2 + 10)
    ctxTarget.stroke();

    ctxTarget.beginPath();
    ctxTarget.moveTo(origW * RATIO / 2 - 10, origW * RATIO / 2 + 2)
    ctxTarget.lineTo(origW * RATIO / 2 - 2, origW * RATIO / 2 + 2)
    ctxTarget.lineTo(origW * RATIO / 2 - 2, origW * RATIO / 2 + 10)
    ctxTarget.stroke();

    ctxTarget.beginPath();
    ctxTarget.moveTo(origW * RATIO / 2 + 10, origW * RATIO / 2 - 2)
    ctxTarget.lineTo(origW * RATIO / 2 + 2, origW * RATIO / 2 - 2)
    ctxTarget.lineTo(origW * RATIO / 2 + 2, origW * RATIO / 2 - 10)
    ctxTarget.stroke();

    drawSend({ type: "MOUSE_MOVE", pointer: pointer });
  }

  const mouseUp = async (_: any) => {
    bMoused = false;
    if ($currentTool !== TOOLS.POLYLINE && $currentTool !== TOOLS.DRAW_LINE) {
      if ($drawState.matches("main.selectState.dragging")) {
        if (selectedCPObj) {
          if (
            !isDoor(selectedObject) &&
            ((snapPointer && snapType === -1) ||
              !checkValidMovePointer(segmentArray, selectedCPObj))
          ) {
            drawSend({ type: "POP" });
            return;
          }
        } else if (selectedObject) {
          if (
            !isBuildingPart(selectedObject) &&
            !isTileWrapper(selectedObject) &&
            !isDoor(selectedObject)
          ) {
            let movingSegments: Segment[] = [];
            movingSegments = [
              ...movingSegments,
              ...getSegmentsOfPoint(segmentArray, selectedObject.startPointer),
            ];
            movingSegments = [
              ...movingSegments,
              ...getSegmentsOfPoint(segmentArray, selectedObject.endPointer),
            ];

            movingSegments = [...new Set(movingSegments)];

            if (!checkValidMoveSegment(segmentArray, movingSegments)) {
              drawSend({ type: "POP" });
              return;
            }
          }
        }
        drawSend({ type: "DRAG_END" });
      } else if ($drawState.matches("main.selectState.resizing")) {
        drawSend({ type: "DRAG_END" });
      }
    }

    if (isEditArc) {
      drawSend({ type: "DRAG_END" });
      deSelect();
    }
  };

  const mouseWheel = (e: any) => {
    if( $drawState.matches("main.savePreview") || $drawState.matches("main.printPreview") || $drawState.matches("main.printing")) return;

    const w = viewBox.w;
    const h = viewBox.h;
    const mx = e.offsetX; //mouse x
    const my = e.offsetY;
    const dw = w * Math.sign(-e.deltaY) * 0.05;
    const dh = h * Math.sign(-e.deltaY) * 0.05;
    const dx = (dw * mx) / svgSize.w;
    const dy = (dh * my) / svgSize.h;

    const newScale = svgSize.w / (w - dw);
    if (newScale > MAX_ZOOM_RATE || newScale < MIN_ZOOM_RATE) {
      return;
    }
    viewBox = {
      x: viewBox.x + dx,
      y: viewBox.y + dy,
      w: viewBox.w - dw,
      h: viewBox.h - dh,
    };
  };

  //watch
  $: if (!!$currentTool) {
    cancelDrawing();
    if ($currentTool === TOOLS.POLYLINE || $currentTool === TOOLS.DRAW_LINE) {
      drawSend({ type: "ENTER_DRAWING", tool: $currentTool });
    }
  }

  const watchEditTool = (nv: TOOLS | undefined) => {
    if (isEditArc) {
      lineLength = getMetricWithUnit(
        selectedObject
          ? Math.round((selectedObject.getLineLength() || 0) * 100) / 100
          : 0,
        $drawState.context.currentMetricUnit,
        true,
        isEditArc ? true : false
      );
      tick().then(() => lineInputRef?.focus());
    } else {
      if (nv === TOOLS.EDIT_ARC && selectedObject && isLine(selectedObject)) {
        const selectedLineObject = selectedObject as Line;
        const midPointer = new Pointer(
          (selectedLineObject.startPointer.x +
            selectedLineObject.endPointer.x) /
            2,
          (selectedLineObject.startPointer.y +
            selectedLineObject.endPointer.y) /
            2
        );

        const isLeftFromStart =
          selectedLineObject.startPointer.x < selectedLineObject.endPointer.x;
        const deltaX =
          -(
            (isLeftFromStart
              ? selectedLineObject.endPointer.y
              : selectedLineObject.startPointer.y) - midPointer.y
          ) / 5;
        const deltaY =
          ((isLeftFromStart
            ? selectedLineObject.endPointer.x
            : selectedLineObject.startPointer.x) -
            midPointer.x) /
          5;

        const selectedArcObj = new Arc(
          selectedLineObject?.startPointer,
          selectedLineObject?.endPointer,
          midPointer.translate(deltaX, deltaY),
          selectedLineObject.id
        );
        drawSend({ type: "CREATE_ARC", segment: selectedArcObj });

        tick().then(() => {
          lineInputRef?.focus();
          lineLength = getMetricWithUnit(
            Math.round((selectedArcObj.getLineLength() || 0) * 100) / 100,
            $drawState.context.currentMetricUnit,
            true,
            isEditArc ? true : false
          );
        });
      } else if (nv === TOOLS.EDIT_SPLIT) {
        drawSend({ type: "SPLIT_LINE", splitCount: 2 });
      }
    }
  };

  const watchLineLength = (nv: number | null) => {
    if (nv !== null && nv > 0) {
      const newLength = convertUnit(nv, $drawState.context.currentMetricUnit, isEditArc ? true : false)
      if (
        drawingObject &&
        Math.round(drawingObject.getLineLength() * 100) / 100 !==
          Math.round(newLength * 100) / 100
      ) {
        const angle = getRoundedAngle(drawingObject.getLineAngle());
        const realLength = newLength;
        const endPointer = drawingObject.startPointer.translate(
          realLength * Math.cos(angle),
          -realLength * Math.sin(angle)
        );

        const pointer = makePointerInSvgBox(endPointer, svgSize);
        const clientW = viewBox.w * rootRef.clientWidth / svgSize.w;
        const clientH = viewBox.h * rootRef.clientHeight / svgSize.h;
        viewBox = {
          x: pointer.x > clientW + viewBox.x || pointer.x < viewBox.x ? pointer.x - clientW / 2 : viewBox.x,
          y: pointer.y > clientH + viewBox.y || pointer.y < viewBox.y ? pointer.y - clientH / 2 : viewBox.y,
          w: viewBox.w,
          h: viewBox.h,
        };
        
        tick().then(() => 
          drawSend({
            type: "MOUSE_MOVE",
            disableSnapping: true,
            pointer,
          })
        );
      } else if (
        isEditArc &&
        Math.round(selectedObject!.getLineLength() * 100) / 100 !==
          Math.round(newLength * 100) / 100
      ) {
        const arcObject = selectedObject as Arc;
        const realLength = newLength;

        const linePointerY =
          Math.tan(
            Math.atan2(
              arcObject.endPointer.y - arcObject.startPointer.y,
              arcObject.endPointer.x - arcObject.startPointer.x
            )
          ) *
            (arcObject.heightPointer.x - arcObject.startPointer.x) +
          arcObject.startPointer.y;

        const sign = linePointerY < arcObject.heightPointer.y ? 1 : -1;

        const leftPointer =
          arcObject.startPointer.x <= arcObject.endPointer.x
            ? arcObject.startPointer
            : arcObject.endPointer;
        const rightPointer =
          arcObject.startPointer.x > arcObject.endPointer.x
            ? arcObject.startPointer
            : arcObject.endPointer;

        const deltaX = -Math.abs(
          realLength *
            Math.cos(
              Math.atan2(
                rightPointer.y - leftPointer.y,
                rightPointer.x - leftPointer.x
              )
            )
        );

        const deltaY =
          (Math.atan2(
            rightPointer.y - leftPointer.y,
            rightPointer.x - leftPointer.x
          ) > 0
            ? 1
            : -1) *
          Math.abs(
            realLength *
              Math.sin(
                Math.atan2(
                  arcObject.endPointer.y - arcObject.startPointer.y,
                  arcObject.endPointer.x - arcObject.startPointer.x
                )
              )
          );
        drawSend({
          type: "CHANGE_ARC",
          pointer: arcObject
            .getCenterPointer()
            .translate(-deltaY * sign, -deltaX * sign),
          disableSnapping: true,
        });
      }
    }
  };

  $: watchEditTool($editTool);

  $: watchLineLength(Number(lineLength));

  $: if ($accessToken) {
    loadResources();
  }

  const findGeometryForTileWrapper = (tileWrapper: TileWrapper) : LayoutGeometry => {
    return $drawState.context.layoutContext.layoutGeometries.find((geometry) => geometry.id === tileWrapper.layoutGeometryId)
  }

  const loadTiles = () => {
    try {
      $drawState.context.current.segments.filter((seg) => isTileWrapper(seg)).forEach((seg) => {
        const tileWrapper = seg as TileWrapper;
        const layoutGeometry = findGeometryForTileWrapper(tileWrapper)
        // const tileData = createRealizedLayoutFromGeometry(layoutGeometry, $drawState.context.layoutContext.baseShapes);

        drawSend({
          type: "LOAD_GEOMETRY",
          layoutGeometry,
          savedGeometryLayoutId: layoutGeometry.id,
        });
        drawSend({
          type: "SHOW_LAYOUT_GEOMETRY",
          tileData: $drawState.context.layoutContext.layout,
          savedGeometryLayoutId: layoutGeometry.id,
          segment: tileWrapper,
        });
        // drawSend({
        //   type: "LOAD_LAYOUT_GEOMETRY",
        //   segment: seg,
        //   tileData: tileData,
        //   savedGeometryLayoutId: layoutGeometry.id,
        // });
      })
    } catch( e ) {
      // console.log(e);
    }
    drawSend({ type: "LOAD_RESOURCE" });

    tick().then(checkAsyncSegments);
    // tick().then(() => drawSend({ type: "LOAD_SUCCESS" }));
  }

  const checkAsyncSegments = () => {

    if (!loadingAsyncSegments)
    {
      drawSend({ type: "LOAD_SUCCESS" })
      changeAllInView();
    }
    else
      setTimeout(checkAsyncSegments, 100);
  };

  const loadResources = () => {
    getElementCategories().then((res) => {
      if (res && res?.element_categories) {
        getValidNode(res.element_categories);
        categories = res.element_categories as CategoryElement[];
      }
    });

    createSampleShapes(!!$accessToken && $expireTime > Date.now())
    .then((res) => {
      drawSend({ type: "LOAD_TILE_SHAPE", data: res });
      return getLayoutGeometries()
    })
    .then(({ data }) => {
      const baseShapes = $drawState.context.layoutContext.baseShapes;
      const layoutGeometries : LayoutGeometry[] = data.layout_geometries.map((layout) => ({
          ...layout,
          tile_shapes: layout.tile_shapes.map((tileShapeId) => {
            const baseShape = baseShapes.find((v) => v.shapeId === tileShapeId)
            if (!baseShape) return null
            return {
              id: tileShapeId,
              name: baseShape.name,
              svg_path: baseShape.path,
              default_width: baseShape.width,
              default_height: baseShape.height
            }
          }).filter((v) => !!v)
      }))

      drawSend({
        type: "LOAD_LAYOUT_GEOMETRY",
        layoutGeometries: layoutGeometries,
      });

      if( layoutGeometries.length > 0 ) {
        drawSend({ type: "LOAD_GEOMETRY", layoutGeometry: layoutGeometries[0],
                    savedGeometryLayoutId: layoutGeometries[0].id });
      }

      if( storedDrawState ) { 
        errMissingParts.set({ layout: [], tile: [] });
        drawSend({ 
          type: "LOAD_PROJECT_LOCAL", 
          savedLocalData: JSON.parse(storedDrawState), 
          onError: notifications?.handleError
        });

        tick().then(checkAsyncSegments);

        // tick().then(() => {
        //   drawSend({ type: "LOAD_SUCCESS" })
        //   changeAllInView();
        // });
      }
      else
        loadTiles();
    });
  };

  const savePreviewImage = async () => {
      await tick();
    // get svg data
      let clonedSvgElement = svgRef.cloneNode(true);
      const allSegments = clonedSvgElement.querySelectorAll(".segment_path");
      allSegments.forEach((seg) => seg.setAttribute('stroke', '#333333'));
      const canvas = document.createElement('canvas'); 
      const shapeW = viewBox.w * scale;
      const shapeH = viewBox.h * scale;
      const marginX = 0; 
      const marginY = 0;
      canvas.width = shapeW + marginX * 2;
      canvas.height = shapeH + marginY * 2;

      const browserZoomLevel = window.devicePixelRatio;
      const ctx = canvas.getContext("2d");
      const svgCanvas = await htmlToCanvas(svgWrapperRef)
      const startPointer = convertPointerToClientBox(new Pointer(viewBox.x, viewBox.y), svgRef);
      ctx.drawImage(layout.canvas, 0, 0, layout.canvas.width, layout.canvas.height, 
        marginX, marginY, shapeW, shapeH);
      const svgContext = svgCanvas.getContext("2d");
      let imgData = svgContext.getImageData(0, 0, svgCanvas.width, svgCanvas.height);

      const data = imgData.data;
      for(let i = 0; i < data.length; i += 4) {
        if(data[i] === 0xFF && data[i + 1] === 0xFF && data[i + 2] === 0xFF)
          data[i + 3] = 0x01;
      }
      svgContext.putImageData(imgData, 0, 0);
      ctx.drawImage(svgCanvas, 
        (startPointer.x - marginX) * browserZoomLevel, (startPointer.y - marginY) * browserZoomLevel, canvas.width * browserZoomLevel, canvas.height * browserZoomLevel,
        0, 0, canvas.width, canvas.height
      );

      /// clip image

      let boundingRect = [0, 0, 0, 0]
      const rooms = $drawState.context.current.segments.filter((segment) => isClosedArea(segment)) as ClosedArea[]
      rooms.forEach((room, roomIndex) => {
        const bounding = getShapeBoundingRect(room.shape);
        if( roomIndex > 0 )
          boundingRect = mergeBoundingRect(boundingRect, bounding)
        else
          boundingRect = bounding
      })
      
      const fCanvas = document.createElement('canvas'); 
      const ctxFinal = fCanvas.getContext("2d");
      const newStartPointer = convertPointerToClientBox(new Pointer(boundingRect[0], boundingRect[1]), svgRef);

      const newShapeW = boundingRect[2] * scale;
      const newShapeH = boundingRect[3] * scale;
      const newMarginX = newShapeW * 0.1; 
      const newMarginY = newShapeH * 0.1; 
      fCanvas.width = newShapeW + newMarginX * 2;
      fCanvas.height = newShapeH + newMarginY * 2;

      ctxFinal.drawImage(canvas, 
        (newStartPointer.x - newMarginX), (newStartPointer.y - newMarginY), fCanvas.width, fCanvas.height,
        0, 0, fCanvas.width, fCanvas.height
      );

      previewImage.set(fCanvas.toDataURL())
      handleCancelPrint();
  }

  const handlePrint = async () => {
    // get svg data
    
      let clonedSvgElement = svgRef.cloneNode(true);
      const allSegments = clonedSvgElement.querySelectorAll(".segment_path");
      allSegments.forEach((seg) => seg.setAttribute('stroke', '#333333'));
      
      const baseShapes = $drawState.context.layoutContext.baseShapes;
      const rooms = $drawState.context.current.segments.filter((segment) => isClosedArea(segment)) as ClosedArea[]
      
      const tileInfoAll = rooms.map((room) => 
        room.tileWrappers.map((tileWrapper) => {
          const geometry = $drawState.context.layoutContext.layoutGeometries.find(e => e.id === tileWrapper.layoutGeometryId);
          const tileShapes = getLayoutShapes(tileWrapper, geometry, baseShapes);
          const uniqueTileShapes = tileShapes.filter((v, idx) => tileShapes.findIndex((tile) => tile.tileId === v.tileId) === idx)
          
          const tilesInShape = limitLayoutTileDepthForDrag(tileWrapper.tileLayout, false).filter((tile) => tileInShape(tile, tileWrapper));
          
          let calcResult = {};
          tilesInShape.forEach((v) => {
            if( calcResult[v.shape.tileId] ) {
              calcResult[v.shape.tileId].count ++;
            } else {
              const points = v.shape.path.toPoints().map((v) => new Pointer(v.x, v.y));
              const boundingRect = getBoundingRect(points);
              let totalArea = Math.abs(area(points));
              calcResult[v.shape.tileId] = {
                count: 1,
                area: totalArea * TILE_TRANSFORM_SCALE * TILE_TRANSFORM_SCALE,
                width: boundingRect[2],
                height: boundingRect[3],
              };
            }
          })

          const totalTileArea = tileShapes.reduce((acc: number, current) => acc + calcResult[current.tileId].area, 0)
          const roomArea = tileWrapper.getArea()
          return uniqueTileShapes.map((shape) => {
            if (!calcResult[shape.tileId]) return null;
            const sameShapeCnt = tileShapes.filter((v) => v.tileId === shape.tileId).length;
            return {
              shape: shape,
              surface: totalTileArea ? roomArea * calcResult[shape.tileId].area * sameShapeCnt / totalTileArea : 0,
              size: calcResult[shape.tileId].area,
              count: calcResult[shape.tileId].count,
              width: calcResult[shape.tileId].width,
              height: calcResult[shape.tileId].height,
            }
          }).filter((v) => !!v)
        }).flat()
      ).flat();

      const tileInfo = [];
      tileInfoAll.forEach((info) => {
        const dupInfo = tileInfo.find(
          v => v.shape.shapeId === info.shape.shapeId && 
          v.shape.tileId === info.shape.tileId && 
          v.shape.width === info.shape.width && 
          v.shape.height === info.shape.height
        )
        if( dupInfo ) {
          dupInfo.surface += info.surface;
          dupInfo.count += info.count;
        } else {
          tileInfo.push(info);
        }
      })

      const roomInfo = [];
      for (let i = 0; i < rooms.length; i++) {
        const canvas = document.createElement('canvas');
        const room = rooms[i];
        
        drawSend({
          type: "ENTER_SELECT",
          segment: room,
        });
        $selectedRoom = room;
        const boundingRect = getShapeBoundingRect(room.shape);
        setViewBox(boundingRect);

        await tick();

        const shapeW = boundingRect[2] * scale;
        const shapeH = boundingRect[3] * scale;
        const marginX = shapeW * 0.1; 
        const marginY = shapeH * 0.1; 
        canvas.width = shapeW + marginX * 2;
        canvas.height = shapeH + marginY * 2;

        const browserZoomLevel = window.devicePixelRatio;
        const ctx = canvas.getContext("2d");
        const svgCanvas = await htmlToCanvas(svgWrapperRef)
        const startPointer = convertPointerToClientBox(new Pointer(boundingRect[0], boundingRect[1]), svgRef);
        const scaleX = layout.canvas.width / window.innerWidth;
        const scaleY = layout.canvas.height / window.innerHeight;

        ctx.drawImage(layout.canvas, startPointer.x * scaleX, startPointer.y * scaleY, shapeW * scaleX, shapeH * scaleY, 
            marginX, marginY, shapeW, shapeH);
        const svgContext = svgCanvas.getContext("2d");
        let imgData = svgContext.getImageData(0, 0, svgCanvas.width, svgCanvas.height);

        const data = imgData.data;
        for(let i = 0; i < data.length; i += 4) {
          if(data[i] === 0xFF && data[i + 1] === 0xFF && data[i + 2] === 0xFF)
            data[i + 3] = 0x01;
        }
        svgContext.putImageData(imgData, 0, 0);
        ctx.drawImage(svgCanvas, 
          (startPointer.x - marginX) * browserZoomLevel, (startPointer.y - marginY) * browserZoomLevel, canvas.width * browserZoomLevel, canvas.height * browserZoomLevel,
          0, 0, canvas.width, canvas.height
        );

        roomInfo.push({
          room: room,
          image: canvas.toDataURL(),
        })
      }

      const tileImages = merge({}, ...(tileInfo.filter(({ shape }) => shape.tileData.filterId).map(({ shape }) => ({
          [shape.tileData.filterId]: shape.tileData.images[Math.floor(Math.random() * shape.tileData.images.length)]
      }))))

      const currentTime = Date.now();
      const docDefinition : TDocumentDefinitions = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        footer: function(currentPage, pageCount) { 
          return {
            table: {
              widths: [ '*' ],
                body: [
                  [ {text: currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [20, 10]} ],
                ]
            },
            layout: 'noBorders',
            style: 'footer'
          }
        },
        content: [
          ...roomInfo.map(({ image, room }, index) => ({
            columns: [
              {
                image: image,
                fit: [7 * 72, 7 * 72]
              },
              {
                width: 'auto',
                table: {
                  widths: [ 80, '*' ],
                  body: [
                    [ 
                      {text: 'Name', style: ['headerCell', 'cell']},
                      {text: room.getName(), style: 'cell'},
                    ],
                    [ 
                      {text: 'Size', style: ['headerCell', 'cell']},
                      {text: getSquareWithUnit(room.getArea(), $drawState.context.currentMetricUnit), style: 'cell'},
                    ],
                  ],
                },
              }
            ],
            margin: [20, 20],
            headlineLevel: index === 0 ? 0 : 1,
            columnGap: 10
          } as ContentColumns)),
          {
            table: {
              widths: [ 100, 'auto', 'auto', 'auto', 'auto', '*' ],
              headerRows: 1,
              dontBreakRows: true,
              body: [
                [ 'Preview image', 'Item name', 'Surface occupied', 'Tile Size', 'Tile Count', '' ],
                ...tileInfo.map(({ shape, surface, size, count, width, height }) => {
                  let marginTop = 0;
                  if( shape.tileData?.filterId ) {
                    const aspect = width / height;
                    if( aspect > 1 ) {
                      marginTop = (80 - (80 / aspect)) / 2;
                    }
                  }
                  return [
                    {
                      ...(shape.tileData?.filterId ? {
                        image: shape.tileData?.filterId
                      } : {
                        svg: `<svg viewBox="-1 -1 2 2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <defs>
                            <clipPath id="tile-clip-path-${shape.tileId}-${currentTime}">
                              <path d="${shape.path.toSvgPath()}" />
                            </clipPath>
                          </defs>
                          <g
                            id="base_shape_tile_${shape.tileId}"
                            clip-path="url(#tile-clip-path-${shape.tileId}-${currentTime})"
                          >
                            <path
                              d="${shape.path.toSvgPath()}"
                              stroke-width="0.1"
                              fill="white"
                              stroke="#555555"
                              class="segment"
                            />
                          </g>
                        </svg>`
                      }
                      ),
                      fit: [80, 80],
                      margin: [0, marginTop, 0, 0],
                      alignment: 'center'
                    },
                    { text: shape.name, margin: [0, 30], alignment: 'center'},
                    { text: getSquareWithUnit(surface, $drawState.context.currentMetricUnit), margin: [0, 30], alignment: 'center' },
                    { text: getSquareWithUnit(size, $drawState.context.currentMetricUnit, true), margin: [0, 30], alignment: 'center' },
                    { text: `${count}`, margin: [0, 30], alignment: 'center'},
                    ''
                  ]
                })
              ]
            },
            layout: 'lightHorizontalLines',
            headlineLevel: 1,
          }
        ],
        styles: {
          headerCell: {
            fillColor: '#F8F8F8',
          },
          cell: {
            fontSize: 14,
            margin: [5, 5]
          },
          footer: {
            fontSize: 12,
            fillColor: '#EDEDED'
          },
        },
        images: tileImages,
        pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
          return currentNode.headlineLevel === 1;
        }
      };
      pdfMake.createPdf(docDefinition).download();

      ///////////
      // CSV
      ///////////
      /*
      const rows = [
        [ 'Item name', 'Surface occupied', 'Tile Size', 'Tile Count'],
        ...tileInfo.map(({ shape, surface, size, count }) => [ shape.name, `${Math.round(surface / 100) / 100}m²`, `${size}m²`, `${count}`])
      ]
      const csvContent = "data:text/csv;charset=utf-8," 
        + rows.map(e => e.join(",")).join("\n");
      
      const pom = document.createElement('a');
      pom.href = encodeURI(csvContent);
      pom.setAttribute('download', "tiles.csv");
      pom.click();
      */
      ///////////
      handleCancelPrint();
  }
  
  const handleCancelPrint = () => {
    viewBox = savedViewBox;
    savedViewBox = null;
    selectedRoom.set(undefined);
    drawSend({ type: "CANCEL_PREVIEW" });
  }

  const handleEyeDropper = () => {
    // tick().then(() => {
    //   htmlToCanvas(svgWrapperRef).then((svgCanvas) => {
        const canvas = eyedropperRef;
        canvas.width = viewBox.w * scale;
        canvas.height = viewBox.h * scale;
        const shapeW = canvas.width;
        const shapeH = canvas.height;

        const browserZoomLevel = window.devicePixelRatio;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(layout.canvas, 0, 0, layout.canvas.width, layout.canvas.height, 
            0, 0, shapeW, shapeH);
        // const svgContext = svgCanvas.getContext("2d");
        // let imgData = svgContext.getImageData(0, 0, svgCanvas.width, svgCanvas.height);

        // const data = imgData.data;
        // for(let i = 0; i < data.length; i += 4) {
        //   if(data[i] === 0xFF && data[i + 1] === 0xFF && data[i + 2] === 0xFF)
        //     data[i + 3] = 0x01;
        // }
        // svgContext.putImageData(imgData, 0, 0);
        // ctx.drawImage(svgCanvas, 
        //   startPointer.x * browserZoomLevel, startPointer.y * browserZoomLevel, canvas.width * browserZoomLevel, canvas.height * browserZoomLevel,
        //   0, 0, canvas.width, canvas.height
        // );

        isEyeDropper = true;
        drawSend({ type: "EYEDROPPER_LOADED" });
    //   })
    // })
  }

  //mount
  onMount(() => {
    const timer = setInterval(() => {
      if( svgRef ) {
        initSvg()
        clearInterval(timer);
      }
    }, 50);
    window.addEventListener("keydown", keyDown);
    window.addEventListener("resize", resizeWindow);
    if (!$accessToken) {
      loadResources();
    }

    const urlParams = new URLSearchParams(window.location.search);
    const unit = urlParams.get("unit");
    if (unit && unit !== METRIC_UNITS[0]) {
      updateMetric(unit);
    }

    const beforePrint = () => {
      savedViewBox = {...viewBox};
      viewBox = {
        x: 0,
        y: 0,
        w: svgRef.clientWidth,
        h: svgRef.clientHeight,
      };
    };

    const afterPrint = () => {
      viewBox = savedViewBox;
      savedViewBox = null;
    };

    window.addEventListener("beforeprint", beforePrint);
    window.addEventListener("afterprint", afterPrint);

    drawService
      .onTransition((drawState: any) => {
        if (drawState.matches("main.normal")) {
          if (drawState.matches("lineTool.shown"))
            drawSend({ type: "HIDE_LINE_TOOL" });
          if (!drawState.context.drawContext.startPointer) {
            currentTool.set(TOOLS.SELECTION);
            editTool.set(undefined);
          }
        }
      })
      .start();
    
    const unsubscribe = currentTool.subscribe((v) => {
      if( v === TOOLS.EYE_DROPPER ) {
        setTimeout(() => {
          handleEyeDropper();
        }, 50)
      } else {
        isEyeDropper = false;
      }
    })

    return () => {
      window.removeEventListener("keydown", keyDown);
      window.removeEventListener("beforeprint", beforePrint);
      window.removeEventListener("afterprint", afterPrint);
      window.removeEventListener("resize", resizeWindow);
      unsubscribe();
    };
  });
</script>


<Notifications bind:this={notifications} />
{#if page === PAGES.NEW_PROJECT_STEP_1 || page === PAGES.NEW_PROJECT_STEP_2 || page === PAGES.PROJECTS_LIST}
<div class="homepage">
  <HomePage 
    {page}
    {open}
  />
</div>
{/if}
<div class={`root-container ${page === PAGES.BOARD ? '' : 'invisible'}`} bind:this={rootRef}>
  <canvas
    id="eyedropper-canvas"
    on:mousedown={mouseDownEyeDropper}
    on:mousemove={mouseMoveEyeDropper}
    on:mouseenter={() => {
      showCursor = true;
    }}
    on:mouseleave={() => {
      showCursor = false;
      mouseUp(null);
    }}
    class={`absolute inset-0 w-full h-full ${isEyeDropper ? '' : 'hidden'}`} 
    bind:this={eyedropperRef}
  />
  <canvas
    class={`absolute z-10 ${isEyeDropper ? '' : 'hidden'}`} 
    style="left: {$drawState.context.snapContext.mousePointer.x + 24}px; top: {$drawState.context.snapContext.mousePointer.y}px;"
    width="84px"
    height="84px"
    bind:this={eyedropperZoomRef}
  />
  <div class={`svg-container ${isEyeDropper ? 'hidden' : ''}`} bind:this={svgWrapperRef}>
  <svg
    bind:this={svgRef}
    on:mousedown={mouseDown}
    on:mouseup={mouseUp}
    on:mousemove={mouseMove}
    on:mouseenter={() => {
      showCursor = true;
    }}
    on:mouseleave={() => {
      showCursor = false;
      mouseUp(null);
    }}
    on:wheel={mouseWheel}
    width="100%"
    height="100%"
    viewBox={viewBox.x + " " + viewBox.y + " " + viewBox.w + " " + viewBox.h}
  >
    <defs>
      <pattern
        id="smallGrid"
        width={gridUnit * gridUnitScale}
        height={gridUnit * gridUnitScale}
        patternUnits="userSpaceOnUse"
      >
        <path
          d={`M ${gridUnit * gridUnitScale} 0 L 0 0 0 ${
            gridUnit * gridUnitScale
          }`}
          fill="none"
          stroke="gray"
          stroke-width="0.25"
        />
      </pattern>
      <pattern
        id="grid"
        width={gridUnit *
          ($drawState.context.currentMetricUnit === $drawState.context.currentMetricUnit ? 5 : 6)}
        height={gridUnit *
          ($drawState.context.currentMetricUnit === $drawState.context.currentMetricUnit ? 5 : 6)}
        patternUnits="userSpaceOnUse"
      >
        <rect
          width={gridUnit *
            ($drawState.context.currentMetricUnit === $drawState.context.currentMetricUnit ? 5 : 6)}
          height={gridUnit *
            ($drawState.context.currentMetricUnit === $drawState.context.currentMetricUnit ? 5 : 6)}
          fill="url(#smallGrid)"
        />
        <path
          d={`M ${
            gridUnit *
            ($drawState.context.currentMetricUnit === $drawState.context.currentMetricUnit ? 5 : 6)
          } 0 L 0 0 0 ${
            gridUnit *
            ($drawState.context.currentMetricUnit === $drawState.context.currentMetricUnit ? 5 : 6)
          }`}
          fill="none"
          stroke="gray"
          stroke-width="0.75"
        />
      </pattern>
      <pattern
        id="biggrid"
        width={gridUnit *
          ($drawState.context.currentMetricUnit === $drawState.context.currentMetricUnit ? 10 : 12)}
        height={gridUnit *
          ($drawState.context.currentMetricUnit === $drawState.context.currentMetricUnit ? 10 : 12)}
        patternUnits="userSpaceOnUse"
      >
        <rect
          width={gridUnit *
            ($drawState.context.currentMetricUnit === $drawState.context.currentMetricUnit
              ? 10
              : 12)}
          height={gridUnit *
            ($drawState.context.currentMetricUnit === $drawState.context.currentMetricUnit
              ? 10
              : 12)}
          fill="url(#grid)"
        />
        <path
          d={`M ${
            gridUnit *
            ($drawState.context.currentMetricUnit === $drawState.context.currentMetricUnit ? 10 : 12)
          } 0 L 0 0 0 ${
            gridUnit *
            ($drawState.context.currentMetricUnit === $drawState.context.currentMetricUnit ? 10 : 12)
          }`}
          fill="none"
          stroke="gray"
          stroke-width="1.25"
        />
      </pattern>
      {#each getTileLayouts($drawState.context.current.segments) as tileWrapper}
        <clipPath id={getShapeId(tileWrapper.shape)}>
          <path d={getShapePath(tileWrapper.shape)} />
        </clipPath>
      {/each}
      <clipPath id="WebGLRendererClipPath" transform={`translate(${-viewBox.x} ${-viewBox.y})`}>
      {#each getTileLayouts($drawState.context.current.segments) as tileWrapper}
        <path d={getShapePath(tileWrapper.shape)} />
      {/each}
      </clipPath>

      <marker id="marker-dash-normal" viewBox="0 0 6 1.5" refX="0" refY="0.75" markerUnits="userSpaceOnUse" markerWidth="6" markerHeight="1.5" orient="auto" class="fill-normal">
        <rect x="0" y="0" width="4" height="1.5" rx="1"/>
      </marker>
      <marker id="marker-dash-hover" viewBox="0 0 6 1.5" refX="0" refY="0.75" markerUnits="userSpaceOnUse" markerWidth="6" markerHeight="1.5" orient="auto" class="fill-primary">
        <rect x="0" y="0" width="4" height="1.5" rx="1"/>
      </marker>
    </defs>
    {#if $drawState.matches("grid.showGrid") && !$drawState.matches("main.savePreview") && !$drawState.matches("main.printPreview") && !$drawState.matches("main.printing") && $currentTool !== TOOLS.EYE_DROPPER}
      <rect
        width={viewBox.w}
        height={viewBox.h}
        x={viewBox.x}
        y={viewBox.y}
        fill="url(#biggrid)"
        class="gridRect"
      />
    {/if}
    <LayoutRendering
      viewBox={viewBox}
      scale={scale}
      bind:tileWrappers={tileWrappers}
      bind:layout={layout}
    />
    <g>
      {#each tileWrappers as segment}
        {#if !$drawState.matches("main.selectState.dragging") || !(isArc(selectedObject) || isLine(selectedObject))}
          <TileWrapperSvg {segment} {svgRef} {svgSize} {scale} tileLayout={segment?.tileLayout} />
        {/if}
      {/each}
      {#each segmentArray as segment}
        {#if isBuildingPart(segment) && segment instanceof BuildingPart}
          <BuildingPartSvg {segment} {svgRef} {svgSize} {scale} />
        {:else if isDoor(segment) && segment instanceof Door}
          <DoorSvg door={segment} {svgRef} {svgSize} {scale} />
        {:else}
          <SegmentSvg {segment} {svgRef} {svgSize} {scale} />
        {/if}
      {/each}
    </g>
    {#if $drawState.matches("helper.showHelper") && !!getGlobalHelpPath()}
      <g>
        <path
          d={getGlobalHelpPath()}
          fill="none"
          stroke-linecap="round"
          stroke="yellow"
          stroke-width="0.5px"
        />
      </g>
    {/if}
    <PointSvg segments={$drawState.matches("main.printPreview") || $drawState.matches("main.savePreview") ? tileWrappers : undefined} {svgRef} {svgSize} {scale} />
    {#if isBuildingPart(selectedObject) && 
        !$drawState.matches("main.selectState.dragging") && 
        !$drawState.matches("main.savePreview") &&
        !$drawState.matches("main.printPreview") && 
        !$drawState.matches("main.printing") && 
        $currentTool !== TOOLS.EYE_DROPPER
    }
      <ResizeIndicator {scale} />
    {/if}
    
    {#if $drawState.matches("main.selectState.rotating")}
      <TransformIndicator scale={scale} />
    {/if}
  </svg>
  </div>
  {#each tileWrappers as segment}
    <TileWrapperInfo tileWrapper={segment} {scale} />
    <!-- {#if !$drawState.matches("main.selectState.dragging")}
      <AddTileBtn shape={segment.shape} {scale} />
    {/if} -->
  {/each}
  {#if !$drawState.matches("main.printPreview") && !$drawState.matches("main.printing")}
  <!-- <TileCountInfoPanel tileWrappers={tileWrappers}/> -->
  <Toolbox />
  <SidePanel {zoomIn} {zoomOut} {categories} />
  <ActionMenu
    {open}
    {undo}
    {redo}
    {updateMetric}
    availableUndo={!!$drawState.context.past.length}
    availableRedo={!!$drawState.context.future.length}
    metricUnit={$drawState.context.currentMetricUnit}
  />
  {:else}
  <div class="flex flex-col print-actions">
    <Button variant="secondary" title="PDF Download" icon="fa-solid fa-print" on:click={handlePrint} disabled={$drawState.matches("main.printing")} />
    <Button variant="outline-secondary" title="Cancel" class="mt-4" on:click={handleCancelPrint} />
  </div>
  {/if}
  <SelectTile />
  {#if !$drawState.matches("main.drawingState") && $drawState.matches("lineTool.shown") && !isSplitMode && !$drawState.matches("main.savePreview") && !$drawState.matches("main.printPreview") && !$drawState.matches("main.printing")}
    <EditToolbox svgRef={svgRef}/>
  {/if}
  {#if isSplitMode}
    <SplitToolbox svgRef={svgRef} />
  {/if}
  <LineLengthToolbox 
    svgRef={svgRef} 
    onInputLength={onInputLength} 
    bind:lineInputRef={lineInputRef} 
    bind:lineLength={lineLength} 
    isEditArc={isEditArc} 
  />

  <Cursor showCursor={showCursor} />
  
</div>

<style lang="scss" scoped>
  .root-container,
  .svg-container {
    position: relative;
    // top: 0;
    // left: 0;
    // right: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  #eyedropper-canvas,
  svg {
    cursor: none;
  }

  @page {
    size: 1920pt 1080pt;
    margin: 0pt;
  }

  .gridRect {
    mix-blend-mode: difference;
    pointer-events: none;
  }

  @media print {
    .gridRect {
      display: none;
    }
  }
  
  .homepage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 8;
  }

  .print-actions {
    position: absolute;
    left: 36px;
    bottom: 36px;
  }
</style>
