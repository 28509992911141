<script lang="ts">
  import { TOOLS } from "src/global/types";
  import { currentTool } from "../store";
  import { drawState } from "../layout.store";

  export let showCursor: boolean;

</script>

{#if showCursor || $currentTool === TOOLS.EYE_DROPPER}
  {#if $drawState.matches("main.preload") || $drawState.matches("main.loading") || $drawState.matches("main.selectState.eyeDropper.loading")}
    <img
      id="cursor-load"
      alt="cursor"
      src="/cursor-load.jpg"
    />
  {:else if $currentTool === TOOLS.POLYLINE || $currentTool === TOOLS.DRAW_LINE}
    <i
      id="cursor"
      class="fa-solid fa-pencil"
      style="left: {$drawState.context.snapContext.mousePointer.x}px; top: {$drawState.context.snapContext.mousePointer.y - 24}px"
    />
  {:else if $currentTool === TOOLS.EYE_DROPPER}
    <i
      id="cursor"
      class="fa-solid fa-eye-dropper"
      style="left: {$drawState.context.snapContext.mousePointer.x}px; top: {$drawState.context.snapContext.mousePointer.y - 24}px"
    />
  {:else}
    <img
      id="cursor"
      alt="cursor"
      src={$drawState.matches("mouseHover.hovered")
        ? "/finger.png"
        : "/cursor_arrow.png"}
      style="left: {$drawState.context.snapContext.mousePointer.x}px; top: {$drawState.context.snapContext.mousePointer.y}px"
    />
  {/if}
{/if}

<style lang="scss" scoped>
  #cursor-load {
    position: absolute;
    width: 30px;
    height: 30px;
    cursor: none;
    pointer-events: none;
    user-select: none;
    left: 50%; 
    top: 50%; 
    transform: translate(-50%, -50%);
  }
  #cursor {
    position: absolute;
    width: 15px;
    height: 20px;
    cursor: none;
    pointer-events: none;
    user-select: none;
  }
</style>
