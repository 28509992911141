import { useMachine } from "@xstate/svelte";
import { editorMachine } from "./store/drawMachine";
import { loadSuccess } from "./store";
import { get } from "svelte/store";
export const storedDrawState = localStorage.getItem("draw-state");
export const { state: drawState, service: drawService, send: drawSend, } = useMachine(editorMachine, {
    devTools: false,
    // state: localDrawState ? StoreSerializer.importDrawState(JSON.parse(localDrawState)) : undefined,
});
let lastPastCount = -1;
drawService.onTransition((state) => {
    if (state.event.type === "MOUSE_MOVE" ||
        state.event.type === "MOUSE_HOVER" ||
        state.event.type === "MOUSE_LEAVE" ||
        state.event.type === "DRAGGING" ||
        state.event.type === "ROTATING" ||
        state.event.type === "SET_SVG" ||
        state.event.type === "LOAD_RESOURCE" ||
        state.event.type === "xstate.init")
        return;
    if (state.event.type === "LOAD_PROJECT" || state.event.type === "LOAD_PROJECT_LOCAL") {
        loadSuccess.set(false);
        lastPastCount = -1;
    }
    if (state.event.type === "LOAD_SUCCESS")
        loadSuccess.set(true);
    if (!get(loadSuccess))
        return;
    // if (
    //   state.matches("main.normal") && //this check is used to ensure that the local project is not overwritten by quickly refreshing the page 
    //   (state.context.past.length !== lastPastCount ||
    //   state.event.type === "TOGGLE_GRID" ||
    //   state.event.type === "TOGGLE_HELPER" ||
    //   state.event.type === "CHANGE_METRIC" ||
    //   state.event.type === "LOAD_PROJECT" ||
    //   state.event.type === "LOAD_PROJECT_LOCAL")
    // ) {
    if (state.context.past.length !== lastPastCount ||
        state.event.type === "TOGGLE_GRID" ||
        state.event.type === "TOGGLE_HELPER" ||
        state.event.type === "CHANGE_METRIC" ||
        state.event.type === "EMPTY_PROJECT" ||
        state.event.type === "CREATE_ROOM") {
        lastPastCount = state.context.past.length;
        const jsonState = JSON.stringify({
            projectBaseInfo: state.context.projectBaseInfo,
            current: state.context.current,
            past: state.context.past,
            future: state.context.future,
            // lineLength: state.context.lineLength, //is it useful to save it????
            currentMetricUnit: state.context.currentMetricUnit,
        });
        // Example: persisting to localStorage
        // console.log(state)
        try {
            localStorage.setItem('draw-state', jsonState);
        }
        catch (e) {
            // unable to save to localStorage
        }
    }
});
