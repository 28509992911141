<script lang="ts">
  import { drawState, drawSend } from "../../layout.store";

  import OkCancelDialog from "../base/OkCancelDialog.svelte";

  $: shouldRenderRemoveTileDialog = $drawState.matches(
    "layoutDesign.showLayout.selectionState.removeTile.showRemoveTileDialog"
  );
  $: shouldRenderRemoveRootTileDialog = $drawState.matches(
    "layoutDesign.showLayout.selectionState.removeTile.showRemoveRootTileDialog"
  );
</script>

<OkCancelDialog
  toggle={shouldRenderRemoveTileDialog}
  okText="Remove"
  on:clickOk={() => drawSend({ type: "CONFIRM_REMOVE_TILE" })}
  on:clickCancel={() => drawSend({ type: "CANCEL_REMOVE_TILE" })}
>
  <span slot="header">Remove tile</span>
  <span slot="message">Do you want to remove selected tile?</span>
</OkCancelDialog>
<OkCancelDialog
  toggle={shouldRenderRemoveRootTileDialog}
  okText="Remove"
  on:clickOk={() => drawSend({ type: "CONFIRM_REMOVE_TILE" })}
  on:clickCancel={() => drawSend({ type: "CANCEL_REMOVE_TILE" })}
>
  <span slot="header">Remove layout</span>
  <span slot="message"
    >Removing root tile will lead to removing the whole layout. Do you want to
    continue?</span
  >
</OkCancelDialog>
