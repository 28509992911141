<script lang="ts">
  import { METRIC_UNITS, PRECISION_UNITS } from "src/global/variable";
  import { createEventDispatcher, getMetricWithUnit, parseImperialUnit } from "src/helpers";

  export let type = "text",
    className = "",
    inputProps = "",
    label = "",
    value : string | number = "",
    icon = undefined,
    iconPos: "left" | "right" = "right",
    iconProps = "",
    iconTooltip : string | undefined = undefined,
    min : number | undefined = undefined,
    max : number | undefined = undefined,
    step = 1,
    onClick = undefined,
    onClickIcon = undefined,
    fullWidth = false,
    unit : string | undefined = undefined,
    error: boolean | undefined = undefined,
    disabled: boolean = false;
  export {className as class};

  export const getWidth = () => inputEl.clientWidth

  const dispatch = createEventDispatcher();
  let focused = false;
  let fValue = 0;
  let inputEl;
  let inputError: boolean;

  $: {
    if( error !== undefined ) {
      inputError = error;
    } else {
      if (min !== undefined && +value < min) {
        inputError = true;
      } else if (max !== undefined && +value > max) {
        inputError = true;
      } else {
        inputError = false;
      }
    }
  }
  const handleKeyDown = (e) => {
    e.stopPropagation();
  }
  // const handleInput = e => {
  //   // in here, you can switch on type and implement
  //   // whatever behaviour you need
  //   value = type.match(/^(number|range)$/)
  //     ? +e.target.value
  //     : e.target.value;
  // };

  $: {
    fValue = parseFloat(value?.toString());
    if (unit === METRIC_UNITS[1] && fValue.toString() !== value?.toString()) {
      fValue = parseImperialUnit(value?.toString())
    }
  }

  const handleIncrease = () => {

    if (disabled) return;

    let newValue = 
      unit === METRIC_UNITS[1] ? 
        Math.round((fValue + step) * 10000) / 10000 :
        Math.round((fValue + step) * 100) / 100;

    if( newValue < min )
      newValue = min;
    if( newValue > max )
      newValue = max;

    if( fValue === newValue ) return;

    value = newValue
    // inputEl.value = unit === METRIC_UNITS[1] ? getMetricWithUnit(value, METRIC_UNITS[1]) : value;
    inputEl.value = newValue;
    dispatch('input', inputEl, null)
  }

  const handleDecrease = () => {

    if (disabled) return;

    let newValue = 
      unit === METRIC_UNITS[1] ? 
        Math.round((fValue - step) * 10000) / 10000 :
        Math.round((fValue - step) * 100) / 100;

    if( newValue < min )
      newValue = min;
    if( newValue > max )
      newValue = max;

    if( fValue === newValue ) return;

    value = newValue
    // inputEl.value = unit === METRIC_UNITS[1] ? getMetricWithUnit(value, METRIC_UNITS[1]) : value;
    inputEl.value = newValue;
    dispatch('input', inputEl, null)
  }

</script>

<div class={`${fullWidth ? 'w-full' : 'w-fit'} ${className}`}>
  {#if label}
    <div class="text-sm font-bold text-black mb-1">{label}</div>
  {/if}
  <div class={`relative ${(onClick ? "cursor-pointer" : "")}`} on:click={onClick} on:keydown>
    <input
      bind:this={inputEl}
      class={`${inputProps} ${fullWidth ? 'w-full' : ''} flex items-center rounded-xl py-2 px-5 border-1 ${inputError ? 'border-red-700' : 'border-gray-200'} focus-visible:outline-none ${
        (icon || $$slots.icon || type === "number") ? (iconPos === "left" ? "pl-14" : "pr-14") : ""}`}
      {step}
      {min}
      {max}
      type={unit && !focused ? 'text' : type}
      value={unit && !focused && unit !== METRIC_UNITS[1] ? value + unit : (unit === METRIC_UNITS[1] && focused) ? parseImperialUnit(value?.toString()) : value}
      {...$$restProps}
      disabled={disabled}
      on:keydown={handleKeyDown}
      on:focus={() => focused = true}
      on:blur={() => focused = false}
      on:input
    />
    {#if icon || $$slots.icon}
      <div
        class={`absolute top-0 w-10 h-full bg-gray-200 flex items-center justify-center flex-shrink-0 ${iconProps} ${
          iconPos === "right" ? "right-0 rounded-r-xl " : "left-0 rounded-l-xl"
        } ${onClickIcon ? "cursor-pointer" : ""}`}
        on:click={onClickIcon} on:keydown
      >
        <div class="flex align-center group relative w-max">
        {#if icon}
          <i class={`${icon} font-black text-xl text-secondary-500`} />
        {:else if $$slots.icon}
          <slot name="icon" />
        {/if}
        {#if iconTooltip}
          <span class="bg-primary-300 color-white py-1 px-2 rounded-lg pointer-events-none absolute -top-12 left-0 w-max opacity-0 transition-opacity group-hover:opacity-100">
            {iconTooltip}
          </span>
        {/if}
        </div>
      </div>
    {/if}
    {#if type === 'number' && !((icon || $$slots.icon) && iconPos === "right")}
      <div
        class="absolute top-px right-px w-10 bg-gray-200 flex items-center justify-center flex-shrink-0 rounded-r-xl"
        style="height: calc(100% - 2px);"
      >
        <div class="flex flex-col divide-y divide-gray-400">
          <div class="w-full flex items-center justify-center flex-1 {disabled || fValue >= max ? '' : 'cursor-pointer'}" on:click={handleIncrease} on:keydown>
            <i class={`fa-solid fa-chevron-up font-black text-lg ${disabled || fValue >= max ? 'text-gray-400' : 'text-secondary-500'}`} />
          </div>
          <div class="w-full flex items-center justify-center flex-1 {disabled || fValue <= min ? '' : 'cursor-pointer'}" on:click={handleDecrease} on:keydown>
            <i class={`fa-solid fa-chevron-down font-black text-lg ${disabled || fValue <= min ? 'text-gray-400' : 'text-secondary-500'}`} />
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>

