<!-- <svelte:options immutable={true} /> -->

<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from "svelte";  
  import Pagination from "../../base/Pagination.svelte";
  import { drawState, drawService, drawSend } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import { emptyLayout, type LayoutGeometry, type RecentLayout } from "src/store/drawMachine";
  import type { TileWrapper } from "src/model";
  import Input from "../../../components/base/Input.svelte";
  import ColorPicker from "../../../components/base/ColorPicker.svelte";
  import Notifications from "../../util/Notifications.svelte"
  import Loading from "../../util/Loading.svelte";
  import GridItem from "./GridItem.svelte";
  import { getMetricWithUnit, getShapeId, isTileWrapper, getLayoutShapes, createPreviewLayout } from "src/helpers";
  import SegmentList from "./SegmentList.svelte";
  import { SEGMENT_LIST_TYPE, TOOLS } from "src/global/types";
  import { debounce, isEqual } from "lodash";
  import type Shape from 'src/model/tile/Shape';
  import { METRIC_UNITS, PRECISION_UNITS, TILE_TRANSFORM_SCALE, TILES_SHAPES } from "src/global/variable";
  import { currentTool, recentLayouts, visibleSideMenu, currentUser, cachedUserLayouts } from "src/store";
  import { getNotificationsContext } from 'svelte-notifications';
  import { limitLayoutTileDepthForDrag } from "src/tools/LayoutTools";
  import { getUserLayouts } from "src/services/api";
  import StoreSerializer from "src/store/serializer";
  import type RealizedLayout from "src/model/tile/RealizedLayout";
  import { useSelector } from "@xstate/svelte";
  
  enum LAYOUT_TAB {
    RECENT,
    MY_LAYOUTS,
    GEOMETRY,
  }

  const { addNotification } = getNotificationsContext();

  let notifications: Notifications;
  let tileWrapper: TileWrapper;
  let layoutShapes: Shape[] = [];
  let orderedLayouts: LayoutGeometry[] = [];
  let groutColor: string;
  let currentRecentPage = 1;
  let currentMyLayoutsPage = 1;
  let currentPage = 1;
  let recentPageSize = 8;
  let myLayoutsPageSize = 8;
  let pageSize = 8;

  let userLayoutsLoading: boolean = true;
  let userLayoutsList: {
    total: number,
    total_pages: number,
    layouts: RecentLayout[]
  };

  let activeTab = LAYOUT_TAB.RECENT;

  let tabs = [
    { id: LAYOUT_TAB.RECENT, title: "side_menu.layout.recent" },
    { id: LAYOUT_TAB.MY_LAYOUTS, title: "side_menu.layout.my_layouts" },
    { id: LAYOUT_TAB.GEOMETRY, title: "side_menu.layout.geometries" }
  ];

  const EMPTY_LAYOUT_GEOMETRY = {
    id: 0,
    name: 'Empty',
    svg_path: undefined,
    preview_image: undefined,
    min_gap_size: 0,
    num_shapes: 0,
    tile_shapes: []
  }

  const paginatedUserLayouts = useSelector(drawService, (state) => state.context.paginatedUserLayouts);
  const loadingAsyncUserLayouts = useSelector(drawService, (state) => state.context.loadingAsyncUserLayouts);

  $: $currentUser, currentUserChanged()
  function currentUserChanged() {

    if (!$currentUser) {

      userLayoutsList = undefined;
      currentMyLayoutsPage = 1;
    }
    else
      updateUserLayoutsList();
  }

  $: $loadingAsyncUserLayouts, $paginatedUserLayouts, reactiveUserLayouts()
  function reactiveUserLayouts() {

    if (!$loadingAsyncUserLayouts)
    {
      calcUserLayouts();
      userLayoutsLoading = false;
    }
    else
      userLayoutsLoading = true;
  }

  const calcUserLayouts = async () => {

    if (userLayoutsList !== undefined && userLayoutsList.total > 0 && $paginatedUserLayouts.length > 0 && $cachedUserLayouts?.size > 0)
    {
      const length = $paginatedUserLayouts.length;
      for (let i = 0; i < length; ++i)
      {
        if (typeof $paginatedUserLayouts[i] === 'number')
          continue;

        const userLayout = $paginatedUserLayouts[i] as RealizedLayout;
        const geometry = $drawState.context.layoutContext.layoutGeometries.find(e => e.id === userLayout.layoutGeometryId);
        const tileWrapper = $drawState.context.dragContext.selectedObject as TileWrapper;
        const tilesToRender = limitLayoutTileDepthForDrag(userLayout, false);
        const preview = await createPreviewLayout(
          tilesToRender,
          userLayout,
          userLayout.shapes,
          true,
          92,
          true
        );

        const newLayout: RecentLayout = {
          layout: userLayout,
          geometryId: userLayout.layoutGeometryId,
          name: geometry?.name ?? "Layout" + i,
          preview: URL.createObjectURL(preview.blob),
          active: false,
          deletable: false,
          clonable: false,
        }
        userLayoutsList.layouts[i] = newLayout;
      }
    }
  }

  const updateUserLayoutsList = debounce((resetPagination: boolean = true) => {

    userLayoutsLoading = true;
    let queryParams : { limit?: number, page?: number } = {
      limit: myLayoutsPageSize,
      page: currentMyLayoutsPage
    };

    getUserLayouts(queryParams).then((res) => updateUserLayoutsListSuccess(res)).catch(() => userLayoutsLoading = false)

    async function updateUserLayoutsListSuccess (res: any) {

      let list: {
          total: number,
          total_pages: number,
          layouts: { id: number, updated_at: string, layout_geometry: number, tiles: number[] }[]
        } = res?.data;

      userLayoutsList = {
        total: list.total,
        total_pages: list.total_pages,
        layouts: []
      };

      drawSend({
        type: "LOAD_USER_LAYOUTS",
        userLayouts: list.layouts,
        onError: notifications?.handleError
      });
    }
  }, 500)
  
  $: {
    const selectedObj = $drawState.context.dragContext.selectedObject;
    if (selectedObj && isTileWrapper(selectedObj))
    {
      layoutShapes = [];
      tileWrapper = selectedObj as TileWrapper;
      groutColor = tileWrapper.tileLayout.groutColor;

      if (tileWrapper.layoutGeometryId !== undefined && tileWrapper.layoutGeometryId > 0)
      {
        const geometry = $drawState.context.layoutContext.layoutGeometries.find(e => e.id === tileWrapper.layoutGeometryId);
        // const shapeToOverride = svgPath.shape_index[shapeIndex];
        const baseShapes = $drawState.context.layoutContext.baseShapes;

        layoutShapes = getLayoutShapes(tileWrapper, geometry, baseShapes);

        // let mapTiles = new Map();
        // let tiles: ShapeInstance[] = limitLayoutTileDepthForDrag(tileWrapper.tileLayout, false);

        // tiles.forEach(tile => {

        //   let find = mapTiles.get(tile.shape.tileId);
        //   if (find === undefined)
        //     mapTiles.set(tile.shape.tileId, tile.shape);
        // });

        // layoutShapes = [...mapTiles.values()];
      }
    }
    else
      dispatch("back");
  }

  let previousReplaceContext = $drawState.context.layoutContext.replaceTileContext;

  $: {
    if ( activeTab === LAYOUT_TAB.RECENT ) {
      calcRecentLayouts();
      sortLayouts();
    }
  }
  $: {
    if (previousReplaceContext?.replaceTileIndices !== undefined && $drawState.context.layoutContext.replaceTileContext?.replaceTileIndices === undefined) {
      calcRecentLayouts();
    }
    previousReplaceContext = $drawState.context.layoutContext.replaceTileContext
  }
  
  const sortLayouts = () => {
    $recentLayouts.sort((a, b) => (b.active ? 1 : 0) - (a.active ? 1 : 0))
  }

  const calcRecentLayouts = async () => {
    const newLayouts: RecentLayout[] = $recentLayouts.map((v) => ({...v, active: false, deletable: true, clonable: true}))
    const tileWrappers = $drawState.context.current.segments.filter((segment) => isTileWrapper(segment))
    for (const [index, wrapper] of tileWrappers.entries()) {
      const _tileWrapper = wrapper as TileWrapper;
      const tilesToRender = limitLayoutTileDepthForDrag(_tileWrapper.tileLayout, false);
      const geometry = $drawState.context.layoutContext.layoutGeometries.find(e => e.id === _tileWrapper.layoutGeometryId);
      const shapes = getLayoutShapes(_tileWrapper, geometry, $drawState.context.layoutContext.baseShapes);

      const preview = (await createPreviewLayout(
        tilesToRender, 
        _tileWrapper.tileLayout, 
        shapes, 
        true,
        92,
        true
      ));

      const layoutIndex = newLayouts.findIndex((v) => v.layout.id === _tileWrapper.tileLayout.id)
      const newLayout = {
        layout: _tileWrapper.tileLayout,
        geometryId: _tileWrapper.layoutGeometryId,
        name: geometry?.name ?? "Layout" + index,
        preview: URL.createObjectURL(preview.blob),
        active: tileWrapper.tileLayout.id === _tileWrapper.tileLayout.id,
        deletable: false,
        clonable: true,
      }

      if (layoutIndex === -1)
        newLayouts.push(newLayout);
      else
        newLayouts[layoutIndex] = newLayout;
    }
    $recentLayouts = newLayouts;
  }

  onMount(() => {
    orderedLayouts = $drawState.context.layoutContext.layoutGeometries.sort((a, b) => {
      if( a.id === tileWrapper?.layoutGeometryId ) {
        return -1;
      }
      return 0;
    })

    orderedLayouts = [EMPTY_LAYOUT_GEOMETRY, ...orderedLayouts]
  })
  $: paginatedLayouts = orderedLayouts.slice((currentPage - 1) * pageSize, currentPage * pageSize)
  $: paginatedRecentLayouts = $recentLayouts.slice((currentRecentPage - 1) * recentPageSize, currentRecentPage * recentPageSize)

  const dispatch = createEventDispatcher();

  const loadLayout = async (recentLayout: RecentLayout, duplicate?: boolean) => {
    if ( recentLayout.active && !duplicate ) return;

    drawSend({
      type: "LOAD_TILE_LAYOUT",
      tileData: recentLayout.layout,
      savedGeometryLayoutId: recentLayout.geometryId,
      duplicate: duplicate
    });
    await calcRecentLayouts();
  }
  
  const deleteRecentLayout = (recentLayout: RecentLayout) => {
    $recentLayouts = $recentLayouts.filter((v) => v.layout.id !== recentLayout.layout.id)
  }

  const loadLayoutGeometry = async (layoutGeometry: LayoutGeometry) => {
    if( layoutGeometry.id ) {
      const recentLayout = $recentLayouts.find((v) => v.geometryId === layoutGeometry.id && v.layout.overrideAspectRatio.length === 0 )
      if( !recentLayout ) {
        drawSend({
          type: "LOAD_GEOMETRY",
          layoutGeometry,
          savedGeometryLayoutId: layoutGeometry.id,
        });
        drawSend({
          type: "SHOW_LAYOUT_GEOMETRY",
          tileData: $drawState.context.layoutContext.layout,
          savedGeometryLayoutId: layoutGeometry.id,
          segment: tileWrapper,
          isNewLayout: true,
        });
      } else {
        drawSend({
          type: "LOAD_TILE_LAYOUT",
          tileData: recentLayout.layout,
          savedGeometryLayoutId: recentLayout.geometryId,
          duplicate: false
        });
      }
    } else {
      drawSend({
        type: "SHOW_LAYOUT_GEOMETRY",
        tileData: emptyLayout,
        savedGeometryLayoutId: 0,
        segment: tileWrapper,
        isNewLayout: true,
      });
    }
    await calcRecentLayouts();
  };

  const handleNewLayout = (e) => {
    // visibleSideMenu.set(false)
    if( $drawState.matches("main.selectState.replacingTile") )
      drawSend("CANCEL_TILE_SELECTION")

    drawSend({
      type: "EDIT_TILE_LAYOUT",
      shapeId: getShapeId(tileWrapper.shape)
    });
    drawSend({
      type: "LOAD_CONTEXT",
      tileContext: null
    });
  };

  $: isMetricMeter = $drawState.context.currentMetricUnit === METRIC_UNITS[0]
  $: rotation = ($drawState.context.dragContext.selectedObject as TileWrapper)?.rotation
  $: minGap = 0
  $: maxGap = isMetricMeter ? 20 : 3 / 4

  const highlightTiles = (e) => {

    //highlight tiles only if the layout has at least two different ones
    // if (layoutShapes.length > 1)
    {
      drawSend({
        type: "HIGHLIGHT_TILES",
        highlightTile: { index: e.detail.index, on: true }
      });
    }
  };

  const removeHighlight = (e) => {

    // if (layoutShapes.length > 1)
    {
      drawSend({
        type: "HIGHLIGHT_TILES",
        highlightTile: { index: e.detail.index, on: false }
      });
    }
  };

  const replaceTile = (e) => {

    const geometry = $drawState.context.layoutContext.layoutGeometries.find(e => e.id === tileWrapper.layoutGeometryId);
    const svgPath = JSON.parse(geometry.svg_path);
    const shape: Shape = e.detail.segment;
    const baseShapes = $drawState.context.layoutContext.baseShapes;
    const tileShape = baseShapes.find((bs) => bs.tileId === shape.shapeId);
    let width, height;

    if (tileShape.shapeId !== TILES_SHAPES.SQUARE)
    {
      const allSquaresReplaced = !svgPath.shape_index.some((tileId, index) => {

        const baseShape = baseShapes.find((bs) => bs.tileId === tileId);

        return baseShape?.tileId === TILES_SHAPES.SQUARE && !tileWrapper.tileLayout.overrideAspectRatio.find(oar => oar.shapeIndex === index);
      });

      if (!allSquaresReplaced)
      {
        // clearNotifications();
        addNotification({
          type: 'warning',
          removeAfter: 3000,
          text: "Please, pick square tiles first for this layout",
          position: 'bottom-center',
        })
        return;
      }
    }

    if (tileWrapper.tileLayout.overrideAspectRatio.length > 1 ||
       (tileWrapper.tileLayout.overrideAspectRatio.length === 1 && tileWrapper.tileLayout.overrideAspectRatio[0].shapeIndex !== e.detail.index))
    {
      // const isReplacedTile = tileWrapper.overrideAspectRatio.find(el => el.shapeIndex === e.detail.index);
      const shapeToOverride = svgPath.shape_index[e.detail.index];
      const dimensionsShape = Array.isArray(svgPath.dimensions) && svgPath.dimensions.length > e.detail.index ? svgPath.dimensions[e.detail.index] : undefined;
      const geometryShape = geometry.tile_shapes.find((gs) => gs.id === shapeToOverride);

      const factors = tileWrapper.tileLayout.getMultiplyFactors(geometry, e.detail.index);
      let widthMultiplyFactor  = factors.widthMultiplyFactor;
      let heightMultiplyFactor = factors.heightMultiplyFactor;

      if (dimensionsShape)
      {
        width  = widthMultiplyFactor  * dimensionsShape[0];
        height = heightMultiplyFactor * dimensionsShape[1];
      }
      else if (geometryShape)
      {
        width  = widthMultiplyFactor  * geometryShape.default_width;
        height = heightMultiplyFactor * geometryShape.default_height;
      }
      width = Math.round(width * 10) / 10;
      height = Math.round(height * 10) / 10;
    }

    const arrTileIndices = [];
    layoutShapes.forEach((_shape, index) => {
      if( e.detail.index === index )
        arrTileIndices.push(index)
      else if (shape.shapeId === _shape.shapeId && shape.shapeId === shape.tileId) {
        if ( Array.isArray(svgPath.dimensions) && svgPath.dimensions.length > Math.max(e.detail.index, index) ) {
          if( isEqual(svgPath.dimensions[e.detail.index], svgPath.dimensions[index]) )
            arrTileIndices.push(index)
        }
      }
    })

    drawSend({
      type: "REPLACE_TILE",
      replaceTileContext: {
        replaceTileIndices: arrTileIndices,
        lockShape: shape.shapeId,
        lockWidth: width,
        lockHeight: height,
        layoutShapes: layoutShapes
      }
    });
  };

  const handleChangeRotation = debounce((e) => {
    drawSend({
      type: "CHANGE_ROTATION",
      newAngle: Math.round(parseFloat(e.target.value)),
    });
  }, 300);

  
  const handleChangeGap = debounce((e) => {
    let gap = parseFloat(e.target.value);
    if (gap < minGap || gap > maxGap) return;

    const newGap = isMetricMeter
        ? gap * 0.1
        : gap * PRECISION_UNITS[METRIC_UNITS[1]];
          
    drawSend({
      type: "CHANGE_LAYOUT_GAP",
      newGap: newGap / TILE_TRANSFORM_SCALE,
    });
  }, 300);

  const handleChangeColor = (v: string) => {
    drawSend({
      type: "CHANGE_GROUT_COLOR",
      newGroutColor: v,
    });
  }
  
  const handleClickEyeDropper = () => {
    drawSend({ type: "EYEDROPPER" });
    currentTool.set(TOOLS.EYE_DROPPER);
  }
</script>

<Notifications bind:this={notifications} />
<div class="w-full flex flex-col gap-3">
  <nav class="flex">
    {#each tabs as tab (tab.id)}
      <button class="border-transparent hover:bg-gray-200 border-0 rounded-md w-full px-3 py-2 text-sm 
        {tab.id === LAYOUT_TAB.MY_LAYOUTS && !$currentUser? 'hidden' : ''}
        {activeTab === tab.id ? 'font-bold bg-gray-100 text-secondary-500' : 'font-medium'}"
        on:click={() => activeTab = tab.id}
      >
        {$_(tab.title)}
      </button>
    {/each}
  </nav>
  {#if activeTab === LAYOUT_TAB.RECENT}
    <SegmentList 
      type={SEGMENT_LIST_TYPE.TILED_LAYOUT} 
      segments={paginatedRecentLayouts}
      on:select={(e) => {
        if (e.detail.action === "delete" ) {
          deleteRecentLayout(e.detail.segment);
          return;
        } 
        loadLayout(e.detail.segment, e.detail.action === "duplicate")
      }}
    />
    <Pagination 
      totalItems={$recentLayouts.length} 
      itemsPerPage={recentPageSize} 
      bind:currentPage={currentRecentPage}
    />
  {:else if activeTab === LAYOUT_TAB.MY_LAYOUTS}
    {#if userLayoutsLoading}
      <div class="flex h-full justify-center items-center">
        <Loading />
      </div>
    {:else}
      <SegmentList
        type={SEGMENT_LIST_TYPE.TILED_LAYOUT}
        segments={userLayoutsList.layouts}
        on:select={(e) => {
          loadLayout(e.detail.segment)
        }}
      />
      <Pagination
          bind:totalItems={userLayoutsList.total}
          bind:itemsPerPage={myLayoutsPageSize}
          bind:currentPage={currentMyLayoutsPage}
          onPageChange={() => updateUserLayoutsList()}
      />
    {/if}
  {:else}
    {#if (currentPage - 1 === Math.floor(orderedLayouts.length / pageSize))}
      <SegmentList 
        type={SEGMENT_LIST_TYPE.LAYOUT} 
        segments={paginatedLayouts}
        on:select={(e) => loadLayoutGeometry(e.detail.segment)}
      > 
        <GridItem slot="add" size="xl" title={$_("side_menu.layout.new_layout")} special on:click={handleNewLayout}>
          <i class="fa-regular fa-window text-4xl" />
        </GridItem>
      </SegmentList>
    {:else}
      <SegmentList 
          type={SEGMENT_LIST_TYPE.LAYOUT} 
          segments={paginatedLayouts}
          on:select={(e) => loadLayoutGeometry(e.detail.segment)}
      />
    {/if}
    <Pagination 
      totalItems={orderedLayouts.length + 1} 
      itemsPerPage={pageSize} 
      bind:currentPage={currentPage}
    />
  {/if}
  
  {#if tileWrapper?.layoutGeometryId > 0}
  <div>
    <div class="text-lg font-bold">
      {$_("side_menu.layout.change_tile")}
    </div>
    
    <SegmentList 
      class="mt-3"
      type={SEGMENT_LIST_TYPE.SHAPE} 
      segments={layoutShapes}
      on:mouseenter={highlightTiles}
      on:mouseleave={removeHighlight}
      on:select={replaceTile}
    />
  </div>

  <div class="flex items-center">
    <i class="fa-solid fa-rotate font-black text-xl text-secondary-500 mr-2" />
    <div class="text-lg font-bold mr-auto">{$_("side_menu.rotation")}</div>
    <Input
      type="number"
      inputProps="w-40"
      min={0}
      max={360}
      class="rotation-input"
      value={rotation % 360}
      unit="°"
      on:input={handleChangeRotation}
    />
  </div>
  <div class="flex items-center">
    <i class="fa-solid fa-arrows-from-dotted-line font-black text-xl text-secondary-500 mr-2" />
    <div class="text-lg font-bold mr-auto">
      {$_("side_menu.layout.leak_dist")}
    </div>
    <Input
      type="number"
      inputProps="w-40"
      min={minGap}
      max={maxGap}
      step={isMetricMeter ? 1 : 1 / 16}
      value={getMetricWithUnit(
        (tileWrapper.tileLayout.gapSize * TILE_TRANSFORM_SCALE) * (isMetricMeter ? 1000 :  1),
        $drawState.context.currentMetricUnit,
        $drawState.context.currentMetricUnit === METRIC_UNITS[0]
      )}
      unit={isMetricMeter ? "mm" : METRIC_UNITS[1] }
      on:input={handleChangeGap}
    />
  </div>
  <div class="flex items-center">
    <div class="text-lg font-bold mr-auto">
      {$_("side_menu.layout.joint_color")}
    </div>
    <button class="mr-2 w-9 h-9 flex items-center justify-center border-0 rounded-full" on:click={handleClickEyeDropper}>
      <i class="fa fa-eye-dropper font-black text-secondary-500" />
    </button>
    <ColorPicker hex={groutColor} onChange={handleChangeColor} />
  </div>
  {/if}
</div>