<script type="typescript">
  export let x = 0;
  export let y = 0;
  export let name = "";
  export let size = 0.4;
  export let zoomScale = 1.3;
  export let rotationDeg = 0;
  export let fill = "#333";
  export let fillHover = "green";
  export let passive = false;

  const iconVPSize = 24;

  let correctPosX = x - size / 2;
  let correctPosY = y - size / 2;
  let scaledVPSize = iconVPSize * zoomScale;
  let offset = scaledVPSize / 2 - iconVPSize / 2;
  let centeredViewport = `-${offset} -${offset} ${scaledVPSize} ${scaledVPSize}`;
  let cssTransform = computeTransform(false);
  let cssTransformWithScale = computeTransform(true);

  function computeTransform(includeScale: boolean) {
    const scale = includeScale ? `scale(${zoomScale})` : "";
    return `translate(-${offset}px, -${offset}px) rotate(${rotationDeg}deg) ${scale} translate(${offset}px, ${offset}px)`;
  }
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width={size}
  height={size}
  viewBox={centeredViewport}
  aria-labelledby={name}
  role="presentation"
  x={correctPosX}
  y={correctPosY}
>
  <title id={name} lang="en">{name}</title>
  <g
    style="--icon-scale-transform: {cssTransformWithScale}; --icon-transform: {cssTransform}; --icon-fill: {fill}; --icon-fill-hover: {fillHover}"
    class:icon={!passive}
    class:icon-passive={passive}
    on:click
    on:mouseover
    on:mouseleave
    on:focus
  >
    <slot />
  </g>
</svg>

<style>
  .icon {
    pointer-events: visible;
    transform-origin: center center;
    fill: var(--icon-fill);
    transform: var(--icon-transform);
  }

  .icon:hover {
    transition: transform 0.4s ease-out;
    fill: var(--icon-fill-hover);
    transform: var(--icon-scale-transform);
  }

  .icon-passive {
    pointer-events: none;
    transform-origin: center center;
    fill: var(--icon-fill);
    transform: var(--icon-transform);
  }
</style>
