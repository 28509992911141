<script lang="ts">
  import { onMount } from "svelte";
  export let className = "";
  export let title: string | undefined = undefined;
  export let arrow: boolean = true;
  export let dropClassName = "";
  export let dropdownWidth = "256px";
  export let align: "left" | "right" = "left";
  export let forceToggle: boolean | undefined = undefined;
  export let fitContent = false;
  export let shadow = true;
  
  export {className as class};

  let isDropdownOpen = false; // default state (dropdown close)
  let menuElement;
  let container;
  let buttonEl;
  
  const handleDropdownClick = () => {
    isDropdownOpen = !isDropdownOpen; // togle state on click
    if ( forceToggle !== undefined )
      forceToggle = isDropdownOpen;
  };

  onMount(() => {
    const handleClickOutside = (event: Event) => {
      if (
        container &&
        event.target instanceof Node &&
        !container.contains(event.target) &&
        menuElement &&
        !menuElement.contains(event.target)
      ) {
        isDropdownOpen = false;
        if ( forceToggle !== undefined )
          forceToggle = false;
      }
    };

    window.addEventListener("click", handleClickOutside);
    
    return () => {
      window.removeEventListener("click", handleClickOutside);
    }
  })

  $: {
    if (forceToggle !== undefined) isDropdownOpen = forceToggle;
  }

  $: {
    if( fitContent && buttonEl ) {
      dropdownWidth = buttonEl.clientWidth + "px";
    }
  }
</script>

<div class={`relative ${className}`} bind:this={container}>
  {#if !title}
    <div
      class="w-fit"
      bind:this={buttonEl}
      on:click={forceToggle === undefined ? handleDropdownClick : undefined}
    >
      <slot name="button" />
    </div>
  {:else}
    <button
      bind:this={buttonEl}
      class="btn-default flex items-center border-0"
      on:click={handleDropdownClick}
    >
      <span class="mr-3">{title}</span>
      {#if arrow}
        <i
          class={`fa ${
            isDropdownOpen ? "fa-angle-up" : "fa-angle-down"
          } font-black text-secondary-500 text-xs`}
        />
      {/if}
    </button>
  {/if}

  <ul
    class={`absolute overflow-hidden bg-white ${align}-0 rounded z-10 ${shadow ? 'shadow-dropdown' : ''} ${dropClassName}`}
    style:display={isDropdownOpen ? "block" : "none"}
    style:width={dropdownWidth}
    bind:this={menuElement}
    on:click={handleDropdownClick}
  >
    <slot />
  </ul>
</div>
