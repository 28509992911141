<script lang="ts">
  import Modal from "./Modal.svelte";
  import { _ } from "../../services/i18n";
  import Loading from "../util/Loading.svelte";
  import { drawState, drawService, drawSend } from "src/layout.store";
  import { useSelector } from "@xstate/svelte";
  import { isBuildingPart, isDoor, isTileWrapper, createPreviewLayout, getLayoutShapes } from "src/helpers";
  import type { BuildingPart, TileWrapper } from "src/model";
  import { jsonDownload } from "src/helpers/download";
  import { previewImage, selectedRoom } from "src/store";
  import { createProject, updateProject, authorizeUrl } from "src/services/api";
  import { QUERY_PARAMS, ACTION_TYPES } from "src/global/variable";
  import type { SaveFormat, ProjectBaseInfo } from "src/global/types";
  import Button from "../base/Button.svelte";
  import { activePath, currentUser, loadSuccess } from "src/store";
  import routes, { ROUTE } from "../../router";
  import { limitLayoutTileDepthForDrag } from "src/tools/LayoutTools";

  let name: string;
  let onSaving = false;
  let openModal;
  let userAvailable = false;

  const projectBaseInfo = useSelector(drawService, (state) => state.context.projectBaseInfo);
  $: $projectBaseInfo, reactiveProjectBaseInfo()
  function reactiveProjectBaseInfo() {
    name = $projectBaseInfo?.name;
  }

  $: if ($currentUser && !userAvailable && $loadSuccess) {
    userAvailable = true;
    const url = new URL(window.location.href.replace("#", "?"));
    const searchParams = new URLSearchParams(url.search);
    // const action = searchParams?.get(QUERY_PARAMS.ACTION);
    //take last slug
    const action = url.pathname.match(/[^\/]+/g).at(-1);

    if (action && action === ACTION_TYPES.PROJECT_SAVE)
      onOpening();
  }

  async function prepareRequestBody() {

    const buildings = $drawState.context.current.segments.filter(
      (s) => isBuildingPart(s)
    );
    const furnitures = buildings.reduce((r, b) => {
      const furniture = b as BuildingPart;
      if (
        r.find(
          (a) =>
            // a.buildingType === furniture.buildingType &&
            a.buildingId === furniture.buildingId
        )
      ) {
        return r;
      }
      return [
        ...r,
        furniture.buildingId
      ];
    }, []);

    const tileWrappers = $drawState.context.current.segments.filter((s) => isTileWrapper(s));
    const length = tileWrappers.length;
    let layouts = [];
    for (let i = 0; i < length; ++i)
    {
      const tileWrapper = tileWrappers[i] as TileWrapper;
      const graph = tileWrapper.tileLayout.getGraph();
      const tileShape = graph.tile_shapes;
      const tiles = graph.tiles;

      if (tileWrapper.layoutGeometryId === -1)
        continue;

      if (
        layouts.find((a) =>
          a.layoutGeometry === tileWrapper.layoutGeometryId &&
          a.tiles.length === tiles.length &&
          a.tiles.every((at) => tiles.includes(at)))
      ) {
        continue;
      }

      const tilesToRender = limitLayoutTileDepthForDrag(tileWrapper.tileLayout, false);
      const geometry = $drawState.context.layoutContext.layoutGeometries.find(e => e.id === tileWrapper.layoutGeometryId);
      const shapes = getLayoutShapes(tileWrapper, geometry, $drawState.context.layoutContext.baseShapes);
      const preview = await createPreviewLayout(tilesToRender, tileWrapper.tileLayout, shapes, true, undefined, true);

      layouts.push({
        layoutGeometry: tileWrapper.layoutGeometryId,
        tiles: tiles,
        complete: tileShape.some(shape => tiles.find(tile => tile === shape)) ? 0 : 1,
        preview_image: preview.base64
      });
    }

    const requestBody: { project: SaveFormat } = {
      project: {
        name,
        options: [], // array of project options (eg "top" if view from top or "front"). In the future we could have more options
        preview_image: $previewImage, // base64 preview image of the project (screenshot of the view when user clicks "save")
        data: {
          layouts,
          furnitures,
          segments: $drawState.context.current.segments
            .sort((a, b) => Number(isTileWrapper(a)) - Number(isTileWrapper(b)))
            .map((s) => {
              if (isTileWrapper(s))
              {
                const tileJSON = s.toJSON();
                return tileJSON;
                // return {
                //   ...tileJSON,
                //   tileLayout: layouts.findIndex(
                //     (layout) =>
                //       layout.layoutGeometry ===
                //         tileJSON.tileLayout.layoutGeometry &&
                //       layout.tiles.length === tileJSON.tileLayout.tiles.length &&
                //       layout.tiles.every((at) =>
                //         tileJSON.tileLayout.tiles.includes(at)
                //       )
                //   ),
                // };
              }
              else if (isDoor(s)) {
                const buildingJSON = s.toJSON();
                const building = buildingJSON.buildingType;

                return {
                  ...buildingJSON,
                  building,
                };
              }
              else if (isBuildingPart(s))
              {
                const buildingJSON = s.toJSON();
                const building = furnitures.findIndex(
                  (bId) =>
                    bId === buildingJSON.buildingId
                );
                delete buildingJSON.buildingType;
                delete buildingJSON.buildingId;
                return {
                  ...buildingJSON,
                  building,
                };
              }

              return s.toJSON();
            }),
        }
      }
    };

    return requestBody;
  }

  function onOpening() {

    previewImage.set(undefined);
    openModal();
    //Don't change the timer below!!! We need to give the modal enough time to open before generating the preview
    setTimeout(generatePreview, 100);
  }

  function replaceUrl() {

    const url = new URL(window.location.href);
    const action = url.pathname.match(/[^\/]+/g).at(-1);

    if (action && action === ACTION_TYPES.PROJECT_SAVE)
      window.history.replaceState(window.history.state, "", window.location.href.replace("/" + ACTION_TYPES.PROJECT_SAVE, ""));
  }

  const save = async () => {

    onSaving = true;
    let requestBody = await prepareRequestBody();
    //save in cloud
    let response = createProject(requestBody)
      .then((res) => {
        let projectInfo: ProjectBaseInfo = {
          // id: $projectBaseInfo.id,
          name: name,
          slug: res.data.project.slug,
        };
        drawSend({ type: "UPDATE_PROJECT_INFO", projectInfo: projectInfo});
      })
    //save in local
    // jsonDownload(JSON.stringify(requestBody), `${name}.json`);
    return response;
  }

  const update = async () => {

    onSaving = true;
    let requestBody = await prepareRequestBody();
    //save in cloud
    let response = updateProject($projectBaseInfo.slug, requestBody)
      .then((res) => {
        let projectInfo: ProjectBaseInfo = {
          id: $projectBaseInfo.id,
          name: name,
          slug: res.data.project.slug,
        };
        drawSend({ type: "UPDATE_PROJECT_INFO", projectInfo: projectInfo});
      })
    //save in local
    // jsonDownload(JSON.stringify(requestBody), `${name}.json`);
    return response;
  }

  const generatePreview = () => {
    // previewImage.set(undefined);
    const tileWrappers : TileWrapper[] = $drawState.context.current.segments.filter((s) => isTileWrapper(s)) as TileWrapper[];
    if ( tileWrappers.every((tw) => !tw.loadingLayout) ) {
      selectedRoom.set(undefined);
      drawSend({ type: "ENTER_SELECT", segment: undefined });
      drawSend({ type: "SAVE_PREVIEW" });
    } else {
      setTimeout(generatePreview, 100)
    }
  }
</script>

<Modal bind:openModal onClose={replaceUrl}>
  <div slot="trigger" let:open>
    <button
      class={`w-15 h-15 flex items-center justify-center border-none` + 
             `${!$loadSuccess ? " cursor-default" : ""}` + 
             `${!$currentUser && $loadSuccess ? " bg-yellow-100" : ""}`} disabled={!$loadSuccess}

      on:click={() => {
        if (!$currentUser)
        {
          activePath.set(routes[ROUTE.BOARD].path);
          window.open(
            authorizeUrl(ACTION_TYPES.PROJECT_SAVE),
            "_self",
            `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes`
          );
        }
        else
          onOpening();
      }}
    >
      <i class={`fa-solid fa-save text-xl ${!$loadSuccess ? "text-gray-300 cursor-default" : "text-secondary-500"}`}
        title={$_("action_menu.save")}
      />
    </button>
  </div>
  <div slot="header" let:close>
    <div class="flex bg-primary-300 justify-between items-center px-2 py-3">
      <div class="text-2xl mr-2">{$_(`action_menu.file.title`)}</div>
      {#if !onSaving}
        <i
          class="fal fa-times cursor-pointer"
          color={"black"}
          on:click={() => {
            drawSend({ type: "CANCEL_PREVIEW" });
            close()
          }}
          on:keydown
        />
      {/if}
    </div>
  </div>
  <div slot="content" class="p-3">
    <div class="flex items-center justify-center h-36 border border-solid border-gray-300 rounded-md">
    {#if $previewImage}
      <img src={$previewImage} class="w-full h-full object-contain" alt="preview" />
    {:else}
      <div class="flex items-center gap-3">
        <Loading />
        <div class="w-full text-center">Creating Preview Image ...</div>
      </div>
    {/if}
    </div>
    <div class="flex items-center mt-3">
      <div class="text-xl">{$_("tile.name")}</div>
      <input bind:value={name} class="ml-2 p-2 flex-1" />
    </div>
  </div>
  <div slot="footer" let:close>
    <div class="flex justify-end items-center px-2 py-3">
      {#if onSaving}
        <Loading />
      {:else if $projectBaseInfo?.slug !== undefined}
        <Button class="mr-4" variant="secondary" title={$_("common.update")} disabled={!$previewImage || !name} 
          on:click={() => {
            update().finally(() => {
              onSaving = false;
              close();
            });
          }} />
        <Button variant="outline-secondary" title={$_("common.clone")} icon="fa-solid fa-clone" disabled={!$previewImage || !name}
          on:click={() => {
            save().finally(() => {
              onSaving = false;
              close();
            });
          }} />
      {:else}
        <Button variant="secondary" title={$_("action_menu.save")} icon="fa-solid fa-print" disabled={!$previewImage || !name}
          on:click={() => {
            save().finally(() => {
              onSaving = false;
              close();
            });
          }} />
        <!-- <button
          on:click={() => {
            save().finally(() => {
              onSaving = false;
              close();
            });
          }}
          disabled={!$previewImage || !name}
          class="px-4 py-2 ml-3 secondary_action_button"
          >{$_("action_menu.save")}</button
        > -->
      {/if}
    </div>
  </div>
</Modal>
