import * as THREE from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import { TileWrapper } from "src/model";
// import { getShapeBoundingRect } from "../helpers";
import { beforeUpdate } from "svelte";
import { limitLayoutTileDepthForDrag } from "../../tools/LayoutTools";
import { Pointer, Segment } from "src/model/";
import { v4 as uuidv4 } from 'uuid';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
export default class Room extends TileWrapper {
    linesGroup;
    tilesGroup;
    mainGroup;
    stencilBufferReference; // 0 is not a valid reference
    previousOffset;
    stencilMeshes;
    backupMaterial;
    clock;
    backupScale;
    groutColor;
    tilesImages;
    constructor(t, index) {
        super(t.startPointer, t.endPointer, t.id, t.closedAreaId, t.parentId, t.shape, t.tileLayout);
        this.copy(t);
        this.stencilBufferReference = index + 1;
        this.previousOffset = new Pointer(0, 0);
        this.stencilMeshes = [];
        this.backupMaterial = [];
        this.clock = new THREE.Clock;
        this.backupScale = [];
        this.tilesImages = [];
    }
    copy(t) {
        this.startPointer = t.startPointer;
        this.endPointer = t.endPointer;
        this.id = t.id;
        this.parentId = t.parentId;
        this.shape = t.shape;
        this.tileLayout = t.tileLayout;
        this.rotation = t.rotation;
        this.offset = t.offset;
        this.snapTile = t.snapTile;
        this.zIndex = t.zIndex;
        this.class = t.class;
        this.layoutGeometryId = t.layoutGeometryId;
        this.tiles = t.tiles;
    }
    getTiles() {
        return this.layoutGeometryId !== -1 ? limitLayoutTileDepthForDrag(this.tileLayout, false) : undefined; // .filter((tile) => tileInShape(tile, segment));
    }
    setLinesColor(newColor) {
        const color = new THREE.Color(newColor);
        for (var child of this.linesGroup.children) {
            let line = child;
            let colorAttr = line.geometry.getAttribute('color');
            for (var i = 0; i < colorAttr.count; i++)
                color.toArray(colorAttr.array, i * colorAttr.itemSize);
        }
    }
    setMeshColor(newColor, indexTiles) {
        const color = new THREE.Color(newColor);
        this.tilesGroup.forEach((tiles, index) => {
            if (indexTiles === -1 || indexTiles === index) {
                for (var tile of tiles.children) {
                    let mesh = tile;
                    mesh.material.color.set(color);
                }
            }
        });
    }
    setGapColor(newColor) {
        this.groutColor = newColor.toString();
        const color = new THREE.Color(newColor);
        for (var mesh of this.stencilMeshes)
            mesh.material.color.set(color);
    }
    animateMesh(indexTiles, highlight) {
        let tiles = this.tilesGroup[indexTiles];
        let length = tiles.children.length;
        if (length === 0)
            return false;
        if (this.clock)
            this.clock.stop();
        if (!highlight)
            this.clock = undefined;
        else
            this.clock = new THREE.Clock;
        return true;
    }
    highlightMesh(indexTiles, highlight) {
        let tiles = this.tilesGroup[indexTiles];
        let length = tiles.children.length;
        if (length === 0)
            return false;
        if (highlight)
            this.backupMaterial = Array(length).fill(undefined);
        for (let i = 0; i < length; ++i) {
            let mesh = tiles.children[i];
            const color = new THREE.Color(highlight ? 0xFF00000 : 0xFFFFFF);
            const material = new THREE.MeshBasicMaterial({ color: color });
            if (highlight) {
                this.backupMaterial[i] = mesh.material;
                mesh.material = material;
            }
            else {
                mesh.material = this.backupMaterial[i];
                this.backupMaterial[i] = undefined;
            }
        }
        if (!highlight)
            this.backupMaterial = [];
        return true;
    }
}
/* shapeBounding = getShapeBoundingRect(segment.shape)
<foreignObject
  x={shapeBounding[0]}
  y={shapeBounding[1]}
  width={shapeBounding[2]}
  height={shapeBounding[3]}
>

  <canvas bind:this={canvas} width={shapeBounding[2]} height={shapeBounding[3]} on:mousedown={mouseDownE} class="segment">
  </canvas> */ 
