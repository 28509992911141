export var TOOLS;
(function (TOOLS) {
    TOOLS[TOOLS["EDIT_ARC"] = 0] = "EDIT_ARC";
    TOOLS[TOOLS["EDIT_SPLIT"] = 1] = "EDIT_SPLIT";
    TOOLS[TOOLS["DRAW_WINDOW"] = 2] = "DRAW_WINDOW";
    TOOLS[TOOLS["DRAW_DOOR"] = 3] = "DRAW_DOOR";
    TOOLS[TOOLS["SELECTION"] = 4] = "SELECTION";
    TOOLS[TOOLS["POLYLINE"] = 5] = "POLYLINE";
    TOOLS[TOOLS["DRAW_LINE"] = 6] = "DRAW_LINE";
    TOOLS[TOOLS["EYE_DROPPER"] = 7] = "EYE_DROPPER";
})(TOOLS || (TOOLS = {}));
export var SIDE_MENUS;
(function (SIDE_MENUS) {
    SIDE_MENUS[SIDE_MENUS["ROOM"] = 0] = "ROOM";
    SIDE_MENUS[SIDE_MENUS["AREA"] = 1] = "AREA";
    SIDE_MENUS[SIDE_MENUS["WALL"] = 2] = "WALL";
    SIDE_MENUS[SIDE_MENUS["LAYOUT"] = 3] = "LAYOUT";
    SIDE_MENUS[SIDE_MENUS["DOOR"] = 4] = "DOOR";
    SIDE_MENUS[SIDE_MENUS["WINDOW"] = 5] = "WINDOW";
    SIDE_MENUS[SIDE_MENUS["FURNITURE"] = 6] = "FURNITURE";
})(SIDE_MENUS || (SIDE_MENUS = {}));
export var SEGMENT_LIST_TYPE;
(function (SEGMENT_LIST_TYPE) {
    SEGMENT_LIST_TYPE["BUILDING_PART"] = "BuildingPart";
    SEGMENT_LIST_TYPE["DOOR"] = "Door";
    SEGMENT_LIST_TYPE["LINE"] = "Line";
    SEGMENT_LIST_TYPE["TILE_WRAPPER"] = "TileWrapper";
    SEGMENT_LIST_TYPE["LAYOUT"] = "Layout";
    SEGMENT_LIST_TYPE["TILED_LAYOUT"] = "TILED_LAYOUT";
    SEGMENT_LIST_TYPE["AREA"] = "Area";
    SEGMENT_LIST_TYPE["SHAPE"] = "Shape";
})(SEGMENT_LIST_TYPE || (SEGMENT_LIST_TYPE = {}));
export var PAGES;
(function (PAGES) {
    PAGES[PAGES["NEW_PROJECT_STEP_1"] = 0] = "NEW_PROJECT_STEP_1";
    PAGES[PAGES["NEW_PROJECT_STEP_2"] = 1] = "NEW_PROJECT_STEP_2";
    PAGES[PAGES["BOARD"] = 2] = "BOARD";
    PAGES[PAGES["PROJECTS_LIST"] = 3] = "PROJECTS_LIST";
})(PAGES || (PAGES = {}));
