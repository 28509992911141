<script lang="ts">
  import { BuildingPart, Pointer } from "../../model";
  import { drawSend, drawState } from "../../layout.store";

  export let scale: number;
  const delta = 3;

  $: selectedObject = $drawState.context.dragContext
    .selectedObject as BuildingPart;
  $: centerPointer = new Pointer(
    selectedObject.startPointer.x + selectedObject.width / 2,
    selectedObject.startPointer.y + selectedObject.length / 2
  );
  $: pointers = [
    new Pointer(
      selectedObject.startPointer.x - delta,
      selectedObject.startPointer.y - delta
    ),
    new Pointer(
      selectedObject.startPointer.x + selectedObject.width - delta,
      selectedObject.startPointer.y - delta
    ),
    new Pointer(
      selectedObject.startPointer.x - delta,
      selectedObject.startPointer.y + selectedObject.length - delta
    ),
    new Pointer(
      selectedObject.startPointer.x + selectedObject.width - delta,
      selectedObject.startPointer.y + selectedObject.length - delta
    ),
    new Pointer(
      selectedObject.startPointer.x + selectedObject.width / 2 - delta,
      selectedObject.startPointer.y - delta
    ),
    new Pointer(
      selectedObject.startPointer.x + selectedObject.width / 2 - delta,
      selectedObject.startPointer.y + selectedObject.length - delta
    ),
    new Pointer(
      selectedObject.startPointer.x - delta,
      selectedObject.startPointer.y + selectedObject.length / 2 - delta
    ),
    new Pointer(
      selectedObject.startPointer.x + selectedObject.width - delta,
      selectedObject.startPointer.y + selectedObject.length / 2 - delta
    ),
  ];

  $: snapInfos = selectedObject.getSnapInfos(
    $drawState.context.current.segments
  );

  const segmentHovered = () => {
    if ($drawState.matches("main.drawingState")) return;
    drawSend({ type: "MOUSE_HOVER" });
  };

  const segmentMouseLeave = () => {
    if ($drawState.matches("main.drawingState")) return;
    drawSend({ type: "MOUSE_LEAVE" });
  };
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<g
  class="group .print-none"
  transform={`rotate(${snapInfos.deltaAngle} ${snapInfos.snapPointer?.x ?? 0} ${
    snapInfos.snapPointer?.y ?? 0
  }) translate(${snapInfos.distance.deltaX} ${
    snapInfos.distance.deltaY
  }) rotate(${selectedObject.rotation} ${centerPointer.x} ${centerPointer.y})`}
  on:mouseover={() => segmentHovered()}
  on:mouseleave={() => segmentMouseLeave()}
>
  <g transform={`translate(${selectedObject.startPointer.x}, ${selectedObject.startPointer.y})`}>
    <rect width="{selectedObject.width}" height="{selectedObject.length}" stroke-width="1" fill="none" class="stroke-secondary group-hover:stroke-primary"/>
  </g>

  {#each pointers as p, i}
    <g
      transform={`translate(${p.x}, ${p.y})`}
    >
      <!-- <path
        d="M10,0,0,0,0,10"
        stroke-width={`${6 / scale}px`}
        fill="none"
        class="resize-indicator"
        id={`indicator_${i + 1}`}
      /> -->
      <rect width="6" height="6" stroke-width="0" class="fill-secondary" />
      <rect x="1" y="1" width="4" height="4" stroke-width="0" 
        class="resize-indicator {$drawState.matches("main.selectState.resizing") && $drawState.context.dragContext.resizeIndicator === i + 1 ? "fill-primary" : "fill-white"} 
          {$drawState.matches("main.selectState.resizing") ? "" : "hover:fill-primary"}"
        id={`indicator_${i + 1}`}
      />
    </g>
  {/each}
</g>