import { SegmentClass } from ".";
import { BuildingPart } from "./BuildingPart";
import { Pointer } from "./Pointer";
import { dist, getHelperTextAngle, getTextPointer } from "../helpers";
import { v4 as uuidv4 } from 'uuid';
export var DOORTYPE;
(function (DOORTYPE) {
    DOORTYPE["DOOR"] = "door";
    DOORTYPE["WINDOW"] = "window";
})(DOORTYPE || (DOORTYPE = {}));
export var FOLDINGTYPE;
(function (FOLDINGTYPE) {
    FOLDINGTYPE["KNOCKING"] = "knocking";
    FOLDINGTYPE["FOLDING"] = "folding";
    FOLDINGTYPE["SLIDING"] = "sliding";
})(FOLDINGTYPE || (FOLDINGTYPE = {}));
export class Door extends BuildingPart {
    startPointer;
    endPointer;
    parentId;
    width;
    length;
    rotation;
    buildingType;
    buildingId;
    id;
    zIndex;
    name;
    foldingType;
    openToOutSide;
    wallSide;
    wallOpening;
    constructor(startPointer, endPointer, parentId = 0, width = 0, length = 210, //default length
    rotation = 0, buildingType = DOORTYPE.DOOR, buildingId = 0, id = uuidv4(), zIndex = 0, name = "", foldingType = FOLDINGTYPE.KNOCKING, openToOutSide = false, wallSide = true, wallOpening = true) {
        super(startPointer, endPointer, width, length, parentId, rotation, buildingType, buildingId, parentId, id, zIndex, name);
        this.startPointer = startPointer;
        this.endPointer = endPointer;
        this.parentId = parentId;
        this.width = width;
        this.length = length;
        this.rotation = rotation;
        this.buildingType = buildingType;
        this.buildingId = buildingId;
        this.id = id;
        this.zIndex = zIndex;
        this.name = name;
        this.foldingType = foldingType;
        this.openToOutSide = openToOutSide;
        this.wallSide = wallSide;
        this.wallOpening = wallOpening;
        this.class = SegmentClass.DOOR;
    }
    generatePath(isFromShape = false, startPointer = undefined) {
        return "";
    }
    generateHelperPath() {
        if (this.startPointer.equals(this.endPointer)) {
            return [];
        }
        const helperLength = 7;
        const result = [];
        let path = "";
        const helpStartPointer = new Pointer(this.startPointer.x +
            ((this.openToOutSide ? -1 : 1) *
                ((this.endPointer.y - this.startPointer.y) * helperLength)) /
                dist(this.startPointer, this.endPointer), this.startPointer.y -
            ((this.openToOutSide ? -1 : 1) *
                ((this.endPointer.x - this.startPointer.x) * helperLength)) /
                dist(this.startPointer, this.endPointer));
        const helperCenterPointer = new Pointer((this.startPointer.x + helpStartPointer.x) / 2, (this.startPointer.y + helpStartPointer.y) / 2);
        path =
            path +
                "M" +
                this.startPointer.x +
                "," +
                this.startPointer.y +
                " " +
                "L" +
                helpStartPointer.x +
                "," +
                helpStartPointer.y;
        const angleRotate = (1 / 4) * Math.PI;
        let deltaX = (this.startPointer.x - helperCenterPointer.x) * Math.cos(angleRotate) -
            (this.startPointer.y - helperCenterPointer.y) * Math.sin(angleRotate);
        let deltaY = (this.startPointer.x - helperCenterPointer.x) * Math.sin(angleRotate) +
            (this.startPointer.y - helperCenterPointer.y) * Math.cos(angleRotate);
        const helper1 = helperCenterPointer.translate(deltaX, deltaY);
        deltaX =
            (helpStartPointer.x - helperCenterPointer.x) * Math.cos(angleRotate) -
                (helpStartPointer.y - helperCenterPointer.y) * Math.sin(angleRotate);
        deltaY =
            (helpStartPointer.x - helperCenterPointer.x) * Math.sin(angleRotate) +
                (helpStartPointer.y - helperCenterPointer.y) * Math.cos(angleRotate);
        const helper2 = helperCenterPointer.translate(deltaX, deltaY);
        path =
            path +
                " " +
                "M" +
                helper1.x +
                "," +
                helper1.y +
                " " +
                "L" +
                helper2.x +
                "," +
                helper2.y;
        const helpEndPointer = new Pointer(this.endPointer.x -
            ((this.openToOutSide ? -1 : 1) *
                ((this.startPointer.y - this.endPointer.y) * helperLength)) /
                dist(this.endPointer, this.startPointer), this.endPointer.y +
            ((this.openToOutSide ? -1 : 1) *
                ((this.startPointer.x - this.endPointer.x) * helperLength)) /
                dist(this.endPointer, this.startPointer));
        path =
            path +
                " " +
                "M" +
                this.endPointer.x +
                "," +
                this.endPointer.y +
                " " +
                "L" +
                helpEndPointer.x +
                "," +
                helpEndPointer.y +
                ",";
        const helperCenterPointer2 = new Pointer((this.endPointer.x + helpEndPointer.x) / 2, (this.endPointer.y + helpEndPointer.y) / 2);
        deltaX =
            (this.endPointer.x - helperCenterPointer2.x) * Math.cos(angleRotate) -
                (this.endPointer.y - helperCenterPointer2.y) * Math.sin(angleRotate);
        deltaY =
            (this.endPointer.x - helperCenterPointer2.x) * Math.sin(angleRotate) +
                (this.endPointer.y - helperCenterPointer2.y) * Math.cos(angleRotate);
        const helper3 = helperCenterPointer2.translate(deltaX, deltaY);
        deltaX =
            (helpEndPointer.x - helperCenterPointer2.x) * Math.cos(angleRotate) -
                (helpEndPointer.y - helperCenterPointer2.y) * Math.sin(angleRotate);
        deltaY =
            (helpEndPointer.x - helperCenterPointer2.x) * Math.sin(angleRotate) +
                (helpEndPointer.y - helperCenterPointer2.y) * Math.cos(angleRotate);
        const helper4 = helperCenterPointer2.translate(deltaX, deltaY);
        path =
            path +
                " " +
                "M" +
                helper3.x +
                "," +
                helper3.y +
                " " +
                "L" +
                helper4.x +
                "," +
                helper4.y;
        path =
            path +
                " " +
                "M" +
                helperCenterPointer.x +
                "," +
                helperCenterPointer.y +
                " " +
                "L" +
                helperCenterPointer2.x +
                "," +
                helperCenterPointer2.y;
        result.push(path);
        return result;
    }
    generateHelperTextData() {
        return [
            {
                x: this.openToOutSide
                    ? getTextPointer(this.endPointer, this.startPointer).x
                    : getTextPointer(this.startPointer, this.endPointer).x,
                y: this.openToOutSide
                    ? getTextPointer(this.endPointer, this.startPointer).y
                    : getTextPointer(this.startPointer, this.endPointer).y,
                angle: getHelperTextAngle(this.startPointer, this.endPointer),
                length: this.getLineLength(),
            },
        ];
    }
    getRealTransition(dx, dy, svgSize) {
        return { dx: dx, dy: dy };
    }
    getPointsArray() {
        return [];
    }
    translate(dx, dy, segments) {
        this.startPointer = this.startPointer.translate(dx, dy);
        this.endPointer = this.endPointer.translate(dx, dy);
    }
    getLineLength() {
        return dist(this.startPointer, this.endPointer);
    }
    isInSegment(pointer, acceptInPath = true) {
        return false;
    }
    getSnapInfos = (segments) => {
        const length = this.length / 2;
        const width = this.width / 2;
        const centerPointer = new Pointer(this.startPointer.x + width, this.startPointer.y + length);
        return {
            snapPointer: centerPointer,
            snapLinePointer1: centerPointer,
            snapLinePointer2: centerPointer,
            lineAngle: 0,
            deltaAngle: 0,
            distance: {
                deltaX: 0,
                deltaY: 0,
            },
        };
    };
    getRectPoints() {
        return [];
    }
    clone() {
        return new Door(new Pointer(this.startPointer.x, this.startPointer.y), new Pointer(this.endPointer.x, this.endPointer.y), this.parentId, this.width, this.length, this.rotation, this.buildingType, this.buildingId, this.id, this.zIndex, this.name, this.foldingType, this.openToOutSide, this.wallSide, this.wallOpening);
    }
    toJSON() {
        return {
            ...super.toJSON(),
            foldingType: this.foldingType,
            openToOutSide: this.openToOutSide,
            wallSide: this.wallSide,
            wallOpening: this.wallOpening,
        };
    }
}
