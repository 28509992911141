import { uniqueNumbers } from "./Tools";
export class NeighborOffset {
    absolute;
    relativeToGap;
    constructor(absolute = 0, relativeToGap = 0) {
        this.absolute = absolute;
        this.relativeToGap = relativeToGap;
        // this.relativeToGap = 1;
    }
    offset(gapSize) {
        return this.absolute + gapSize * this.relativeToGap;
    }
    isZero() {
        return (Math.abs(this.absolute) < 1e-5 && Math.abs(this.relativeToGap) < 1e-5);
    }
    add(other) {
        return new NeighborOffset(this.absolute + other.absolute, this.relativeToGap + other.relativeToGap);
    }
    scale(by) {
        return new NeighborOffset(this.absolute * by, this.relativeToGap * by);
    }
    subtract(other) {
        return new NeighborOffset(this.absolute - other.absolute, this.relativeToGap - other.relativeToGap);
    }
}
export default class Neighbor {
    source;
    target;
    offset;
    snapPoints;
    sourceEdgeLength;
    targetEdgeLength;
    constructor(source, target, offset = new NeighborOffset()) {
        this.source = source;
        this.target = target;
        this.offset = offset;
        this.sourceEdgeLength = this.source.edge.length;
        this.targetEdgeLength = this.target.edge.length;
        let basePoints = [];
        const minPoint = (-this.sourceEdgeLength - this.targetEdgeLength) / 2;
        let mainPoint = minPoint;
        let srcFlag = "0";
        basePoints.push({ position: mainPoint, srcFlag, destFlag: "1" });
        basePoints.push({ position: mainPoint + this.targetEdgeLength / 4, srcFlag, destFlag: "3/4" });
        basePoints.push({ position: mainPoint + this.targetEdgeLength / 3, srcFlag, destFlag: "2/3" });
        basePoints.push({ position: mainPoint + this.targetEdgeLength / 2, srcFlag, destFlag: "1/2" });
        basePoints.push({ position: mainPoint + this.targetEdgeLength * 2 / 3, srcFlag, destFlag: "1/3" });
        basePoints.push({ position: mainPoint + this.targetEdgeLength * 3 / 4, srcFlag, destFlag: "1/4" });
        basePoints.push({ position: mainPoint + this.targetEdgeLength, srcFlag, destFlag: "0" });
        mainPoint = minPoint + this.sourceEdgeLength / 4;
        srcFlag = "1/4";
        basePoints.push({ position: mainPoint, srcFlag, destFlag: "1" });
        // basePoints.push({ position: mainPoint + this.targetEdgeLength / 4, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength / 3, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength / 2, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength * 2 / 3, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength * 3 / 4, srcFlag })
        basePoints.push({ position: mainPoint + this.targetEdgeLength, srcFlag, destFlag: "0" });
        mainPoint = minPoint + this.sourceEdgeLength / 3;
        srcFlag = "1/3";
        basePoints.push({ position: mainPoint, srcFlag, destFlag: "1" });
        // basePoints.push({ position: mainPoint + this.targetEdgeLength / 4, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength / 3, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength / 2, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength * 2 / 3, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength * 3 / 4, srcFlag })
        basePoints.push({ position: mainPoint + this.targetEdgeLength, srcFlag, destFlag: "0" });
        mainPoint = minPoint + this.sourceEdgeLength / 2;
        srcFlag = "1/2";
        basePoints.push({ position: mainPoint, srcFlag, destFlag: "1" });
        // basePoints.push({ position: mainPoint + this.targetEdgeLength / 4, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength / 3, srcFlag })
        basePoints.push({ position: mainPoint + this.targetEdgeLength / 2, srcFlag, destFlag: "1/2" });
        // basePoints.push({ position: mainPoint + this.targetEdgeLength * 2 / 3, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength * 3 / 4, srcFlag })
        basePoints.push({ position: mainPoint + this.targetEdgeLength, srcFlag, destFlag: "0" });
        mainPoint = minPoint + this.sourceEdgeLength * 2 / 3;
        srcFlag = "2/3";
        basePoints.push({ position: mainPoint, srcFlag, destFlag: "1" });
        // basePoints.push({ position: mainPoint + this.targetEdgeLength / 4, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength / 3, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength / 2, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength * 2 / 3, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength * 3 / 4, srcFlag })
        basePoints.push({ position: mainPoint + this.targetEdgeLength, srcFlag, destFlag: "0" });
        mainPoint = minPoint + this.sourceEdgeLength * 3 / 4;
        srcFlag = "3/4";
        basePoints.push({ position: mainPoint, srcFlag, destFlag: "1" });
        // basePoints.push({ position: mainPoint + this.targetEdgeLength / 4, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength / 3, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength / 2, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength * 2 / 3, srcFlag })
        // basePoints.push({ position: mainPoint + this.targetEdgeLength * 3 / 4, srcFlag })
        basePoints.push({ position: mainPoint + this.targetEdgeLength, srcFlag, destFlag: "0" });
        mainPoint = minPoint + this.sourceEdgeLength;
        srcFlag = "1";
        basePoints.push({ position: mainPoint, srcFlag, destFlag: "1" });
        basePoints.push({ position: mainPoint + this.targetEdgeLength / 4, srcFlag, destFlag: "1/4" });
        basePoints.push({ position: mainPoint + this.targetEdgeLength / 3, srcFlag, destFlag: "1/3" });
        basePoints.push({ position: mainPoint + this.targetEdgeLength / 2, srcFlag, destFlag: "1/2" });
        basePoints.push({ position: mainPoint + this.targetEdgeLength * 2 / 3, srcFlag, destFlag: "2/3" });
        basePoints.push({ position: mainPoint + this.targetEdgeLength * 3 / 4, srcFlag, destFlag: "3/4" });
        basePoints.push({ position: mainPoint + this.targetEdgeLength, srcFlag, destFlag: "0" });
        this.snapPoints = uniqueNumbers(basePoints, (snap) => snap.position);
    }
    /**
     * Scale number on another range
     */
    static scale(inputY, yRange, xRange) {
        const [xMin, xMax] = xRange;
        const [yMin, yMax] = yRange;
        const percent = (inputY - yMin) / (yMax - yMin);
        const outputX = percent * (xMax - xMin) + xMin;
        return outputX;
    }
    /**
     * Calculates internal movement range values ​​given the length of the sides
     */
    static movementRange(sourceEdgeLength, targetEdgeLength) {
        return [
            -sourceEdgeLength / 2 - targetEdgeLength / 2,
            sourceEdgeLength / 2 + targetEdgeLength / 2,
        ];
    }
    reversed() {
        return new Neighbor(this.target, this.source, this.offset);
    }
    get targetShape() {
        return this.target.shape;
    }
    get sourceShape() {
        return this.source.shape;
    }
    /**
     * Calculates neighbor internal movement range values
     */
    get movementRange() {
        return [
            -this.sourceEdgeLength / 2 - this.targetEdgeLength / 2,
            this.sourceEdgeLength / 2 + this.targetEdgeLength / 2,
        ];
    }
    isReverse(of) {
        return (this.sourceShape === of.targetShape &&
            this.source.index === of.target.index &&
            this.targetShape === of.sourceShape &&
            this.target.index === of.source.index);
    }
    withOffset(newOffset) {
        return new Neighbor(this.source, this.target, newOffset);
    }
    toJSON(shapes) {
        return {
            offset: {
                // WHY divide by source shape edge length?????
                // absolute: this.offset.absolute / this.source.edge.length,
                absolute: this.offset.absolute,
                relativeToGap: this.offset.relativeToGap,
            },
            source: this.source.toJSON(shapes),
            target: this.target.toJSON(shapes),
        };
    }
}
