import { TOLERANCE_DISTANCE } from "src/global/variable";
import { LineSegment, ArcSegment, CubicBezierSegment, QuadraticBezierSegment, } from "./Path";
import Vector2 from "./Vector2";
export class PathHandler {
    ignoreSegmentsShorterThan;
    first = undefined;
    last = undefined;
    lastConstructor = undefined;
    lastSegment;
    shapes = [];
    constructor(ignoreSegmentsShorterThan = 0.1 // TOLERANCE_DISTANCE
    ) {
        this.ignoreSegmentsShorterThan = ignoreSegmentsShorterThan;
    }
    getResult() {
        return this.shapes;
    }
    beginParse() {
        // zero out the sub-path array
        this.shapes = [];
        this.first = undefined;
        this.last = undefined;
        // need to remember last command constructor to properly close paths
        this.lastConstructor = undefined;
    }
    addShape(shape) {
        this.shapes.push(shape);
    }
    arcAbs(rx, ry, xAxisRotation, arcFlag, sweepFlag, x, y) {
        this.arc(rx, ry, xAxisRotation, arcFlag, sweepFlag, x, y, false);
    }
    arcRel(rx, ry, xAxisRotation, arcFlag, sweepFlag, x, y) {
        this.arc(rx, ry, xAxisRotation, arcFlag, sweepFlag, x, y, true);
    }
    curvetoCubicAbs(x1, y1, x2, y2, x, y) {
        this.cubic(x1, y1, x2, y2, x, y, false);
    }
    curvetoCubicRel(x1, y1, x2, y2, x, y) {
        this.cubic(x1, y1, x2, y2, x, y, true);
    }
    linetoHorizontalAbs(x) {
        this.line(x, undefined, false);
    }
    linetoHorizontalRel(x) {
        this.line(x, undefined, true);
    }
    linetoAbs(x, y) {
        this.line(x, y, false);
    }
    linetoRel(x, y) {
        this.line(x, y, false);
    }
    movetoAbs(x, y) {
        this.first = this.last = new Vector2(x, y);
        this.lastSegment = undefined;
        this.lastConstructor = undefined;
    }
    movetoRel(x, y) {
        if (this.first === undefined || this.last === undefined) {
            this.movetoAbs(x, y);
            return;
        }
        const p = new Vector2(x, y);
        this.first = this.first.add(p);
        this.last = this.last.add(p);
        this.lastSegment = undefined;
        this.lastConstructor = undefined;
    }
    curvetoQuadraticAbs(x1, y1, x, y) {
        this.quadratic(x1, y1, x, y, false);
    }
    curvetoQuadraticRel(x1, y1, x, y) {
        this.quadratic(x1, y1, x, y, true);
    }
    curvetoCubicSmoothAbs(x2, y2, x, y) {
        this.cubicSmooth(x2, y2, x, y, false);
    }
    curvetoCubicSmoothRel(x2, y2, x, y) {
        this.cubicSmooth(x2, y2, x, y, true);
    }
    curvetoQuadraticSmoothAbs(x, y) {
        this.quadraticSmooth(x, y, false);
    }
    curvetoQuadraticSmoothRel(x, y) {
        this.quadraticSmooth(x, y, true);
    }
    linetoVerticalAbs(y) {
        this.line(undefined, y, false);
    }
    linetoVerticalRel(y) {
        this.line(undefined, y, true);
    }
    closePath() {
        if (this.last === undefined || this.first === undefined) {
            return;
        }
        if (this.last.distanceSquared(this.first) <=
            this.ignoreSegmentsShorterThan * this.ignoreSegmentsShorterThan &&
            this.lastConstructor !== undefined &&
            this.shapes.length > 0) {
            const lastShape = this.shapes[this.shapes.length - 1];
            if (lastShape.end === this.first) {
                return;
            }
            // Make sure the last segment ends with the first point
            this.shapes[this.shapes.length - 1] = this.lastConstructor(lastShape.start, this.first);
            return;
        }
        this.line(this.first.x, this.first.y, false);
    }
    createSegment(relative, endX, endY, segmentConstructor) {
        if (this.last === undefined) {
            return;
        }
        let p = new Vector2(endX, endY);
        if (relative) {
            p = p.add(this.last);
        }
        const newSegment = segmentConstructor(this.last, p);
        this.lastSegment = newSegment;
        if (newSegment.length <= this.ignoreSegmentsShorterThan) {
            return;
        }
        this.addShape(newSegment);
        this.last = p;
        this.lastConstructor = segmentConstructor;
    }
    arc(rx, ry, xAxisRotation, arcFlag, sweepFlag, x, y, relative) {
        function createArc(start, end) {
            if (rx === 0 || ry === 0) {
                return new LineSegment(start, end);
            }
            else {
                return new ArcSegment(start, end, rx, ry, xAxisRotation, arcFlag, sweepFlag);
            }
        }
        this.createSegment(relative, x, y, createArc);
    }
    cubic(x1, y1, x2, y2, x, y, relative) {
        function createCubic(start, end) {
            const cp1 = new Vector2(x1, y1);
            const cp2 = new Vector2(x2, y2);
            return CubicBezierSegment.fromPoints(start, relative ? cp1.add(start) : cp1, relative ? cp2.add(start) : cp2, end);
        }
        this.createSegment(relative, x, y, createCubic);
        if (this.last === undefined) {
            return;
        }
    }
    line(x, y, relative) {
        function createLine(start, end) {
            return new LineSegment(start, end);
        }
        if (this.last === undefined) {
            return;
        }
        const finalX = x === undefined ? (relative ? 0 : this.last.x) : x;
        const finalY = y === undefined ? (relative ? 0 : this.last.y) : y;
        this.createSegment(relative, finalX, finalY, createLine);
    }
    quadratic(x1, y1, x, y, relative) {
        function createQuadratic(start, end) {
            const cp = new Vector2(x1, y1);
            return QuadraticBezierSegment.fromPoints(start, relative ? cp.add(start) : cp, end);
        }
        this.createSegment(relative, x, y, createQuadratic);
    }
    cubicSmooth(x2, y2, x, y, relative) {
        const lastSegment = this.lastSegment;
        const createSmooth = (start, end) => {
            let control = start;
            if (lastSegment !== undefined) {
                let secondToLast;
                if (lastSegment instanceof CubicBezierSegment) {
                    secondToLast = lastSegment.controlPoint2;
                }
                else if (lastSegment instanceof QuadraticBezierSegment) {
                    secondToLast = lastSegment.controlPoint;
                }
                if (secondToLast !== undefined) {
                    control = new Vector2(2 * start.x - secondToLast.x, 2 * start.y - secondToLast.y);
                }
            }
            const cp2 = new Vector2(x2, y2);
            return CubicBezierSegment.fromPoints(start, control, relative ? cp2.add(start) : cp2, end);
        };
        this.createSegment(relative, x, y, createSmooth);
    }
    quadraticSmooth(x, y, relative) {
        const lastSegment = this.lastSegment;
        const createSmooth = (start, end) => {
            let control = start;
            if (lastSegment !== undefined) {
                let secondToLast;
                if (lastSegment instanceof QuadraticBezierSegment) {
                    secondToLast = lastSegment.controlPoint;
                }
                else if (lastSegment instanceof CubicBezierSegment) {
                    secondToLast = lastSegment.controlPoint2;
                }
                if (secondToLast !== undefined) {
                    control = new Vector2(2 * start.x - secondToLast.x, 2 * start.y - secondToLast.y);
                }
            }
            return QuadraticBezierSegment.fromPoints(start, control, end);
        };
        this.createSegment(relative, x, y, createSmooth);
    }
}
