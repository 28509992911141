<script lang="ts">
  import { activeMenu, editSegment, selectedRoom, visibleSideMenu } from "src/store";
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import Header from "./Header.svelte";
  import GridItem from "./GridItem.svelte";
  import Icon from "../../../components/base/Icon.svelte";
  import Button from "../../../components/base/Button.svelte";
  import {
  checkBuildingPartInRoom,
  getNewSegmentName,
    getSquareWithUnit,
    isBuildingPart,
    isClosedArea,
    isDoor,
    isLine,
    isTileWrapper,
  } from "src/helpers";
  import {
    type TileWrapper,
    type Line,
    type BuildingPart,
    Door,
    Pointer,
    DOORTYPE,
  } from "src/model";
  import ListMenuItem from "./ListMenuItem.svelte";
  import { getMetricWithUnit, createDoor } from "src/helpers";
  import { SEGMENT_LIST_TYPE, SIDE_MENUS } from "src/global/types";
  import type { CategoryElement } from "src/model/CategoryElement";
  import WallInfo from "./WallInfo.svelte";
  import DoorInfo from "./DoorInfo.svelte";
  import SegmentList from "./SegmentList.svelte";
  import FurnitureCategories from "./FurnitureCategories.svelte";
  import FurnitureItemList from "./FurnitureItemList.svelte";
  import FurnitureInfo from "./FurnitureInfo.svelte";
  import AreaInfo from "./AreaInfo.svelte";
  import { ClosedArea } from "src/model/ClosedArea";
  import { onDestroy, onMount } from "svelte";
  import { METRIC_UNITS } from "src/global/variable";

  export let categories: CategoryElement[] = [];

  enum ROOM_VIEW {
    INDEX = "index",
    LIST_MENU = "list-menu",
    EDIT_LAYOUT = "rooms_menu.layout",
    WALLS = "walls",
    WALL_DETAIL = "wall-info",
    DOORS = "door.list_door",
    ADD_DOOR = "door.add_door",
    EDIT_DOOR = "door.edit_door",
    WINDOWS = "door.list_window",
    ADD_WINDOW = "door.add_window",
    EDIT_WINDOW = "door.edit_window",
    FURNITURES = "furniture.list_furniture",
    FURNITURE_CATEGORY_LIST = "furniture-category-list",
    FURNITURE_ITEM_LIST = "furniture-item-list",
    EDIT_FURNITURE = "furniture.edit_furniture",
    AREAS = "area.list_area",
    EDIT_AREA = "area.edit_area",
  }

  let routes: ROOM_VIEW[] = [ROOM_VIEW.INDEX];

  let currentView: ROOM_VIEW = ROOM_VIEW.INDEX;
  let headerInfo = {
    title: "",
    info: "",
    path: "",
    editable: false,
  };
  let currentWall: Line;
  let currentCategory: CategoryElement;
  
  let isValidSegment: boolean;
  let areas: TileWrapper[];
  let walls: Line[];
  let unsubscribe;

  onMount(() => {
    unsubscribe = visibleSideMenu.subscribe((visible) => {
      if( $activeMenu !== SIDE_MENUS.ROOM )
        return;

      if (!visible) {
        handleBack();
        routes = [ROOM_VIEW.INDEX];
        currentView = ROOM_VIEW.INDEX;
        currentWall = undefined;
      } else if ($editSegment) {
        if( isClosedArea($editSegment) ) {
          selectedRoom.set($editSegment);
          routes = [ROOM_VIEW.INDEX, ROOM_VIEW.LIST_MENU];
          currentView = ROOM_VIEW.LIST_MENU
        } else {
          selectedRoom.set(undefined);
          routes = [ROOM_VIEW.INDEX];
          currentView = ROOM_VIEW.INDEX;
        }
        editSegment.set(undefined);
      }
    });
  })

  $: rooms = $drawState.context.current.segments.filter((segment) =>
    isClosedArea(segment)
  ).sort((a, b) => a.name.localeCompare(b.name)) as ClosedArea[];

  $: {
    if( currentView === ROOM_VIEW.AREAS ) 
      areas = $selectedRoom?.tileWrappers.sort((a, b) => a.name.localeCompare(b.name)) as TileWrapper[];
    else if ( currentView === ROOM_VIEW.WALLS || currentView === ROOM_VIEW.DOORS || currentView === ROOM_VIEW.WINDOWS )
      walls = $selectedRoom ? $selectedRoom?.shape?.results.filter(
        (segment) => isLine(segment) && !(segment as Line).isDashed()
      ).sort((a, b) => a.name.localeCompare(b.name)) as Line[] : [];
  }

  $: allDoors = $drawState.context.current.segments.filter(
    (segment) =>
      isDoor(segment) &&
      walls?.some((wall) => wall.id === (segment as Door).parentId)
  ).sort((a, b) => a.name.localeCompare(b.name)) as Door[];

  $: buildingDoors = allDoors.filter(
    (segment) => segment.buildingType === DOORTYPE.DOOR
  ) as Door[];

  $: buildingWindows = allDoors.filter(
    (segment) => segment.buildingType === DOORTYPE.WINDOW
  ) as Door[];

  $: buildingParts = $drawState.context.current.segments.filter((segment) =>
    isBuildingPart(segment) && (segment as BuildingPart).closedAreaId === $selectedRoom?.id
  ) as BuildingPart[];

  const handleNext = (view: ROOM_VIEW) => {
    currentView = view;
    routes.push(currentView);
  };

  const handleBack = () => {
    drawSend({
      type: "CONFIRM_UPDATE",
    });
    routes.pop();
    if (currentView === ROOM_VIEW.EDIT_FURNITURE) {
      routes.pop();
      routes.pop();      
    }
    if (routes.length) currentView = routes[routes.length - 1];
  };

  const handleReset = () => {
    drawSend({
      type: "CANCEL_UPDATE",
    });
  }

  const handleSelectRoom = (e: CustomEvent) => {
    const room = e.detail.segment;
    selectedRoom.set(room);
    drawSend({
      type: "ENTER_SELECT",
      segment: room,
    });
    currentView = ROOM_VIEW.LIST_MENU;
    routes.push(currentView);
  };

  const handleSelectWall = (e: CustomEvent) => {
    currentWall = e.detail.segment;
    handleNext(ROOM_VIEW.WALL_DETAIL);
  };

  const handleSelectDoor = (e: CustomEvent) => {
    let currentDoor = e.detail.segment;
    if (!currentDoor) {
      const line = isLine(currentWall) ? (currentWall as Line) : $selectedRoom?.shape?.results.find((v) => isLine(v) && !(v as Line).isDashed()) as Line;
      const doorName = getNewSegmentName($_("segment.door"), $drawState.context.current.segments)
      currentDoor = createDoor(line, DOORTYPE.DOOR, doorName);

      drawSend({
        type: "CREATE_BUILDING_PART",
        segment: currentDoor,
      });
    }
    drawSend({
      type: "ENTER_SELECT",
      segment: currentDoor,
    });
    handleNext(e.detail.segment ? ROOM_VIEW.EDIT_DOOR : ROOM_VIEW.ADD_DOOR);
  };

  const handleSelectWindow = (e: CustomEvent) => {
    let currentWindow = e.detail.segment;
    if (!currentWindow) {
      const line = isLine(currentWall) ? (currentWall as Line) : $selectedRoom?.shape?.results.find((v) => isLine(v) && !(v as Line).isDashed()) as Line;
      const windowName = getNewSegmentName($_("segment.window"), $drawState.context.current.segments)
      currentWindow = createDoor(line, DOORTYPE.WINDOW, windowName);

      drawSend({
        type: "CREATE_BUILDING_PART",
        segment: currentWindow,
      });
    }
    drawSend({
      type: "ENTER_SELECT",
      segment: currentWindow,
    });
    handleNext(e.detail.segment ? ROOM_VIEW.EDIT_WINDOW : ROOM_VIEW.ADD_WINDOW);
  };

  const handleSelectFurniture = (e: CustomEvent) => {
    let current = e.detail.segment;
    if (current) {
      drawSend({
        type: "ENTER_SELECT",
        segment: current,
      });
    }
    handleNext(
      e.detail.segment
        ? ROOM_VIEW.EDIT_FURNITURE
        : ROOM_VIEW.FURNITURE_CATEGORY_LIST
    );
  };

  const handleSelectArea = (e: CustomEvent) => {
    let current = e.detail.segment;
    if (current) {
      drawSend({
        type: "ENTER_SELECT",
        segment: current,
      });
    }
    handleNext(ROOM_VIEW.EDIT_AREA);
  };

  const handleSelectCategory = (e: CustomEvent) => {
    currentCategory = e.detail.category;
    handleNext(ROOM_VIEW.FURNITURE_ITEM_LIST);
  };

  
  $: {
    isValidSegment = true;
    const selectedObj = $drawState.context.dragContext.selectedObject;
    if ( selectedObj ) {
      if( currentView === ROOM_VIEW.EDIT_FURNITURE && isBuildingPart(selectedObj) ) {
        const buildingPart = selectedObj as BuildingPart;
        const closedArea = $drawState.context.current.segments.find(
            (s) =>
              s instanceof ClosedArea &&
              s.id === buildingPart.closedAreaId
          ) as ClosedArea;
        isValidSegment = closedArea && checkBuildingPartInRoom(buildingPart, closedArea)
      }
    }
  }

  $: {
    let areaShape;
    switch (currentView) {
      case ROOM_VIEW.INDEX:
        headerInfo = {
          title: $_("side_menu.rooms"),
          info: "",
          path: "",
          editable: false,
        };
        break;
      case ROOM_VIEW.LIST_MENU:
        areaShape = $selectedRoom?.getArea();
        headerInfo = {
          title: $selectedRoom?.getName(),
          info: getSquareWithUnit(areaShape, $drawState.context.currentMetricUnit),
          path: "",
          editable: true,
        };
        break;
      case ROOM_VIEW.EDIT_LAYOUT:
        areaShape = $selectedRoom?.getArea();
        headerInfo = {
          title: $_(`side_menu.${currentView}`),
          info: getSquareWithUnit(areaShape, $drawState.context.currentMetricUnit),
          path: "",
          editable: false,
        };
        break;
      case ROOM_VIEW.WALLS:
      case ROOM_VIEW.DOORS:
      case ROOM_VIEW.WINDOWS:
      case ROOM_VIEW.FURNITURES:
      case ROOM_VIEW.AREAS:
        headerInfo = {
          title: $_(`side_menu.${currentView}`),
          info: "",
          path: `${$_("side_menu.rooms")} > ${$selectedRoom?.getName()}`,
          editable: false,
        };
        break;      
      case ROOM_VIEW.ADD_DOOR:
      case ROOM_VIEW.EDIT_DOOR:
      case ROOM_VIEW.ADD_WINDOW:
      case ROOM_VIEW.EDIT_WINDOW:
      case ROOM_VIEW.EDIT_FURNITURE:
      case ROOM_VIEW.EDIT_AREA:
        headerInfo = {
          title: $drawState.context.dragContext.selectedObject?.getName(),
          info: "",
          path: `${$_("side_menu.rooms")} > ${$selectedRoom?.getName()} > ${$_(`side_menu.${currentView}`)}`,
          editable: true,
        };
        break;
      case ROOM_VIEW.WALL_DETAIL:
        headerInfo = {
          title: currentWall.getName(),
          info: getMetricWithUnit(
            currentWall.getLineLength(),
            $drawState.context.currentMetricUnit
          ).toString(),
          path: `${$_("side_menu.rooms")} > ${$selectedRoom?.getName()} > ${$_(
            "side_menu.walls"
          )}`,
          editable: true,
        };
        break;
      case ROOM_VIEW.FURNITURE_CATEGORY_LIST:
      case ROOM_VIEW.FURNITURE_ITEM_LIST:
        headerInfo = {
          title: $_("side_menu.furniture.add_furniture"),
          info: "",
          path: `${$_("side_menu.rooms")} > ${$selectedRoom?.getName()}`,
          editable: false,
        };
        break;
    }
  }
  
  const handleTitleChange = (e) => {
    switch(currentView) {
      case ROOM_VIEW.LIST_MENU:
        // currentArea.name = e.detail.value;
        drawSend({
          type: "CHANGE_NAME",
          segment: $selectedRoom,
          name: e.detail.value,
        });
      break;
      
      case ROOM_VIEW.ADD_DOOR:
      case ROOM_VIEW.EDIT_DOOR:
      case ROOM_VIEW.ADD_WINDOW:
      case ROOM_VIEW.EDIT_WINDOW:
      case ROOM_VIEW.EDIT_FURNITURE:
      case ROOM_VIEW.EDIT_AREA:
        drawSend({
          type: "CHANGE_NAME",
          name: e.detail.value,
        });
        break;
        
      case ROOM_VIEW.WALL_DETAIL:
        currentWall.name = e.detail.value;
        drawSend({
          type: "CHANGE_NAME",
          segment: currentWall,
          name: e.detail.value,
        });
        break;
    }
  }

  onDestroy(() => {
    if( unsubscribe ) unsubscribe();
  })

</script>

<div class="flex flex-col h-full">
  <Header {...headerInfo} on:change={handleTitleChange}/>

  {#if currentView !== ROOM_VIEW.INDEX}
    <div class="flex items-center gap-1 px-3 hover:text-primary-500 cursor-pointer" on:click={handleBack}>
      <i class="fa-regular fa-chevron-left text-sm" />
      <span class="font-medium text-sm">{$_("side_menu.back")}</span>
    </div>
  {/if}
  <div class="w-full flex flex-col flex-1 p-3 overflow-auto gap-4">
    {#if currentView === ROOM_VIEW.INDEX}
      <SegmentList
        type={SEGMENT_LIST_TYPE.TILE_WRAPPER}
        segments={rooms}
        on:select={handleSelectRoom}
      />
    {:else if currentView === ROOM_VIEW.LIST_MENU}
      <div class="w-full flex flex-col gap-2.5">
        {#if $selectedRoom?.tileWrappers.length === 1}
          <ListMenuItem
            title={$_("side_menu.rooms_menu.layout")}
            on:click={() => {
              drawSend({
                type: "ENTER_SELECT",
                segment: $selectedRoom?.tileWrappers[0],
              });
              handleNext(ROOM_VIEW.EDIT_LAYOUT);
            }}
          >
            <!-- <img
              slot="icon"
              alt="layout"
              src="/layout.png"
              class="w-5 h-5 rounded-sm"
            /> -->
            <i slot="icon" class="fa-regular fa-block-brick text-xl" />
          </ListMenuItem>
        {:else}
          <ListMenuItem
            title={$_("side_menu.rooms_menu.area")}
            on:click={() => handleNext(ROOM_VIEW.AREAS)}
          >
            <i slot="icon" class="fa-regular fa-square-dashed text-xl" />
          </ListMenuItem>
        {/if}
        <ListMenuItem
          title={$_("side_menu.rooms_menu.walls")}
          on:click={() => handleNext(ROOM_VIEW.WALLS)}
        >
          <i slot="icon" class="fa-regular fa-draw-square text-xl" />
        </ListMenuItem>
        <ListMenuItem
          title={$_("side_menu.rooms_menu.doors")}
          on:click={() => handleNext(ROOM_VIEW.DOORS)}
        >
          <i slot="icon" class="fa-regular fa-door-closed text-xl" />
        </ListMenuItem>
        <ListMenuItem
          title={$_("side_menu.rooms_menu.windows")}
          on:click={() => handleNext(ROOM_VIEW.WINDOWS)}
        >
          <i slot="icon" class="fa-regular fa-window-frame text-xl" />
        </ListMenuItem>
        <ListMenuItem
          title={$_("side_menu.rooms_menu.furnitures")}
          on:click={() => handleNext(ROOM_VIEW.FURNITURES)}
        >
          <i slot="icon" class="fa-regular fa-couch text-xl" />
        </ListMenuItem>
      </div>
    {:else if currentView === ROOM_VIEW.EDIT_LAYOUT}
      <AreaInfo />
    {:else if currentView === ROOM_VIEW.WALLS}
      <SegmentList
        type={SEGMENT_LIST_TYPE.LINE}
        segments={walls}
        on:select={handleSelectWall}
      />
    {:else if currentView === ROOM_VIEW.WALL_DETAIL}
      <WallInfo
        wall={currentWall}
        on:door={handleSelectDoor}
        on:window={handleSelectWindow}
      />
    {:else if currentView === ROOM_VIEW.DOORS}
      <SegmentList
        type={SEGMENT_LIST_TYPE.DOOR}
        doorType={DOORTYPE.DOOR}
        segments={buildingDoors}
        on:select={handleSelectDoor}
        hasAdd
      />
    {:else if currentView === ROOM_VIEW.WINDOWS}
      <SegmentList
        type={SEGMENT_LIST_TYPE.DOOR}
        doorType={DOORTYPE.WINDOW}
        segments={buildingWindows}
        on:select={handleSelectWindow}
        hasAdd
      />
    {:else if currentView === ROOM_VIEW.ADD_DOOR || currentView === ROOM_VIEW.EDIT_DOOR}
      <DoorInfo type={DOORTYPE.DOOR} on:back={handleBack} />
    {:else if currentView === ROOM_VIEW.ADD_WINDOW || currentView === ROOM_VIEW.EDIT_WINDOW}
      <DoorInfo type={DOORTYPE.WINDOW} on:back={handleBack} />
    {:else if currentView === ROOM_VIEW.FURNITURES}
      <SegmentList
        type={SEGMENT_LIST_TYPE.BUILDING_PART}
        segments={buildingParts}
        on:select={handleSelectFurniture}
        hasAdd
      />
    {:else if currentView === ROOM_VIEW.FURNITURE_CATEGORY_LIST}
      <FurnitureCategories {categories} on:select={handleSelectCategory} />
    {:else if currentView === ROOM_VIEW.FURNITURE_ITEM_LIST}
      <FurnitureItemList
        categories={currentCategory?.children}
        room={$selectedRoom}
        on:select={handleSelectFurniture}
      />
    {:else if currentView === ROOM_VIEW.EDIT_FURNITURE}
      <FurnitureInfo on:back={handleBack}/>
    {:else if currentView === ROOM_VIEW.AREAS}
      <SegmentList
        type={SEGMENT_LIST_TYPE.AREA}
        segments={areas}
        on:select={handleSelectArea}
      />
    {:else if currentView === ROOM_VIEW.EDIT_AREA}
      <AreaInfo />
    {/if}
    {#if currentView !== ROOM_VIEW.INDEX}
      <div class="flex items-center justify-between mt-auto">
        {#if currentView === ROOM_VIEW.ADD_DOOR || currentView === ROOM_VIEW.EDIT_DOOR || currentView === ROOM_VIEW.ADD_WINDOW || currentView === ROOM_VIEW.EDIT_WINDOW || currentView === ROOM_VIEW.EDIT_FURNITURE}
          <Button
            variant="outline-secondary"
            title={$_("side_menu.reset")}
            class="w-wide-btn ml-auto"
            on:click={handleReset}
            disabled={!isValidSegment}
          />
        {/if}
      </div>
    {/if}
  </div>
</div>
