<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import Input from "./Input.svelte";
  const dispatch = createEventDispatcher();

  export let className = "p-2";
  export let value: number | string;
  export {className as class};

  let container;
  let _value: string;

  let isEdit = false;
  
  onMount(() => {
    const handleClickOutside = (event: Event) => {
      if (
        isEdit &&
        container &&
        event.target instanceof Node &&
        !container.contains(event.target)
      ) {
        isEdit = false;
      }
    };

    window.addEventListener("click", handleClickOutside);
    
    return () => {
      window.removeEventListener("click", handleClickOutside);
    }
  })

  const handleEdit = (e) => {
    e.stopPropagation();
    _value = value.toString();
    isEdit = true;
  }

  const handleSave = (e) => {
    e.stopPropagation();
    isEdit = false;
    dispatch("change", {value: _value})
  }

</script>
<div 
  class={`flex justify-center items-center hover:border-primary-500 border-transparent border-2 rounded-xl transition-colors duration-300 gap-2 max-w-sm mx-auto ${className}`} 
  bind:this={container}
  on:click={handleEdit}
>
  {#if isEdit}
  <Input 
    icon="fa-solid fa-save" 
    value={_value} 
    on:input={(e) => _value = e.target.value} 
    onClickIcon={handleSave} 
    fullWidth
    autofocus 
  />
  {:else}
    <div class="w-5 h-5"></div>
    <slot />
    <div class="w-5 h-5 flex items-center justify-center cursor-pointer">
      <i class="fa-solid fa-edit font-black text-lg text-secondary-500" />
    </div>
  {/if}
</div>
  