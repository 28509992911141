<script lang="ts">
  import CloseDialog from "../icons/CloseDialog.svelte";
  import ConfirmLayoutRepeat from "../icons/ConfirmLayoutRepeat.svelte";
  import ConnectionPoint from "../icons/ConnectionPoint.svelte";
  import Delete from "../icons/Delete.svelte";
  import PlusFilledIcon from "../icons/PlusFilledIcon.svelte";
  import PlusIcon from "../icons/PlusIcon.svelte";
  import RotateRight from "../icons/RotateRight.svelte";
  import RotateRightRepeat from "../icons/RotateRightRepeat.svelte";
  import SnapPoint from "../icons/SnapPoint.svelte";
  import Room from "../icons/Room.svelte";
  import AddRoom from "../icons/AddRoom.svelte";
  import Wall from "../icons/Wall.svelte";

  export let className = "";
  export let name = "";
  export let icon = "";
  export let width = "w-6";
  export let height = "h-6";
  export let stroke = "currentColor";
  export let fill = "currentColor";
  export {className as class};

  const icons = {
    "close": {
      icon: CloseDialog,
    },
    "confirm-layout-repeat": {
      icon: ConfirmLayoutRepeat
    },
    "connect-point": {
      icon: ConnectionPoint
    },
    "delete": {
      icon: Delete
    },
    "plus-filled": {
      icon: PlusFilledIcon
    },
    "plus": {
      icon: PlusIcon
    },
    "rotate-right": {
      icon: RotateRight
    },
    "rotate-right-repeat": {
      icon: RotateRightRepeat
    },
    "snap-point": {
      icon: SnapPoint
    },
    "room": {
      icon: Room,
      viewBox: "0 0 55 55"
    },
    "add-room": {
      icon: AddRoom,
      viewBox: "0 0 55 55"
    },
    "wall": {
      icon: Wall,
      viewBox: "0 0 29 55"
    }
  }
</script>

<svg
  class="{className} {width} {height}"
  xmlns="http://www.w3.org/2000/svg"
  fill={fill}
  viewBox={icons[icon]?.viewBox || "0 0 24 24"}
  stroke={icons[icon]?.stroke || stroke}
  aria-hidden="true"
  aria-labelledby={name}
>
  <title id={name} lang="en">{name}</title>
  <g>
    {#if icons[icon]}
      <svelte:component this={icons[icon].icon} />
    {/if}
  </g>
</svg>
