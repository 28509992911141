<script lang="ts">
  export let className = "";
  export let title;
  export { className as class };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class={`${className} w-full flex items-center p-3 gap-2.5 rounded-lg text-secondary-500 bg-light-gray cursor-pointer`} on:click>
  <slot name="icon" />
  <h3 class="flex-1">{title}</h3>
  <i class="fa-solid fa-chevron-right text-xl mx-auto">
</div>
