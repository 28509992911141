export class NeighborInstance {
    neighbor;
    sourceInstance;
    gapSize;
    sourceSegment;
    targetSegment;
    targetShape;
    constructor(neighbor, sourceInstance, targetInstance, gapSize) {
        this.neighbor = neighbor;
        this.sourceInstance = sourceInstance;
        this.gapSize = gapSize;
        this.sourceSegment = sourceInstance.segments[this.neighbor.source.index];
        this.targetSegment = targetInstance.segments[this.neighbor.target.index];
        this.targetShape = targetInstance.shape;
    }
    get sourceIndex() {
        return this.neighbor.source.index;
    }
    get targetIndex() {
        return this.neighbor.target.index;
    }
    /**
     * Returns this neighbor offset relative to the length of the source edge
     */
    get offsetAsSourceParameter() {
        return (0.5 +
            this.neighbor.offset.offset(this.gapSize) / this.sourceSegment.length);
    }
    /**
     * Returns this neighbor offset relative to the length of the target edge
     */
    get offsetAsTargetParameter() {
        return (0.5 +
            this.neighbor.offset.offset(this.gapSize) / this.targetSegment.length);
    }
}
