<script lang="ts">
  import { drawState, drawSend } from "../../layout.store";
  import { Pointer, Door, Line } from "../../model";
  import {
    convertPointerToViewBox,
    dist,
    getFixedBuildingElement,
    getHelperTextAngle,
    getMetricWithUnit,
    getParentLine,
    getTextPointer,
    makePointerInSvgBox,
  } from "../../helpers";
  import { METRIC_UNITS } from "src/global/variable";
  import { currentTool } from "src/store";
  import { TOOLS } from "src/global/types";

  export let door: Door;
  export let svgRef: any;
  export let svgSize: any;
  export let scale: number;

  $: selectedObject = $drawState.context.dragContext.selectedObject;
  $: parentSegment = getParentLine(
    $drawState.context.current.segments,
    door.parentId
  );
  $: buildingElement = getFixedBuildingElement(
    door.buildingType,
    door.foldingType
  );

  const mouseDown = (e: any) => {
    let pointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );
    pointer = convertPointerToViewBox(pointer, svgRef);
    pointer = makePointerInSvgBox(pointer, svgSize);
    
    if( $currentTool === TOOLS.EYE_DROPPER ) {
      return;
    }

    drawSend({ type: "ENTER_SELECT", segment: door });
    drawSend({
      type: "UPDATE_OFFSET",
      pointer,
    });
    drawSend({ type: "SHOW_LINE_TOOL" });
  };

  const segmentHovered = () => {
    if ($drawState.matches("main.drawingState")) return;
    drawSend({ type: "MOUSE_HOVER" });
  };

  const segmentMouseLeave = () => {
    if ($drawState.matches("main.drawingState")) return;
    drawSend({ type: "MOUSE_LEAVE" });
  };

  const getRotation = () => {
    return parentSegment ? -(parentSegment?.getLineAngle() * 180) / Math.PI : 0;
  };

  $: generateHelperPath = () => {
    if (!parentSegment) {
      return "";
    }

    const helperLength = 7;

    let path = "";
    const helpStartPointer = new Pointer(
      parentSegment.startPointer.x +
        ((door.openToOutSide ? -1 : 1) *
          ((parentSegment.endPointer.y - parentSegment.startPointer.y) *
            helperLength)) /
          dist(parentSegment.startPointer, parentSegment.endPointer),
      parentSegment.startPointer.y -
        ((door.openToOutSide ? -1 : 1) *
          ((parentSegment.endPointer.x - parentSegment.startPointer.x) *
            helperLength)) /
          dist(parentSegment.startPointer, parentSegment.endPointer)
    );

    const helperCenterPointer = new Pointer(
      (parentSegment.startPointer.x + helpStartPointer.x) / 2,
      (parentSegment.startPointer.y + helpStartPointer.y) / 2
    );

    path =
      path +
      "M" +
      parentSegment.startPointer.x +
      "," +
      parentSegment.startPointer.y +
      " " +
      "L" +
      helpStartPointer.x +
      "," +
      helpStartPointer.y;

    const angleRotate = (1 / 4) * Math.PI;
    let deltaX =
      (parentSegment.startPointer.x - helperCenterPointer.x) *
        Math.cos(angleRotate) -
      (parentSegment.startPointer.y - helperCenterPointer.y) *
        Math.sin(angleRotate);
    let deltaY =
      (parentSegment.startPointer.x - helperCenterPointer.x) *
        Math.sin(angleRotate) +
      (parentSegment.startPointer.y - helperCenterPointer.y) *
        Math.cos(angleRotate);
    const helper1 = helperCenterPointer.translate(deltaX, deltaY);

    deltaX =
      (helpStartPointer.x - helperCenterPointer.x) * Math.cos(angleRotate) -
      (helpStartPointer.y - helperCenterPointer.y) * Math.sin(angleRotate);
    deltaY =
      (helpStartPointer.x - helperCenterPointer.x) * Math.sin(angleRotate) +
      (helpStartPointer.y - helperCenterPointer.y) * Math.cos(angleRotate);

    const helper2 = helperCenterPointer.translate(deltaX, deltaY);
    path =
      path +
      " " +
      "M" +
      helper1.x +
      "," +
      helper1.y +
      " " +
      "L" +
      helper2.x +
      "," +
      helper2.y;

    const helpEndPointer = new Pointer(
      parentSegment.endPointer.x -
        ((door.openToOutSide ? -1 : 1) *
          ((parentSegment.startPointer.y - parentSegment.endPointer.y) *
            helperLength)) /
          dist(parentSegment.endPointer, parentSegment.startPointer),
      parentSegment.endPointer.y +
        ((door.openToOutSide ? -1 : 1) *
          ((parentSegment.startPointer.x - parentSegment.endPointer.x) *
            helperLength)) /
          dist(parentSegment.endPointer, parentSegment.startPointer)
    );

    path =
      path +
      " " +
      "M" +
      parentSegment.endPointer.x +
      "," +
      parentSegment.endPointer.y +
      " " +
      "L" +
      helpEndPointer.x +
      "," +
      helpEndPointer.y +
      ",";

    const helperCenterPointer2 = new Pointer(
      (parentSegment.endPointer.x + helpEndPointer.x) / 2,
      (parentSegment.endPointer.y + helpEndPointer.y) / 2
    );

    deltaX =
      (parentSegment.endPointer.x - helperCenterPointer2.x) *
        Math.cos(angleRotate) -
      (parentSegment.endPointer.y - helperCenterPointer2.y) *
        Math.sin(angleRotate);
    deltaY =
      (parentSegment.endPointer.x - helperCenterPointer2.x) *
        Math.sin(angleRotate) +
      (parentSegment.endPointer.y - helperCenterPointer2.y) *
        Math.cos(angleRotate);
    const helper3 = helperCenterPointer2.translate(deltaX, deltaY);

    deltaX =
      (helpEndPointer.x - helperCenterPointer2.x) * Math.cos(angleRotate) -
      (helpEndPointer.y - helperCenterPointer2.y) * Math.sin(angleRotate);
    deltaY =
      (helpEndPointer.x - helperCenterPointer2.x) * Math.sin(angleRotate) +
      (helpEndPointer.y - helperCenterPointer2.y) * Math.cos(angleRotate);

    const helper4 = helperCenterPointer2.translate(deltaX, deltaY);
    path =
      path +
      " " +
      "M" +
      helper3.x +
      "," +
      helper3.y +
      " " +
      "L" +
      helper4.x +
      "," +
      helper4.y;

    const isDoorStartNearToLineStartPointer =
      dist(door.startPointer, parentSegment.startPointer) <
      dist(door.endPointer, parentSegment.startPointer);

    let helpNearPointer = new Pointer(
      door.startPointer.x +
        ((door.openToOutSide ? -1 : 1) *
          ((door.endPointer.y - door.startPointer.y) * helperLength)) /
          dist(door.startPointer, door.endPointer),
      door.startPointer.y -
        ((door.openToOutSide ? -1 : 1) *
          ((door.endPointer.x - door.startPointer.x) * helperLength)) /
          dist(door.startPointer, door.endPointer)
    );

    helpNearPointer = new Pointer(
      (door.startPointer.x + helpNearPointer.x) / 2,
      (door.startPointer.y + helpNearPointer.y) / 2
    );

    let helpFarPointer = new Pointer(
      door.endPointer.x -
        ((door.openToOutSide ? -1 : 1) *
          ((door.startPointer.y - door.endPointer.y) * helperLength)) /
          dist(door.endPointer, door.startPointer),
      door.endPointer.y +
        ((door.openToOutSide ? -1 : 1) *
          ((door.startPointer.x - door.endPointer.x) * helperLength)) /
          dist(door.endPointer, door.startPointer)
    );
    helpFarPointer = new Pointer(
      (door.endPointer.x + helpFarPointer.x) / 2,
      (door.endPointer.y + helpFarPointer.y) / 2
    );

    path =
      path +
      " " +
      "M" +
      helperCenterPointer.x +
      "," +
      helperCenterPointer.y +
      " " +
      "L" +
      (isDoorStartNearToLineStartPointer
        ? helpNearPointer.x
        : helpFarPointer.x) +
      "," +
      (isDoorStartNearToLineStartPointer
        ? helpNearPointer.y
        : helpFarPointer.y);

    path =
      path +
      " " +
      "M" +
      (isDoorStartNearToLineStartPointer
        ? helpNearPointer.x
        : helpFarPointer.x) +
      "," +
      (isDoorStartNearToLineStartPointer
        ? helpNearPointer.y
        : helpFarPointer.y) +
      " " +
      "L" +
      helperCenterPointer2.x +
      "," +
      helperCenterPointer2.y;

    return path;
  };

  $: generateHelperTextData = () => {
    if (!parentSegment) return [];
    const nearPointer =
      dist(door.startPointer, parentSegment.startPointer) <
      dist(door.endPointer, parentSegment.startPointer)
        ? door.startPointer
        : door.endPointer;
    const farPointer = nearPointer.equals(door.startPointer)
      ? door.endPointer
      : door.startPointer;
    return [
      {
        x: door.openToOutSide
          ? getTextPointer(nearPointer, parentSegment.startPointer).x
          : getTextPointer(parentSegment.startPointer, nearPointer).x,
        y: door.openToOutSide
          ? getTextPointer(nearPointer, parentSegment.startPointer).y
          : getTextPointer(parentSegment.startPointer, nearPointer).y,
        angle: getHelperTextAngle(parentSegment.startPointer, nearPointer),
        length: new Line(
          parentSegment.startPointer,
          nearPointer
        ).getLineLength(),
      },
      {
        x: door.openToOutSide
          ? getTextPointer(parentSegment.endPointer, farPointer).x
          : getTextPointer(farPointer, parentSegment.endPointer).x,
        y: door.openToOutSide
          ? getTextPointer(parentSegment.endPointer, farPointer).y
          : getTextPointer(farPointer, parentSegment.endPointer).y,
        angle: getHelperTextAngle(farPointer, parentSegment.endPointer),
        length:
          Math.round(parentSegment.getLineLength()) -
          Math.round(door.getLineLength()) -
          Math.round(
            new Line(parentSegment.startPointer, nearPointer).getLineLength()
          ),
      },
    ];
  };
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<g>
  <g
    class={`${
      selectedObject === door ? "segment_path_selected" : ""
    } segment_path segment`}
    on:mousedown={(e) => mouseDown(e)}
    on:mouseover={() => segmentHovered()}
    on:mouseleave={() => segmentMouseLeave()}
  >
    <g
      transform={`rotate(${door.openToOutSide ? 180 : 0} ${
        (door.startPointer.x + door.endPointer.x) / 2
      } ${
        (door.startPointer.y + door.endPointer.y) / 2
      }) rotate(${getRotation()} ${door.startPointer.x} ${
        door.startPointer.y
      }) matrix(${door.getLineLength() / buildingElement.width}, 0, 0, ${
        1.5 / buildingElement.thicker
      }, ${door.startPointer.x}, ${door.startPointer.y - 0.75})`}
      class="segment"
    >
      {@html buildingElement.staticPath}
    </g>
    <g
      transform={`rotate(${door.openToOutSide ? 180 : 0} ${
        (door.startPointer.x + door.endPointer.x) / 2
      } ${
        (door.startPointer.y + door.endPointer.y) / 2
      }) rotate(${getRotation()} ${door.startPointer.x} ${
        door.startPointer.y
      }) matrix(${
        ((door.wallOpening ? 1 : -1) * door.getLineLength()) /
        buildingElement.width
      }, 0, 0, ${
        ((door.wallSide ? 1 : -1) * door.getLineLength()) /
        buildingElement.width
      }, ${
        door.startPointer.x + (!door.wallOpening ? door.getLineLength() : 0)
      }, ${door.startPointer.y - 0.75})`}
      class="segment"
    >
      {@html buildingElement.path}
    </g>
  </g>
  {#if $drawState.matches("helper.showHelper") && selectedObject?.id === door.id}
    <g>
      {#each door.generateHelperPath() as helperItem, j}
        <path
          d={helperItem}
          fill="none"
          stroke-linecap="round"
          stroke="grey"
          stroke-width="0.3px"
        />
      {/each}
      {#each door.generateHelperTextData() as helperTextItem, j}
        <g>
          {#if helperTextItem}
            <text
              class="stroke-text"
              transform="translate({helperTextItem.x}, {helperTextItem.y}) rotate({helperTextItem.angle})"
              style={`font-size: ${16 / scale}px;`}
            >
              {"W " +
                getMetricWithUnit(
                  Math.round(helperTextItem.length * 100) / 100,
                  $drawState.context.currentMetricUnit,
                  false,
                  true
                ) +
                " / H " +
                getMetricWithUnit(
                  door.length,
                  $drawState.context.currentMetricUnit,
                  false,
                  true
                )}
            </text>
          {/if}
        </g>
      {/each}
    </g>
    <g>
      {#if generateHelperPath()}
        <path
          d={generateHelperPath()}
          fill="none"
          stroke-linecap="round"
          stroke="grey"
          stroke-width="0.3px"
        />
      {/if}
      {#each generateHelperTextData() as helperTextItem, j}
        <g>
          {#if helperTextItem}
            <text
              class="stroke-text"
              transform="translate({helperTextItem.x}, {helperTextItem.y}) rotate({helperTextItem.angle})"
              style={`font-size: ${16 / scale}px;`}
            >
              {getMetricWithUnit(
                j < 1
                  ? Math.round(helperTextItem.length * 100) / 100
                  : Math.floor(helperTextItem.length * 100) / 100,
                $drawState.context.currentMetricUnit,
                false,
                true
              )}
            </text>
          {/if}
        </g>
      {/each}
    </g>
  {/if}</g
>

<style>
  text {
    font-family: sans-serif;
    text-anchor: middle;
    pointer-events: none;
    user-select: none;
  }
</style>
