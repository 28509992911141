<svelte:options immutable={true} />

<script lang="ts">
  import { pannable } from "../actions/pannable";
  import type Shape from "../../model/tile/Shape";

  export let shape: Shape;
  export let id: string;
  export let transform: string | undefined = undefined;
  export let svgPath: string;
  export let strokeWidth: number | undefined = undefined;
  export let fillColor: string | undefined = undefined;
  export let strokeColor: string | undefined = undefined;
  export let textureId: string | undefined = undefined;
  export let opacity: number | undefined = undefined;
  export let outlineStrokeColor: string | undefined = undefined;
  export let outlineStrokeWidth: number | undefined = undefined;
  export let label: string | undefined = undefined;
  export let passive: boolean = false;

  const currentTime = Date.now();
</script>

<defs>
  {#each shape.tileData.images || [] as image, index (image)}
    <filter id={`${shape.tileData.filterId}-${index}`} x="0" y="0" width="100%" height="100%" filterUnits="objectBoundingBox">
      <feImage xlink:href={image} preserveAspectRatio="none" />
    </filter>
  {/each}
  <clipPath id={`tile-clip-path-${id}-${currentTime}`}>
    <path d={svgPath} />
  </clipPath>
</defs>
<g
  {id}
  {transform}
  use:pannable={!passive}
  {opacity}
  clip-path={`url(#tile-clip-path-${id}-${currentTime})`}
  on:panstart
  on:panmove
  on:panend
  on:mousedown
  on:mouseenter
  on:mouseleave
  on:click
  on:keydown
>
  {#if shape.rotation > 0}
    <g transform={`rotate(-${shape.rotation})`}>
      <g filter={textureId !== undefined ? `url(#${textureId})` : undefined} >
        <g transform={`rotate(${shape.rotation})`}>
          <path
            d={svgPath}
            stroke-width={strokeWidth}
            fill={fillColor}
            stroke={strokeColor}
            class="segment"
          >
            {#if label !== undefined}
              <title>{label}</title>
            {/if}
          </path>
        </g>
      </g>
    </g>
  {:else}
    <path
      d={svgPath}
      stroke-width={strokeWidth}
      fill={fillColor}
      stroke={strokeColor}
      class="segment"
      style={textureId !== undefined ? `filter:url(#${textureId})` : undefined}
    >
      {#if label !== undefined}
        <title>{label}</title>
      {/if}
    </path>
  {/if}
  {#if outlineStrokeWidth !== undefined}
    <path
      d={svgPath}
      stroke-width={outlineStrokeWidth}
      stroke={outlineStrokeColor}
      fill="none"
    />
  {/if}
</g>
