<script lang="ts">
  import { currentTool, visibleSideMenu } from "../../store";
  import { drawSend, drawState } from "../../layout.store";
  import { _ } from "../../services/i18n";
  import { TOOLS, type Tool } from "src/global/types";

  const items: Tool[] = [
    {
      text: "select",
      icon: "fa fa-arrow-pointer",
      type: TOOLS.SELECTION,
    },
    {
      text: "draw",
      icon: "fa fa-vector-square",
      type: TOOLS.POLYLINE,
    },
    {
      text: "draw-line",
      icon: "fa fa-square-dashed",
      type: TOOLS.DRAW_LINE,
    },
  ];

  const selectTool = (tool: Tool) => {
    currentTool.set(tool.type);
    visibleSideMenu.set(false);
    if ($drawState.matches("lineTool.shown"))
      drawSend({ type: "HIDE_LINE_TOOL" });
  };
</script>

<div class="fixed top-1/2 left-0 transform -translate-y-1/2 bg-white rounded-r-2xl shadow-dropdown flex flex-col py-3 px-1.5 gap-2.5 z-10 print:hidden">
  {#each items as item, i}
    <div
      on:click={(_) => selectTool(item)}
      class="flex items-center justify-center w-9 h-9 font-black cursor-pointer text-secondary-500"
    >
      <i class={`${item.icon}`} title={$_(`tool.${item.text}`)} />
    </div>
  {/each}
</div>
