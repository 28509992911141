import { Pointer } from "./Pointer";
import { Segment, SegmentClass } from "./Segment";
import { dist, translatePointerWithSegments } from "../helpers";
import { v4 as uuidv4 } from 'uuid';
export class Arc extends Segment {
    startPointer;
    endPointer;
    heightPointer;
    id;
    zIndex;
    name;
    pointsArray;
    constructor(startPointer, endPointer, heightPointer, id = uuidv4(), zIndex = 0, name = "") {
        super(startPointer, endPointer, id, zIndex, name);
        this.startPointer = startPointer;
        this.endPointer = endPointer;
        this.heightPointer = heightPointer;
        this.id = id;
        this.zIndex = zIndex;
        this.name = name;
        this.class = SegmentClass.ARC;
    }
    getRealTransition(dx, dy, svgSize) {
        return { dx, dy };
    }
    generatePath(isFromShape = false, startPointer = undefined) {
        const sPointer = !startPointer || startPointer.equals(this.startPointer)
            ? this.startPointer
            : this.endPointer;
        const ePointer = sPointer.equals(this.startPointer)
            ? this.endPointer
            : this.startPointer;
        const A = dist(ePointer, this.heightPointer);
        const B = dist(this.heightPointer, sPointer);
        const C = dist(sPointer, ePointer);
        if (A === 0 || B === 0 || C === 0)
            return "";
        const angle = Math.acos((A * A + B * B - C * C) / (2 * A * B));
        //calc radius of circle
        const K = 0.5 * A * B * Math.sin(angle);
        let r = (A * B * C) / 4 / K;
        r = Math.round(r * 1000) / 1000;
        //large arc flag
        const laf = +(Math.PI / 2 > angle);
        //sweep flag
        const saf = +((ePointer.x - sPointer.x) * (this.heightPointer.y - sPointer.y) -
            (ePointer.y - sPointer.y) * (this.heightPointer.x - sPointer.x) <
            0);
        return ((isFromShape ? "" : "M") +
            `${sPointer.x},${sPointer.y} A${r},${r} 0 ${laf}, ${saf} ${ePointer.x}, ${ePointer.y}`);
    }
    getHelperPointer() {
        const A = dist(this.endPointer, this.heightPointer);
        const B = dist(this.heightPointer, this.startPointer);
        const C = dist(this.startPointer, this.endPointer);
        const angle = Math.acos((A * A + B * B - C * C) / (2 * A * B));
        //calc radius of circle
        const K = 0.5 * A * B * Math.sin(angle);
        let radius = (A * B * C) / 4 / K;
        radius = Math.round(radius * 1000) / 1000;
        const centerPointer = new Pointer((this.startPointer.x + this.endPointer.x) / 2, (this.startPointer.y + this.endPointer.y) / 2);
        //large arc flag
        const laf = +(Math.PI / 2 > angle);
        const height = laf
            ? radius +
                Math.sqrt(radius * radius - Math.pow(dist(centerPointer, this.startPointer), 2))
            : radius -
                Math.sqrt(radius * radius - Math.pow(dist(centerPointer, this.startPointer), 2));
        const linePointerY = Math.tan(Math.atan2(this.endPointer.y - this.startPointer.y, this.endPointer.x - this.startPointer.x)) *
            (this.heightPointer.x - this.startPointer.x) +
            this.startPointer.y;
        const sign = linePointerY < this.heightPointer.y ? 1 : -1;
        const leftPointer = this.startPointer.x <= this.endPointer.x
            ? this.startPointer
            : this.endPointer;
        const rightPointer = this.startPointer.x > this.endPointer.x
            ? this.startPointer
            : this.endPointer;
        const deltaX = -Math.abs(height *
            Math.cos(Math.atan2(rightPointer.y - leftPointer.y, rightPointer.x - leftPointer.x)));
        const deltaY = (Math.atan2(rightPointer.y - leftPointer.y, rightPointer.x - leftPointer.x) > 0
            ? 1
            : -1) *
            Math.abs(height *
                Math.sin(Math.atan2(this.endPointer.y - this.startPointer.y, this.endPointer.x - this.startPointer.x)));
        const helpPointer = centerPointer.translate(-deltaY * sign, -deltaX * sign);
        return helpPointer;
    }
    generateHelperPath() {
        const helpPointer = this.getHelperPointer();
        const centerPointer = new Pointer((this.startPointer.x + this.endPointer.x) / 2, (this.startPointer.y + this.endPointer.y) / 2);
        const path = "M" +
            centerPointer.x +
            "," +
            centerPointer.y +
            " " +
            "L" +
            helpPointer.x +
            "," +
            helpPointer.y;
        return [path];
    }
    getHelperTextAngle() {
        let angle = 0;
        const helpPointer = this.getHelperPointer();
        const centerPointer = new Pointer((this.startPointer.x + this.endPointer.x) / 2, (this.startPointer.y + this.endPointer.y) / 2);
        if (centerPointer.x === helpPointer.x) {
            if (centerPointer.y < helpPointer.y) {
                angle = Math.PI / 2;
            }
            else {
                angle = -Math.PI / 2;
            }
        }
        else {
            angle = Math.atan(-(centerPointer.y - helpPointer.y) / (centerPointer.x - helpPointer.x));
        }
        return -(angle * 180) / Math.PI;
    }
    getCenterPointer() {
        return new Pointer((this.startPointer.x + this.endPointer.x) / 2, (this.startPointer.y + this.endPointer.y) / 2);
    }
    getTextPointer() {
        const angle = -this.getHelperTextAngle();
        const dx = 7 * Math.cos((angle * Math.PI) / 180);
        const dy = 7 * Math.sin((angle * Math.PI) / 180);
        const helpPointer = this.getHelperPointer();
        const centerPointer = new Pointer((this.startPointer.x + this.endPointer.x) / 2, (this.startPointer.y + this.endPointer.y) / 2);
        let textPointer = new Pointer((helpPointer.x + centerPointer.x) / 2, (helpPointer.y + centerPointer.y) / 2);
        textPointer = textPointer.translate((centerPointer.x < helpPointer.x ? 1.5 : -1) * dy, (centerPointer.x < helpPointer.x ? 1.5 : -1) * dx);
        return textPointer;
    }
    getLineLength() {
        const helpPointer = this.getHelperPointer();
        const centerPointer = new Pointer((this.startPointer.x + this.endPointer.x) / 2, (this.startPointer.y + this.endPointer.y) / 2);
        return dist(centerPointer, helpPointer);
    }
    generateHelperTextData() {
        return [
            {
                x: this.getTextPointer().x,
                y: this.getTextPointer().y,
                angle: this.getHelperTextAngle(),
                length: this.getLineLength(),
            },
        ];
    }
    translate(dx, dy, segments) {
        const oldStartPointer = new Pointer(this.startPointer.x, this.startPointer.y);
        const oldEndPoiinter = new Pointer(this.endPointer.x, this.endPointer.y);
        this.startPointer = this.startPointer.translate(dx, dy);
        this.endPointer = this.endPointer.translate(dx, dy);
        this.heightPointer = this.heightPointer.translate(dx, dy);
        this.startPointer = translatePointerWithSegments(dx, dy, oldStartPointer, segments);
        this.endPointer = translatePointerWithSegments(dx, dy, oldEndPoiinter, segments);
        this.updatePointsArray();
    }
    getPointsArray() {
        if (!this.pointsArray) {
            this.pointsArray = [];
            const node = document.createElementNS("http://www.w3.org/2000/svg", "path");
            node.setAttributeNS(null, "d", this.generatePath());
            node.setAttributeNS(null, "stroke-width", "1px");
            for (let step = 0; step < node.getTotalLength(); step++) {
                const pointer = node.getPointAtLength(step);
                this.pointsArray.push(new Pointer(pointer.x, pointer.y));
            }
        }
        return this.pointsArray;
    }
    updatePointsArray() {
        this.pointsArray = undefined;
    }
    isInSegment(pointer, acceptInPath = true) {
        return false;
    }
    clone() {
        return new Arc(new Pointer(this.startPointer.x, this.startPointer.y), new Pointer(this.endPointer.x, this.endPointer.y), new Pointer(this.heightPointer.x, this.heightPointer.y), this.id, this.zIndex, this.name);
    }
    toJSON() {
        return {
            ...super.toJSON(),
            heightPointer: this.heightPointer.toJSON(),
        };
    }
}
