import en from "./en-GB.json";
import de from "./de-DE.json";
import cs from "./cs-CZ.json";
import es from "./es-ES.json";
import fr from "./fr-FR.json";
import hu from "./hu-HU.json";
import pt from "./pt-PT.json";
import it from "./it-IT.json";
export const SUPPORTED_LOCALIZATIONS = {
    en: "en-GB",
    cs: "cs-CZ",
    de: "de-DE",
    es: "es-ES",
    fr: "fr-FR",
    hu: "hu-HU",
    it: "it-IT",
    pt: "pt-PT",
};
export const getLocalizations = () => {
    return {
        "en-GB": en,
        "cs-CZ": cs,
        "de-DE": de,
        "es-ES": es,
        "fr-FR": fr,
        "hu-HU": hu,
        "it-IT": it,
        "pt-PT": pt,
    };
};
