<script lang="ts">

  import { _ } from "../../services/i18n";
  import OkCancelDialog from "../base/OkCancelDialog.svelte";
  import { getProject, updateProject, deleteProject } from "src/services/api";
  import { format } from "date-fns";

  export let project: any = {};
  export let onLoad: Function;
  export let onDelete: Function | undefined = undefined;

  let showDeleteDialog = false;
  let showFloatingMenu = false;
  let centerX = 0;
  let centerY = 0;
  let loading = false;

  function showMenu(event: MouseEvent) {
    centerX = event.clientX;
    centerY = event.clientY;
    showFloatingMenu = true;
  };

  function closeMenu() {
    showFloatingMenu = false;
  };

  function loadProject() {
    loading = true;
    getProject(project.slug)
      .then((res) => {
        loading = false;
        if (res !== undefined)
          onLoad(res.data);
      })
  }

  function setShowDeleteDialog(show: boolean = false) {
    showDeleteDialog = show;
  }

  function onRemove() {
    deleteProject(project.slug)
      .then(() => {
        onDelete();
      });
  }

  function clickOutside(event: MouseEvent) {

    if (showFloatingMenu)
    {
      var parent = document.getElementById(project.slug);
      let target: HTMLElement = event.target as HTMLElement;

      if (target !== parent && !parent.contains(target))
        closeMenu();
    }
  };
</script>

<svelte:body
  on:click={clickOutside}
/>

<div class="flex flex-col justify-self-center group gap-2">
  <div 
    class="flex flex-col gap-2 transition-colors duration-300 w-full
    border-2 rounded-xl p-4 cursor-pointer border-grid-item text-grid-item
    group-hover:border-primary-500 group-hover:text-primary-500"
    on:click={loadProject}
  >
    <div class="w-full p-2">
      <div class="relative aspect-w-1 aspect-h-1 h-full overflow-hidden rounded-md bg-white">
        {#if project && project.preview_image}
          <img 
            src={project.preview_image} 
            class="absolute inset-0 h-full w-full transform-gpu transition-transform duration-300 scale-100 transition-all 
                    group-hover:scale-110 object-contain translate-x-0"
            alt={project.name}
          />
        {/if}
        {#if loading}
          <img
            id="cursor-load"
            alt="cursor"
            src="/cursor-load.jpg"
          />
        {/if}
      </div>
    </div>
    {#if onDelete}
    <div class="flex items-center justify-end">
      <i 
        class="fa-solid fa-trash text-red-300 hover:text-red-500 text-base"
        on:click={(e) => {
          e.stopPropagation();
          setShowDeleteDialog(true);
        }}
      />
    </div>
    {/if}
  </div>
  {#if project}
    <div>
      <div class="px-2 text-center text-base font-semibold text-gray-900 group-hover:text-primary-700 truncate pointer-events-none mb-1">
        {project.name}
      </div>
      <div class="px-2 text-center text-sm font-semibold text-gray-900 group-hover:text-primary-700 truncate pointer-events-none">
        {format(project.updated_at, "d MMM yyyy")}
      </div>
    </div>
  {/if}
</div>

<OkCancelDialog
  toggle={showDeleteDialog}
  okText={$_("common.delete")}
  on:clickOk={onRemove}
  on:clickCancel={() => setShowDeleteDialog()}
>
  <span slot="header">{$_("home.delete_project")}</span>
  <span slot="message">{$_("home.delete_project_message", { values: { name: project.name } })}</span>
</OkCancelDialog>