<script lang="ts">
  import { activeMenu, editSegment, selectedRoom, viewBox, visibleSideMenu } from "src/store";
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import Header from "./Header.svelte";
  import Button from "../../base/Button.svelte";
  import { centroid, createDoor, dist, getNewSegmentName, isClosedArea, isDoor, isLine, isTileWrapper } from "src/helpers";
  import { DOORTYPE, SegmentClass, type Door, Line, Pointer, ClosedArea } from "src/model";
  import DoorInfo from "./DoorInfo.svelte";
  import SegmentList from "./SegmentList.svelte";
  import { SEGMENT_LIST_TYPE, SIDE_MENUS } from "src/global/types";
  import { onMount, onDestroy } from "svelte";
  
  export let type : DOORTYPE;

  enum DOOR_VIEW {
    INDEX,
    ADD_DOOR,
    EDIT_DOOR,
    ADD_WINDOW,
    EDIT_WINDOW
  }

  let routes : DOOR_VIEW[] = [DOOR_VIEW.INDEX];

  let currentView: DOOR_VIEW = DOOR_VIEW.INDEX;
  let headerInfo = {
    title: "",
    info: "",
    path: "",
    editable: false
  };

  let unsubscribe
  onMount(() => {
    unsubscribe = visibleSideMenu.subscribe((visible) => {
      if( $activeMenu !== SIDE_MENUS.DOOR && $activeMenu !== SIDE_MENUS.WINDOW )
        return;

      if( !visible ) {
        handleBack();
        routes = [DOOR_VIEW.INDEX];
        currentView = DOOR_VIEW.INDEX;
      } else if ($editSegment) {
        if( !isDoor($editSegment) ) {
          routes = [DOOR_VIEW.INDEX];
          currentView = DOOR_VIEW.INDEX;
          editSegment.set(undefined)
          return;
        }
        const door = $editSegment as Door
        if( type === DOORTYPE.DOOR ) {
          routes = [DOOR_VIEW.INDEX, DOOR_VIEW.EDIT_DOOR];
          currentView = DOOR_VIEW.EDIT_DOOR
          if( door.buildingType === DOORTYPE.DOOR ) 
            editSegment.set(undefined)
        } else {
          routes = [DOOR_VIEW.INDEX, DOOR_VIEW.EDIT_WINDOW];
          currentView = DOOR_VIEW.EDIT_WINDOW
          if( door.buildingType === DOORTYPE.WINDOW ) 
            editSegment.set(undefined)
        }
      }
    })
  })
  
  $: rooms = $drawState.context.current.segments.filter((segment) =>
    isClosedArea(segment)
  ) as ClosedArea[];

  $: doors = $drawState.context.current.segments.filter((segment) =>
    isDoor(segment) && (segment as Door).buildingType === type
  ).sort((a, b) => a.name.localeCompare(b.name)) as Door[];

  const handleNext = (view: DOOR_VIEW) => {
    currentView = view;
    routes.push(currentView);
  };

  const handleBack = () => {
    drawSend({
      type: "CONFIRM_UPDATE",
    });
    routes.pop()
    if( routes.length )
      currentView = routes[routes.length - 1]
  };
  
  const handleReset = () => {
    drawSend({
      type: "CANCEL_UPDATE",
    });
  };

  const handleSelectDoor = (e: CustomEvent) => {
    let currentDoor = e.detail.segment;
    if (!currentDoor && rooms.length > 0) {
      const viewCenter = new Pointer($viewBox.x + $viewBox.w / 2, $viewBox.y + $viewBox.h / 2)
      const sortedRooms = rooms.map((room) => {
        const centerPointer = centroid(room.shape);
        return {
          room,
          distance: dist(viewCenter, centerPointer)
        }
      }).sort((a, b) => a.distance - b.distance)

      const line = sortedRooms[0].room.shape.results.find((v) => isLine(v) && !(v as Line).isDashed()) as Line;
      const doorName = getNewSegmentName(type === DOORTYPE.DOOR ? $_("segment.door") : $_("segment.window"), $drawState.context.current.segments)
      currentDoor = createDoor(line, type, doorName);

      drawSend({
        type: "CREATE_BUILDING_PART",
        segment: currentDoor,
      });
    }
    drawSend({
      type: "ENTER_SELECT",
      segment: currentDoor,
    });
    
    if( type === DOORTYPE.DOOR )
      handleNext(e.detail.segment ? DOOR_VIEW.EDIT_DOOR : DOOR_VIEW.ADD_DOOR);
    else if( type === DOORTYPE.WINDOW )
      handleNext(e.detail.segment ? DOOR_VIEW.EDIT_WINDOW : DOOR_VIEW.ADD_WINDOW);
  }

  $: {
    switch (currentView) {
      case DOOR_VIEW.INDEX:
        headerInfo = {
          title: type === DOORTYPE.DOOR ? $_("side_menu.doors") : $_("side_menu.windows"),
          info: "",
          path: "",
          editable: false
        };
        break;
      case DOOR_VIEW.ADD_DOOR:
      case DOOR_VIEW.EDIT_DOOR:
        headerInfo = {
          title: $drawState.context.dragContext.selectedObject?.getName(),
          info: "",
          path: `${$_("side_menu.doors")} > ${currentView === DOOR_VIEW.ADD_DOOR ? $_("side_menu.door.add_door") : $_("side_menu.door.edit_door")}`,
          editable: true
        };
        break;
      case DOOR_VIEW.ADD_WINDOW:
      case DOOR_VIEW.EDIT_WINDOW:
        headerInfo = {
          title: $drawState.context.dragContext.selectedObject?.getName(),
          info: "",
          path: `${$_("side_menu.windows")} > ${currentView === DOOR_VIEW.ADD_WINDOW ? $_("side_menu.door.add_window") : $_("side_menu.door.edit_window")}`,
          editable: true
        };
        break;
    }
  }

  const handleTitleChange = (e) => {
    switch(currentView) {
      case DOOR_VIEW.ADD_DOOR:
      case DOOR_VIEW.EDIT_DOOR:
      case DOOR_VIEW.ADD_WINDOW:
      case DOOR_VIEW.EDIT_WINDOW:
        drawSend({
          type: "CHANGE_NAME",
          name: e.detail.value,
        });
      break;
    }
  }

  onDestroy(() => {
    if( unsubscribe ) unsubscribe();
  })

</script>

<div class="flex flex-col h-full">
  <Header {...headerInfo} on:change={handleTitleChange}/>

  {#if currentView !== DOOR_VIEW.INDEX}
    <div class="flex items-center gap-1 px-3 hover:text-primary-500 cursor-pointer" on:click={handleBack}>
      <i class="fa-regular fa-chevron-left text-sm" />
      <span class="font-medium text-sm">{$_("side_menu.back")}</span>
    </div>
  {/if}
  <div class="w-full flex flex-col flex-1 p-3 overflow-auto gap-4">
    {#if currentView === DOOR_VIEW.INDEX}
      <SegmentList segments={doors} type={SEGMENT_LIST_TYPE.DOOR} doorType={type} on:select={handleSelectDoor} hasAdd={rooms.length > 0} />
    {:else}
      <DoorInfo {type} on:back={handleBack}/>
    {/if}
    {#if currentView !== DOOR_VIEW.INDEX}
      <div class="flex items-center mt-auto">
        {#if currentView === DOOR_VIEW.ADD_DOOR || currentView === DOOR_VIEW.EDIT_DOOR || currentView === DOOR_VIEW.ADD_WINDOW || currentView === DOOR_VIEW.EDIT_WINDOW}
          <Button
            variant="outline-secondary"
            title={$_("side_menu.reset")}
            class="w-wide-btn ml-auto"
            on:click={handleReset}
          />
        {/if}
      </div>
    {/if}
  </div>
</div>
