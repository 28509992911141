<script lang="ts">
  export let onOpen: Function = () => {};
  export let onClose: Function = () => {};
  export let width: string = "";
  export let height: string = "";
  export let contentClass: string = "overflow-auto";

  let isOpen = false;

  const open = () => {
    isOpen = true;
    onOpen();
  };

  const close = () => {
    isOpen = false;
    onClose();
  };

  const keydown = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === "Escape") {
      close();
    }
  };

  export const openModal = open;
</script>

<slot name="trigger" {open} />

{#if isOpen}
  <div
    class="fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-30"
    on:keydown={keydown}
  >
    <div
      class="absolute w-full h-full bg-black bg-opacity-40"
      on:click={close}
    />
    <div class="bg-white {width} max-w-9/10 {height} max-h-9/10 z-30 rounded flex flex-col">
      <slot name="header" {close} />
      <slot name="content" {close} contentClass={contentClass + " min-h-0"} />
      <slot name="footer" {close} />
    </div>
  </div>
{/if}
