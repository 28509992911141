<script lang="ts">
  import Icon from "../base/Icon.svelte";

  import { drawSend } from "../../layout.store";
</script>

<div class="absolute bottom-8 right-8">
  <button
    type="button"
    class="inline-flex items-center p-2.5 mt-auto border border-transparent rounded-full shadow-sm text-white opacity-30 bg-green-500 hover:opacity-100 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
    on:click={() => drawSend({ type: "CONFIRM_AUTO_REPEAT_LAYOUT" })}
  >
    <Icon name="Confirm layout repeat" icon="confirm-layout-repeat" />
    &nbsp;repeat
  </button>
</div>
