export class Graph {
    size;
    adj;
    result;
    constructor(size) {
        this.size = size;
        this.adj = Array(size).fill([]);
        this.result = [];
    }
    addEdge(v, w) {
        // Add w to v’s list.
        this.adj[v] = [...this.adj[v], w];
        // Add v to w’s list.
        this.adj[w] = [...this.adj[w], v];
    }
    shortestPath(a, b) {
        // Finds a shortest path (using BFS) between a and b while ignoring
        // direct connection between a and b. That ensures we find the smallest cycle containing
        // both a and b.
        const visited = Array(this.size).fill(false);
        const queue = [[b, []]]; // Trick - we go from b to a so that we don’t have to reverse parents array when we finish
        const result = [];
        while (queue.length > 0) {
            const [n, parents] = queue.shift();
            if (n === a && parents.length > 0 && parents[0] !== b) {
                result.push(parents);
                continue;
                // return parents; // We got from b to a, and our parent is not b, we found the shortest non-direct path
            }
            if (visited[n]) {
                continue;
            }
            if (parents.includes(n)) {
                continue;
            }
            visited[n] = true;
            for (const i of this.adj[n]) {
                const isEdgeBetweenAAndB = (i === b && n === a) || (i === a && n === b);
                if (i !== parents[0] && !isEdgeBetweenAAndB) {
                    queue.push([i, [n, ...parents]]);
                }
            }
        }
        return result;
    }
    isCyclicUtil() {
        this.adj.map((a) => a.sort((v1, v2) => v1 - v2));
        // Mark the current node as visited
        const visited = Array(this.size).fill(false);
        const result = new Array();
        while (visited.some((v) => !v)) {
            const index = visited.findIndex((v) => !v);
            const queue = [[index, []]];
            while (queue.length > 0) {
                const [v, parents] = queue.shift();
                if (visited[v]) {
                    continue;
                }
                visited[v] = true;
                // Recur for all the vertices
                // adjacent to this vertex
                for (const i of this.adj[v]) {
                    // If an adjacent vertex is not visited,
                    //then recur for that adjacent
                    // if (!visited[i]) {
                    if (!parents.includes(v)) {
                        queue.push([i, [v, ...parents]]);
                    }
                    // If an adjacent vertex is visited and
                    // is not parent of current vertex,
                    // then there exists a cycle in the graph.
                    // else
                    if (parents.length > 0 && i !== parents[0]) {
                        // if (!result.some((r) => r.includes(v) && r.includes(i))) {
                        const path = this.shortestPath(v, i);
                        path.forEach((p) => {
                            const newShape = [v, ...p, v];
                            if (!result.some((r) => r.length === newShape.length &&
                                newShape.every((node) => r.includes(node)) &&
                                r.every((node) => newShape.includes(node)))) {
                                result.push([v, ...p, v]);
                            }
                        });
                    }
                    // }
                }
            }
        }
        return result;
    }
    // Returns true if the graph contains
    // a cycle, else false.
    getCyclic() {
        if (this.adj.length === 0) {
            return [];
        }
        // Call the helper
        // function to detect cycle in different
        // BFS trees
        this.result = this.isCyclicUtil();
        return this.result;
    }
}
