/**
 * Gauss-Legendre numerical integration, based on
 * https://rosettacode.org/wiki/Numerical_integration/Gauss-Legendre_Quadrature.
 */
export class GaussLegendre {
    static default = new GaussLegendre();
    static legeCoef(N) {
        const lcoef = Array.from(Array(N + 1), () => Array(N + 1).fill(1));
        lcoef[0][0] = lcoef[1][1] = 1;
        for (let n = 2; n <= N; n++) {
            lcoef[n][0] = (-(n - 1) * lcoef[n - 2][0]) / n;
            for (let i = 1; i <= n; i++) {
                lcoef[n][i] =
                    ((2 * n - 1) * lcoef[n - 1][i - 1] - (n - 1) * lcoef[n - 2][i]) / n;
            }
        }
        return lcoef;
    }
    static legeEval(n, x, lcoef) {
        let s = lcoef[n][n];
        for (let i = n; i > 0; i--) {
            s = s * x + lcoef[n][i - 1];
        }
        return s;
    }
    static legeDiff(n, x, lcoef) {
        return ((n *
            (x * GaussLegendre.legeEval(n, x, lcoef) -
                GaussLegendre.legeEval(n - 1, x, lcoef))) /
            (x * x - 1));
    }
    static legeRoots(N, lcoef) {
        const lroots = Array(N);
        const weight = Array(N);
        for (let i = 1; i <= N; i++) {
            let x = Math.cos((Math.PI * (i - 0.25)) / (N + 0.5));
            let x1 = x;
            do {
                x1 = x;
                x -=
                    GaussLegendre.legeEval(N, x, lcoef) /
                        GaussLegendre.legeDiff(N, x, lcoef);
            } while (x !== x1);
            lroots[i - 1] = x;
            x1 = GaussLegendre.legeDiff(N, x, lcoef);
            weight[i - 1] = 2 / ((1 - x * x) * x1 * x1);
        }
        return { lroots, weight };
    }
    // Legendre-Gauss roots and weights, copied from bezier.js
    lroots = [
        -0.0640568928626056260850430826247450385909,
        0.0640568928626056260850430826247450385909,
        -0.1911188674736163091586398207570696318404,
        0.1911188674736163091586398207570696318404,
        -0.3150426796961633743867932913198102407864,
        0.3150426796961633743867932913198102407864,
        -0.4337935076260451384870842319133497124524,
        0.4337935076260451384870842319133497124524,
        -0.5454214713888395356583756172183723700107,
        0.5454214713888395356583756172183723700107,
        -0.6480936519369755692524957869107476266696,
        0.6480936519369755692524957869107476266696,
        -0.7401241915785543642438281030999784255232,
        0.7401241915785543642438281030999784255232,
        -0.8200019859739029219539498726697452080761,
        0.8200019859739029219539498726697452080761,
        -0.8864155270044010342131543419821967550873,
        0.8864155270044010342131543419821967550873,
        -0.9382745520027327585236490017087214496548,
        0.9382745520027327585236490017087214496548,
        -0.9747285559713094981983919930081690617411,
        0.9747285559713094981983919930081690617411,
        -0.9951872199970213601799974097007368118745,
        0.9951872199970213601799974097007368118745,
    ];
    weights = [
        0.1279381953467521569740561652246953718517,
        0.1279381953467521569740561652246953718517,
        0.1258374563468282961213753825111836887264,
        0.1258374563468282961213753825111836887264,
        0.121670472927803391204463153476262425607,
        0.121670472927803391204463153476262425607,
        0.1155056680537256013533444839067835598622,
        0.1155056680537256013533444839067835598622,
        0.1074442701159656347825773424466062227946,
        0.1074442701159656347825773424466062227946,
        0.0976186521041138882698806644642471544279,
        0.0976186521041138882698806644642471544279,
        0.086190161531953275917185202983742667185,
        0.086190161531953275917185202983742667185,
        0.0733464814110803057340336152531165181193,
        0.0733464814110803057340336152531165181193,
        0.0592985849154367807463677585001085845412,
        0.0592985849154367807463677585001085845412,
        0.0442774388174198061686027482113382288593,
        0.0442774388174198061686027482113382288593,
        0.0285313886289336631813078159518782864491,
        0.0285313886289336631813078159518782864491,
        0.0123412297999871995468056670700372915759,
        0.0123412297999871995468056670700372915759,
    ];
    constructor() {
        // If we ever need to compute more coefficients, uncomment this and generate them.
        // This is a quadratic algorithm so we do not want to run it every time.
        // const lcoef = GaussLegendre.legeCoef(maxSubdivisions);
        // const rootsAndWeights = GaussLegendre.legeRoots(maxSubdivisions, lcoef);
        // this.lroots = rootsAndWeights.lroots;
        // this.weights = rootsAndWeights.weight;
    }
    integrate(f, from = 0, to = 1, subdivisions = this.weights.length) {
        const c1 = (to - from) / 2;
        const c2 = (from + to) / 2;
        let sum = 0;
        const n = Math.min(this.weights.length, subdivisions);
        for (let i = 0; i < n; i++) {
            sum += this.weights[i] * f(c1 * this.lroots[i] + c2);
        }
        return c1 * sum;
    }
}
