import { Path } from "./Path";
import { NeighborInstance } from "./NeighborInstance";
export default class ShapeInstance {
    shape;
    transform;
    collides;
    depth;
    polygon;
    collisionPolygon;
    get edges() {
        return this.shape.edges.map((_, idx) => this.shape.edge(idx));
    }
    static pathFromShapeAndTransform(shape, transform) {
        return shape.path.transformed(transform);
    }
    orientationVector;
    neighborLink;
    bounds;
    center;
    diameter;
    segments;
    constructor(shape, parent, neighborLink, transform, collides, depth, gapSize, polygon = ShapeInstance.pathFromShapeAndTransform(shape, transform), collisionPolygon = polygon.offset(-1e-2)) {
        this.shape = shape;
        this.transform = transform;
        this.collides = collides;
        this.depth = depth;
        this.polygon = polygon;
        this.collisionPolygon = collisionPolygon;
        this.bounds = this.polygon.bounds;
        this.center = this.polygon.center;
        this.diameter = this.polygon.diameter;
        this.segments = this.polygon.segments;
        const orientationEnd = this.transform.mulVec(shape.center.add(shape.orientation));
        this.orientationVector = orientationEnd.sub(this.center);
        if (neighborLink !== null && parent !== null) {
            this.neighborLink = new NeighborInstance(neighborLink, parent, this, gapSize);
        }
        else {
            this.neighborLink = null;
        }
    }
    distanceToCenter(position) {
        return position.distance(this.center);
    }
    edge(index, offset, anchorPoint) {
        return this.shape.edge(index, offset, anchorPoint);
    }
    intersects(other) {
        if (other instanceof Path) {
            return this.polygon.intersects(other);
        }
        return this.polygon.intersects(other.polygon);
    }
}
