<script lang="ts">
  import { activeMenu, currentTool, visibleSideMenu } from "src/store";
  import { drawSend, drawState } from "../../layout.store";
  import { _ } from "../../services/i18n";
  import { SIDE_MENUS, TOOLS } from "src/global/types";
  import Transition from "../util/Transition.svelte";
  import RoomPanel from "./SubPanels/RoomPanel.svelte";
  import WallPanel from "./SubPanels/WallPanel.svelte";
  import DoorPanel from "./SubPanels/DoorPanel.svelte";
  import FurniturePanel from "./SubPanels/FurniturePanel.svelte";
  import { DOORTYPE } from "src/model";
  import type { CategoryElement } from "src/model/CategoryElement";
  import AreaPanel from "./SubPanels/AreaPanel.svelte";
  import LayoutPanel from "./SubPanels/LayoutPanel.svelte";

  export let zoomOut: any;
  export let zoomIn: any;
  export let categories: CategoryElement[] = [];

  const closePanel = () => {
    visibleSideMenu.set(false)
    activeMenu.set(undefined)
  };

  const items = [
    {
      text: "rooms",
      icon: "fa-solid fa-vector-square",
      type: SIDE_MENUS.ROOM,
    },
    {
      text: "areas",
      icon: "fa-solid fa-square-dashed",
      type: SIDE_MENUS.AREA,
    },
    {
      text: "walls",
      icon: "fa-solid fa-block-brick",
      type: SIDE_MENUS.WALL,
    },
    // {
    //   text: "layouts",
    //   icon: "fa-solid fa-square",
    //   type: SIDE_MENUS.LAYOUT,
    // },
    {
      text: "doors",
      icon: "fa-solid fa-door-closed",
      type: SIDE_MENUS.DOOR,
    },
    {
      text: "windows",
      icon: "fa-solid fa-window-frame",
      type: SIDE_MENUS.WINDOW,
    },
    {
      text: "furnitures",
      icon: "fa-solid fa-couch",
      type: SIDE_MENUS.FURNITURE,
    },
  ];

  const selectTool = (tool) => {
    if( tool.type === $activeMenu ) {
      closePanel();
    } else {
      activeMenu.set(tool.type);
      currentTool.set(TOOLS.SELECTION);
      visibleSideMenu.set(true)
      drawSend({ type: "UPDATE_BACKUP" })
    }
    // currentTool.set(tool.type);
    // if ($drawState.matches("lineTool.shown"))
    //   drawSend({ type: "HIDE_LINE_TOOL" });
  };  
</script>

<Transition
  transitions="ease-in-out duration-500"
  inState="opacity-0"
  onState="opacity-100"
>
  {#if $visibleSideMenu && $currentTool !== TOOLS.EYE_DROPPER}
  <div
    class="fixed inset-0"
    aria-hidden="true"
    on:click={closePanel}
  />
  {/if}
  <div class="fixed top-0 right-0 flex h-full mt-15 pb-15 z-10">
    <Transition
      toggle={$visibleSideMenu}
      transitions="transform transition ease-in-out duration-500 sm:duration-700 h-full"
      inState="translate-x-full"
      onState="translate-x-0"
    >
      <div class={`w-screen overflow-hidden rounded-tl-lg mt-3 bg-white shadow-sidepanel ${$currentTool === TOOLS.EYE_DROPPER ? 'hidden' : ''}`} style={`max-width: 555px; height: calc(100% - 12px)`}>
        {#if $activeMenu === SIDE_MENUS.ROOM}
          <RoomPanel {categories} />
        {:else if $activeMenu === SIDE_MENUS.AREA}
          <AreaPanel />
        {:else if $activeMenu === SIDE_MENUS.WALL}
          <WallPanel />
        {:else if $activeMenu === SIDE_MENUS.DOOR}
          <DoorPanel type={DOORTYPE.DOOR} />
        {:else if $activeMenu === SIDE_MENUS.WINDOW}
          <DoorPanel type={DOORTYPE.WINDOW} />
        {:else if $activeMenu === SIDE_MENUS.FURNITURE}
          <FurniturePanel {categories} />
        <!-- {:else if $activeMenu === SIDE_MENUS.LAYOUT}
          <LayoutPanel /> -->
        {/if}
      </div>
    </Transition>
    <div class="w-15 h-full py-3 bg-secondary-500 flex flex-col z-10 print:hidden">
      {#each items as item, i}
        <div
          on:click={(_) => selectTool(item)}
          class={`flex flex-col items-center w-15 pt-3 pb-1.5 cursor-pointer 
                  ${$activeMenu === item.type ? 'bg-white text-secondary-500' : 'text-white'}`}
        >
          <i class={`${item.icon}`} title={$_(`side_menu.${item.text}`)} />
          <div class="text-xxs mt-1">{$_(`side_menu.${item.text}`)}</div>
        </div>
      {/each}

      <div class="mx-auto mt-auto">
        <div
          on:click={() => zoomIn()}
          class="flex items-center justify-center w-9 h-9 rounded-full bg-white cursor-pointer text-secondary-500"
        >
          <i
            class="fa-regular fa-plus"
            title={$_("tool.zoom.in")}
          />
        </div>
        <div
          on:click={() => zoomOut()}
          class="flex items-center justify-center w-9 h-9 rounded-full bg-white cursor-pointer text-secondary-500 mt-3"
        >
          <i
            class="fa-regular fa-minus"
            title={$_("tool.zoom.out")}
          />
        </div>
      </div>
    </div>
  </div>
</Transition>
