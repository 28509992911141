import Vector2 from "./Vector2";
export default class Matrix2D {
    elements;
    static IDENTITY = new Matrix2D([1, 0, 0, 0, 1, 0, 0, 0, 1]);
    static fromRotationTranslation(angleRadians, translation = new Vector2(0, 0)) {
        const c = Math.cos(angleRadians);
        const s = Math.sin(angleRadians);
        return new Matrix2D([c, -s, translation.x, s, c, translation.y, 0, 0, 1]);
    }
    static translation(t) {
        return new Matrix2D([1, 0, t.x, 0, 1, t.y, 0, 0, 1]);
    }
    static scale(s) {
        return new Matrix2D([s.x, 0, 0, 0, s.y, 0, 0, 0, 1]);
    }
    /**
     * Computes a rotation matrix that aligns the direction of v2 to the direction of v1.
     * Both vectors are expected to be normalized.
     * @param v1 normalized reference vector
     * @param v2 normalized vector to align
     * @param translation optional translation part of the matrix
     */
    static fromAlignedVectorsAndTranslation(v1, v2, translation = new Vector2(0, 0)) {
        const c = v1.dot(v2);
        const s = v1.crossMagnitude(v2);
        return new Matrix2D([c, -s, translation.x, s, c, translation.y, 0, 0, 1]);
    }
    static createIdentity() {
        return new Matrix2D([1, 0, 0, 0, 1, 0, 0, 0, 1]);
    }
    constructor(elements) {
        this.elements = elements;
        if (elements.length !== 9) {
            throw new Error("Invalid matrix length " + elements.length);
        }
    }
    mulMat(other) {
        if (other === Matrix2D.IDENTITY || other === null || other === undefined) {
            return this;
        }
        if (this === Matrix2D.IDENTITY) {
            return other;
        }
        const e1 = this.elements;
        const e2 = other.elements;
        return new Matrix2D([
            e1[0] * e2[0] + e1[1] * e2[3] + e1[2] * e2[6],
            e1[0] * e2[1] + e1[1] * e2[4] + e1[2] * e2[7],
            e1[0] * e2[2] + e1[1] * e2[5] + e1[2] * e2[8],
            e1[3] * e2[0] + e1[4] * e2[3] + e1[5] * e2[6],
            e1[3] * e2[1] + e1[4] * e2[4] + e1[5] * e2[7],
            e1[3] * e2[2] + e1[4] * e2[5] + e1[5] * e2[8],
            e1[6] * e2[0] + e1[7] * e2[3] + e1[8] * e2[6],
            e1[6] * e2[1] + e1[7] * e2[4] + e1[8] * e2[7],
            e1[6] * e2[2] + e1[7] * e2[5] + e1[8] * e2[8], // tslint:disable-line:max-line-length
        ]);
    }
    mulVec(other) {
        if (this === Matrix2D.IDENTITY && other instanceof Vector2) {
            return other;
        }
        return this.mulCoords(other.x, other.y);
    }
    mulCoords(x, y) {
        const e = this.elements;
        return new Vector2(e[0] * x + e[1] * y + e[2], e[3] * x + e[4] * y + e[5]);
    }
    get translation() {
        return new Vector2(this.elements[2], this.elements[5]);
    }
    withTranslation(translation) {
        const copy = this.elements.slice();
        copy[2] = translation.x;
        copy[5] = translation.y;
        return new Matrix2D(copy);
    }
    transposed() {
        const e = this.elements;
        return new Matrix2D([e[0], e[3], e[6], e[1], e[4], e[7], e[2], e[5], e[8]]);
    }
    get determinant() {
        const e = this.elements;
        return (e[0] * (e[4] * e[8] - e[5] * e[7]) -
            e[1] * (e[3] * e[8] - e[5] * e[6]) +
            e[2] * (e[3] * e[7] - e[4] * e[6]));
    }
    get inverse() {
        if (this === Matrix2D.IDENTITY) {
            return this;
        }
        const e = this.elements;
        const d = this.determinant;
        if (Math.abs(d) < 1e-10) {
            return undefined;
        }
        const r = [
            e[4] * e[8] - e[5] * e[7],
            -(e[1] * e[8] - e[2] * e[7]),
            e[1] * e[5] - e[2] * e[4],
            -(e[3] * e[8] - e[5] * e[6]),
            e[0] * e[8] - e[2] * e[6],
            -(e[0] * e[5] - e[2] * e[3]),
            e[3] * e[7] - e[4] * e[6],
            -(e[0] * e[7] - e[1] * e[6]),
            e[0] * e[4] - e[1] * e[3],
        ];
        return new Matrix2D(r);
    }
    get lossyScale() {
        const sx = Math.sqrt(this.elements[0] * this.elements[0] + this.elements[3] * this.elements[3]);
        const sy = Math.sqrt(this.elements[1] * this.elements[1] + this.elements[4] * this.elements[4]);
        return new Vector2(sx, sy);
    }
    /**
     * Determinant of the top-left 2x2 submatrix
     */
    get determinant2D() {
        return (this.elements[0] * this.elements[4] - this.elements[1] * this.elements[3]);
    }
    get rotation() {
        return Math.acos(this.elements[0]);
    }
    get rotationDegrees() {
        return (this.rotation * 180.0) / Math.PI;
    }
    equals(other, eps = 1e-10) {
        if (other === null || other === undefined) {
            return false;
        }
        for (let i = 0; i < 9; i++) {
            if (Math.abs(this.elements[i] - other.elements[i]) > eps) {
                return false;
            }
        }
        return true;
    }
    // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/transform
    // matrix is column based
    asSVGMatrixString() {
        const e = this.elements;
        return `matrix(${e[0]}, ${e[3]}, ${e[1]}, ${e[4]}, ${e[2]}, ${e[5]})`;
    }
}
