<script lang="ts">
  import IconSVG from "../base/IconSVG.svelte";
  import SnapPoint from "../icons/SnapPoint.svelte";

  import type Vector2 from "../../model/tile/Vector2";
  import { drawState } from "../../layout.store";

  $: snapPoint = $drawState.context.layoutContext.dragContext.snapPoint;
  $: showSnapPoint = snapPoint !== undefined;
  $: layout = $drawState.context.layoutContext.layout;
  $: selectedTileID = $drawState.context.layoutContext.selectedTileID;

  let snapPosition: Vector2 | undefined = undefined;
  $: {
    if (selectedTileID && snapPoint) {
      let link = layout.tiles.get(selectedTileID)?.neighborLink ?? undefined;
      if (link) {
        switch (snapPoint.srcFlag) {
          case "0":
            snapPosition = link.sourceSegment.start;
            break;
          case "1/4":
            snapPosition = link.sourceSegment.quarter;
            break;
          case "1/3":
            snapPosition = link.sourceSegment.third;
            break;
          case "1/2":
            snapPosition = link.sourceSegment.center;
            break;
          case "2/3":
            snapPosition = link.sourceSegment.third2;
            break;
          case "3/4":
            snapPosition = link.sourceSegment.quarter3;
            break;
          case "1":
            snapPosition = link.sourceSegment.end;
            break;
        }
      }
    }
  }
</script>

{#if showSnapPoint}
  <IconSVG x={snapPosition?.x ?? 0} y={snapPosition?.y ?? 0} passive={true}>
    <SnapPoint />
  </IconSVG>
{/if}
