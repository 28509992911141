<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { _ } from "src/services/i18n";
  import type { CategoryElement } from "src/model/CategoryElement";
  import { BuildingJson } from "src/global/variable";
  import { ClosedArea, BuildingPart, TileWrapper, Pointer } from "src/model";
  import { centroid, dist, getNewSegmentName, getShapeId, isInShape, isTileWrapper } from "src/helpers";
  import { drawSend, drawState } from "../../../layout.store";
  import GridItem from "./GridItem.svelte";
  import { v4 as uuidv4 } from 'uuid';

  export let categories: CategoryElement[] = [];
  export let room: ClosedArea;

  const getBuildingElement = (id: number) => {
    const item = BuildingJson.find((i) => i.ids.includes(id));
    return item ?? undefined;
  };

  $: categoriesWithBuilding = categories.map((category) => ({
    ...category,
    building: getBuildingElement(category.id),
  }));

  const dispatch = createEventDispatcher();

  const handleSelectCategory = (category: any) => {
    const buildingElement = category.building

    let width = buildingElement.x;
    let height = buildingElement.y;
    let steps = 0;
    const centerPointer = centroid(room.shape);
    let finalStartPointer: Pointer | undefined = undefined;
    let finalEndPointer: Pointer | undefined = undefined;
    let finalWidth = 0;
    let finalHeight = 0;
    
    do {
      steps++;
      const startPointer = centerPointer.translate(
          -width / 2,
          -height / 2
        );
      const endPointer = centerPointer.translate(
          width / 2,
          height / 2
        );
      
      const vertex = [startPointer, endPointer, startPointer.translate(width, 0), startPointer.translate(0, height)];
      if (vertex.every((v) => isInShape(v, room.shape))) {
        finalWidth = width;
        finalHeight = height;
        finalStartPointer = startPointer.translate(0, 0);
        finalEndPointer = endPointer.translate(0, 0);

        if ( steps === 1 ) 
          break;
        
        width = width + buildingElement.x / Math.pow(2, steps)
        height = height + buildingElement.y / Math.pow(2, steps)
      } else {
        width = width - buildingElement.x / Math.pow(2, steps)
        height = height - buildingElement.y / Math.pow(2, steps)
      }
    } while (steps < 5)

    if( !finalStartPointer || !finalEndPointer) {
      return
    }
    const furnitureName = getNewSegmentName(category.name, $drawState.context.current.segments)
    const buildingPart = new BuildingPart(
      finalStartPointer,
      finalEndPointer,
      finalWidth,
      finalHeight,
      0,
      0,
      category.name,
      category.id,
      room.id,
      uuidv4(),
      buildingElement.zIndex,
      furnitureName
    )
    drawSend({
      type: "CREATE_BUILDING_PART",
      segment: buildingPart,
    });
    dispatch("select", { segment: buildingPart });
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="w-full grid grid-cols-4 gap-4">
  {#each categoriesWithBuilding as category}
    <GridItem
      title={category.name}
      on:click={() => handleSelectCategory(category)}
    >
      {#if category.building}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${category.building.width} ${category.building.height}`}
          width="92px"
          height="92px"
        >
          <g stroke="black">
            {@html category.building.path}
          </g>
        </svg>
      {/if}
    </GridItem>
  {/each}
</div>
