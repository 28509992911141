import Vector2 from "./Vector2";
export default class BoundingBox {
    min;
    max;
    static INFINITE = new BoundingBox(new Vector2(Number.MIN_SAFE_INTEGER / 2, Number.MIN_SAFE_INTEGER / 2), new Vector2(Number.MAX_SAFE_INTEGER / 2, Number.MAX_SAFE_INTEGER / 2));
    static fromPoints(points) {
        let min = points[0];
        let max = points[0];
        for (const pt of points) {
            min = pt.min(min);
            max = pt.max(max);
        }
        return new BoundingBox(min, max);
    }
    static fromBoundingBoxes(bboxes) {
        let min = bboxes[0].min;
        let max = bboxes[0].max;
        for (const bbox of bboxes) {
            min = min.min(bbox.min);
            max = max.max(bbox.max);
        }
        return new BoundingBox(min, max);
    }
    center;
    area;
    constructor(min, max) {
        this.min = min;
        this.max = max;
        this.center = min.add(max).mul(0.5);
        this.area = (max.x - min.x) * (max.y - min.y);
    }
    get extents() {
        return this.max.sub(this.center);
    }
    get size() {
        return this.max.sub(this.min);
    }
    get maxExtent() {
        const extents = this.extents;
        return Math.max(extents.x, extents.y);
    }
    get minExtent() {
        const extents = this.extents;
        return Math.min(extents.x, extents.y);
    }
    get radius() {
        return this.extents.length();
    }
    distance(p) {
        return Math.sqrt(this.distanceSquared(p));
    }
    distanceSquared(p) {
        let dx = 0.0;
        let dy = 0.0;
        if (p.x < this.min.x) {
            dx = p.x - this.min.x;
        }
        else if (p.x > this.max.x) {
            dx = p.x - this.max.x;
        }
        if (p.y < this.min.y) {
            dy = p.y - this.min.y;
        }
        else if (p.y > this.max.y) {
            dy = p.y - this.max.y;
        }
        return dx * dx + dy * dy;
    }
    contains(point) {
        return (point.x >= this.min.x &&
            point.x <= this.max.x &&
            point.y >= this.min.y &&
            point.y <= this.max.y);
    }
    union(other) {
        return new BoundingBox(this.min.min(other.min), this.max.max(other.max));
    }
    translated(by) {
        if (by === null || by === undefined) {
            return this;
        }
        return new BoundingBox(this.min.add(by), this.max.add(by));
    }
    transformed(by) {
        const p1 = by.mulVec(this.min);
        const p2 = by.mulCoords(this.min.x, this.max.y);
        const p3 = by.mulCoords(this.max.x, this.min.y);
        const p4 = by.mulVec(this.max);
        return BoundingBox.fromPoints([p1, p2, p3, p4]);
    }
    expanded(by) {
        return new BoundingBox(new Vector2(this.min.x - by, this.min.y - by), new Vector2(this.max.x + by, this.max.y + by));
    }
    intersects(other) {
        return !(other.min.x > this.max.x ||
            other.max.x < this.min.x ||
            other.min.y > this.max.y ||
            other.max.y < this.min.y);
    }
    toString() {
        return ("BB[min=" +
            this.min.toString() +
            ", max=" +
            this.max.toString() +
            ", size=" +
            this.size.toString() +
            "]");
    }
}
