import { Pointer } from "./Pointer";
import { Segment, SegmentClass } from "./Segment";
import { dist } from "../helpers";
import { v4 as uuidv4 } from 'uuid';
export class ClosedArea extends Segment {
    startPointer;
    endPointer;
    id;
    parentId;
    shape;
    zIndex;
    name;
    tileWrappers;
    constructor(startPointer, endPointer, id = uuidv4(), parentId = 0, shape = {
        results: [],
        points: [],
    }, zIndex = 0, name = "") {
        super(startPointer, endPointer, id, zIndex, name);
        this.startPointer = startPointer;
        this.endPointer = endPointer;
        this.id = id;
        this.parentId = parentId;
        this.shape = shape;
        this.zIndex = zIndex;
        this.name = name;
        this.class = SegmentClass.CLOSED_AREA;
        this.tileWrappers = [];
    }
    generatePath(isFromShape = false, startPointer = undefined) {
        return "";
    }
    generateHelperPath() {
        return [];
    }
    getRealTransition(dx, dy, svgSize) {
        return { dx: dx, dy: dy };
    }
    generateHelperTextData() {
        return [];
    }
    getPointsArray() {
        return [];
    }
    translate(dx, dy, segments) {
        return;
    }
    getLineLength() {
        return dist(this.startPointer, this.endPointer);
    }
    isInSegment(pointer, acceptInPath = true) {
        return false;
    }
    getSnapInfos = () => {
        return {
            snapPointer: new Pointer(0, 0),
            snapLinePointer1: new Pointer(0, 0),
            snapLinePointer2: new Pointer(0, 0),
            deltaAngle: 0,
            lineAngle: 0,
            distance: {
                deltaX: 0,
                deltaY: 0,
            },
        };
    };
    setTileWrappers = (tileWrappers) => {
        this.tileWrappers = tileWrappers;
        this.tileWrappers?.forEach((v) => v.closedAreaId = this.id);
    };
    addTileWrapper = (tileWrapper) => {
        tileWrapper.closedAreaId = this.id;
        this.tileWrappers.push(tileWrapper);
    };
    getArea = () => {
        let totalArea = 0;
        this.tileWrappers.forEach((tileWrapper) => totalArea += tileWrapper.getArea());
        return totalArea;
    };
    clone() {
        const result = new ClosedArea(this.startPointer?.translate(0, 0), this.endPointer?.translate(0, 0), this.id, this.parentId, {
            results: this.shape.results.map((r) => r.clone()),
            points: this.shape.points.map((p) => p.translate(0, 0)),
        }, this.zIndex, this.name);
        result.setTileWrappers(this.tileWrappers);
        result.altName = this.altName;
        return result;
    }
    toJSON() {
        return {
            ...super.toJSON(),
            parentId: this.parentId,
            shape: {
                results: this.shape.results.map((r) => r.id),
                points: this.shape.points.map((p) => p.toJSON()),
            },
            tileWrappers: this.tileWrappers?.map((v) => v.id) || []
        };
    }
}
