<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import GridItem from "./GridItem.svelte";
  import {
  BuildingPart,
    ClosedArea,
    DOORTYPE,
    Line,
    Pointer,
    Segment,
    TileWrapper,
  } from "src/model";
  import PassiveTile from "../../tiles/PassiveTile.svelte";
  import { SEGMENT_LIST_TYPE } from "src/global/types";
  import type { LayoutGeometry, RecentLayout } from "src/store/drawMachine";
  import type Shape from "src/model/tile/Shape";
  import { BuildingJson } from "src/global/variable";
  import { getBoundingRect, getShapePath } from "src/helpers";

  export let className = "";
  export { className as class };
  
  type SegmentType = Segment | LayoutGeometry | Shape | RecentLayout;
  export let segments: SegmentType[];
  export let type: SEGMENT_LIST_TYPE;
  export let doorType: DOORTYPE = DOORTYPE.DOOR;
  export let hasAdd: boolean = false;
  export let cols: string = 'grid-cols-4';

  const dispatch = createEventDispatcher();

  $: getViewBox = (segment: Segment) => {
    let boundingRect;
    if( type === SEGMENT_LIST_TYPE.LINE ) {
      const line = segment as Line;
      boundingRect = getBoundingRect([line.startPointer, line.endPointer])
    } else if ( type === SEGMENT_LIST_TYPE.AREA ) {
      const area = segment as TileWrapper;
      boundingRect = getBoundingRect(area.shape.points);
    } else if ( type === SEGMENT_LIST_TYPE.TILE_WRAPPER ) {
      const area = segment as ClosedArea;
      boundingRect = getBoundingRect(area.shape.points);
    } else {
      return "";
    }
     
    const centerPoint = new Pointer(boundingRect[0] + boundingRect[2] / 2, boundingRect[1] + boundingRect[3] / 2)
    const radius = Math.max(boundingRect[2], boundingRect[3]) * 1.2;

    return `${centerPoint.x - radius / 2} ${centerPoint.y - radius / 2} ${radius} ${radius}`
  }

  const isSegment = (t: SEGMENT_LIST_TYPE) => {
    return t === SEGMENT_LIST_TYPE.DOOR || t === SEGMENT_LIST_TYPE.LINE || 
            t === SEGMENT_LIST_TYPE.BUILDING_PART || t === SEGMENT_LIST_TYPE.TILE_WRAPPER || t === SEGMENT_LIST_TYPE.AREA
  }

  const getBuildingElement = (id) => {
    const item = BuildingJson.find((i) => i.ids.includes(id));
    return item ?? undefined;
  };

  const hoverSegment = (seg: SegmentType, index?: number) => {

    dispatch("mouseenter", { segment: seg, index: index });

    if (type !== SEGMENT_LIST_TYPE.TILED_LAYOUT && type !== SEGMENT_LIST_TYPE.LAYOUT && type !== SEGMENT_LIST_TYPE.SHAPE)
      drawSend({
        type: "ENTER_SELECT",
        segment: seg as Segment,
      });
  };

  const hoverOffSegment = (seg: SegmentType, index?: number) => {

    dispatch("mouseleave", { segment: seg, index: index });
  };

  const handleSelectSegment = (seg?: SegmentType, index?: number) => {
    dispatch("select", {
      segment: seg,
      index: index,
    });
  };

  const handleDuplicateLayout = (seg?: SegmentType) => {
    dispatch("select", {
      segment: seg,
      action: "duplicate",
    });
  };

  const handleDeleteLayout = (seg?: SegmentType) => {
    dispatch("select", {
      segment: seg,
      action: "delete",
    });
  };

  const getSegmentName = (t: SEGMENT_LIST_TYPE, seg: SegmentType, index: number) => {
    if( isSegment(t) ) return (seg as Segment).getName();
    if( t === SEGMENT_LIST_TYPE.TILED_LAYOUT )
      return seg?.name ?? `Layout ${index}`
    if( t === SEGMENT_LIST_TYPE.LAYOUT )
      return seg?.name ?? `Layout ${index}`
    return seg?.name ?? `Tile ${index}`
  }

  const castSegment = (seg: SegmentType) => {
    return seg as Segment
  }
  
  const castLine = (seg: SegmentType) => {
    return seg as Line
  }
  
  const castBuildingPart = (seg: SegmentType) => {
    return seg as BuildingPart
  }
  
  const castShape = (seg: SegmentType) => {
    return seg as Shape
  }

  const castLayoutGeometry = (seg: SegmentType) => {
    return seg as LayoutGeometry
  }

  const castTileWrapper = (seg: SegmentType) => {
    return seg as TileWrapper;
  }

  const castClosedArea = (seg: SegmentType) => {
    return seg as ClosedArea;
  }

  const castTiledLayout = (seg: SegmentType) => {
    return seg as RecentLayout;
  }
</script>

<div class={`${className} w-full grid ${cols} gap-4`}>
  {#each segments as segment, i}
    <GridItem
      title={getSegmentName(type, segment, i)}
      size={type === SEGMENT_LIST_TYPE.TILED_LAYOUT ? "2xl" : type === SEGMENT_LIST_TYPE.SHAPE ? "lg" : "xl"}
      on:mouseenter={() => hoverSegment(segment, i)}
      on:mouseleave={() => hoverOffSegment(segment, i)}
      on:click={() => handleSelectSegment(segment, i)}
      active={type === SEGMENT_LIST_TYPE.TILED_LAYOUT ? castTiledLayout(segment)?.active : false}
    >
      {#if type === SEGMENT_LIST_TYPE.LINE}
        <svg
          viewBox={`${getViewBox(castSegment(segment))}`}
          width="70px"
          height="70px"
        >
          <path 
            d={castSegment(segment).generatePath()} 
            stroke-linecap="round"
            stroke-width="3px"
            stroke="#333333"
            fill="none"
          />
        </svg>
      {:else if type === SEGMENT_LIST_TYPE.AREA}
        <svg
          viewBox={`${getViewBox(castSegment(segment))}`}
          width="70px"
          height="70px"
        >
          <path 
            d={getShapePath(castTileWrapper(segment).shape)} 
            stroke-linecap="round"
            stroke-width="3px"
            stroke="#333333"
            fill="none"
          />
        </svg>
      {:else if type === SEGMENT_LIST_TYPE.TILE_WRAPPER}
        <svg
          viewBox={`${getViewBox(castSegment(segment))}`}
          width="70px"
          height="70px"
        >
          <path 
            d={getShapePath(castClosedArea(segment).shape)} 
            stroke-linecap="round"
            stroke-width="3px"
            stroke="#333333"
            fill="none"
          />
        </svg>
      {:else if type === SEGMENT_LIST_TYPE.DOOR}
        <i
          class={`fa-regular text-4xl text-secondary-400 ${
            doorType === DOORTYPE.DOOR ? "fa-door-closed" : "fa-window-frame"
          }`}
        />
      {:else if type === SEGMENT_LIST_TYPE.BUILDING_PART}
        {@const buildingElement = getBuildingElement(castBuildingPart(segment).buildingId)}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${buildingElement?.width} ${buildingElement?.height}`}
          width="70px"
          height="70px"
        >
          <g stroke="black">
            {@html buildingElement?.path}
          </g>
        </svg>
      {:else if type === SEGMENT_LIST_TYPE.LAYOUT}
        {#if castLayoutGeometry(segment)?.preview_image || castLayoutGeometry(segment)?.svg_path}
        <img
          src={castLayoutGeometry(segment)?.preview_image || castLayoutGeometry(segment)?.svg_path}
          alt={segment?.name}
        />
        {/if}
      {:else if type === SEGMENT_LIST_TYPE.TILED_LAYOUT}
        <div class="flex flex-col gap-2">
          {#if castTiledLayout(segment)?.preview}
            <img src={castTiledLayout(segment).preview} class="w-full h-full object-contain" alt="preview" />
            <!-- {@html castTiledLayout(segment).preview} -->
          {/if}
          <div class="flex items-center justify-end gap-2">
            {#if castTiledLayout(segment)?.clonable}
              <i
                class="fa-solid fa-clone text-secondary-300 hover:text-secondary-500 text-base" 
                on:click={(e) => {
                  e.stopPropagation();
                  handleDuplicateLayout(segment)
                }}
                on:keydown
              />
            {/if}
            {#if castTiledLayout(segment)?.deletable}
              <i
                class="fa-solid fa-trash text-red-300 hover:text-red-500 text-base"
                on:click={(e) => {
                  e.stopPropagation();
                  handleDeleteLayout(segment)
                }}
                on:keydown
              />
            {/if}
          </div>
        </div>
      {:else if type === SEGMENT_LIST_TYPE.SHAPE}
        <svg viewBox="-1 -1 2 2">
          <PassiveTile shape={castShape(segment)} tileID={castShape(segment).tileId} strokeWidth={0.1}/>
        </svg>
      {/if}
    </GridItem>
  {/each}
  <slot name="add">
    {#if hasAdd}
    <GridItem 
      title={$_("side_menu.add")} 
      size="xl" 
      special
      on:click={() => handleSelectSegment(null)}
    >
      <i class="fa-regular fa-plus text-4xl" />
    </GridItem>
    {/if}
  </slot>
</div>
