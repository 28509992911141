<script lang="ts">
  import EditableLabel from "../../base/EditableLabel.svelte";
  export let title = "";
  export let path : string = undefined;
  export let info : string = undefined;
  export let editable: boolean = false;
</script>

<div class="min-w-40 text-center p-3">
  {#if path}
    <div class="text-sm text-gray-400 mb-1">{path}</div>
  {/if}

  {#if editable}
  <EditableLabel value={title} on:change>
    <h3 class="w-full text-secondary-500 uppercase">{title}</h3>
  </EditableLabel>
  {:else}
    <h3 class="w-full text-secondary-500 uppercase">{title}</h3>
  {/if}

  {#if info}
    <div class="text-xxs text-gray-400 mt-1">{info}</div>
  {/if}
</div>