/**
 * Axis Aligned Bounding Box Tree
 */
export default class AABBTree {
    rootNode;
    shapeToNodeMap;
    constructor() {
        this.shapeToNodeMap = new Map();
    }
    /**
     * Adds a new shape to the tree.
     * @param shape The shape to add to the tree (Must be an implementation of Path interface)
     */
    add(shape, shapeAABB) {
        // If there is no item in the tree we create the root node
        if (this.rootNode === undefined) {
            this.rootNode = new AABBNode(shapeAABB, shape, undefined, undefined, undefined);
            this.shapeToNodeMap.set(shape, this.rootNode);
            return;
        }
        let currentNode = this.rootNode;
        let newAabb = this.rootNode.aabb;
        while (!currentNode.isLeaf) {
            // We have to do this so that typescript doesn't yell at us
            const leftNode = currentNode.leftNode;
            const rightNode = currentNode.rightNode;
            const newNodeAabb = currentNode.aabb.union(shapeAABB);
            const newLeftAabb = leftNode.aabb.union(shapeAABB);
            const newRightAabb = rightNode.aabb.union(shapeAABB);
            const volumeDifference = newNodeAabb.area - currentNode.aabb.area;
            if (volumeDifference > 0) {
                let leftCost;
                let rightCost;
                if (leftNode.isLeaf) {
                    leftCost = newLeftAabb.area + volumeDifference;
                }
                else {
                    leftCost = newLeftAabb.area - leftNode.aabb.area + volumeDifference;
                }
                if (rightNode.isLeaf) {
                    rightCost = newRightAabb.area + volumeDifference;
                }
                else {
                    rightCost =
                        newRightAabb.area - rightNode.aabb.area + volumeDifference;
                }
                // For some reason 1.3 is a good bias to introduce. Might need to understand that some day...
                if (newNodeAabb.area < leftCost * 1.3 &&
                    newNodeAabb.area < rightCost * 1.3) {
                    break;
                }
                currentNode.aabb = newNodeAabb;
                if (leftCost > rightCost) {
                    currentNode = rightNode;
                    newAabb = newRightAabb;
                }
                else {
                    currentNode = leftNode;
                    newAabb = newLeftAabb;
                }
                continue;
            }
            // Set the new AABB right away so we don't have to traverse the tree backwards after insert
            currentNode.aabb = newNodeAabb;
            const leftVolumeIncrease = newLeftAabb.area - leftNode.aabb.area;
            const rightVolumeIncrease = newRightAabb.area - rightNode.aabb.area;
            if (leftVolumeIncrease > rightVolumeIncrease) {
                currentNode = rightNode;
                newAabb = newRightAabb;
            }
            else {
                currentNode = leftNode;
                newAabb = newLeftAabb;
            }
        }
        const newChild = new AABBNode(currentNode.aabb, currentNode.shape, currentNode, currentNode.rightNode, currentNode.leftNode);
        if (newChild.shape !== undefined) {
            // Update the new shape to the new shape
            this.shapeToNodeMap.set(newChild.shape, newChild);
        }
        currentNode.leftNode = newChild;
        currentNode.rightNode = new AABBNode(shapeAABB, shape, currentNode, undefined, undefined);
        // If rootNode is leaf we didn't pass through while loop so we didn't get a new AABB
        currentNode.aabb =
            currentNode === this.rootNode
                ? this.rootNode.aabb.union(shapeAABB)
                : newAabb;
        currentNode.shape = undefined;
        this.shapeToNodeMap.set(shape, currentNode.rightNode);
        return;
    }
    /**
     * Removes the shape and balances the tree
     * @remarks The method will gracefully exit if the shape doesn't exist in the tree
     * @param shape - The shape to remove from the tree
     */
    remove(shape) {
        const node = this.shapeToNodeMap.get(shape);
        if (node === undefined) {
            return;
        }
        this.removeNode(node);
        this.shapeToNodeMap.delete(shape);
    }
    /**
     * Finds all shapes overlapping with the given point
     * @remarks if the tree is empty this function will always return an empty array
     * @param shape - The shape to check overlaps against
     * @returns An array containing all the shape overlapping with the point
     */
    getShapesAtPoint(point, containsPointCheck) {
        if (this.rootNode === undefined) {
            return [];
        }
        const collidingNodes = [];
        const nodesToCheck = [this.rootNode];
        let index = 0;
        while (nodesToCheck.length > index) {
            const leftNode = nodesToCheck[index]
                .leftNode;
            const rightNode = nodesToCheck[index]
                .rightNode;
            // Eventualy move this out of the loop so we dont have to check on every loop
            if (nodesToCheck[index] === this.rootNode && this.rootNode.shape) {
                if (containsPointCheck(this.rootNode.shape, point)) {
                    collidingNodes.push(this.rootNode.shape);
                }
            }
            if (leftNode) {
                if (leftNode.aabb.contains(point)) {
                    if (!leftNode.isLeaf) {
                        nodesToCheck.push(leftNode);
                    }
                    else if (containsPointCheck(leftNode.shape, point)) {
                        collidingNodes.push(leftNode.shape);
                    }
                }
            }
            if (rightNode) {
                if (rightNode.aabb.contains(point)) {
                    if (!rightNode.isLeaf) {
                        nodesToCheck.push(rightNode);
                    }
                    else if (containsPointCheck(rightNode.shape, point)) {
                        collidingNodes.push(rightNode.shape);
                    }
                }
            }
            index++;
        }
        return collidingNodes;
    }
    /**
     * Finds all shapes overlapping with the given shape
     * @remarks if the tree is empty this function will always return an empty array
     * @param shape - The shape to check overlaps against
     * @returns An array containing all the shape overlapping with the shape
     */
    getIntersectingShapes(shape, bbox, intersects) {
        if (this.rootNode === undefined) {
            return [];
        }
        const collidingNodes = [];
        const nodesToCheck = [this.rootNode];
        let index = 0;
        while (nodesToCheck.length > index) {
            const leftNode = nodesToCheck[index]
                .leftNode;
            const rightNode = nodesToCheck[index]
                .rightNode;
            // Eventualy move this out of the loop so we dont have to check on every loop
            if (nodesToCheck[index] === this.rootNode && this.rootNode.shape) {
                if (intersects(this.rootNode.shape, shape)) {
                    collidingNodes.push(this.rootNode.shape);
                }
            }
            if (leftNode) {
                if (leftNode.aabb.intersects(bbox)) {
                    if (!leftNode.isLeaf) {
                        nodesToCheck.push(leftNode);
                    }
                    else if (intersects(leftNode.shape, shape)) {
                        collidingNodes.push(leftNode.shape);
                    }
                }
            }
            if (rightNode) {
                if (rightNode.aabb.intersects(bbox)) {
                    if (!rightNode.isLeaf) {
                        nodesToCheck.push(rightNode);
                    }
                    else if (intersects(rightNode.shape, shape)) {
                        collidingNodes.push(rightNode.shape);
                    }
                }
            }
            index++;
        }
        return collidingNodes;
    }
    /**
     * Finds all shapes overlapping with the given point
     * @remarks if the tree is empty this function will always return an empty array
     * @param shape - The shape to check overlaps against
     * @returns An array containing all the shape overlapping with the point
     */
    intersectsShape(shape, bbox, intersects) {
        if (this.rootNode === undefined) {
            return false;
        }
        const nodesToCheck = [this.rootNode];
        let index = 0;
        while (nodesToCheck.length > index) {
            const leftNode = nodesToCheck[index]
                .leftNode;
            const rightNode = nodesToCheck[index]
                .rightNode;
            // Eventualy move this out of the loop so we dont have to check on every loop
            if (nodesToCheck[index] === this.rootNode && this.rootNode.shape) {
                if (intersects(this.rootNode.shape, shape)) {
                    return true;
                }
            }
            if (leftNode) {
                if (leftNode.aabb.intersects(bbox)) {
                    if (!leftNode.isLeaf) {
                        nodesToCheck.push(leftNode);
                    }
                    else if (intersects(leftNode.shape, shape)) {
                        return true;
                    }
                }
            }
            if (rightNode) {
                if (rightNode.aabb.intersects(bbox)) {
                    if (!rightNode.isLeaf) {
                        nodesToCheck.push(rightNode);
                    }
                    else if (intersects(rightNode.shape, shape)) {
                        return true;
                    }
                }
            }
            index++;
        }
        return false;
    }
    /**
     * Returns all the node in the tree
     *
     * @remarks
     * The nodes that serves as parent containers are also returned. Do not expect every node to contain a shape
     *
     * @returns An array containing all the nodes the tree contains
     */
    get allNodes() {
        const nodes = [];
        if (this.rootNode !== undefined) {
            this.nodeIterator(this.rootNode, nodes);
        }
        return nodes;
    }
    /**
     * Iterates recursivly over the entire three and all the node to the array
     *
     * @param node - The node to iterate over
     * @param array - Reference to the array that will contain all the nodes in the tree
     */
    nodeIterator(node, array) {
        array.push(node);
        if (!node.isLeaf) {
            this.nodeIterator(node.rightNode, array);
            this.nodeIterator(node.leftNode, array);
        }
    }
    /**
     * Remove a node from the tree and balances the tree if needed
     *
     * @param node - The node to remove from the tree
     */
    removeNode(node) {
        // if this is the root node we delete everything
        if (node.parentNode === undefined) {
            this.rootNode = undefined;
            return;
        }
        const parentNode = node.parentNode;
        const sibling = (parentNode.leftNode === node ? parentNode.rightNode : parentNode.leftNode);
        // Move the sibling up a level and clean references
        parentNode.aabb = sibling.aabb;
        parentNode.shape = sibling.shape;
        parentNode.leftNode = sibling.leftNode;
        parentNode.rightNode = sibling.rightNode;
        // change the reference to the children to point the their new parent
        if (!sibling.isLeaf) {
            const left = sibling.leftNode;
            const right = sibling.rightNode;
            left.parentNode = parentNode;
            right.parentNode = parentNode;
        }
        if (this.shapeToNodeMap.has(parentNode.shape)) {
            this.shapeToNodeMap.set(parentNode.shape, parentNode);
        }
        // Fix tree upwards
        let currentNode = parentNode.parentNode;
        while (currentNode !== undefined) {
            currentNode.aabb = currentNode.leftNode.aabb.union(currentNode.rightNode.aabb);
            currentNode = currentNode.parentNode;
        }
    }
}
/**
 * Axis Aligned Bouding Box Node
 *
 * @remark
 * The bounding box on this nodes either fully contains the shape or the two child nodes.
 */
class AABBNode {
    aabb;
    shape;
    parentNode;
    leftNode;
    rightNode;
    /**
     * @param aabb - A bounding box containing the shape or the two child nodes
     * @param shape - A reference to a shape implementation of IAABBShape
     * @param parentNode - The node parenting this node
     * @param leftNode - The first child node
     * @param rightNode - The second child node
     */
    constructor(Aabb, shape, parentNode, leftNode, rightNode) {
        this.aabb = Aabb;
        this.shape = shape;
        this.parentNode = parentNode;
        this.leftNode = leftNode;
        this.rightNode = rightNode;
    }
    /**
     * @remark
     * The node is considered a leaf when is has no reference to any child nodes.
     * Only leaf nodes should contain shapes
     */
    get isLeaf() {
        return this.leftNode === undefined;
    }
}
