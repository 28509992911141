<script lang="ts">
  import { fade } from 'svelte/transition';
  import { currentTool, editSegment, editTool, savedViewBox, selectedRoom, viewBox } from "../../store";
  import { Pointer, type ClosedArea, type TileWrapper, BuildingPart } from "../../model";
  import { Door, DOORTYPE } from "../../model/Door";
  import { centroid, convertPointerToClientBox, convertPointerToViewBox, getShapeBoundingRect, isArc, isBuildingPart, isClosedArea, isDoor, isLine, isTileWrapper } from "../../helpers";
  import { drawSend, drawState } from "../../layout.store";
  import { _ } from "../../services/i18n";
  import { TOOLS, type Tool } from "src/global/types";
  import { activeMenu, visibleSideMenu } from "src/store";
  import { SIDE_MENUS } from "src/global/types";
  import { onMount, tick } from "svelte";
  import { emptyLayout } from 'src/store/drawMachine';

  export let svgRef: SVGAElement;
  
  $: toolPosition =
    $drawState.context.dragContext.offset && svgRef
      ? convertPointerToClientBox($drawState.context.dragContext.offset, svgRef)
      : new Pointer(70, 8);

  $: selectedObject = $drawState.context.dragContext.selectedObject
  $: canRotate = isBuildingPart(selectedObject) || isTileWrapper(selectedObject) || isClosedArea(selectedObject)
  let position: Pointer;
  $: {
    position = isBuildingPart(selectedObject) ? 
      convertPointerToClientBox(new Pointer(selectedObject.startPointer.x + (selectedObject as BuildingPart).width / 2, selectedObject.startPointer.y + (selectedObject as BuildingPart).length / 2), svgRef) : 
      isTileWrapper(selectedObject) || isClosedArea(selectedObject) ?
      convertPointerToClientBox(centroid((selectedObject as any).shape), svgRef) : 
      toolPosition
    if ( position.x < 170 ) {
      position.x = 170
    }
    if ( position.x > svgRef.clientWidth - 200 ) {
      position.x = svgRef.clientWidth - 200
    }
    if ( position.y < 195 ) {
      position.y = 195
    }
    let margin = canRotate ? 150 : 40;
    if ( position.y > svgRef.clientHeight - margin ) {
      position.y = svgRef.clientHeight - margin
    }
  }

  const items: Tool[] = [
    {
      text: $_("edit_tool.arc"),
      icon: "fa-solid fa-arrow-down-to-arc fa-rotate-180",
      type: TOOLS.EDIT_ARC,
    },
    {
      text: $_("edit_tool.split"),
      icon: "fa-solid fa-vector-polygon",
      type: TOOLS.EDIT_SPLIT,
    },
    // {
    //   text: $_("edit_tool.window"),
    //   icon: "fa-regular fa-window-frame",
    //   type: TOOLS.DRAW_WINDOW,
    // },
    // {
    //   text: $_("edit_tool.door"),
    //   icon: "fa-regular fa-door-closed",
    //   type: TOOLS.DRAW_DOOR,
    // },
    {
      text: $_("edit_tool.clear"),
      icon: "fa-solid fa-broom-wide",
      type: undefined,
    },
    {
      text: $_("edit_tool.edit"),
      icon: "fa-solid fa-edit",
      type: undefined,
    },
    // {
    //   text: $_("edit_tool.z_index_up"),
    //   icon: "fa-solid fa-bring-forward",
    //   type: undefined,
    // },
    // {
    //   text: $_("edit_tool.z_index_down"),
    //   icon: "fa-solid fa-send-backward",
    //   type: undefined,
    // },
    {
      text: $_("edit_tool.delete"),
      icon: "fa-solid fa-trash-xmark",
      type: undefined,
    },
  ];
  
  $: filteredItems = $editTool
    ? items.filter((item) => item.type === $editTool)
    : isArc(selectedObject)
    ? items.slice(-2)
    : isTileWrapper(selectedObject) || isClosedArea(selectedObject)
    ? [items[3], items[2]]
    : isBuildingPart(selectedObject) ||
      isDoor(selectedObject) 
      // isTileWrapper(selectedObject)
    ? items.slice(-2)
    : [items[3],...items.slice(0, 2), ...items.slice(-1)];

  let centerPointer: Pointer | undefined;
  let radius: number;
  let snapInfos: any;
  let rotation: number;
  let rotating = false;

  $: if ($drawState.context.dragContext.selectedObject) {
    if (isBuildingPart($drawState.context.dragContext.selectedObject)) {
      const selectedObject = $drawState.context.dragContext
        .selectedObject as BuildingPart;
      centerPointer = new Pointer(
        selectedObject.startPointer.x + selectedObject.width / 2,
        selectedObject.startPointer.y + selectedObject.length / 2
      );
      radius =
        Math.sqrt(
          selectedObject.width * selectedObject.width +
            selectedObject.length * selectedObject.length
        ) /
          2 +
        10;
      snapInfos = selectedObject.getSnapInfos(
        $drawState.context.current.segments
      );
      rotation = selectedObject.rotation;
    } else if (isTileWrapper($drawState.context.dragContext.selectedObject)) {
      const selectedObject = $drawState.context.dragContext
        .selectedObject as TileWrapper;
      centerPointer = centroid(selectedObject.shape);
      radius = 20;
      snapInfos = selectedObject.getSnapInfos();
      rotation = selectedObject.rotation;
    }
  }

  let RADIUS = 0;
  const ANGLE_DURATION = 45;
  $: getActionPosition = (index: number, total: number) : Pointer => {
    let angle = index === -1 ? -90 : 90 + ANGLE_DURATION * (total - 1) / 2 - ANGLE_DURATION * index;
    angle = angle * Math.PI / 180;

    return new Pointer(0, 0).translate(
      RADIUS * Math.cos(angle) - 24,
      -RADIUS * Math.sin(angle) - 24
    );
  }

  onMount(() => {
    RADIUS = 100;
  })

  const selectEditTool = (tool: Tool) => {
    drawSend({ type: "HIDE_LINE_TOOL" });
    
    if (tool.text === $_("edit_tool.z_index_up")) {
      editTool.set(undefined);
      drawSend({
        type: "Z_INDEX_UPDATE",
        newZIndex:
          Math.max(
            ...$drawState.context.current.segments
              .filter((s) => isBuildingPart(s))
              .map((s) => s.zIndex)
          ) + 1,
      });
    } else if (tool.text === $_("edit_tool.z_index_down")) {
      editTool.set(undefined);
      drawSend({
        type: "Z_INDEX_UPDATE",
        newZIndex:
          Math.max(Math.min(
            ...$drawState.context.current.segments
              .filter((s) => isBuildingPart(s))
              .map((s) => s.zIndex)
          ) - 1, 1)
      });
    } else if (tool.text === $_("edit_tool.delete")) {
      editTool.set(undefined);
      drawSend({ type: "DELETE" });
    } else if (tool.text === $_("edit_tool.clear")) {
      editTool.set(undefined);
      if ( isTileWrapper(selectedObject) ) {
        drawSend({
          type: "SHOW_LAYOUT_GEOMETRY",
          tileData: emptyLayout,
          savedGeometryLayoutId: 0,
          segment: selectedObject,
          isNewLayout: true,
        });
      }
    } else if (tool.text === $_("edit_tool.edit")) {
      if( isArc(selectedObject) ) {
        editTool.set(TOOLS.EDIT_ARC);
        return;
      }

      editTool.set(undefined);
      // drawSend({ type: "TOGGLE_EDIT_PANEL" });
      if( isDoor(selectedObject) ) {
        const door = selectedObject as Door
        editSegment.set(selectedObject)
        activeMenu.set(door.buildingType === DOORTYPE.DOOR ? SIDE_MENUS.DOOR : SIDE_MENUS.WINDOW);
      } else if ( isBuildingPart(selectedObject) ) {
        editSegment.set(selectedObject)
        activeMenu.set(SIDE_MENUS.FURNITURE);
      } else if ( isLine(selectedObject) || isArc(selectedObject) ) {
        editSegment.set(selectedObject)
        activeMenu.set(SIDE_MENUS.WALL);
      } else if ( isClosedArea(selectedObject) ) {
        editSegment.set(selectedObject)
        activeMenu.set(SIDE_MENUS.ROOM);
        drawSend({ type: "UPDATE_BACKUP" })
      } else if ( isTileWrapper(selectedObject) ) {
        const closedArea = $drawState.context.current.segments.find((_seg) => _seg.id === (selectedObject as TileWrapper).closedAreaId &&
          isClosedArea(_seg)
        ) as ClosedArea;
        const tileWrappers = $drawState.context.current.segments.filter((_seg) => isTileWrapper(_seg) && (_seg as TileWrapper).closedAreaId === closedArea.id);

        if( tileWrappers.length === 1 ) {
          activeMenu.set(SIDE_MENUS.ROOM);
          editSegment.set(closedArea)
        } else {
          activeMenu.set(SIDE_MENUS.AREA);
          editSegment.set(selectedObject)
        }
        drawSend({ type: "UPDATE_BACKUP" })
      }
      currentTool.set(TOOLS.SELECTION);
      visibleSideMenu.set(true)
      drawSend({ type: "UPDATE_BACKUP" })
    } else if (tool.text === $_("edit_tool.arc")) {
      editTool.set(TOOLS.EDIT_ARC);
    } else if (tool.text === $_("edit_tool.split")) {
      editTool.set(TOOLS.EDIT_SPLIT);
    } else if ($editTool === tool.type) {
      editTool.set(undefined);
    }
  };

	function dragMe(node) {
    node.style.userSelect = 'none';
    let left = parseInt(node.style.left)
    let top = parseInt(node.style.top)

    const handleMouseDown = (e) => {
      rotating = true;
        
      left = getActionPosition(-1, filteredItems.length).x
      top = getActionPosition(-1, filteredItems.length).y
      node.style.top = `${top}px`;
      node.style.left = `${left}px`;

      const clientPointer = new Pointer(
        e.clientX || (e.touches && e.touches[0].clientX),
        e.clientY || (e.touches && e.touches[0].clientY)
      );

      if (!clientPointer.x || !clientPointer.y) {
        return;
      }

      let pointer = convertPointerToViewBox(clientPointer, svgRef);

      // const buttonPosition = getActionPosition(-1, filteredItems.length);
      // mouseOffset = new Pointer(leftPosition + buttonPosition.x - clientPointer.x, topPosition + buttonPosition.y - clientPointer.y);

      if ($currentTool !== TOOLS.POLYLINE && $currentTool !== TOOLS.DRAW_LINE) {
        drawSend({ type: "ROTATING", pointer, newAngle: rotation });
      }
    }

    const handleMouseMove = (e) => {
      if (rotating) {
        left += e.movementX;
        top += e.movementY;
        node.style.top = `${top}px`;
        node.style.left = `${left}px`;

        const clientPointer = new Pointer(e.clientX, e.clientY);

        if (!clientPointer.x || !clientPointer.y) {
          return;
        }

        let pointer = convertPointerToViewBox(clientPointer, svgRef);

        if ($currentTool !== TOOLS.POLYLINE && $currentTool !== TOOLS.DRAW_LINE && $currentTool !== TOOLS.EYE_DROPPER) {
          drawSend({ type: "ROTATING", pointer });
        }
      }
    }

    const handleMouseUp = () => {
      rotating = false;
      
      left = getActionPosition(-1, filteredItems.length).x
      top = getActionPosition(-1, filteredItems.length).y
      node.style.top = `${top}px`;
      node.style.left = `${left}px`;
      if ($currentTool !== TOOLS.POLYLINE && $currentTool !== TOOLS.DRAW_LINE) {
        if ( $drawState.matches("main.selectState.rotating") ) {
          drawSend({ type: "DRAG_END" });
        }
      }
    }

    node.addEventListener('mousedown', handleMouseDown);
		 
		window.addEventListener('mousemove', handleMouseMove);
		
    window.addEventListener('mouseup', handleMouseUp);

    return {
      destroy() {
        node.removeEventListener('mousedown', handleMouseDown);
		 
        window.removeEventListener('mousemove', handleMouseMove);
        
        window.removeEventListener('mouseup', handleMouseUp);
      }
    }
	}


</script>

<div class="absolute print:hidden" style="left: {position.x}px; top: {position.y}px">
  <div class="relative">
    {#if !rotating}
      {#each filteredItems as item, index}
        <button 
          class="absolute group border-0 rounded-full w-16 h-16 flex items-center justify-center bg-white shadow-dropdown transform-gpu transition-all" 
          aria-label={item.text}
          style="left: {getActionPosition(index, filteredItems.length).x}px; top: {getActionPosition(index, filteredItems.length).y}px; opacity: {RADIUS === 0 ? 0 : 1}"
          on:click={() => selectEditTool(item)}
        >
          <div class="flex flex-col items-center text-secondary-500 group-hover:text-primary-500 mt-2">
            <i
              class={`${item.icon} text-xl`}
              title={item.text}
            />
            <div class="text-sm">{item.text}</div>
          </div>
        </button>
      {/each}
    {/if}
    
    {#if canRotate}
    <button 
      class="absolute group border-0 rounded-full w-16 h-16 flex items-center justify-center bg-white shadow-dropdown cursor-none {rotating ? '' : 'transform-gpu transition-all'}" 
      aria-label="Rotate"
      use:dragMe
      style={`left: ${getActionPosition(-1, filteredItems.length).x}px; top: ${getActionPosition(-1, filteredItems.length).y}px; opacity: ${RADIUS === 0 ? 0 : 1}`}
    >
      <div class="flex flex-col items-center text-secondary-500 group-hover:text-primary-500 mt-2">
        <i class="fa-solid fa-arrow-rotate-right text-xl"></i>
        <div class="text-sm">{$_("edit_tool.rotate")}</div>
      </div>
    </button>
    {/if}
  </div>
</div>