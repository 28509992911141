<script lang="ts">
  import { METRIC_UNITS, QUERY_PARAMS } from "src/global/variable";
  import ProjectWizardModal from "../dialogs/ProjectWizardModal.svelte";
  import Login from "./Login.svelte";
  import { _ } from "../../services/i18n";
  import SaveProjectModal from "../dialogs/SaveProjectModal.svelte";
  import Dropdown from "../base/Dropdown.svelte";
  import { drawSend, drawState } from "../../layout.store";
  import Toggle from "../base/Toggle.svelte";
  import router from "page";
  import routes, { ROUTE } from "../../router";
  import { currentTool, activePath, currentUser, loadSuccess, selectedRoom, visibleSideMenu } from "src/store";
  import { TOOLS, type ProjectBaseInfo } from "src/global/types";
  import { authorizeUrl } from "src/services/api";
  import EditableLabel from "../base/EditableLabel.svelte";
  import LocaleSelect from "./LocaleSelect.svelte";

  export let open: Function;
  export let undo: Function;
  export let redo: Function;
  export let updateMetric: Function;
  export let availableUndo: boolean;
  export let availableRedo: boolean;
  export let metricUnit: string;

  let demension = '2D';
  const demensionOptions = [
    { label: '2D', value: '2D' },
    { label: '3D', value: '3D' },
  ]

  let fileInput;
  let files : FileList;

  $: projectBaseInfo = $drawState.context.projectBaseInfo;

  $: if (files) {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        open(JSON.parse(reader.result));
      }
      fileInput.value = null;
    };
    reader.readAsText(files[0]);
  }

  const login = () => {
    activePath.set(routes[ROUTE.BOARD].path);
    window.open(
      authorizeUrl(),
      "_self",
      `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes`
    );
  };

  const updateGrid = () => {
    drawSend({ type: "TOGGLE_GRID" });
  };
  
  const goToHome = () => {
    router.show(routes[ROUTE.NEW_PROJECT_STEP_1].path)
  }

  const goToProjectsList = () => {
    router.show(routes[ROUTE.PROJECTS_LIST].path)
  }

  const print = () => {
    selectedRoom.set(undefined);
    drawSend({ type: "ENTER_SELECT", segment: undefined });
    drawSend({ type: "PRINT_PREVIEW" });
  }

  const hideSideMenu = () => {
    currentTool.set(TOOLS.SELECTION)
    visibleSideMenu.set(false)
  }

  const createNewProject = () => {
    // drawSend({ type: "EMPTY_PROJECT" });
  }

  const handleTitleChange = (e) => {
    const projectInfo: ProjectBaseInfo = {
      ...projectBaseInfo,
      name: e.detail.value,
    };
    drawSend({ type: "UPDATE_PROJECT_INFO", projectInfo: projectInfo});
  }

</script>

<div class="action-container">
  <div class="flex items-stretch shadow-card bg-white divide-x divide-gray-200" on:click={hideSideMenu} on:keydown>
    <button class="flex items-center gap-2.5 btn-default p-2.5" on:click={goToHome}>
      <img src="/logo.png" alt="logo" width="40px" height="40px"/>
      
      <div class="inline bg-primary-300 text-secondary-500 font-medium rounded-md py-1 px-2">
        BETA
      </div>
    </button>
    <!-- <ProjectWizardModal /> -->
    <Dropdown title={$_("action_menu.file.projects")} class="px-6 flex item-center" dropClassName="mt-16">
      {#if $currentUser && $loadSuccess}
        <li>
          <!-- <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded" on:click={() => fileInput.click()}> -->
          <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded" on:click={() => goToProjectsList()}>
            <div class="w-9 h-9 flex items-center justify-center flex-shrink-0">
              <i class="fa-regular fa-folder-open text-xl text-secondary-500"/>
            </div>
            <div class="flex-grow text-left text-[13px]">{$_("action_menu.file.open_project")} </div>
          </button>
          <input type="file" bind:this={fileInput} bind:files={files} />
        </li>
      {/if}
      <li>
        <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded" on:click={goToHome}>
          <div class="w-9 h-9 flex items-center justify-center flex-shrink-0">
            <i class="fa-regular fa-file-circle-plus text-xl text-secondary-500"/>
          </div>
          <div class="flex-grow text-left text-[13px]">{$_("action_menu.file.new_project")} </div>
        </button>
      </li>
      <!-- <li>
        <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded">
          <div class="w-9 h-9 flex items-center justify-center flex-shrink-0">
            <i class="fa-regular fa-file-import text-xl text-secondary-500"/>
          </div>
          <div class="flex-grow text-left text-[13px]">{$_("action_menu.file.import")} </div>
        </button>
      </li> -->
      <!-- <li>
        <button 
          class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded" 
          on:click={() => window.print()} 
          disabled={!(availableUndo || availableRedo)}
        >
          <div class="w-9 h-9 flex items-center justify-center flex-shrink-0">
            <i class={`fa-regular fa-file-export text-xl ${!(availableUndo || availableRedo) ? "text-gray-300" : "text-secondary-500"}`}/>
          </div>
          <div class={`flex-grow text-left text-[13px] ${!(availableUndo || availableRedo) ? "text-gray-300" : ""}`}>{$_("action_menu.file.export")} </div>
        </button>
      </li> -->
      <!-- <li class="border-t border-gray-200">
        <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded">
          <div class="w-9 h-9 flex items-center justify-center flex-shrink-0">
            <i class="fa-regular fa-share-nodes text-xl text-secondary-500"/>
          </div>
          <div class="flex-grow text-left text-[13px]">{$_("action_menu.file.share")} </div>
        </button>
      </li> -->
    </Dropdown>

    <SaveProjectModal />
    
    <button class={`w-15 h-15 flex items-center justify-center border-t-0 border-b-0` + 
                   `${!$currentUser || !$loadSuccess ? " cursor-default" : ""}` + 
                   `${!$currentUser && $loadSuccess ? " bg-yellow-100" : ""}`} on:click={() => print()} disabled={!$currentUser || !$loadSuccess}>
      <i class={`fa-solid fa-print text-xl ${!$currentUser || !$loadSuccess ? "text-gray-300 cursor-default" : "text-secondary-500"}`}
        title={$_("action_menu.print")}
      />
    </button>

    <button class={`w-15 h-15 flex items-center justify-center border-t-0 border-b-0 ${!availableUndo ? "cursor-default" : ""}`}
            on:click={() => undo()} disabled={!availableUndo}>
      <i class={`fa-regular fa-undo text-xl ${!availableUndo ? "text-gray-300 cursor-default" : "text-secondary-500"}`}
        title={$_("action_menu.undo")}
      />
    </button>
    <button class={`w-15 h-15 flex items-center justify-center border-t-0 border-b-0 ${!availableRedo ? "cursor-default" : ""}`}
            on:click={() => redo()} disabled={!availableRedo}>
      <i class={`fa-regular fa-redo text-xl ${!availableRedo ? "text-gray-300 cursor-default" : "text-secondary-500"}`}
        title={$_("action_menu.redo")}
      />
    </button>
    <!-- <div class="flex border border-slate-400 divide-x divide-solid">
      {#each METRIC_UNITS as unit}
        <div
          class={`py-1 px-4 border-0 cursor-pointer ${
            metricUnit === unit ? "bg-secondary-300 text-white" : ""
          }`}
          on:click={() => updateMetric(unit)}
        >
          {unit}
        </div>
      {/each}
    </div> -->
    <div class="flex items-center justify-center flex-grow px-2.5">
      <EditableLabel value={projectBaseInfo ? projectBaseInfo.name : $_("project_wizard.untitled")} class="p-1" on:change={handleTitleChange}>
        {projectBaseInfo ? projectBaseInfo.name : $_("project_wizard.untitled")}
      </EditableLabel>
    </div>

    <!-- <div class="flex items-center px-2.5">
      <Toggle options={demensionOptions} value={demension} />
    </div> -->

    <LocaleSelect />
    
    <Dropdown 
      class="flex item-center" 
      dropClassName="mt-16"
      align="right"
    > 
      <button slot="button" class="ml-auto w-15 h-15 flex items-center justify-center border-0">
        <i class="fa fa-cog font-black text-secondary-500" />
      </button>

      <li>
        <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded" on:click={() => {
          drawSend({type: "TOGGLE_HELPER"});
        }}>
          <div class="w-9 h-9 flex items-center justify-center flex-shrink-0">
            <i class="fa-solid fa-text text-xl font-black text-secondary-500"/>
          </div>
          <div class="flex-grow text-left text-[13px]">{$_("action_menu.setting.measure_unit")} </div>
        </button>
      </li>
      <li class="border-t border-gray-200">
        <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded" on:click={() => {
          if (metricUnit === METRIC_UNITS[0]) {
            updateMetric(METRIC_UNITS[1]);
          }
          else {
            updateMetric(METRIC_UNITS[0]);
          }
        }}>
          <div class="w-9 h-9 flex items-center justify-center flex-shrink-0">
            <i class="fa-solid fa-ruler text-xl font-black text-secondary-500"/>
          </div>
          <div class="flex-grow text-left text-[13px]">{$_("action_menu.setting.change_unit")} </div>
        </button>
      </li>
      <li class="border-t border-gray-200">
        <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded" on:click={updateGrid}>
          <div class="w-9 h-9 flex items-center justify-center flex-shrink-0">
            <i class="fa-solid fa-table-cells text-xl font-black text-secondary-500"/>
          </div>
          <div class="flex-grow text-left text-[13px]">{$drawState.matches("grid.showGrid") ? $_("action_menu.setting.hide_grid") : $_("action_menu.setting.show_grid")} </div>
        </button>
      </li>
    </Dropdown>

    <Login path={routes[ROUTE.BOARD].path}/>
  </div>
  {#if !$currentUser && $loadSuccess}
    <div class="flex border-l-4 border-yellow-400 bg-yellow-100 p-4 shadow-card">
      <div class="flex items-center">
        <i class="text-yellow-400 fa-solid fa-triangle-exclamation"/>
        <div class="ml-3">
          <p class="text-sm text-yellow-700">
            {$_("action_menu.login_warning")}
            <button class="ml-4 border-0 font-medium text-yellow-700 underline hover:text-yellow-600" on:click={login}>{$_("action_menu.login")}</button>
          </p>
        </div>
      </div>
    </div>
  {/if}
</div>

<style scoped lang="scss">
  .action-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @media print {
      display: none;
    }
  }

  input[type="file"] {
    display: none;
  }
</style>
