<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import Icon from "../../base/Icon.svelte";
  import { DOORTYPE, FOLDINGTYPE, type Door, type Line } from "src/model";
  import Input from "../../base/Input.svelte";
  import GridItem from "./GridItem.svelte";
  import { convertUnit, getMetricWithUnit, isDoor } from "src/helpers";
  import { METRIC_SMALL_UNITS, METRIC_UNITS, PRECISION_UNITS } from "src/global/variable";
  import { linkTool } from "src/store";

  export let type : DOORTYPE;

  const dispatch = createEventDispatcher();

  let door: Door;
  $: {
    const selectedObj = $drawState.context.dragContext.selectedObject;
    if (
      selectedObj &&
      isDoor(selectedObj) &&
      (selectedObj as Door)?.buildingType === type
    )
      door = selectedObj as Door;
    else dispatch("back");
  }

  const DOOR_TYPES = type === DOORTYPE.DOOR ? [
    {
      label: "const.folding_type.knocker",
      icon: "/door.knocking.png",
      value: FOLDINGTYPE.KNOCKING,
    },
    // {
    //   label: "const.folding_type.folding",
    //   icon: "/door.folding.png",
    //   value: FOLDINGTYPE.FOLDING,
    // },
    {
      label: "const.folding_type.sliding",
      icon: "/door.sliding.svg",
      value: FOLDINGTYPE.SLIDING,
    },
  ] : [
    {
      label: "const.folding_type.knocker",
      icon: "/door.knocking.png",
      value: FOLDINGTYPE.KNOCKING,
    },
    {
      label: "const.folding_type.sliding",
      icon: "/door.sliding.svg",
      value: FOLDINGTYPE.SLIDING,
    },
  ]
  $: isMetric = $drawState.context.currentMetricUnit === METRIC_UNITS[0]
  $: minLen = isMetric ? 10 : 1;
  $: step = isMetric ? 1 : 1;

  $: name = door?.getName();
  $: length = getMetricWithUnit(
    Math.round(door?.getLineLength() * 10000) / 10000,
    $drawState.context.currentMetricUnit,
    $drawState.context.currentMetricUnit === METRIC_UNITS[0],
    true
  );
  $: height = getMetricWithUnit(
    Math.round(door?.length * 10000) / 10000,
    $drawState.context.currentMetricUnit,
    $drawState.context.currentMetricUnit === METRIC_UNITS[0],
    true
  );
  $: layer = door?.zIndex;

  const handleChangeLength = (e) => {
    if ( +e.target.value >= step ) {
      drawSend({
        type: "CHANGE_WIDTH",
        newWidth: convertUnit(+e.target.value, $drawState.context.currentMetricUnit, true)
      });
    }
  };

  const handleChangeHeight = (e) => {
    if ( +e.target.value >= step ) {
      drawSend({
        type: "CHANGE_HEIGHT",
        newHeight: convertUnit(+e.target.value, $drawState.context.currentMetricUnit, true)
      });
    }
  };

  const handleChangeFoldingType = (doorType) => {
    drawSend({
      type: "CHANGE_FOLDING_TYPE",
      newFoldingType: doorType.value as FOLDINGTYPE,
    });
  };

  const handleChangeAngle = (side: boolean) => {
    if (door.wallOpening !== side) {
      drawSend({ type: "CHANGE_OPEN_SIDE" });
    }
  };

  const handleChangeZIndex = (e) => {
    drawSend({
      type: "Z_INDEX_UPDATE",
      newZIndex: Number(e.target.value),
    });
  }
</script>

<div class="w-full flex flex-col gap-4">
  <div class="w-full">
    <div class="text-lg font-bold mb-2.5">
      {$_("side_menu.dimensions")}
    </div>
    <div class="w-full flex items-end gap-4">
      <Input
        type="number"
        label={$_("side_menu.height")}
        value={height}
        min={minLen}
        step={step}
        on:input={handleChangeHeight}
        unit={$drawState.context.currentMetricUnit === METRIC_UNITS[0] ? METRIC_SMALL_UNITS[0] : METRIC_SMALL_UNITS[1] }
        fullWidth
      />
      <Input
        type="number"
        label={$_("side_menu.length")}
        value={length}
        min={minLen}
        step={step}
        on:input={handleChangeLength}
        unit={$drawState.context.currentMetricUnit === METRIC_UNITS[0] ? METRIC_SMALL_UNITS[0] : METRIC_SMALL_UNITS[1] }
        fullWidth
      />
      <button
        class="btn-default p-1"
        on:click={() => {
          linkTool.set(!$linkTool);
        }}
      >
        <span class="fa-stack">
          <i class="fa-solid fa-link" />
          {#if !$linkTool}
            <i class="fal fa-slash fa-stack-1x" color="black" />
          {/if}
        </span>
      </button>
    </div>
  </div>
  <div class="w-full">
    <div class="text-lg font-bold mb-2.5">
      {$_(type === DOORTYPE.DOOR ? "side_menu.door.door_type" : "side_menu.door.window_type")}
    </div>
    <div class="w-full grid grid-cols-4 gap-4">
      {#each DOOR_TYPES as doorType}
        <GridItem
          title={$_(doorType.label)}
          on:click={() => handleChangeFoldingType(doorType)}
          active={door?.foldingType === doorType.value}
        >
          <img src={doorType.icon} alt={$_(doorType.label)} />
        </GridItem>
      {/each}
    </div>
  </div>
  {#if type === DOORTYPE.DOOR}
  <div class="w-full flex items-center gap-4">
    <div class="text-lg font-bold mr-auto">
      {$_("side_menu.door.open_angle")}
    </div>
    <GridItem
      size="sm"
      on:click={() => handleChangeAngle(true)}
      active={door?.wallOpening === true}
    >
      <i
        class="fa-solid fa-angle-90 text-secondary-500 text-4xl transform rotate-90"
      />
    </GridItem>
    <GridItem
      size="sm"
      on:click={() => handleChangeAngle(false)}
      active={door?.wallOpening === false}
    >
      <i class="fa-solid fa-angle-90 text-secondary-500 text-4xl" />
    </GridItem>
  </div>
  {/if}
  
  <div class="w-full flex items-center gap-2">
    <i class="fa-solid fa-layer-group font-black text-xl text-secondary-500" />
    <div class="text-lg font-bold mr-auto">
      {$_("side_menu.layer")}
    </div>
    <Input
      type="number"
      value={layer}
      step={1}
      class="w-input-sm"
      on:input={handleChangeZIndex}
      fullWidth
    />
  </div>
</div>
