export default class Lazy {
    supplier;
    value;
    constructor(supplier) {
        this.supplier = supplier;
    }
    get() {
        if (this.value !== undefined) {
            return this.value;
        }
        this.value = this.supplier();
        return this.value;
    }
}
