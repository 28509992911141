<script lang="ts">
  import { QUERY_PARAMS } from "src/global/variable";
  import Dropdown from "../base/Dropdown.svelte";
  import { localeStore } from "src/store";
  import { get } from "svelte/store";
  import { SUPPORTED_LOCALIZATIONS } from "src/services/locales";

  let locale = get(localeStore) ?? SUPPORTED_LOCALIZATIONS.en
  const flags = [
    { locale: SUPPORTED_LOCALIZATIONS.en, flag: '/flags/gb.svg', name: 'English'},
    { locale: SUPPORTED_LOCALIZATIONS.it, flag: '/flags/it.svg', name: 'Italiano'},
    { locale: SUPPORTED_LOCALIZATIONS.es, flag: '/flags/es.svg', name: 'Español'},
    { locale: SUPPORTED_LOCALIZATIONS.fr, flag: '/flags/fr.svg', name: 'Français'},
    { locale: SUPPORTED_LOCALIZATIONS.de, flag: '/flags/de.svg', name: 'Deutsch'},
    { locale: SUPPORTED_LOCALIZATIONS.pt, flag: '/flags/pt.svg', name: 'Português'},
    { locale: SUPPORTED_LOCALIZATIONS.cs, flag: '/flags/cz.svg', name: 'Čeština'},
    { locale: SUPPORTED_LOCALIZATIONS.hu, flag: '/flags/hu.svg', name: 'Magyar'},
  ]

  $: currentFlag = flags.find((v) => v.locale === locale) ?? flags[0]

  const updateLocale = (newLocale) => {
    localeStore.set(newLocale)
    locale = newLocale;
    if (history.pushState) {
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${QUERY_PARAMS.LOCALE}=${newLocale}`;
      window.history.pushState({path:newurl},'',newurl);
    }
  }

</script>
  
<Dropdown 
  class="flex item-center" 
  dropClassName="mt-16"
  dropdownWidth="144px"
  align="left"
> 
  <button slot="button" class="btn-default h-full flex items-center gap-2 px-3 py-1.5">
    <img src={currentFlag.flag} alt={currentFlag.locale} class="w-6" />
    <span>{currentFlag.name}</span>
  </button>

  {#each flags as item}
    {#if item.locale !== locale}
    <li>
      <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded" on:click={() => {updateLocale(item.locale)}}>
        <img src={item.flag} alt={item.locale} class="w-6"/>
        <span>{item.name}</span>
      </button>
    </li>
    {/if}
  {/each}
</Dropdown>
  