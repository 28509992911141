<svelte:options immutable={true} />

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import Icon from "../../base/Icon.svelte";
  import { DOORTYPE, type Door, type Line } from "src/model";
  import Input from "../../../components/base/Input.svelte";
  import GridItem from "./GridItem.svelte";
  import { convertUnit, getMetricWithUnit, isDoor } from "src/helpers";
  import { METRIC_UNITS } from "src/global/variable";

  export let wall: Line | undefined = undefined;

  $: isMetric = $drawState.context.currentMetricUnit === METRIC_UNITS[0]
  $: minLen = isMetric ? 0.1 : 1 / 16;
  $: step = isMetric ? 0.1 : 1;
  $: {
    if( !$drawState.context.dragContext.selectedObject && wall ) {
      drawSend({
        type: "ENTER_SELECT",
        segment: wall,
      });
    }
  }
  $: doors = $drawState.context.current.segments.filter(
    (segment) => isDoor(segment) && (segment as Door).parentId === wall?.id
  ) as Door[];
  $: wallDoors = doors.filter(
    (segment) => segment.buildingType === DOORTYPE.DOOR
  );
  $: wallWindows = doors.filter(
    (segment) => segment.buildingType === DOORTYPE.WINDOW
  );

  $: name = wall?.getName();
  $: height = getMetricWithUnit(
    Math.round(wall?.height * 100000) / 100000,
    $drawState.context.currentMetricUnit,
    $drawState.context.currentMetricUnit === METRIC_UNITS[0]
  );
  $: length = getMetricWithUnit(
    Math.round(wall?.getLineLength() * 100000) / 100000,
    $drawState.context.currentMetricUnit,
    $drawState.context.currentMetricUnit === METRIC_UNITS[0]
  );
  $: thickness = getMetricWithUnit(
    Math.round(wall?.thick * 100000) / 100000,
    $drawState.context.currentMetricUnit,
    $drawState.context.currentMetricUnit === METRIC_UNITS[0]
  );

  const dispatch = createEventDispatcher();

  const handleSelectDoor = (seg?: Door) => {
    dispatch("door", {
      segment: seg,
    });
  };

  const handleSelectWindow = (seg?: Door) => {
    dispatch("window", {
      segment: seg,
    });
  };

  const handleChangeWidth = (e) => {
    if ( +e.target.value >= minLen ) {
      drawSend({
        type: "CHANGE_WIDTH",
        newWidth: convertUnit(+e.target.value, $drawState.context.currentMetricUnit),
      });
    }
  };

  const handleChangeHeight = (e) => {
    if ( +e.target.value >= minLen ) {
      drawSend({
        type: "CHANGE_HEIGHT",
        newHeight: convertUnit(+e.target.value, $drawState.context.currentMetricUnit),
      });
    }
  };

  const handleChangeThick = (e) => {
    if ( +e.target.value >= minLen ) {
      drawSend({
        type: "CHANGE_THICK",
        newThick: convertUnit(+e.target.value, $drawState.context.currentMetricUnit),
      });
    }
  };

  const handleSwitchPoints = () => {
    drawSend({
      type: "SWITCH_LINE_POINTS",
      segment: wall,
    });
  }

</script>

<div class="w-full flex flex-col gap-4">
  <div class="w-full">
    <div class="text-lg font-bold mb-2.5">
      {$_("side_menu.dimensions")}
    </div>
    
    <div class="flex items-center">
      <div class="text-lg font-bold mr-auto">
        {$_("side_menu.length")}
      </div>
      <button class="mr-2 w-9 h-9 flex items-center justify-center border-0 rounded-full" on:click={handleSwitchPoints}>
        <i class="fa-solid fa-arrow-right-arrow-left font-black text-secondary-500" />
      </button>
      
      <Input
        type="number"
        inputProps="w-40"
        value={length}
        on:input={handleChangeWidth}
        unit={isMetric ? "m" : METRIC_UNITS[1] }
        step={step}
      />
    </div>
    <div class="w-full grid grid-cols-3 gap-4">
      <!-- <Input
        type="number"
        label={$_("side_menu.height")}
        value={height}
        on:input={handleChangeHeight}
        unit={isMetric ? "m" : METRIC_UNITS[1] }
        step={step}
        fullWidth
      /> -->
      <!-- <Input
        type="number"
        label={$_("side_menu.thickness")}
        value={thickness}
        on:input={handleChangeThick}
        unit={isMetric ? "m" : METRIC_UNITS[1] }
        step={step}
        fullWidth
      /> -->
    </div>
  </div>

  <div class="w-full">
    <div class="text-lg font-bold mb-2.5">
      {$_("side_menu.doors")}
    </div>
    <div class="w-full grid grid-cols-4 gap-4">
      {#each wallDoors as door}
        <GridItem 
          title={door.getName()} 
          on:click={() => handleSelectDoor(door)}
        >
          <i class="fa-regular fa-door-closed text-4xl text-secondary-400" />
        </GridItem>
      {/each}
      <GridItem
        title={$_("side_menu.add")}
        special
        on:click={() => handleSelectDoor()}
      >
        <i class="fa-regular fa-plus text-4xl" />
      </GridItem>
    </div>
  </div>

  <div class="w-full">
    <div class="text-lg font-bold mb-2.5">
      {$_("side_menu.windows")}
    </div>
    <div class="w-full grid grid-cols-4 gap-4">
      {#each wallWindows as wallWindow}
        <GridItem 
          title={wallWindow.getName()}
          on:click={() => handleSelectWindow(wallWindow)}
        >
          <i class="fa-regular fa-window-frame text-4xl text-secondary-400" />
        </GridItem>
      {/each}
      <GridItem
        title={$_("side_menu.add")}
        special
        on:click={() => handleSelectWindow()}
      >
        <i class="fa-regular fa-plus text-4xl" />
      </GridItem>
    </div>
  </div>
</div>
