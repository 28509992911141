class FilterCategory {
    category;
    subcategory = undefined;
    expanded = false;
    filters = [];
    constructor(category, filters, subcategory) {
        this.set(category, filters, subcategory);
    }
    set(category, filters, subcategory) {
        this.category = category;
        this.subcategory = subcategory ?? category;
        if (!filters)
            return;
        for (let filter of filters) {
            if (filter?.id && filter?.name && filter?.slug) {
                const filterObj = new Filter(filter.id, category, filter.name, filter.slug, filter.children, subcategory);
                filterObj.setData(filter);
                this.filters.push(filterObj);
            }
        }
    }
    toggle() {
        this.expanded = !this.expanded;
    }
}
var COLLAPSING_STATUS;
(function (COLLAPSING_STATUS) {
    COLLAPSING_STATUS[COLLAPSING_STATUS["ALWAYS_VISIBILE"] = -1] = "ALWAYS_VISIBILE";
    COLLAPSING_STATUS[COLLAPSING_STATUS["COLLAPSED"] = 0] = "COLLAPSED";
    COLLAPSING_STATUS[COLLAPSING_STATUS["NOT_COLLAPSED"] = 1] = "NOT_COLLAPSED";
})(COLLAPSING_STATUS || (COLLAPSING_STATUS = {}));
class Filter {
    id;
    available = false;
    checked = false;
    indeterminate = false;
    indipendentSubfilters = false; // if true filters and subfilters can be checked independently
    collapsingSubfilters = COLLAPSING_STATUS.ALWAYS_VISIBILE; //see COLLAPSING_STATUS
    category;
    subcategory = undefined;
    name;
    slug;
    subfilters = [];
    parent;
    data;
    constructor(id, category, name, slug, subfilters, subcategory, parent) {
        this.set(id, category, name, slug, subfilters, subcategory, parent);
    }
    set(id, category, name, slug, subfilters, subcategory, parent) {
        this.id = id;
        this.category = category;
        this.subcategory = subcategory;
        this.name = name;
        this.slug = slug;
        this.parent = parent;
        if (!subfilters)
            return;
        for (let filter of subfilters) {
            if (filter?.id && filter?.name && filter?.slug)
                this.subfilters.push(new Filter(filter.id, subcategory, filter.name, filter.slug, filter.children, subcategory, this));
        }
    }
    setIndipendentSubfilters(status) {
        this.indipendentSubfilters = status;
    }
    setCollapsingSubfilters(status) {
        this.collapsingSubfilters = status;
    }
    toggleCollapsingSubfilters() {
        switch (this.collapsingSubfilters) {
            case COLLAPSING_STATUS.COLLAPSED:
                this.collapsingSubfilters = COLLAPSING_STATUS.NOT_COLLAPSED;
                break;
            case COLLAPSING_STATUS.NOT_COLLAPSED:
                this.collapsingSubfilters = COLLAPSING_STATUS.COLLAPSED;
                break;
            default: break;
        }
    }
    setData(data) {
        this.data = data;
    }
    root() {
        return this.parent !== undefined ? this.parent.root() : this;
    }
    getLastCheckedAncestor() {
        if (this.parent === undefined)
            return this;
        return this.parent.checked ? this.parent.getLastCheckedAncestor() : this;
    }
    getDescendantsArray() {
        let array = [];
        for (let subfilter of this.subfilters) {
            array.push(subfilter);
            if (subfilter.subfilters.length > 0)
                array = [...array, ...subfilter.getDescendantsArray()];
        }
        return array;
    }
    getCheckedDescendantsArray(moreDepth = false) {
        let array = [];
        for (let subfilter of this.subfilters) {
            if (subfilter.checked)
                array.push(subfilter);
            if (subfilter.subfilters.length > 0 && (!subfilter.checked || moreDepth))
                array = [...array, ...subfilter.getDescendantsArray()];
        }
        return array;
    }
    allDescendantsChecked() {
        return (this.subfilters.length === 0 || (this.subfilters.length > 0 &&
            this.subfilters.every((sf) => !sf.available || (sf.available && sf.checked && sf.allDescendantsChecked()))));
    }
    allDescendantsUnchecked() {
        return (this.subfilters.length === 0 || (this.subfilters.length > 0 &&
            this.subfilters.every((sf) => !sf.available || (sf.available && !sf.checked && sf.allDescendantsUnchecked()))));
    }
    toggle(subfilterChecked, parentChecked) {
        let toCheck = (subfilterChecked !== undefined) ? subfilterChecked : !this.checked;
        // set parent status
        if (parentChecked !== undefined) {
            if (parentChecked && this.allDescendantsChecked()) {
                this.checked = true;
                this.indeterminate = false;
            }
            else if (!parentChecked && this.allDescendantsUnchecked()) {
                this.checked = false;
                this.indeterminate = false;
            }
            else {
                this.checked = false;
                this.indeterminate = true;
            }
            return;
        }
        if (toCheck || this.indeterminate) {
            this.checked = true;
            this.indeterminate = false;
            // check all descendants subfilters
            if (!this.indipendentSubfilters && this.subfilters.length > 0)
                this.subfilters.forEach((sf) => sf.available && sf.toggle(true));
            // if not checking descendants subfilters and parent exist set its state
            if (subfilterChecked === undefined && this.parent !== undefined && !this.parent.indipendentSubfilters)
                this.parent.toggle(undefined, true);
        }
        else {
            this.checked = false;
            this.indeterminate = false;
            // uncheck all descendants subfilters
            if (!this.indipendentSubfilters && this.subfilters.length > 0)
                this.subfilters.forEach((sf) => sf.available && sf.toggle(false));
            // if not unchecking descendants subfilters and parent exist set its state
            if (subfilterChecked === undefined && this.parent !== undefined && !this.parent.indipendentSubfilters)
                this.parent.toggle(undefined, false);
        }
    }
}
export { COLLAPSING_STATUS, FilterCategory, Filter };
