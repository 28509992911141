<script lang="ts">
  import { drawState } from "../../layout.store";

  import {
    evalTilesOrientationData,
    limitLayoutTileDepthForDrag,
  } from "../../tools/LayoutTools";
  import type { TileOrientationData } from "../../tools/LayoutTools";

  let tileCoordinates: TileOrientationData = [];

  $: debugState = $drawState.matches("layoutDesign.showLayout.layoutDebugState.enabled");
  $: {
    if (debugState) {
      const tiles = limitLayoutTileDepthForDrag(
        $drawState.context.layoutContext.layout,
        $drawState.matches("layoutDesign.showLayout.selectionState.dragging")
      );
      tileCoordinates = evalTilesOrientationData(tiles);
    }
  }
  $: renderDebugLayer = tileCoordinates.length !== 0 && debugState;
</script>

{#if renderDebugLayer}
  <g>
    {#each tileCoordinates as { x1, x2, y1, y2 }}
      <line
        {x1}
        {y1}
        {x2}
        {y2}
        class="stroke-current text-yellow-500 text-opacity-70"
        stroke-linecap="round"
        stroke-opacity="70%"
        stroke-width="0.8%"
        style="pointer-events: none"
      />
    {/each}
  </g>
{/if}
