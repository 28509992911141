<script lang="ts">
  import { drawState } from "../../layout.store";
  import {
    getHelperPath,
    dist,
    getTextPointer,
    getHelperTextAngle,
    getDistanceLineAndPointer,
    getDistancePointer,
  } from "../../helpers";
  import { METRIC_UNITS, TILE_TRANSFORM_SCALE } from "src/global/variable";
  import { Line, Pointer } from "src/model";
  import type { SNAP_FLAG } from "src/model/tile/Neighbor";

  $: selectedTileID = $drawState.context.layoutContext.selectedTileID;
  $: snapPoint = $drawState.context.layoutContext.dragContext.snapPoint;
  $: direction = $drawState.context.layoutContext.dragContext.direction;

  const invertFlag = (flag: SNAP_FLAG) : SNAP_FLAG => {
    switch(flag) {
      case "0":
        return "1";
      case "1/4":
        return "3/4";
      case "1/3":
        return "2/3";
      case "1/2":
        return "1/2";
      case "2/3":
        return "1/3";
      case "3/4":
        return "1/4";
      case "1":
        return "0";
    }
  }

  $: generateHelperPathWithText = () => {
    const layout = $drawState.context.layoutContext.layout;
    const tile = layout.tiles.get(selectedTileID);
    const result = [];
    const helperLength = 0.3;

    if (tile) {
      let srcStartPoint = new Pointer(
        tile.neighborLink.sourceSegment.start.x,
        tile.neighborLink.sourceSegment.start.y
      );
      let srcEndPoint = new Pointer(
        tile.neighborLink.sourceSegment.end.x,
        tile.neighborLink.sourceSegment.end.y
      );
      let dstStartPoint = new Pointer(
        tile.neighborLink.targetSegment.start.x,
        tile.neighborLink.targetSegment.start.y
      );
      let dstEndPoint = new Pointer(
        tile.neighborLink.targetSegment.end.x,
        tile.neighborLink.targetSegment.end.y
      );
      let dstCenterPoint = new Pointer(
        tile.neighborLink.targetSegment.center.x,
        tile.neighborLink.targetSegment.center.y
      );

      let _direction = direction;

      if (srcStartPoint.x === srcEndPoint.x) {
        if (srcStartPoint.y > srcEndPoint.y) {
          const temp = srcEndPoint;
          srcEndPoint = srcStartPoint;
          srcStartPoint = temp;
          _direction = !direction;
        }
      } else if (srcStartPoint.x > srcEndPoint.x) {
        const temp = srcEndPoint;
        srcEndPoint = srcStartPoint;
        srcStartPoint = temp;
        _direction = !direction;
      }

      if (dstStartPoint.x === dstEndPoint.x) {
        if (dstStartPoint.y > dstEndPoint.y) {
          const temp = dstEndPoint;
          dstEndPoint = dstStartPoint;
          dstStartPoint = temp;
        }
      } else if (dstStartPoint.x > dstEndPoint.x) {
        const temp = dstEndPoint;
        dstEndPoint = dstStartPoint;
        dstStartPoint = temp;
      }

      let angle = Math.atan2(
        -(srcEndPoint.y - srcStartPoint.y),
        srcEndPoint.x - srcStartPoint.x
      );

      const line = new Line(dstStartPoint, dstEndPoint);
      const d = getDistanceLineAndPointer(line, srcStartPoint);
      const distPoint = getDistancePointer(line, srcStartPoint);

      if (distPoint.x >= srcStartPoint.x) angle -= Math.PI;
      else angle += Math.PI;
      if (distPoint.y >= srcStartPoint.y) angle += Math.PI / 2;
      else angle -= Math.PI / 2;

      const deltaX = d * Math.cos(angle);
      const deltaY = -d * Math.sin(angle);

      srcStartPoint = srcStartPoint.translate(deltaX, deltaY);
      srcEndPoint = srcEndPoint.translate(deltaX, deltaY);

      let snapInfo = "";
      if( snapPoint ) {
        let srcFlag = snapPoint.srcFlag
        let destFlag = snapPoint.destFlag
        
        if( direction !== _direction ) {
          srcFlag = invertFlag(srcFlag)
        }

        if( srcFlag === '0' ) {  // source element start point
          if( destFlag !== '0' && destFlag !== '1' ) {
            if( _direction ) {
              snapInfo = destFlag;
            } else {
              result.push({
                path: getHelperPath(srcStartPoint, dstStartPoint, helperLength),
                text: {
                  x: getTextPointer(srcStartPoint, dstStartPoint, helperLength).x,
                  y: getTextPointer(srcStartPoint, dstStartPoint, helperLength).y,
                  angle: getHelperTextAngle(srcStartPoint, dstStartPoint),
                  length: dist(srcStartPoint, dstStartPoint) * TILE_TRANSFORM_SCALE,
                  snapInfo: destFlag
                },
              });
            }
          }
        } else if( srcFlag === '1' ) { // source element end point
          if( destFlag !== '0' && destFlag !== '1' ) {
            if( !_direction ) {
              snapInfo = destFlag;
            } else {
              result.push({
                path: getHelperPath(srcEndPoint, dstEndPoint, helperLength),
                text: {
                  x: getTextPointer(srcEndPoint, dstEndPoint, helperLength).x,
                  y: getTextPointer(srcEndPoint, dstEndPoint, helperLength).y,
                  angle: getHelperTextAngle(srcEndPoint, dstEndPoint),
                  length: dist(srcEndPoint, dstEndPoint) * TILE_TRANSFORM_SCALE,
                  snapInfo: destFlag
                },
              });
            }
          }
        } else if( destFlag === '1/2' ) {
          const tmpStartPoint = _direction ? dstEndPoint : dstStartPoint;
          result.push({
            path: getHelperPath(tmpStartPoint, dstCenterPoint, helperLength),
            text: {
              x: getTextPointer(tmpStartPoint, dstCenterPoint, helperLength).x,
              y: getTextPointer(tmpStartPoint, dstCenterPoint, helperLength).y,
              angle: getHelperTextAngle(tmpStartPoint, dstCenterPoint),
              length: dist(tmpStartPoint, dstCenterPoint) * TILE_TRANSFORM_SCALE,
              snapInfo: destFlag
            },
          });
        } else {
          if( direction !== _direction ) {
            destFlag = invertFlag(destFlag);
          }
          if( destFlag === '0' ) {  // target element start point
            if( _direction ) {
              snapInfo = srcFlag;
            } else {
              result.push({
                path: getHelperPath(srcStartPoint, dstStartPoint, helperLength),
                text: {
                  x: getTextPointer(srcStartPoint, dstStartPoint, helperLength).x,
                  y: getTextPointer(srcStartPoint, dstStartPoint, helperLength).y,
                  angle: getHelperTextAngle(srcStartPoint, dstStartPoint),
                  length: dist(srcStartPoint, dstStartPoint) * TILE_TRANSFORM_SCALE,
                  snapInfo: srcFlag
                },
              });
            }
          } else if( destFlag === '1' ) {  // target element start point
            if( !_direction ) {
              snapInfo = invertFlag(srcFlag);
            } else {
              result.push({
                path: getHelperPath(srcEndPoint, dstEndPoint, helperLength),
                text: {
                  x: getTextPointer(srcEndPoint, dstEndPoint, helperLength).x,
                  y: getTextPointer(srcEndPoint, dstEndPoint, helperLength).y,
                  angle: getHelperTextAngle(srcEndPoint, dstEndPoint),
                  length: dist(srcEndPoint, dstEndPoint) * TILE_TRANSFORM_SCALE,
                  snapInfo: invertFlag(srcFlag)
                },
              });
            }
          }
        }
      }

      if( _direction ) {
        result.push({
          path: getHelperPath(srcStartPoint, dstStartPoint, helperLength),
          text: {
            x: getTextPointer(srcStartPoint, dstStartPoint, helperLength).x,
            y: getTextPointer(srcStartPoint, dstStartPoint, helperLength).y,
            angle: getHelperTextAngle(srcStartPoint, dstStartPoint),
            length: dist(srcStartPoint, dstStartPoint) * TILE_TRANSFORM_SCALE,
            snapInfo: snapInfo
          },
        });
      } else {
        result.push({
          path: getHelperPath(srcEndPoint, dstEndPoint, helperLength),
          text: {
            x: getTextPointer(srcEndPoint, dstEndPoint, helperLength).x,
            y: getTextPointer(srcEndPoint, dstEndPoint, helperLength).y,
            angle: getHelperTextAngle(srcEndPoint, dstEndPoint),
            length: dist(srcEndPoint, dstEndPoint) * TILE_TRANSFORM_SCALE,
            snapInfo: snapInfo
          },
        });
      }
    }
    return result;
  };
</script>

<g>
  {#each generateHelperPathWithText() as helperItem, j}
    <path
      d={helperItem.path}
      fill="none"
      stroke-linecap="round"
      stroke="grey"
      stroke-width="0.01"
    />
    {#if helperItem.text}
      <text
        transform="translate({helperItem.text.x}, {helperItem.text
          .y}) rotate({helperItem.text.angle})"
        style={`font-size: 0.2px;`}
      >
        <tspan x="0" text-anchor="middle">{`${Math.round(helperItem.text.length * 10) / 10}cm`}</tspan>
        {#if helperItem.text.snapInfo}
          <tspan x="0" dy="1.2em" text-anchor="middle">({helperItem.text.snapInfo})</tspan>
        {/if}
      </text>
    {/if}
  {/each}
</g>
