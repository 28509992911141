/**
 * Represents a point on the edge where another tile can be added.
 */
export default class AddPoint {
    tileId;
    edge;
    shapeInstance;
    location;
    range;
    onEdgeRange;
    static createForEdge(tileId, edge, shapeInstance) {
        return new AddPoint(tileId, edge, shapeInstance, 0, [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER], [-edge.edge.length / 2, edge.edge.length / 2]);
    }
    constructor(tileId, edge, shapeInstance, location, range, // in absolute units, NOT relative to the edge length
    onEdgeRange /// the range above clamped to the corners of the parent edge
    ) {
        this.tileId = tileId;
        this.edge = edge;
        this.shapeInstance = shapeInstance;
        this.location = location;
        this.range = range;
        this.onEdgeRange = onEdgeRange;
    }
    get length() {
        return this.range[1] - this.range[0];
    }
    get onEdgeLength() {
        return this.onEdgeRange[1] - this.onEdgeRange[0];
    }
    get segment() {
        return this.shapeInstance.segments[this.edge.index];
    }
    get locationAsSegmentParameter() {
        return this.location / this.segment.length + 0.5;
    }
}
