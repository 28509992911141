export class EdgeOccupancySegment {
    shape;
    range;
    edgeIndex;
    constructor(shape, range, edgeIndex) {
        this.shape = shape;
        this.range = range;
        this.edgeIndex = edgeIndex;
    }
    get sourceEdgeLength() {
        return this.shape.path.segments[this.edgeIndex].length;
    }
    get lengthInRelativeUnits() {
        return this.range[1] - this.range[0];
    }
    get lengthInAbsoluteUnits() {
        return (this.range[1] - this.range[0]) * this.sourceEdgeLength;
    }
    get rangeInAbsoluteUnits() {
        return [
            this.range[0] * this.sourceEdgeLength,
            this.range[1] * this.sourceEdgeLength,
        ];
    }
    get center() {
        return (this.range[0] + this.range[1]) * 0.5;
    }
}
export class EdgeOccupancy {
    segments;
    edgeIndex;
    constructor(segments, edgeIndex) {
        this.segments = segments;
        this.edgeIndex = edgeIndex;
    }
    freeSegments(sourceShape, ignoreTiles = [], range = [0, 1]) {
        let current = range[0];
        const result = new Array();
        for (const o of this.segments) {
            if (ignoreTiles.includes(o.shape)) {
                continue;
            }
            if (o.range[0] - current >= range[0] + 1e-5) {
                result.push(new EdgeOccupancySegment(sourceShape, [current, o.range[0]], o.edgeIndex));
            }
            current = o.range[1];
        }
        if (Math.abs(range[1] - current) > 1e-5) {
            result.push(new EdgeOccupancySegment(sourceShape, [current, range[1]], this.edgeIndex));
        }
        return result;
    }
}
